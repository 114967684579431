/**
 * LIST Default
 */
const dataTableNoDataText = "データがありません";
const dataTableFooterProps = {
  "items-per-page-text": "PAGE",
  "items-per-page-options": [10, 25, 50, 75, 100, -1],
};

export { dataTableNoDataText, dataTableFooterProps };
