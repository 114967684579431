import { setDisplayList } from "@/utils/selectUtility.js";
import { requiredRules, selectedRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "契約プラン",
  apiAddress: "/plan/",
  listSortBy: ["plan_code"],
  listSortDesc: [false],
  isCsvImport: true,
  showExpand: true,
  expandType: "structure",
  isStructureCsvImport: true,
};

const forms = [
  {
    column: "plan_code",
    label: "プランコード",
    type: "text",
    rules: [requiredRules],
    importUnique: true,
  },
  {
    column: "plan_name",
    label: "プラン",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "basic_charge",
    label: "基本料金",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "account_price",
    label: "アカウント単価",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "order_display",
    label: "申込表示",
    type: "select",
    items: setDisplayList(),
    default: setDisplayList(1),
    rules: [selectedRules],
    cols: 6,
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "プランコード",
    value: "plan_code",
    align: "start",
  },
  {
    text: "プラン",
    value: "plan_name",
  },
  {
    text: "基本料金",
    value: "basic_charge",
    type: "number",
    align: "end",
  },
  {
    text: "アカウント単価",
    value: "account_price",
    type: "number",
    align: "end",
  },
  {
    text: "申込表示",
    value: "order_display",
    type: "select",
    items: setDisplayList(),
    align: "center",
  },
  {
    text: "設定",
    value: "settingItem",
    align: "center",
    sortable: false,
    settingType: "structure",
  },
  {
    text: "構成",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  forms,
  headers,
};
