const setting = {
  title: "契約情報",
  apiAddress: "/contract/",
  listSortBy: ["contract_code"],
  listSortDesc: [false],
  isListReadonly: true,
  showExpand: true,
  expandType: "contractinformation",
};

const forms = [];

const postFixedValue = [
  {
    column: "customer",
    type: "props",
  },
];

const headers = [
  {
    text: "契約コード",
    value: "contract_code",
    parentKey: "availability_revision",
    child: "contract_plan",
    childKey: "contract_revision",
    align: "start",
  },
  {
    text: "区分",
    value: "contract_class_status",
    parentKey: "availability_revision",
    child: "contract_plan",
    childKey: "contract_revision",
    align: "center",
  },
  {
    text: "プラン",
    value: "plan_name",
    parentKey: "availability_revision",
    child: "contract_plan",
    childKey: "contract_revision",
    align: "start",
  },
  {
    text: "サイト名称",
    value: "site_name",
    align: "start",
  },
  {
    text: "サイト",
    value: "siteAddress",
    align: "center",
    sortable: false,
    handleKey: ["site_address"],
  },
  {
    text: "設定",
    value: "contractSite",
    align: "center",
    sortable: false,
    settingType: "contractsite",
  },
  {
    text: "支払",
    value: "contractIncentive",
    parentKey: "availability_revision",
    child: "contract_plan",
    childKey: "contract_revision",
    childHandleKey: "id",
    align: "center",
    sortable: false,
    settingType: "contractincentive",
    handleKey: ["is_contract_incentive"],
  },
  // {
  //   text: "解約",
  //   value: "contractSiteCancel",
  //   align: "center",
  //   sortable: false,
  //   settingType: "contractsitecancel",
  //   // handleKey: ["contract_class_status"],
  // },
  {
    text: "詳細",
    value: "data-table-expand",
    parentKey: "availability_revision",
    child: "contract_plan",
    childKey: "contract_revision",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
