<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container :class="$vuetify.breakpoint.xs ? '' : 'px-16 py-10'">
      <v-row v-if="isStaffAll">
        <v-col cols="12" class="pb-0">
          <v-icon color="mygenText--text" class="mb-2">bi-bell</v-icon>
          <SpanHeadline :propsText="'申し込み対応'" />
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col
              v-for="(row, index) in infoDataFirst"
              :key="'col-' + index + '-' + formKey"
              cols="12"
              md="4"
            >
              <CardHomeInfo
                :key="'card-info-' + index + '-' + formKey"
                :propsTitle="row.title"
                :propsComment="row.comment"
                :propsCount="Number(row.count)"
                :propsButtonLabel="row.button"
                :propsButtonPath="row.path"
                :propsButtonSearch="row.search"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="isStaffAll">
        <v-col
          cols="12"
          md="6"
          v-for="(row, index) in infoDataSecond"
          :key="'row-' + index + '-' + formKey"
        >
          <v-row class="pt-7">
            <v-col>
              <v-icon color="mygenText--text" class="mb-2">
                {{ row.icon }}
              </v-icon>
              <SpanHeadline :propsText="row.header" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CardHomeInfo
                :key="'card-info-' + index + '-' + formKey"
                :propsTitle="row.title"
                :propsComment="row.comment"
                :propsCount="Number(row.count)"
                :propsButtonLabel="row.button"
                :propsButtonPath="row.path"
                :propsButtonSearch="row.search"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pt-7 pb-10">
        <v-col cols="12" class="pb-0">
          <v-icon color="mygenText--text" class="mb-2"> bi-megaphone </v-icon>
          <SpanHeadline :propsText="'GENからのお知らせ'" />
        </v-col>
        <v-col cols="12">
          <CardInformation
            propsLabel="お知らせ"
            :propsInformationData="informationData"
            :key="'information-' + formKey"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";
import CardHomeInfo from "@/components/molecules/CardHomeInfo.vue";
import CardInformation from "@/components/molecules/CardInformation.vue";

export default defineComponent({
  name: "DivHomeStaff",

  components: { SpanHeadline, CardHomeInfo, CardInformation },

  setup() {
    const countData = reactive({
      reception: 0,
      setting: 0,
      support: 0,
      release: 0,
      coin: 0,
      user: 0,
    });
    const contractHeaders = ref([
      { text: "name", value: "name" },
      { text: "count", value: "count", align: "end" },
    ]);
    const infoDataFirst = reactive({
      reception: {
        title: "❶ 申込受付",
        comment: "新規・変更申込の受付を行います。",
        count: 0,
        button: "受付対応へ",
        path: "/contractflow-list",
        search: 0,
      },
      setting: {
        title: "❷ 設定対応",
        comment: "環境構築・設定変更の登録を行います。",
        count: 0,
        button: "設定対応へ",
        path: "/contractflow-list",
        search: 10,
      },
      release: {
        title: "❸ 公開対応",
        comment: "設定の公開を行います。",
        count: 0,
        button: "公開対応へ",
        path: "/contractflow-list",
        search: 20,
      },
      coin: {
        title: "COIN申込",
        comment: "GEN-COIN購入申込の受付を行います。",
        count: 0,
        button: "COIN受付へ",
        path: "/coincharge-list",
      },
      cancel: {
        title: "解約申込",
        comment: "解約申込の受付を行います。",
        count: 0,
        button: "解約対応へ",
        path: "/contractcancel-list",
      },
    });
    const infoDataSecond = reactive({
      bank: {
        icon: "bi-people",
        header: "お客様情報",
        title: "支払設定未登録",
        comment: "支払設定が未登録のお客様を表示します。",
        count: 0,
        button: "お客様リストへ",
        path: "/customer-list",
        search: 0,
      },
      support: {
        icon: "bi-envelope",
        header: "お問い合わせ情報",
        title: "未完了お問い合わせ",
        comment: "対応が未完了のお問い合わせを表示します。",
        count: 0,
        button: "お問い合わせリストへ",
        path: "/support-list",
      },
    });
    const informationData = ref([]);
    const progress = ref(false);
    const formKey = ref(createKey());

    const staffClass = computed(() => {
      return store.state.auth.staffClass;
    });
    const isStaffAll = computed(() => {
      if (staffClass.value === 999) return true;
      return false;
    });

    const loadData = () => {
      // api:スタッフ用情報取得
      api({
        method: "get",
        url: "/staffinformation/",
      }).then((response) => {
        // apiで取得したデータをdataにセットする
        const res = response.data;
        const countData = res["staff_information_data"];
        for (const key in countData) {
          if (infoDataFirst[key]) {
            infoDataFirst[key]["count"] = countData[key];
          } else if (infoDataSecond[key]) {
            infoDataSecond[key]["count"] = countData[key];
          }
        }
        formKey.value = createKey();
      });
      // api:申込情報取得
      api({
        method: "get",
        url: "/information/?information_display__gte=1",
      }).then((response) => {
        // apiで取得したデータをdataにセットする
        const res = response.data;
        const listData = [];
        if (res) {
          for (const row of res) {
            const line = {};
            line["date"] = row.information_date;
            line["type"] = row.information_type;
            line["title"] = row.information_title;
            line["link"] = row.information_link;
            if (row.information_display === 1) {
              listData.push(line);
            }
          }
        }
        informationData.value = listData;
        formKey.value = createKey();
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // スクロール位置調整
    window.scroll(0, 0);

    // データ取得
    loadData();

    return {
      countData,
      contractHeaders,
      infoDataFirst,
      infoDataSecond,
      informationData,
      progress,
      formKey,
      staffClass,
      isStaffAll,
    };
  },
});
</script>
