import { getDateObject } from "@/utils/utilities.js";

const setting = {
  title: "解約対応",
  apiAddress: "/cancelhandle/",
  apiListAddress: "/contractcancelbase/",
  isListReadonly: true,
  listSortBy: ["order_code"],
  listSortDesc: [false],
};

const headers = [
  {
    text: "申込コード",
    value: "cancel_order_code",
    align: "start",
  },
  {
    text: "受付",
    value: "contractCancelReceptionLink",
    reference: "cancel_order_status",
    align: "center",
    sortable: false,
    handleType: "contractcancelreception",
  },
  {
    text: "対応",
    value: "contractCancelSettingLink",
    reference: "cancel_order_status",
    align: "center",
    sortable: false,
    handleType: "contractcancelsetting",
  },
  {
    text: "公開",
    value: "contractCancelReleaseLink",
    reference: "cancel_order_status",
    align: "center",
    sortable: false,
    handleType: "contractcancelrelease",
  },
  {
    text: "取消",
    value: "contractCancelDeleteLink",
    reference: "cancel_order_status",
    align: "center",
    sortable: false,
    handleType: "contractcancelreception",
  },
  {
    text: "ステータス",
    value: "cancel_order_status",
    align: "center",
  },
  {
    text: "お客様コード",
    value: "contract___customer_reference___customer_code",
    align: "start",
  },
  {
    text: "お客様名",
    value: "contract___customer_reference___customer_name",
    align: "start",
  },
  {
    text: "契約コード",
    value: "contract___contract_identifier",
    align: "center",
  },
  {
    text: "申込日",
    value: "cancel_order_date",
    align: "center",
  },
  {
    text: "受付日",
    value: "reception_date",
    align: "center",
  },
];

const searchDateFrom = {
  label: "申込日（以降）",
  column: "cancel_order_date",
  menu: false,
  default: getDateObject("first", -1),
  formula: "gte", // 以上
};

const searchSelect = {
  label: "区分",
  column: "cancel_order_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "受付未対応" },
    { value: 10, text: "受付済" },
    { value: 20, text: "設定済" },
    { value: 500, text: "未公開" }, // 500はfilterで設定
    { value: 50, text: "公開済" },
  ],
  filter: {
    500: {
      value: 50,
      formula: "lt", // 未満
      // formula: "lte", // 以下
    },
  },
  value: 500,
};

export default {
  setting,
  headers,
  searchDateFrom,
  searchSelect,
};
