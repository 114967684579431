<template>
  <v-card flat rounded="lg" class="mygenCard pa-5">
    <v-card-text
      v-for="(row, index) in informationData"
      :key="'cardText-' + index"
      class="pa-2"
    >
      <v-row>
        <v-col
          cols="12"
          lg="1"
          md="2"
          class="mygenCard mygenText--text"
          style="white-space: nowrap"
        >
          <p>{{ row["date"] }}</p>
        </v-col>
        <v-col cols="12" lg="2" md="2" px-1 class="mygenCard text-center">
          <ChipStatus
            :key="'chip-' + index"
            :propsType="'information_type'"
            :propsValue="row['type']"
            style="white-space: nowrap"
          />
        </v-col>
        <v-col cols="12" lg="9" md="8" class="mygenCard mygenText--text pl-3">
          <span style="white-space: normal">
            {{ row["title"] }}
            <a
              v-if="row['link']"
              style="text-decoration: none"
              :href="row['link']"
              target="_blank"
              class="ml-2"
            >
              詳しくはこちら>>>
            </a>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="!informationData.length">
      {{ nonMessage }}
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import ChipStatus from "@/components/atoms/ChipStatus.vue";

export default defineComponent({
  name: "CardInformation",

  components: { ChipStatus },

  props: {
    propsLabel: { type: String, required: true },
    propsInformationData: { type: Array, default: () => [], required: true },
  },

  setup(props) {
    const label = ref(props.propsLabel);
    const informationData = ref(props.propsInformationData);
    const nonMessage = "現在" + label.value + "はありません。";

    return {
      informationData,
      nonMessage,
    };
  },
});
</script>
