import { getDateObject } from "@/utils/utilities.js";
import {
  requiredRules,
  selectedRules,
  dateRules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "契約更新",
  originalTitle: "契約更新",
  apiAddress: "/contractrenewalsingle/",
  formComment: [
    "現在のマスタデータに基づいて契約更新データを作成します。",
    "個別更新の場合は変更費用は¥0となります。",
  ],
};

const forms = [
  {
    column: "renewal_date",
    label: "更新日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "order_renewal",
    label: "契約を更新する",
    type: "checkbox",
    rules: [selectedRules],
  },
];

const headers = [];

export default {
  setting,
  forms,
  headers,
};
