import { setPrefectureList } from "@/utils/selectUtility.js";
import {
  requiredRules,
  zipCodeRules,
  selectedRules,
  telRules,
  charLength100Rules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "ブラザー",
  apiAddress: "/brother/",
  listSortBy: ["brother_code"],
  listSortDesc: [false],
  isCsvImport: true,
  showExpand: true,
  expandType: "brother",
};

const forms = [
  {
    column: "brother_code",
    label: "ブラザーコード",
    type: "text",
    rules: [requiredRules],
    importUnique: true,
  },
  {
    column: "brother_name",
    label: "ブラザー名",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "person_in_charge",
    label: "担当者名",
    type: "text",
    rules: [requiredRules, charLength100Rules],
  },
  {
    column: "zip_code",
    label: "郵便番号",
    type: "text",
    rules: [requiredRules, zipCodeRules],
    cols: 6,
    hint: "0009999（ハイフンなし）",
  },
  {
    column: "prefecture",
    label: "都道府県",
    type: "select",
    items: setPrefectureList(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "address_1",
    label: "住所（市町村・番地）",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "address_2",
    label: "住所（建物名）",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "tel",
    label: "電話番号",
    type: "text",
    rules: [requiredRules, telRules],
    cols: 6,
    hint: "0311112222（ハイフンなし）",
  },
  {
    column: "registration_number",
    label: "登録番号",
    type: "text",
    rules: [charLength100Rules],
  },
  {
    column: "remarks",
    label: "備考",
    type: "text",
    rules: [charLength200Rules],
  },
  // {
  //   column: "user",
  //   label: "アカウント",
  //   type: "selectUser",
  //   items: [],
  //   rules: [],
  //   exportColumn: "user_reference___email",
  //   hint: "設定に注意してください！ アカウントユーザーにBrother情報が閲覧可能になります。",
  // },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "ブラザーコード",
    value: "brother_code",
    align: "start",
  },
  {
    text: "ブラザー名",
    value: "brother_name",
    align: "start",
  },
  {
    text: "担当者名",
    value: "person_in_charge",
  },
  {
    text: "コネクト",
    value: "brotherConnection",
    align: "center",
    sortable: false,
    handleKey: ["user"],
  },
  {
    text: "切断",
    value: "brotherDisconnect",
    align: "center",
    sortable: false,
    handleKey: ["user"],
  },
  {
    text: "アカウント",
    value: "user_reference___email",
  },
  {
    text: "担当社数",
    value: "customer_count",
    type: "number",
    align: "end",
  },
  {
    text: "詳細",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  forms,
  headers,
};
