import { getSelectObject } from "@/utils/selectUtility.js";
import { charLength200Rules } from "@/utils/ruleUtility.js";

const setting = {
  title: "解約対応",
  originalTitle: "受付登録",
  apiAddress: "/cancelhandle/",
  isListReadonly: true,
};

const forms = [
  {
    column: "cancel_order_status",
    label: "受付対応",
    type: "radio",
    items: getSelectObject("cancel_order_status", "cancelreception"),
    rules: [],
  },
  {
    column: "staff_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const postFixedValue = [
  {
    column: "reception_date",
    type: "today",
    equalOrRules: [
      ["cancel_order_status", 10],
      ["cancel_order_status", 90],
    ],
  },
];

const headers = [];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
