import { getSelectObject } from "@/utils/selectUtility.js";

const setting = {
  title: "契約更新公開",
  originalTitle: "契約更新公開",
  apiAddress: "/contractrenewal/",
  isListReadonly: true,
};

const forms = [
  {
    column: "contract_renewal_status",
    label: "設定対応",
    type: "radio",
    items: getSelectObject("contract_status", "renewal"),
    rules: [],
  },
];

const postFixedValue = [
  {
    column: "release_date",
    type: "today",
    equalRules: ["order_status", 50],
  },
];

const headers = [];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
