<template>
  <v-expansion-panels v-model="panel" accordion hover multiple>
    <v-expansion-panel>
      <PanelHeader :propsLabel="'お客様情報'" />
      <v-expansion-panel-content>
        <ContainerCustomerInfo
          :key="'customer-' + formKey"
          :propsFormsFirst="formsFirst"
          :propsFormsSecond="formsSecond"
        />
        <ContainerCustomerSetting
          :key="'customer-setting-' + formKey"
          :propsCustomerData="customerData"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <PanelHeader :propsLabel="'申込内容'" />
      <v-expansion-panel-content>
        <v-container>
          <v-row align="center">
            <v-col>
              <ContainerCoinChargeDetail
                :key="'contract-coincharge-detail-' + formKey"
                :propsData="tableData"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <PanelHeader :propsLabel="'対応内容'" />
      <v-expansion-panel-content>
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          :no-data-text="dataTableNoDataText"
          hide-default-footer
        >
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import {
  settingDictionaryForms,
  setResponseApplyFormsDict,
  setApiToListData,
} from "@/utils/formUtility.js";
import { setPaymentMethod } from "@/utils/selectUtility.js";
import { dataTableNoDataText } from "@/utils/listUtility.js";
import PanelHeader from "@/components/atoms/PanelHeader.vue";
import ContainerCustomerInfo from "@/components/molecules/ContainerCustomerInfo.vue";
import ContainerCustomerSetting from "@/components/molecules/ContainerCustomerSetting.vue";
import ContainerCoinChargeDetail from "@/components/molecules/ContainerCoinChargeDetail.vue";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";
import FormsCustomer from "@/forms/customer.js";

export default defineComponent({
  name: "PanelCoinCharge",

  components: {
    PanelHeader,
    ContainerCoinChargeDetail,
    ContainerCustomerInfo,
    ContainerCustomerSetting,
  },

  props: {
    propsContractCoinId: { type: String, require: true },
  },

  setup(props) {
    const contractCoinId = ref(props.propsContractCoinId);
    let formsFirst = reactive(settingDictionaryForms(FormsCustomer.forms));
    let formsSecond = reactive(
      settingDictionaryForms(FormsInvoiceAddress.forms)
    );
    let tableData = ref({});
    let customerData = ref({});
    const panel = ref([1]);
    const formKey = ref(createKey());

    const headers = ref([
      {
        text: "対応日",
        value: "reception_date",
        align: "start",
        sortable: false,
      },
      {
        text: "支払方法",
        value: "coin_payment_method",
        type: "select",
        items: setPaymentMethod(),
        align: "center",
        sortable: false,
      },
      {
        text: "インセンティブ",
        value: "incentive_rate",
        align: "center",
        sortable: false,
      },
      {
        text: "受付コメント",
        value: "reception_comment",
        align: "start",
        sortable: false,
      },
    ]);
    const options = reactive({
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    });
    const items = ref([]);

    const loadData = () => {
      api({
        method: "get",
        url: "/contractcoin/?id=" + contractCoinId.value,
      }).then((response) => {
        const res = response.data[0];
        const orderCustomerData = res["customer_reference"];
        tableData.value = res;
        customerData.value = orderCustomerData;
        // 契約者情報セット
        formsFirst = reactive(
          setResponseApplyFormsDict(orderCustomerData, formsFirst)
        );
        // 請求先情報セット
        formsSecond = reactive(
          setResponseApplyFormsDict(orderCustomerData, formsSecond)
        );

        // 対応内容セット
        const itemData = setApiToListData([res], headers.value);
        items.value = itemData["listData"];

        formKey.value = createKey();
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      contractCoinId,
      formsFirst,
      formsSecond,
      tableData,
      customerData,
      panel,

      dataTableNoDataText,
      headers,
      options,
      items,

      formKey,
    };
  },
});
</script>
