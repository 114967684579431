import { getMonthObject } from "@/utils/utilities.js";
import {
  requiredRules,
  dateMonthRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "インセンティブアジャスト",
  apiAddress: "/paymentadjustment/",
  listSortBy: ["payment_adjustment_date"],
  listSortDesc: [false],
};

const forms = [
  {
    column: "payment_adjustment_date",
    label: "対象月",
    type: "dateMonthFirst",
    menu: false,
    default: getMonthObject(),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
  },
  {
    column: "payment_adjustment_name",
    label: "調整名",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "payment_adjustment_charge",
    label: "調整金額",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "payment_adjustment_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const postFixedValue = [
  {
    column: "brother",
    type: "props",
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "調整コード",
    value: "payment_adjustment_code",
  },
  {
    text: "対象月",
    value: "payment_adjustment_date",
    align: "center",
  },
  {
    text: "調整名",
    value: "payment_adjustment_name",
  },
  {
    text: "対象金額",
    value: "payment_adjustment_charge",
    type: "number",
    align: "end",
  },
  {
    text: "コメント",
    value: "payment_adjustment_comment",
  },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
