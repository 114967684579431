<template>
  <v-container>
    <v-row v-if="isSetting" class="mb-7">
      <v-col cols="12" v-if="isFlow">
        <v-card
          class="mx-auto"
          width="100%"
          style="overflow-wrap: anywhere !important"
          flat
        >
          <v-card-text class="pa-3">
            <v-btn
              block
              color="primary"
              outlined
              rounded
              class="font-weight-bold"
              @click="copyText(copyValue)"
            >
              {{ tooltipCommentCopy }}
              <v-icon right dark> mdi-content-copy </v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-for="(row, index) in settingData"
        :key="'col-setting-' + index"
        cols="12"
        :md="row['cols'] ? row['cols'] : 6"
        class="py-1"
      >
        <v-card
          class="mx-auto"
          width="100%"
          outlined
          style="overflow-wrap: anywhere !important"
        >
          <v-card-text class="pa-3">
            <div class="text-caption">{{ row["title"] }}</div>
            <v-tooltip v-if="row['isAccount']" top>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="copyText(row['value'])"
                  v-bind="attrs"
                  v-on="on"
                  class="text-body-1 primary--text font-weight-bold text-decoration-none"
                >
                  {{ row["value"] }}
                </a>
              </template>
              <span>{{ tooltipAccountCopy }}</span>
            </v-tooltip>
            <div v-else class="text-body-1 mygenText--text">
              {{ row["value"] }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-for="(row, index) in confirmData"
      :key="'row-' + index"
      :class="row[0] === 'label' ? 'mygenBase mx-auto' : 'mx-auto'"
      style="max-width: 800px"
    >
      <v-col
        :class="
          row[0] === 'label'
            ? 'py-1 mygenBase mygenText--text'
            : row[0] === 'dataCoupon'
            ? 'info--text'
            : 'mygenText--text'
        "
        cols="12"
        md="5"
      >
        <span
          :class="[
            row[0] === 'data' || row[0] === 'dataCoupon'
              ? 'font-weight-bold'
              : row[0] === 'total' || row[0] === 'totalCoupon'
              ? 'font-weight-bold primary--text'
              : row[0] === 'totalSub'
              ? 'font-weight-bold secondary--text'
              : row[0] === 'totalDate'
              ? 'text-subtitle-1 font-weight-bold secondary--text'
              : 'text-caption',
          ]"
        >
          {{ row[1] }}
          <v-chip
            v-if="row[3] === true"
            color="pink"
            x-small
            dark
            class="font-weight-bold ml-1"
          >
            変更
          </v-chip>
          <br v-if="row[2]" />
          <div v-if="row[2]">
            <span class="text-caption">{{ row[2] }}</span>
          </div>
        </span>
      </v-col>
      <v-col
        :class="
          row[0] === 'label'
            ? 'py-1 mygenBase mygenText--text'
            : row[0] === 'totalCoupon'
            ? 'primary--text'
            : row[0] === 'dataCoupon'
            ? 'info--text'
            : 'mygenText--text'
        "
        cols="6"
        md="2"
      >
        <div align="end">{{ row[4] }}</div>
      </v-col>
      <v-col
        :class="
          row[0] === 'label'
            ? 'py-1 mygenBase mygenText--text'
            : row[0] === 'totalCoupon'
            ? 'primary--text'
            : row[0] === 'dataCoupon'
            ? 'info--text'
            : 'mygenText--text'
        "
        cols="6"
        md="2"
      >
        <div align="end">{{ row[5] }}</div>
      </v-col>
      <v-col
        :class="
          row[0] === 'label'
            ? 'py-1 mygenBase mygenText--text'
            : 'mygenText--text'
        "
        cols="12"
        md="3"
      >
        <div
          align="end"
          :class="[
            row[0] === 'total' || row[0] === 'totalCoupon'
              ? 'text-right font-weight-bold primary--text'
              : row[0] === 'totalSub'
              ? 'text-right font-weight-bold secondary--text'
              : 'text-right font-weight-bold',
          ]"
        >
          <span v-if="row[6]" class="text-body-1 font-weight-bold">
            {{ row[6] }}
          </span>
          <span v-if="row[6]" class="text-body-2 pr-2">円</span>
          <br v-if="row[7]" />
          <div v-if="row[7]" style="white-space: nowrap">
            <span class="text-caption">（税込</span>
            <span class="text-body-2">{{ row[7] }}</span>
            <span class="text-caption">円）</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import { computed, defineComponent, reactive, ref } from "vue";
import {
  convertNumComma,
  convertOptionFieldToDict,
  copyToClipboard,
} from "@/utils/utilities.js";
import {
  setInvoiceType,
  setPaymentMethod,
  getSelectText,
} from "@/utils/selectUtility.js";

export default defineComponent({
  name: "ContainerContractDetail",

  props: {
    propsData: { type: Object, required: true },
    propsSetting: { type: Boolean, default: false },
    propsFlow: { type: Boolean, default: false },
    propsOrder: { type: Boolean, default: false },
    propsChange: { type: Boolean, default: false },
    propsShowCharge: { type: Boolean, default: false },
  },

  setup(props) {
    const tableData = reactive(props.propsData);
    const isSetting = ref(props.propsSetting);
    const isFlow = ref(props.propsFlow);
    const isOrder = ref(props.propsOrder);
    const isChange = ref(props.propsChange);
    const isShowCharge = ref(props.propsShowCharge);
    const settingData = ref([]);
    const confirmData = ref([]);
    const staffDiffArray = [];
    const dummyText = "-----";

    const copyValueArray = [];
    const copyOptionArray = [];
    const copyChargeArray = [];
    const copyValue = ref("");
    const tooltipCommentCopy = "申込内容をクリップボードにコピー";
    const tooltipAccountCopy = "アカウントをクリップボードにコピー";

    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const optionVariable = computed(() => {
      if (isOrder.value) {
        return "option_fields";
      }
      return "contract_option";
    });
    const coinVariable = computed(() => {
      if (isOrder.value) {
        return "coin_fields";
      }
      return "contract_coin";
    });

    const setTableData = (res) => {
      let isBaseInfo = false;
      if (res && "base_info" in res) {
        isBaseInfo = true;
      }
      let isBaseContract = false;
      if (res && "base_contract" in res) {
        isBaseContract = true;
      }
      // 設定情報
      if (isSetting.value) {
        const contractCode = res["contract_code"] || dummyText;
        const orderDate = res["order_date"] || dummyText;
        const startDate = res["start_date"] || dummyText;
        const contractDate = res["contract_date"] || dummyText;
        const calculatorDate =
          res["invoice_calculator_start_date"] || dummyText;
        let invoiceType = dummyText;
        if (isFlow.value) {
          if (res["contract"]) {
            invoiceType = getSelectText(
              res["contract"]["invoice_type"],
              setInvoiceType()
            );
          }
        } else {
          if (res["invoice_type"] === 0 || res["invoice_type"]) {
            invoiceType = getSelectText(res["invoice_type"], setInvoiceType());
          }
        }
        let commissionPaymentMethod = dummyText;
        if (
          res["commission_payment_method"] === 0 ||
          res["commission_payment_method"]
        ) {
          commissionPaymentMethod = getSelectText(
            res["commission_payment_method"],
            setPaymentMethod()
          );
        }
        let firstRate = dummyText;
        if (res["first_rate"]) {
          firstRate = res["first_rate"] + "%";
        }
        let monthRate = dummyText;
        if (res["month_rate"]) {
          monthRate = res["month_rate"] + "%";
        }
        let incentiveDiscountCharge = dummyText;
        if (res["incentive_discount_charge"]) {
          incentiveDiscountCharge =
            convertNumComma(res["incentive_discount_charge"]) + "円";
        }
        const staffComment = res["staff_comment"] || dummyText;
        let customerCode = dummyText;
        let customerName = dummyText;
        let siteAddress = dummyText;
        let siteName = dummyText;
        if (res["contract"] && res["contract"]["customer_reference"]) {
          customerCode = res["contract"]["customer_reference"]["customer_code"];
          customerName = res["contract"]["customer_reference"]["customer_name"];
        }
        if (res["contract"] && res["contract"]["site_address"] !== null) {
          siteAddress = res["contract"]["site_address"];
        }
        settingData.value = [
          { title: "契約コード", value: contractCode, cols: 12 },
          { title: "申込日", value: orderDate, cols: 4 },
          { title: "利用開始希望日", value: startDate, cols: 4 },
          { title: "課金開始月", value: contractDate, cols: 4 },
          { title: "請求計算開始日", value: calculatorDate, cols: 4 },
          { title: "請求単位", value: invoiceType, cols: 4 },
          {
            title: "初期・変更費用支払方法",
            value: commissionPaymentMethod,
            cols: 4,
          },
          { title: "初回インセンティブ", value: firstRate, cols: 4 },
          { title: "月度インセンティブ", value: monthRate, cols: 4 },
          {
            title: "クーポンのインセンティブ対象",
            value: incentiveDiscountCharge,
            cols: 4,
          },
        ];
        if (isFlow.value) {
          settingData.value.push({
            title: "サイトアドレス",
            value: siteAddress,
            cols: 6,
          });
          if (res["contract"] && res["contract"]["site_name"] !== null) {
            siteName = res["contract"]["site_name"];
          }
          settingData.value.push({
            title: "サイト名称",
            value: siteName,
            cols: 6,
          });
        }
        if (isStaff.value) {
          settingData.value.push({
            title: "コメント",
            value: staffComment,
            cols: 12,
          });
        }

        if (res["order_type"] === 10) {
          copyValueArray.push("【MyGEN：新規申込】");
        } else {
          copyValueArray.push("【MyGEN：変更申込】");
        }
        copyValueArray.push("お客様コード：" + customerCode);
        copyValueArray.push("お客様名：" + customerName);
        copyValueArray.push("契約コード：" + contractCode);
        copyValueArray.push("申込日：" + orderDate);
        copyValueArray.push("利用開始希望日：" + startDate);
        copyValueArray.push("サイトアドレス：" + siteAddress);
        copyValueArray.push("サイト名称：" + siteName);
        copyValueArray.push("コメント：" + staffComment);

        // 後方で差分データを追加している
      }

      // クーポン
      let isCouponFirst = false;
      let isCouponMonthly = false;
      let isCouponLimitation = false;
      if (res["coupon_class"] === -10) {
        isCouponFirst = true;
      } else if (res["coupon_class"] === -20) {
        isCouponMonthly = true;
      } else if (res["coupon_class"] > 0) {
        isCouponLimitation = true;
      }
      let isCouponOrder = false;
      if (res["order_coupon_class"] !== 0) {
        isCouponOrder = true;
      }
      // 表示データ作成
      confirmData.value = [
        ["label", "契約プラン：", "", "", "", "", "", ""],
        [
          "data",
          res["plan_name"],
          "",
          isChange.value && isBaseInfo && res["base_info"]["plan_diff"],
          "@" + convertNumComma(res["basic_charge"]),
          "x" + 1,
          convertNumComma(res["basic_charge"]),
          convertNumComma(res["basic_charge_including_tax"]),
        ],
        ["label", "アカウント数：", "", "", "", "", "", ""],
        [
          "data",
          "アカウント",
          "",
          isChange.value && isBaseInfo && res["base_info"]["account_diff"],
          "@" + convertNumComma(res["account_price"]),
          "x" + convertNumComma(res["account_quantity"]),
          convertNumComma(res["total_account_charge"]),
          convertNumComma(res["total_account_charge_including_tax"]),
        ],
        ["label", "契約オプション：", "", "", "", "", "", ""],
      ];
      // 契約プランの変更情報
      if (
        isChange.value &&
        isBaseInfo &&
        isBaseContract &&
        res["base_info"]["plan_diff"]
      ) {
        staffDiffArray.push(
          "プラン変更：" +
            res["base_contract"]["plan_name"] +
            " → " +
            res["plan_name"]
        );
      }
      // アカウント数の変更情報
      if (
        isChange.value &&
        isBaseInfo &&
        isBaseContract &&
        res["base_info"]["account_diff"]
      ) {
        staffDiffArray.push(
          "アカウント数変更：" +
            res["base_contract"]["account_quantity"] +
            " → " +
            res["account_quantity"]
        );
      }
      let isOption = false;
      // 標準オプション
      const structureData = res["structure"];
      for (const key in structureData) {
        const optionId = structureData[key]["option_id"];
        const isOptionDiffInfo =
          isBaseInfo &&
          res["base_info"]["option_diff_info"][optionId] !== undefined;
        if (structureData[key]["structure_class"] === 10) {
          confirmData.value.push([
            "data",
            structureData[key]["option_name"],
            "",
            isChange.value &&
              isBaseInfo &&
              isOptionDiffInfo &&
              res["base_info"]["option_diff_info"][optionId]["option_diff"],
            "標準",
            "x1",
            convertNumComma(0),
          ]);
          isOption = true;
        }
      }
      // 追加オプション
      const optionArray = [];
      const optionAddData = res[optionVariable.value];
      const optionBaseData = isBaseContract
        ? convertOptionFieldToDict(res["base_contract"][optionVariable.value])
        : {};
      for (const key in optionAddData) {
        const optionId = optionAddData[key]["option"];
        const isOptionDiffInfo =
          isBaseInfo &&
          res["base_info"]["option_diff_info"][optionId] !== undefined;
        confirmData.value.push([
          "data",
          optionAddData[key]["option_name"],
          "",
          isChange.value &&
            isBaseInfo &&
            isOptionDiffInfo &&
            res["base_info"]["option_diff_info"][optionId]["option_diff"],
          optionAddData[key]["option_default"] === true
            ? "標準"
            : "@" + convertNumComma(optionAddData[key]["option_fee"]),
          "x" + optionAddData[key]["option_quantity"],
          convertNumComma(optionAddData[key]["option_charge"]),
          convertNumComma(optionAddData[key]["option_charge_including_tax"]),
        ]);
        optionArray.push(key);
        if (optionAddData[key]["option_default"] === false) {
          copyOptionArray.push(optionAddData[key]["option_name"]);
        }
        // オプションの変更情報
        if (
          isChange.value &&
          isBaseInfo &&
          isOptionDiffInfo &&
          res["base_info"]["option_diff_info"][optionId]["option_diff"]
        ) {
          let optionBaseQuantity = 0;
          if (optionId in optionBaseData) {
            optionBaseQuantity = optionBaseData[optionId]["option_quantity"];
          }
          staffDiffArray.push(
            "オプション変更（" +
              optionAddData[key]["option_name"] +
              "）：" +
              optionBaseQuantity +
              " → " +
              optionAddData[key]["option_quantity"]
          );
        }
        isOption = true;
      }
      if (
        isChange.value &&
        isBaseInfo &&
        res["base_info"]["option_del_info"] !== undefined
      ) {
        const optionDelInfoData = res["base_info"]["option_del_info"];
        for (const key in optionDelInfoData) {
          if (optionArray.indexOf(key) === -1) {
            confirmData.value.push([
              "data",
              optionDelInfoData[key]["option_name"],
              "",
              true,
              "@" + convertNumComma(optionDelInfoData[key]["option_fee"]),
              "x" + 0,
              0,
            ]);
            // オプションの変更情報
            if (isChange.value && isBaseInfo && isBaseContract) {
              staffDiffArray.push(
                "オプション削除：" + optionDelInfoData[key]["option_name"]
              );
            }
            isOption = true;
          }
        }
      }
      if (!isOption) {
        confirmData.value.push([
          "data",
          "-----",
          "",
          "-----",
          "-----",
          "-----",
          0,
        ]);
      }
      confirmData.value.push(
        ["label", "サポート料金：", "", "", "", "", "", ""],
        [
          "data",
          "GMSPプログラム",
          "（契約プラン + 契約オプション）x GEN-Support " +
            convertNumComma(res["support_rate"]) +
            "%",
          isChange.value && isBaseInfo && res["base_info"]["support_rate_diff"],
          convertNumComma(res["support_rate"]) + "%",
          "x1",
          convertNumComma(res["support_charge"]),
          convertNumComma(res["support_charge_including_tax"]),
        ]
      );
      copyChargeArray.push(
        "サポート料金：" +
          convertNumComma(res["support_charge"]) +
          "（" +
          convertNumComma(res["support_charge_including_tax"]) +
          "）"
      );
      // サポートレートの変更情報
      if (
        isChange.value &&
        isBaseInfo &&
        res["base_info"]["support_rate_diff"]
      ) {
        staffDiffArray.push(
          "サポートレート変更：" +
            res["base_contract"]["support_rate"] +
            " → " +
            res["support_rate"]
        );
      }

      let coinData = {};
      if (isOrder.value) {
        coinData = res["coin_fields"];
      } else if (res["contract_coin"]) {
        coinData = res["contract_coin"][0];
      }
      if (
        isShowCharge.value &&
        coinData !== undefined &&
        coinData["coin_quantity"] !== 0
      ) {
        confirmData.value.push(
          ["label", "GEN-COIN：", "", "", "", "", "", ""],
          [
            "data",
            "GEN-COIN",
            "",
            "",
            "@" + convertNumComma(coinData["coin_rate"]),
            "x" + convertNumComma(coinData["coin_quantity"]),
            convertNumComma(res["total_coin_charge"]),
            convertNumComma(res["total_coin_charge_including_tax"]),
          ]
        );
      }
      if (isShowCharge.value && res["order_type"] === 10) {
        // 新規登録の時
        confirmData.value.push(
          ["label", "設定費用：", "", "", "", "", "", ""],
          [
            "data",
            "初回設定費用",
            " 月額料金１ヶ月分",
            "",
            "",
            "",
            convertNumComma(res["first_commission_charge"]),
            convertNumComma(res["first_commission_charge_including_tax"]),
          ]
        );
        copyChargeArray.push(
          "設定費用：" +
            convertNumComma(res["first_commission_charge"]) +
            "（" +
            convertNumComma(res["first_commission_charge_including_tax"]) +
            "）"
        );
      } else if (
        isShowCharge.value &&
        (res["order_type"] === 20 ||
          res["order_type"] === 21 ||
          res["order_type"] === 22)
      ) {
        // 契約変更の時
        confirmData.value.push(
          ["label", "設定費用：", "", "", "", "", "", ""],
          [
            "data",
            "設定変更費用",
            "月額費用の差分料金（最低5,000円）",
            "",
            "",
            "",
            convertNumComma(res["change_commission_charge"]),
            convertNumComma(res["change_commission_charge_including_tax"]),
          ]
        );
        copyChargeArray.push(
          "設定費用：" +
            convertNumComma(res["change_commission_charge"]) +
            "（" +
            convertNumComma(res["change_commission_charge_including_tax"]) +
            "）"
        );
      }
      if (
        (isShowCharge.value && isCouponFirst) ||
        (isShowCharge.value && (isCouponMonthly || isCouponLimitation))
      ) {
        let discountCharge = res["discount_charge_of_first"];
        let discountChargeIncludingTax =
          res["discount_charge_of_first_including_tax"];
        let distountUnit = "x1";
        if (isCouponMonthly || isCouponLimitation) {
          discountCharge = res["discount_charge_of_monthly"];
          discountChargeIncludingTax =
            res["discount_charge_of_monthly_including_tax"];
          distountUnit = "毎月";
          if (res["coupon_class"] > 0) {
            distountUnit = res["coupon_class"] + "ヶ月";
          }
        }
        confirmData.value.push(
          ["label", "クーポン：", "", "", "", "", "", ""],
          [
            "data",
            res["coupon_name"],
            "",
            isChange.value && isBaseInfo && res["base_info"]["coupon_diff"],
            "@" + convertNumComma(discountCharge),
            distountUnit,
            convertNumComma(discountCharge),
            convertNumComma(discountChargeIncludingTax),
          ]
        );
      }
      if (isStaff.value && isCouponOrder) {
        let distountOrderUnit = "";
        if (res["order_coupon_class"] > 0) {
          distountOrderUnit = res["coupon_class"] + "ヶ月";
        } else if (res["order_coupon_class"] === -10) {
          distountOrderUnit = "初回";
        } else if (res["order_coupon_class"] === -20) {
          distountOrderUnit = "月毎";
        }
        confirmData.value.push(
          ["label", "申込クーポン（確認用）：", "", "", "", "", "", ""],
          [
            "dataCoupon",
            res["order_coupon_name"] + " (" + res["order_coupon_code"] + ")",
            "",
            "",
            "@" + convertNumComma(res["order_discount_charge"]),
            distountOrderUnit,
            "",
            "",
          ]
        );
      }
      // お支払い金額
      confirmData.value.push(
        ["label", "お支払い金額：", "", "", "", "", "", ""],
        [
          isCouponMonthly || isCouponLimitation ? "totalSub" : "total",
          "月額料金",
          "",
          "",
          "",
          "",
          convertNumComma(res["monthly_charge"]),
          convertNumComma(res["monthly_charge_including_tax"]),
        ]
      );
      copyChargeArray.push(
        "月額料金：" +
          convertNumComma(res["monthly_charge"]) +
          "（" +
          convertNumComma(res["monthly_charge_including_tax"]) +
          "）"
      );
      if (isShowCharge.value && (isCouponMonthly || isCouponLimitation)) {
        let monthlyCharge = res["monthly_charge_after_discount"];
        let monthlyChargeIncludingTax =
          res["monthly_charge_after_discount_including_tax"];

        confirmData.value.push([
          "totalCoupon",
          "月額料金（クーポン適用）",
          "",
          "",
          "",
          "",
          convertNumComma(monthlyCharge),
          convertNumComma(monthlyChargeIncludingTax),
        ]);
        copyChargeArray.push(
          "月額料金（クーポン適用）：" +
            convertNumComma(monthlyCharge) +
            "（" +
            convertNumComma(monthlyChargeIncludingTax) +
            "）"
        );
      }
      if (isShowCharge.value && res["order_type"] === 10) {
        // 新規登録の時
        confirmData.value.push([
          isCouponFirst ? "totalSub" : "total",
          "初期費用",
          "GEN-COIN + 初回設定費用",
          "",
          "",
          "",
          convertNumComma(res["first_charge"]),
          convertNumComma(res["first_charge_including_tax"]),
        ]);
        copyChargeArray.push(
          "初期費用：" +
            convertNumComma(res["first_charge"]) +
            "（" +
            convertNumComma(res["first_charge_including_tax"]) +
            "）"
        );
      } else if (
        isShowCharge.value &&
        (res["order_type"] === 20 ||
          res["order_type"] === 21 ||
          res["order_type"] === 22)
      ) {
        // 契約変更の時
        confirmData.value.push([
          isCouponFirst ? "totalSub" : "total",
          "変更費用",
          "GEN-COIN + 設定変更費用",
          "",
          "",
          "",
          convertNumComma(res["change_charge"]),
          convertNumComma(res["change_charge_including_tax"]),
        ]);
        copyChargeArray.push(
          "変更費用：" +
            convertNumComma(res["change_charge"]) +
            "（" +
            convertNumComma(res["change_charge_including_tax"]) +
            "）"
        );
      }
      if (isShowCharge.value && isCouponFirst) {
        // 初期化（新規登録ベース）
        let firstChargeLabel = "初期費用（クーポン適用）";
        let firstCharge = res["first_charge_after_discount"];
        let firstChargeIncludingTax =
          res["first_charge_after_discount_including_tax"];
        if (
          res["order_type"] === 20 ||
          res["order_type"] === 21 ||
          res["order_type"] === 22
        ) {
          // 契約変更の時
          firstChargeLabel = "変更費用（クーポン適用）";
          firstCharge = res["change_charge_after_discount"];
          firstChargeIncludingTax =
            res["change_charge_after_discount_including_tax"];
        }
        confirmData.value.push([
          "totalCoupon",
          firstChargeLabel,
          "",
          "",
          "",
          "",
          convertNumComma(firstCharge),
          convertNumComma(firstChargeIncludingTax),
        ]);
        copyChargeArray.push(
          firstChargeLabel +
            "：" +
            convertNumComma(firstCharge) +
            "（" +
            convertNumComma(firstChargeIncludingTax) +
            "）"
        );
      }
      if (res["start_date"]) {
        confirmData.value.push(
          ["label", "利用開始希望日：", "", "", "", "", "", ""],
          ["totalDate", res["start_date"], "", "", "", "", "", ""]
        );
      }

      // 設定情報
      if (isSetting.value && isStaff.value && staffDiffArray.length > 0) {
        settingData.value.push({
          title: "変更内容",
          value: staffDiffArray.join("，"),
          cols: 12,
        });
        settingData.value.push({
          title: "アカウントコメント",
          value: res["order_account_comment"] || dummyText,
          cols: 12,
        });
        settingData.value.push({
          title: "オプションコメント",
          value: res["order_option_comment"] || dummyText,
          cols: 12,
        });
      }

      // コピー情報作成
      copyValue.value =
        copyValueArray.join("\n") +
        "\n" +
        "変更内容：" +
        (staffDiffArray.join("，") || dummyText) +
        "\n" +
        "アカウントコメント：" +
        (res["order_account_comment"] || dummyText) +
        "\n" +
        "オプションコメント：" +
        (res["order_option_comment"] || dummyText) +
        "\n" +
        "契約プラン：" +
        res["plan_name"] +
        "\n" +
        "アカウント数：" +
        res["account_quantity"] +
        "\n" +
        "契約オプション：" +
        (copyOptionArray.join("，") || dummyText) +
        "\n" +
        copyChargeArray.join("\n") +
        "\n";
    };

    setTableData(tableData);

    const copyText = (value) => {
      if (copyToClipboard(value)) {
        store.dispatch("message/setInfoMessage", {
          message: "クリップボードにコピーしました。",
        });
      } else {
        store.dispatch("message/setErrorMessage", {
          message: "クリップボードにコピーできませんでした。",
        });
      }
    };

    return {
      settingData,
      confirmData,
      isSetting,
      isFlow,
      isOrder,
      isChange,
      isShowCharge,
      isStaff,
      optionVariable,
      coinVariable,
      tooltipCommentCopy,
      tooltipAccountCopy,
      copyValue,

      copyText,
    };
  },
});
</script>
