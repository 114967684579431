<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" lg="6">
        <v-card flat rounded="lg" class="pa-10">
          <v-card-text class="mygenText--text text-h6">
            <div>セッションがタイムアウトしました。</div>
            <div>再ログインしてください。</div>
          </v-card-text>
          <v-card-text>
            <ButtonCommon propsLabel="再ログイン" :propsClick="clickLogin" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import { defineComponent } from "vue";
import { useRouter } from "vue-router/composables";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";

export default defineComponent({
  name: "ContainerOutOfSession",

  components: { ButtonCommon },

  setup() {
    const router = useRouter();

    const clickLogin = () => {
      store.dispatch("auth/logout");
      store.dispatch("message/clearMessages");
      router.replace("/auth/login");
    };

    return {
      clickLogin,
    };
  },
});
</script>
