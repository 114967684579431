<template>
  <div>
    <p class="mygenText--text">
      COIN残高
      <span class="ml-2 font-weight-bold mygenAccent--text text-h4">
        {{ getCoinTotal }}
      </span>
      <span class="text-h5">COIN</span>
    </p>
    <v-data-table
      :headers="headers"
      :items="items"
      :calculate-widths="true"
      :options="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :no-data-text="dataTableNoDataText"
      :footer-props="dataTableFooterProps"
      class="mygen-table mygenText--text"
    >
      <template v-slot:[`item.coin_order_status`]="{ item }">
        <ChipStatus
          :propsType="
            isCustomer ? 'coin_order_status_for_customer' : 'coin_order_status'
          "
          :propsValue="item.coin_order_status"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import store from "@/store";
import api from "@/services/api";
import { convertArrayToDict } from "@/utils/utilities.js";
import { setCoinType } from "@/utils/selectUtility.js";
import {
  dataTableNoDataText,
  dataTableFooterProps,
} from "@/utils/listUtility.js";
import ChipStatus from "@/components/atoms/ChipStatus.vue";

export default defineComponent({
  name: "DivCoinInfo",

  components: {
    ChipStatus,
  },

  props: {
    propsCustomerId: { type: String, required: true },
    propsIsCustomer: { type: Boolean, default: false },
  },

  setup(props) {
    const customerId = ref(props.propsCustomerId);
    const isCustomer = ref(props.propsIsCustomer);
    const coinTotal = ref(0);
    const loading = ref(false);

    const headers = ref([
      {
        text: "日付",
        value: "date",
        align: "start",
        sortable: false,
        // width: " 120",
      },
      {
        text: "区分",
        value: "class",
        align: "center",
        sortable: false,
        // width: "220",
      },
      {
        text: "ステータス",
        value: "coin_order_status",
        align: "center",
        sortable: false,
        // width: "110",
      },
      {
        text: "COIN",
        value: "quantity",
        align: "end",
        sortable: false,
        // width: "130",
      },
      {
        text: "備考",
        value: "comment",
        align: "start",
        sortable: false,
        // width: "300",
      },
    ]);
    const options = reactive({
      itemsPerPage: 10,
      sortBy: ["date"],
      sortDesc: [true],
    });
    const items = ref([]);

    const getCoinTotal = computed(() => {
      return coinTotal.value.toLocaleString();
    });

    const loadData = () => {
      const coinType = convertArrayToDict(setCoinType(), "value", "text");
      loading.value = true;
      items.value = [];
      coinTotal.value = 0;

      // api:GEN-COIN情報取得
      api({
        method: "get",
        url: "/contractcoin/?customer=" + customerId.value,
      })
        .then((response) => {
          const coinDataFirst = response.data;
          for (const key in coinDataFirst) {
            if (coinDataFirst[key]["coin_order_status"] === 90) {
              continue;
            }
            const comment =
              "ORDER No. " + coinDataFirst[key]["coin_order_code"];
            const line = {
              date: coinDataFirst[key]["coin_order_date"],
              class:
                coinDataFirst[key]["contract_coin_is_contract_plan"] &&
                coinDataFirst[key]["contract_coin_order_type"] === 10
                  ? "初回チャージ"
                  : "追加チャージ",
              coin_order_status: coinDataFirst[key]["coin_order_status"],
              quantity: coinDataFirst[key]["coin_quantity"].toLocaleString(),
              comment: comment,
              sort: 1,
            };
            items.value.push(line);
            if (coinDataFirst[key]["coin_order_status"] === 50) {
              coinTotal.value += coinDataFirst[key]["coin_quantity"];
            }
          }
        })
        .then(() => {
          api({
            method: "get",
            url: "/managementcoin/?customer=" + customerId.value,
          })
            .then((response) => {
              const coinDataSecond = response.data;
              for (const key in coinDataSecond) {
                const line = {
                  date: coinDataSecond[key]["management_coin_date"],
                  class: coinType[coinDataSecond[key]["management_coin_type"]],
                  coin_order_status:
                    coinDataSecond[key]["management_coin_status"],
                  quantity:
                    coinDataSecond[key][
                      "management_coin_quantity"
                    ].toLocaleString(),
                  comment: coinDataSecond[key]["management_coin_comment"],
                  sort: 2,
                };
                items.value.push(line);
                if (coinDataSecond[key]["management_coin_status"] === 50) {
                  coinTotal.value +=
                    coinDataSecond[key]["management_coin_quantity"];
                }
              }
              loading.value = false;
            })
            .catch(() => {
              loading.value = false;
            });
        })
        .catch(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      customerId,
      isCustomer,

      dataTableNoDataText,
      dataTableFooterProps,
      headers,
      options,
      items,
      getCoinTotal,
      loading,
    };
  },
});
</script>
