<template>
  <v-expansion-panel-header
    expand-icon="mdi-menu-down"
    ripple
    color="mygenCard"
    class="primary--text"
  >
    {{ label }}
  </v-expansion-panel-header>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "PanelHeader",

  props: {
    propsLabel: { type: String, required: true },
  },

  setup(props) {
    const label = ref(props.propsLabel);

    return {
      label,
    };
  },
});
</script>
