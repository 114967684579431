<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container :class="$vuetify.breakpoint.xs ? '' : 'px-16 py-10'">
      <v-row>
        <v-col cols="12">
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsComment="titleComment"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card width="100%" flat class="mt-5" rounded="lg">
            <v-stepper v-model="stepCount" vertical flat>
              <!-- ステップ1 -->
              <v-stepper-step
                :complete="stepCount > 1"
                step="1"
                class="mygenText--text text-h6"
              >
                {{ stepFirstTitle }}
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-form ref="formFirst" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row>
                        <v-col
                          v-for="(form, key) in forms"
                          :key="key + '-' + formKey"
                          cols="12"
                          :sm="form.cols ? form.cols : 12"
                        >
                          <FormControls propsMode="post" :propsForm="form" />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtn1"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ2 -->
              <v-stepper-step
                :complete="stepCount > 2"
                step="2"
                class="mygenText--text text-h6"
              >
                {{ stepSecondTitle }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-form>
                  <v-card flat>
                    <v-container>
                      <v-row>
                        <v-col>
                          <p
                            class="text-caption grey--text text--darken-2 mb-1"
                          >
                            希望対応
                          </p>
                          <p style="white-space: pre-line">
                            {{ supportTypeText }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <p
                            class="text-caption grey--text text--darken-2 mb-1"
                          >
                            お問い合わせ内容
                          </p>
                          <p style="white-space: pre-line">
                            {{ forms.supportComment.value }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="送信"
                            :propsClick="submitBtn2"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ3 -->
              <v-stepper-step
                :complete="stepCount === 3"
                step="3"
                class="mygenText--text text-h6"
              >
                {{ stepThirdTitle }}
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-container>
                  <v-row align="center">
                    <v-col>
                      <span class="font-weight-bold primary--text text-h6">
                        送信が完了しました
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import { requiredRules, charLength500Rules } from "@/utils/ruleUtility.js";
import { getSelectObject, getSelectText } from "@/utils/selectUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";

export default defineComponent({
  name: "FormSupport",

  components: {
    ButtonCommon,
    FormControls,
    DivTitle,
    OverlayProgress,
  },

  setup() {
    const listTitle = ref("お問い合わせ");
    const titleComment = ref([
      "契約に関するお問い合わせを送信していただけます。",
      "・各種調査や確認が必要なお問い合わせは、ご回答まで日数がかかる場合がございます",
      "・お問い合わせには営業時間内でご回答させていただいております",
      "・お問い合わせへのメールでのご回答は、MyGENのアカウントのメールアドレス宛にお送りいたします",
      "・お問い合わせへの電話でのご回答は、お客様情報の電話番号宛に回答差し上げます",
    ]);
    const stepFirstTitle = ref("お問い合わせ入力");
    const stepSecondTitle = ref("お問い合わせ内容確認");
    const stepThirdTitle = ref("送信完了");
    const formFirst = ref(false);

    const stepCount = ref(1);

    const forms = reactive({
      supportType: {
        column: "support_type",
        label: "希望対応",
        type: "radio",
        items: getSelectObject("support_type"),
        value: 10,
        cols: 12,
      },
      supportComment: {
        column: "support_comment",
        label: "お問い合わせ",
        type: "textarea",
        value: "",
        rules: [requiredRules, charLength500Rules],
        hint: "500文字以内でご入力いただけます",
        cols: 12,
      },
    });

    const supportTypeText = computed(() => {
      return getSelectText(forms.supportType.value, forms.supportType.items);
    });
    const progress = ref(false);
    const formKey = ref(createKey());

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const data = {
        customer: store.state.auth.customerId,
        support_type: forms.supportType.value,
        support_comment: forms.supportComment.value,
      };
      // 登録
      api({
        method: "post",
        url: "/support/",
        data: data,
      }).then(
        () => {
          store.dispatch("message/setInfoMessage", {
            message: "お問い合わせの送信が完了しました。",
          });
          stepCount.value++;
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // ボタン1
    const submitBtn1 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン2
    const submitBtn2 = () => {
      saveData();
    };
    // 戻るボタン
    const returnBtn = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // スクロール位置調整
    window.scroll(0, 0);

    return {
      listTitle,
      titleComment,
      stepFirstTitle,
      stepSecondTitle,
      stepThirdTitle,
      formFirst,

      stepCount,

      forms,

      supportTypeText,
      progress,
      formKey,

      submitBtn1,
      submitBtn2,
      returnBtn,
    };
  },
});
</script>
