<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="ishref"
        text
        color="primary"
        style="text-transform: none"
        v-bind="attrs"
        v-on="on"
        :href="link"
        target="_blank"
      >
        {{ label }}
      </v-btn>
      <v-btn
        v-else
        text
        color="primary"
        style="text-transform: none"
        v-bind="attrs"
        v-on="on"
        @click="click(param)"
      >
        {{ label }}
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ButtonLink",

  props: {
    propsLabel: { type: String, required: true },
    propsTooltipText: { type: String, required: true },
    propsIsHref: { type: Boolean, default: false },
    propsLink: { type: String, default: "" },
    propsClick: { type: Function, required: true },
    propsParam: { type: String },
  },

  setup(props) {
    const label = ref(props.propsLabel);
    const tooltipText = ref(props.propsTooltipText);
    const ishref = ref(props.propsIsHref);
    const link = ref(props.propsLink);
    const click = ref(props.propsClick);
    const param = ref(props.propsParam);

    return {
      label,
      tooltipText,
      ishref,
      link,
      click,
      param,
    };
  },
});
</script>
