<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :outlined="outlined"
    block
    rounded
    elevation="4"
    :class="buttonClass"
    @click="click"
  >
    {{ label }}
  </v-btn>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ButtonCommon",

  props: {
    propsColor: { type: String, default: "primary" },
    propsDisabled: { type: Boolean, default: false },
    propsOutlined: { type: Boolean, default: false },
    propsLabel: { type: String, default: "", required: true },
    propsClick: { type: Function, default: () => {} },
  },

  setup(props) {
    const color = ref(props.propsColor);
    const disabled = ref(props.propsDisabled);
    const outlined = ref(props.propsOutlined);
    const label = ref(props.propsLabel);
    const click = ref(props.propsClick);

    const buttonClass = computed(() => {
      if (color.value !== "primary" || outlined.value) {
        return "font-weight-bold mygenText--text";
      }
      return "mygen-button font-weight-bold";
    });

    return {
      color,
      disabled,
      outlined,
      label,
      click,
      buttonClass,
    };
  },
});
</script>
