import { getDateObject } from "@/utils/utilities.js";

const setting = {
  title: "申込対応フロー",
  apiAddress: "/contracthandle/",
  apiListAddress: "/contractplanreadonly/",
  isListReadonly: true,
  listSortBy: ["order_code"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "flow",
};

const headers = [
  {
    text: "申込コード",
    value: "order_code",
    align: "start",
  },
  {
    text: "受付",
    value: "contractReceptionLink",
    reference: "order_status",
    align: "center",
    sortable: false,
    handleType: "reception",
  },
  {
    text: "対応",
    value: "contractSettingLink",
    reference: "order_status",
    align: "center",
    sortable: false,
    handleType: "contractsetting",
    handleKey: ["contract_plan_is_invoice"],
  },
  {
    text: "公開",
    value: "contractReleaseLink",
    reference: "order_status",
    align: "center",
    sortable: false,
    handleType: "contractrelease",
    handleKey: ["contract_plan_is_invoice", "related_count", "cancel_count"],
  },
  {
    text: "設定",
    value: "settingItem",
    align: "center",
    sortable: false,
    settingType: "customersettingwithflow",
    handleKey: [
      "contract___customer_reference___id",
      "order_status",
      "contract_flow_status",
    ],
  },
  {
    text: "取消",
    value: "contractDeleteLink",
    reference: "order_status",
    align: "center",
    sortable: false,
    handleType: "reception",
    handleKey: ["contract_plan_is_invoice", "related_count", "cancel_count"],
  },
  {
    text: "区分",
    value: "contract_flow_status",
    align: "center",
  },
  {
    text: "お客様コード",
    value: "contract___customer_reference___customer_code",
    align: "start",
  },
  {
    text: "お客様名",
    value: "contract___customer_reference___customer_name",
    align: "start",
  },
  {
    text: "契約コード",
    value: "contract_code",
    align: "center",
  },
  {
    text: "プラン",
    value: "plan_name",
  },
  {
    text: "申込日",
    value: "order_date",
    align: "center",
  },
  {
    text: "内容",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchDateFrom = {
  label: "申込日（以降）",
  column: "order_date",
  menu: false,
  default: getDateObject("first", -1),
  formula: "gte", // 以上
};

const searchSelect = {
  label: "対応ステータス",
  column: "order_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "受付未対応" },
    { value: 10, text: "受付済" },
    { value: 20, text: "設定済" },
    { value: 500, text: "未公開" }, // 500はfilterで設定
    { value: 50, text: "公開済" },
  ],
  filter: {
    500: {
      value: 50,
      formula: "lt", // 未満
      // formula: "lte", // 以下
    },
  },
  value: 500,
};

const searchSubSelect = {
  label: "区分",
  column: "order_type",
  items: [
    { value: -1, text: "ALL" },
    { value: 10, text: "新規" },
    { value: 20, text: "変更" },
    { value: 21, text: "一括更新" },
    { value: 22, text: "個別更新" },
  ],
  value: -1,
};

const tooltipText = [
  "契約申込の対応が行えます。",
  "",
  "■受付",
  "一次受付対応が行えます。",
  "受付するとユーザー側には受付が行われた（契約手続きが開始された）ステータスが表示されます。",
  "",
  "■対応 or 公開",
  "サイト構築や設定変更の対応を登録します。",
  "ユーザー側のステータスには変更はなく契約手続き継続中となります。",
  "公開するとユーザー側のステータスが更新され契約が本契約となります。",
  "公開時に登録した課金開始月から請求書に反映されます。",
  "",
  "■設定",
  "お客様リストと同等のお客さまデータの設定が行えます。",
  "グリーンバッチが点灯している場合は、担当がGEN社かブラザーか確認することをお勧めします。",
  "",
  "※対応フローは請求書が発行されるとロックされます。",
];

export default {
  setting,
  headers,
  searchDateFrom,
  searchSelect,
  searchSubSelect,
  tooltipText,
};
