import { getDateObject } from "@/utils/utilities.js";
import { getSelectObject } from "@/utils/selectUtility.js";
import {
  dateOrBlankRules,
  charLength200Rules,
  charLength500Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "お問い合わせ",
  apiAddress: "/support/",
  isListReadonly: true,
  listSortBy: ["support_code"],
  listSortDesc: [true],
  showExpand: true,
  expandType: "support",
};

const forms = [
  {
    column: "support_status",
    label: "対応ステータス",
    type: "radio",
    items: getSelectObject("support_status"),
    rules: [],
  },
  {
    column: "support_link",
    label: "情報リンク",
    type: "text",
    rules: [],
  },
  {
    column: "divider_first",
    label: "対応メモ１",
    type: "divider",
    icon: "mdi-file-outline",
  },
  {
    column: "support_handle_first_date",
    label: "対応日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [dateOrBlankRules],
    cols: 6,
  },
  {
    column: "support_handle_first_staff",
    label: "担当者",
    type: "text",
    rules: [charLength200Rules],
    cols: 6,
  },
  {
    column: "support_handle_first_comment",
    label: "対応コメント",
    type: "textarea",
    rules: [charLength500Rules],
  },
  {
    column: "divider_second",
    label: "対応メモ２",
    type: "divider",
    icon: "mdi-file-outline",
  },
  {
    column: "support_handle_second_date",
    label: "対応日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [dateOrBlankRules],
    cols: 6,
  },
  {
    column: "support_handle_second_staff",
    label: "担当者",
    type: "text",
    rules: [charLength200Rules],
    cols: 6,
  },
  {
    column: "support_handle_second_comment",
    label: "対応コメント",
    type: "textarea",
    rules: [charLength500Rules],
  },
  {
    column: "divider_third",
    label: "対応メモ３",
    type: "divider",
    icon: "mdi-file-outline",
  },
  {
    column: "support_handle_third_date",
    label: "対応日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [dateOrBlankRules],
    cols: 6,
  },
  {
    column: "support_handle_third_staff",
    label: "担当者",
    type: "text",
    rules: [charLength200Rules],
    cols: 6,
  },
  {
    column: "support_handle_third_comment",
    label: "対応コメント",
    type: "textarea",
    rules: [charLength500Rules],
  },
];

const headers = [
  {
    text: "対応",
    value: "handleItem",
    align: "center",
    sortable: false,
    handleType: "support",
  },
  {
    text: "お問い合わせコード",
    value: "support_code",
    align: "start",
  },
  {
    text: "ステータス",
    value: "support_status",
    align: "center",
  },
  {
    text: "希望対応",
    value: "support_type",
    align: "center",
  },
  {
    text: "日時",
    value: "support_date",
    type: "datetime",
    align: "start",
  },
  {
    text: "お客様コード",
    value: "customer_reference___customer_code",
    align: "start",
  },
  {
    text: "お客様名",
    value: "customer_reference___customer_name",
    align: "start",
  },
  {
    text: "内容",
    value: "data-table-expand",
    align: "center",
    sortable: false,
    handleKey: [
      "customer_reference___account",
      "customer_reference___tel",
      "support_comment",
      "support_link",
      "support_handle_first_date",
      "support_handle_first_staff",
      "support_handle_first_comment",
      "support_handle_second_date",
      "support_handle_second_staff",
      "support_handle_second_comment",
      "support_handle_third_date",
      "support_handle_third_staff",
      "support_handle_third_comment",
    ],
  },
];

const searchDateFrom = {
  label: "日付（以降）",
  column: "created_at",
  menu: false,
  default: getDateObject("first", -1),
  formula: "gte", // 以上
};

const searchSelect = {
  label: "ステータス",
  column: "support_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "未対応" },
    { value: 10, text: "対応中" },
    { value: 500, text: "未終息" }, // 500はfilterで設定
    { value: 50, text: "終息" },
  ],
  filter: {
    500: {
      value: 50,
      formula: "lt", // 未満
      // formula: "lte", // 以下
    },
  },
  value: 500,
};

export default {
  setting,
  forms,
  headers,
  searchDateFrom,
  searchSelect,
};
