import { getMonthObject } from "@/utils/utilities.js";
import { getSelectObject } from "@/utils/selectUtility.js";
import {
  requiredRules,
  dateMonthRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "解約対応",
  originalTitle: "設定登録",
  apiAddress: "/cancelhandle/",
  isListReadonly: true,
};

const forms = [
  {
    column: "cancel_order_status",
    label: "設定対応",
    type: "radio",
    items: getSelectObject("cancel_order_status", "cancelsetting"),
    rules: [],
  },
  {
    column: "contract_cancel_date",
    label: "契約満了月（解約月）",
    type: "dateMonthEnd",
    menu: false,
    default: getMonthObject(),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
  },
  {
    column: "staff_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const postFixedValue = [
  {
    column: "setting_date",
    type: "today",
    equalRules: ["cancel_order_status", 20],
  },
];

const headers = [];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
