<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12" sm="6">
        <CardCustomerData
          key="card-customer"
          propsTitle="契約者情報"
          :propsFormsObject="formsFirst"
          :propsIsConfirm="isConfirm"
          :propsIsCopy="isCopy"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <CardCustomerData
          key="card-invoice"
          propsTitle="請求先情報"
          :propsFormsObject="formsSecond"
          :propsIsConfirm="isConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref } from "vue";
import CardCustomerData from "@/components/atoms/CardCustomerData.vue";

export default defineComponent({
  name: "ContainerCustomerInfo",

  components: {
    CardCustomerData,
  },

  props: {
    propsFormsFirst: { type: Object, default: () => {} },
    propsFormsSecond: { type: Object, default: () => {} },
    propsIsConfirm: { type: Boolean, default: false },
    propsIsCopy: { type: Boolean, default: false },
  },

  setup(props) {
    const formsFirst = ref(props.propsFormsFirst);
    const formsSecond = ref(props.propsFormsSecond);
    const isConfirm = ref(props.propsIsConfirm);
    const isCopy = ref(props.propsIsCopy);

    return {
      formsFirst,
      formsSecond,
      isConfirm,
      isCopy,
    };
  },
});
</script>
