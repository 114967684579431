import { requiredRules } from "@/utils/ruleUtility.js";

const setting = {
  originalTitle: "パスワード変更",
  apiAddress: "/auth/users/set_password/",
};

// 注意事項：ModelForm > saveData にてパスワード変更専用バリデーションを設定している
const forms = [
  {
    column: "current_password",
    label: "現在のパスワード",
    type: "password",
    rules: [requiredRules],
    append: false,
  },
  {
    column: "new_password",
    label: "新しいパスワード",
    type: "password",
    rules: [requiredRules],
    append: false,
  },
  {
    column: "re_new_password",
    label: "新しいパスワード（確認）",
    type: "password",
    rules: [requiredRules],
    append: false,
  },
];

export default {
  setting,
  forms,
};
