import { setDepositType, setPaymentMethod } from "@/utils/selectUtility.js";
import {
  bankCodeOrBlankRules,
  kanaHalfOrBlankRules,
  branchCodeOrBlankRules,
  numericOrBlankRules,
  selectedRules,
  dateOrBlankRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "支払設定",
  apiAddress: "/customerbank/",
  apiListAddress: "/customer/",
  isListReadonly: true,
  listSortBy: ["customer_code"],
  listSortDesc: [false],
};

const forms = [
  {
    column: "payment_method",
    label: "支払方法",
    type: "select",
    items: setPaymentMethod(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "space1",
    type: "space",
    cols: 6,
  },
  {
    column: "bank_transfer_start",
    label: "口座振替請求開始可能日",
    type: "date",
    menu: false,
    rules: [dateOrBlankRules],
    cols: 6,
    hint: "請求日より前の日付を指定すると口座振替の請求データが作成されます",
  },
  {
    column: "deposit_type",
    label: "口座種別",
    type: "select",
    items: setDepositType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "bank_code",
    label: "銀行コード",
    type: "text",
    rules: [bankCodeOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_name",
    label: "銀行名",
    type: "text",
    rules: [kanaHalfOrBlankRules],
    cols: 6,
  },
  {
    column: "branch_code",
    label: "支店コード",
    type: "text",
    rules: [branchCodeOrBlankRules],
    cols: 6,
  },
  {
    column: "branch_name",
    label: "支店名",
    type: "text",
    rules: [kanaHalfOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_account_number",
    label: "口座番号",
    type: "text",
    rules: [numericOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_account_name",
    label: "預金者名",
    type: "text",
    rules: [kanaHalfOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_comment",
    label: "口座コメント",
    type: "textarea",
    rules: [charLength200Rules],
    hint: "200文字以内でご入力いただけます",
  },
];

const headers = [
  {
    text: "お客様コード",
    value: "customer_code",
    align: "start",
  },
  {
    text: "会社名",
    value: "customer_name",
    align: "start",
  },
  {
    text: "請求先名",
    value: "invoice_customer_name",
  },
  {
    text: "郵便番号",
    value: "invoice_zip_code",
  },
  {
    text: "都道府県",
    value: "invoice_prefecture",
  },
  {
    text: "住所1",
    value: "invoice_address_1",
  },
  {
    text: "住所2",
    value: "invoice_address_2",
  },
  {
    text: "電話番号",
    value: "invoice_tel",
  },
  {
    text: "口座振替",
    value: "isBankStatus",
    align: "center",
    handleKey: ["is_bank"],
  },
  {
    text: "対応",
    value: "handleItem",
    align: "center",
    sortable: false,
    handleType: "customerbank",
  },
];

const searchSelect = {
  label: "口座",
  column: "is_bank",
  items: [
    { value: -1, text: "ALL" },
    { value: false, text: "未登録" },
    { value: true, text: "登録済" },
  ],
  value: false,
};

export default {
  setting,
  forms,
  headers,
  searchSelect,
};
