import { selectedRules, equiredOrZeroRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "オプション設定",
  apiAddress: "/structure/",
  listSortBy: ["plan___plan_code", "option___option_code"],
  listSortDesc: [false],
};

const forms = [
  {
    column: "plan",
    label: "プラン",
    type: "selectPlan",
    items: [],
    rules: [selectedRules],
    exportColumn: "plan_reference___plan_code",
  },
  {
    column: "option",
    label: "オプション",
    type: "selectOption",
    items: [],
    rules: [selectedRules],
    exportColumn: "option_reference___option_code",
  },
  {
    column: "structure_class",
    label: "区分",
    type: "number",
    default: 0,
    rules: [equiredOrZeroRules],
    cols: 6,
  },
];

export default {
  setting,
  forms,
};
