const setting = {
  title: "支払明細",
  apiAddress: "/paymentdetailbyuser/",
  isListReadonly: true,
  listSortBy: ["payment_detail_code"],
  listSortDesc: [false],
};

const forms = [];

const headers = [
  {
    text: "お客様コード",
    value: "payment_detail_code",
  },
  {
    text: "お客様",
    value: "payment_detail_name",
  },
  {
    text: "対象金額",
    value: "payment_detail_subject_charge_sum",
    type: "number",
    align: "end",
  },
  {
    text: "インセンティブ金額",
    value: "payment_detail_charge_sum",
    type: "number",
    align: "end",
  },
];

export default {
  setting,
  forms,
  headers,
};
