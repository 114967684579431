import { setDisplayList, setOptionClass } from "@/utils/selectUtility.js";
import { requiredRules, selectedRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "契約オプション",
  apiAddress: "/option/",
  listSortBy: ["option_code"],
  listSortDesc: [false],
  isCsvImport: true,
};

const forms = [
  {
    column: "option_code",
    label: "オプションコード",
    type: "text",
    rules: [requiredRules],
    importUnique: true,
  },
  {
    column: "option_name",
    label: "オプション",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "option_fee",
    label: "オプション料金",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "quantity_display",
    label: "数量表示",
    type: "select",
    items: setDisplayList(),
    default: setDisplayList(0),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "quantity_label",
    label: "数量ラベル",
    type: "text",
    rules: [],
    cols: 6,
  },
  {
    column: "option_class",
    label: "オプション区分",
    type: "select",
    items: setOptionClass(),
    default: setOptionClass(0),
    rules: [selectedRules],
    cols: 6,
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "オプションコード",
    value: "option_code",
    align: "start",
  },
  {
    text: "オプション",
    value: "option_name",
  },
  {
    text: "オプション料金",
    value: "option_fee",
    type: "number",
    align: "end",
  },
  {
    text: "数量表示",
    value: "quantity_display",
    type: "select",
    items: setDisplayList(),
    align: "center",
  },
  {
    text: "数量ラベル",
    value: "quantity_label",
  },
  {
    text: "オプション区分",
    value: "option_class",
    type: "select",
    items: setOptionClass(),
    align: "center",
  },
];

export default {
  setting,
  forms,
  headers,
};
