<template>
  <v-card outlined rounded="lg">
    <v-card-title class="mygenText--tex font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            v-for="(form, key) in formsObject"
            :key="'first-' + key"
            cols="12"
            :sm="form.cols ? form.cols : 12"
            class="mygenText--text"
          >
            <div class="caption">{{ form.label }}</div>
            <div class="subtitle-1">
              {{ showValue(form.type, form.value) }}
              <v-tooltip v-if="isCopy && form.copy" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copyText(showValue(form.type, form.value))"
                  >
                    <v-icon color="primary" small> mdi-content-copy </v-icon>
                  </v-btn>
                </template>
                <span>{{ tooltipLabel(form.label) }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import { defineComponent, ref } from "vue";
import { copyToClipboard } from "@/utils/utilities.js";

export default defineComponent({
  name: "CardCustomerData",

  props: {
    propsTitle: { type: String, require: true },
    propsFormsObject: { type: Object, default: () => {} },
    propsIsConfirm: { type: Boolean, default: false },
    propsIsCopy: { type: Boolean, default: false },
  },

  setup(props) {
    const title = ref(props.propsTitle);
    const isConfirm = ref(props.propsIsConfirm);
    const isCopy = ref(props.propsIsCopy);
    const formsObject = ref(props.propsFormsObject);

    const showValue = (type, value) => {
      if (isConfirm.value && type === "select") {
        return value.text;
      }
      return value === null ? "" : value;
    };
    const tooltipLabel = (value) => {
      return value + "をクリップボードにコピー";
    };

    const copyText = (value) => {
      if (copyToClipboard(value)) {
        store.dispatch("message/setInfoMessage", {
          message: "クリップボードにコピーしました。",
        });
      } else {
        store.dispatch("message/setErrorMessage", {
          message: "クリップボードにコピーできませんでした。",
        });
      }
    };

    return {
      title,
      formsObject,
      isConfirm,
      isCopy,
      showValue,
      tooltipLabel,
      copyText,
    };
  },
});
</script>
