<template>
  <v-container>
    <v-row v-if="progress === false">
      <v-spacer />
      <v-col>
        <v-card width="600px" rounded="lg" :flat="true" :class="'pa-5'">
          <v-card-title class="font-weight-bold grey--text text--darken-1">
            {{ cardTitle }}
          </v-card-title>
          <v-card-text>
            <v-form ref="formModel">
              <v-container>
                <v-row>
                  <v-col
                    v-for="(form, key) in forms"
                    :key="key + '-' + formKey"
                    cols="12"
                    :sm="form.cols ? form.cols : 12"
                  >
                    <div class="text-caption font-weight-bold">
                      {{ form.label }}
                    </div>
                    <v-radio-group v-model="form.value" colmun class="mt-1">
                      <v-radio label="非表示" :value="0" color="red" />
                      <v-radio label="標準" :value="10" color="green" />
                      <v-radio label="追加" :value="20" color="orange" />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-text>
            <v-container>
              <v-row>
                <v-spacer />
                <v-col cols="12" sm="6">
                  <ButtonCommon
                    propsLabel="登録"
                    :propsClick="saveData"
                    :propsDisabled="buttonDesabeled"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <ButtonCommon
                    propsColor="secondary"
                    propsLabel="キャンセル"
                    :propsOutlined="true"
                    :propsClick="clickCancel"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row v-else>
      <v-spacer />
      <v-col>
        <OverlayProgress v-if="progress" />
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { computed, defineComponent, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router/composables";
import { createKey } from "@/utils/utilities.js";
import { settingDictionaryForms } from "@/utils/formUtility.js";
import { selectedOrZeroRules } from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";

export default defineComponent({
  name: "FormStructure",

  components: {
    ButtonCommon,
    OverlayProgress,
  },

  props: {
    propsEditId: {
      type: String,
      required: true,
    },
    propsPlanName: {
      type: String,
      default: "",
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const editId = ref(props.propsEditId);
    const planName = ref(props.propsPlanName);
    const formModel = ref(false);

    const cardTitle = computed(() => {
      return planName.value + " オプション設定";
    });
    const forms = ref({});

    const buttonDesabeled = ref(false);
    const progress = ref(false);
    const formKey = ref(createKey());

    /**
     * データ取得処理
     */
    const loadData = () => {
      const options = {};
      forms.value = {};
      if (editId.value == "") {
        // formKey.value = createKey();
        return;
      }

      progress.value = true;

      // api:データ取得
      api({
        method: "get",
        url: "/option/",
      })
        .then((response) => {
          const res = response.data;
          for (const row of res) {
            options[row["id"]] = row;
            options[row["id"]]["structure_class"] = 0;
          }
        })
        .then(() => {
          api({
            method: "get",
            url: "/structure/?plan=" + editId.value,
          }).then((response) => {
            const resStructure = response.data;
            // 構成データをセット
            for (const row of resStructure) {
              const optionId = row["option"];
              if (options[optionId]) {
                options[optionId]["structure_class"] = row["structure_class"];
              }
            }
            // フォームオブジェクトデータ生成
            const tempForms = [];
            for (const key in options) {
              tempForms.push({
                column: options[key]["id"],
                label: options[key]["option_name"],
                rules: [selectedOrZeroRules],
                cols: 3,
              });
            }
            // フォームオブジェクトセット
            forms.value = settingDictionaryForms(tempForms);
            for (const key in forms.value) {
              if (key in options) {
                forms.value[key].value = options[key]["structure_class"];
              }
            }
          });
        })
        .then(() => {
          progress.value = false;
        });
    };

    /**
     * データ登録処理
     */
    const saveData = () => {
      // form バリデーション
      const validResult = formModel.value.validate();
      if (validResult) {
        buttonDesabeled.value = true;
        // apiで渡すデータを生成
        const data = [];
        for (const key in forms.value) {
          // 非表示OPは登録しない
          if (forms.value[key].value !== 0) {
            const line = {
              plan: editId.value,
              option: forms.value[key].column,
              structure_class: forms.value[key].value,
            };
            data.push(line);
          }
        }

        // api:元データ削除
        api({
          method: "delete",
          url: "/structuredestroy/" + editId.value + "/",
        }).then(() => {
          if (data) {
            // api:データ登録
            api({
              method: "post",
              url: "/structure/",
              data: data,
            })
              .then(() => {
                // ダイアログ
                emit("close", "登録しました");
              })
              .catch(function (error) {
                buttonDesabeled.value = false;
                return error.response;
              });
          }
        });
      }
    };

    // キャンセルボタン押下
    const clickCancel = () => {
      // ダイアログ
      emit("close");
    };

    onBeforeRouteUpdate(async (to, from, next) => {
      next();
      // 遷移後処理
      if (to.query !== from.query) {
        // 再描画処理
        loadData();
      }
    });

    // データ取得
    loadData();

    return {
      editId,
      planName,
      formModel,

      cardTitle,
      forms,

      buttonDesabeled,
      progress,
      formKey,

      saveData,
      clickCancel,
    };
  },
});
</script>
