<template>
  <span class="ml-2 text-h6 font-weight-bold">
    {{ headlineText }}
  </span>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SpanHeadline",

  props: {
    propsText: { type: String, default: "" },
  },

  setup(props) {
    const headlineText = ref(props.propsText);

    return {
      headlineText,
    };
  },
});
</script>
