<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container
      :class="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'px-16 py-10'
      "
    >
      <v-row>
        <v-col cols="12">
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsComment="titleComment"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card flat width="100%" class="mygenCard">
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="items"
                item-key="id"
                :calculate-widths="true"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                multi-sort
                :loading="loading"
                loading-text="Loading... Please wait"
                :no-data-text="dataTableNoDataText"
                :footer-props="dataTableFooterProps"
                class="mygen-table mygenText--text"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <router-link
                    :to="{
                      name: 'reportInvoice',
                      params: { id: item.id },
                    }"
                    class="text-decoration-none"
                    target="_blank"
                    replace
                  >
                    <v-btn fab dark small elevation="0" color="mygenIcon">
                      <v-icon size="22" color="mygenText">
                        bi-file-earmark
                      </v-icon>
                    </v-btn>
                  </router-link>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, onMounted, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import {
  dataTableNoDataText,
  dataTableFooterProps,
} from "@/utils/listUtility.js";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";

export default defineComponent({
  name: "ListInvoice",

  components: {
    DivTitle,
    OverlayProgress,
  },

  setup() {
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });

    const listTitle = ref("請求書の確認");
    const titleComment = ref([
      "リストの請求書リンクから詳細を確認できます。",
      "保存を必要とされる方は請求書リンクから印刷機能をご利用ください。",
    ]);

    const headers = ref([
      {
        text: "請求書No",
        value: "invoice_code",
        sortable: false,
      },
      {
        text: "請求月度",
        value: "invoice_date",
        align: "center",
        sortable: false,
      },
      {
        text: "請求料金（税抜）",
        value: "invoice_charge",
        align: "end",
        sortable: false,
      },
      {
        text: "請求料金（税込）",
        value: "invoice_charge_including_tax",
        align: "end",
        sortable: false,
      },
      {
        text: "請求書",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ]);
    const sortBy = ref(["invoice_code"]);
    const sortDesc = ref([true]);
    const items = ref([]);
    const loading = ref(false);

    const progress = ref(false);
    const formKey = ref(createKey());

    const loadData = () => {
      progress.value = true;
      items.value = [];
      // api:請求情報取得
      api({
        method: "get",
        url:
          "/invoiceheader/?invoice_group_status=50&customer=" +
          customerId.value,
      }).then((response) => {
        const res = response.data;
        // 初期化
        for (const row of res) {
          const invoiceDateArr =
            row["invoice_group_reference"]["invoice_date"].split("-");
          const invoidceDate =
            invoiceDateArr[0] + "年" + invoiceDateArr[1] + "月";
          const line = {
            id: row["id"],
            invoice_code: row["invoice_code"],
            invoice_date: invoidceDate,
            invoice_deadline:
              row["invoice_group_reference"]["invoice_deadline"],
            invoice_charge: row["invoice_charge"].toLocaleString(),
            invoice_charge_including_tax:
              row["invoice_charge_including_tax"].toLocaleString(),
            comment: "ORDER. " + row["invoice_code"],
          };
          items.value.push(line);
        }
        progress.value = false;
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // スクロール位置調整
    window.scroll(0, 0);

    // データ取得
    loadData();

    return {
      customerId,

      listTitle,
      titleComment,

      dataTableNoDataText,
      dataTableFooterProps,
      headers,
      sortBy,
      sortDesc,
      items,
      loading,

      progress,
      formKey,
    };
  },
});
</script>
