const setting = {
  title: "アクティベーション",
  buttonLabelSubmit: "確定",
  firstMessage: ["MyGENのアカウントを有効にする"],
  thirdMessage: ["アカウントが有効になりました"],
  third204Message: ["アカウントは有効化されています"],
  third400Message: ["指定されたユーザーのトークンが無効です"],
  isStepThird: true,
  storeAddress: "signup/activation",
  successMessage: "アカウントが有効になりました",
  error204Message: "アカウントは有効化されています",
  error400Message: "指定されたユーザーのトークンが無効です",
};

const forms = {};

export default {
  setting,
  forms,
};
