import { getDateObject } from "@/utils/utilities.js";
import { setPaymentMethod } from "@/utils/selectUtility.js";
import { requiredRules, dateRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "請求明細",
  apiAddress: "/invoiceheader/",
  isListReadonly: true,
  listSortBy: ["invoice_code"],
  listSortDesc: [false],
  isExcelExport: true,
  showExpand: true,
  expandType: "invoicedetail",
};

const forms = [
  {
    column: "invoice_code",
    label: "請求書番号",
    type: "text",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "invoice_group_reference___invoice_date",
    label: "請求日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "customer_reference___customer_code",
    label: "お客様コード",
    type: "text",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "customer_reference___customer_name",
    label: "会社名",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "invoice_charge",
    label: "請求金額",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "invoice_charge_including_tax",
    label: "請求税込金額",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
];

const headers = [
  {
    text: "請求書番号",
    value: "invoice_code",
    wpx: 120,
  },
  {
    text: "お客様コード",
    value: "customer_reference___customer_code",
    wpx: 100,
  },
  {
    text: "検索コード",
    value: "customer_reference___customer_search_code_1",
    wpx: 100,
  },
  {
    text: "会社名",
    value: "customer_reference___customer_name",
    wpx: 200,
  },
  {
    text: "請求日",
    value: "invoice_group_reference___invoice_date",
    align: "center",
    wpx: 80,
  },
  {
    text: "請求金額",
    value: "invoice_charge",
    type: "number",
    align: "end",
    wpx: 100,
  },
  {
    text: "請求税込金額",
    value: "invoice_charge_including_tax",
    type: "number",
    align: "end",
    wpx: 100,
  },
  {
    text: "支払方法",
    value: "payment_type",
    type: "select",
    items: setPaymentMethod(),
    align: "center",
    wpx: 80,
  },
  {
    text: "請求書",
    value: "reportInvoice",
    align: "center",
    sortable: false,
    nonExcel: true,
  },
  {
    text: "明細",
    value: "data-table-expand",
    align: "center",
    sortable: false,
    nonExcel: true,
  },
];

// const searchSelect = {
//   label: "請求グループ",
//   column: "invoice_group",
//   type: "invoice_group",
//   items: [],
//   value: null,
// };

export default {
  setting,
  forms,
  headers,
  // searchSelect,
};
