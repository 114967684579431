import { getDateObject } from "@/utils/utilities.js";
import { getSelectObject, setCoinType } from "@/utils/selectUtility.js";
import {
  selectedNotNullRules,
  requiredRules,
  dateRules,
  selectedRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "移行コイン",
  apiAddress: "/transfercoin/",
  listSortBy: ["client_reference___client_code", "transfer_coin_date"],
  listSortDesc: [false],
  isCsvImport: true,
};

const forms = [
  {
    column: "client",
    label: "顧客",
    type: "selectClient",
    items: [],
    rules: [selectedNotNullRules],
    exportColumn: "client_reference___client_code",
  },
  {
    column: "transfer_coin_date",
    label: "日付",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "transfer_coin_class",
    label: "区分",
    type: "select",
    items: getSelectObject("management_coin_class"),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "transfer_coin_status",
    label: "管理ステータス",
    type: "select",
    items: getSelectObject("coin_order_status", "transfer"),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "transfer_coin_type",
    label: "種別",
    type: "select",
    items: setCoinType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "transfer_coin_quantity",
    label: "コイン枚数",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "transfer_coin_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "transfer_staff",
    label: "担当",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "お客様コード",
    value: "client_reference___client_code",
    align: "start",
  },
  {
    text: "会社名",
    value: "client_reference___client_name",
    align: "start",
  },
  {
    text: "日付",
    value: "transfer_coin_date",
    align: "center",
  },
  {
    text: "ステータス",
    value: "is_transfer",
    align: "center",
  },
  {
    text: "管理ステータス",
    value: "transfer_coin_status",
    align: "center",
  },
  {
    text: "種別",
    value: "transfer_coin_type",
    type: "select",
    items: setCoinType(),
    align: "center",
  },
  {
    text: "コイン枚数",
    value: "transfer_coin_quantity",
    type: "number",
    align: "end",
  },
  {
    text: "コメント",
    value: "transfer_coin_comment",
  },
  {
    text: "担当",
    value: "transfer_staff",
  },
];

const searchSelect = {
  label: "ステータス",
  column: "customer__isnull",
  items: [
    { value: -1, text: "ALL" },
    { value: false, text: "継承済" },
    { value: true, text: "未継承" },
  ],
  value: true,
};

export default {
  setting,
  forms,
  headers,
  searchSelect,
};
