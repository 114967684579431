<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12">
        <v-card outlined rounded="lg">
          <v-card-title class="mygenText--text font-weight-bold">
            口座登録情報
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-for="(form, key) in invoiceData"
                  :key="'third-' + key"
                  cols="12"
                  :sm="form.cols ? form.cols : 12"
                  class="mygenText--text"
                >
                  <div class="caption">{{ form.label }}</div>
                  <v-textarea
                    v-if="form.type === 'textarea'"
                    v-model="form.value"
                    readonly
                    flat
                    solo
                    background-color="#fafafa"
                  />
                  <div v-else class="subtitle-1" style="white-space: pre-wrap">
                    {{ form.value }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref } from "vue";
import { setPaymentMethod, getSelectValue } from "@/utils/selectUtility.js";

export default defineComponent({
  name: "ContainerCustomerBank",

  props: {
    propsCustomerData: { type: Object, default: () => {} },
  },

  setup(props) {
    const formsCustomerData = ref(props.propsCustomerData);
    const invoiceData = ref([]);

    const setCustomerData = () => {
      // 支払方法取得
      const paymentMethod = getSelectValue(
        formsCustomerData.value["payment_method"],
        setPaymentMethod()
      ).text;
      invoiceData.value = [];
      invoiceData.value.push({
        label: "支払方法",
        value: paymentMethod,
        cols: 6,
      });
      invoiceData.value.push({
        label: "口座振替請求開始可能日",
        value: formsCustomerData.value["bank_transfer_start"],
        cols: 6,
      });
      invoiceData.value.push({
        label: "口座コメント",
        value: formsCustomerData.value["bank_comment"],
        cols: 12,
        type: "textarea",
      });
    };

    setCustomerData();

    return {
      formsCustomerData,
      invoiceData,
    };
  },
});
</script>
