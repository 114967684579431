import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.deepPurple.accent4,
        secondary: colors.blueGrey.darken2,
        secondaryOutline: colors.blueGrey.darken1,
        accent: colors.blue.darken1,
        accentOutline: colors.blue.accent3,
        error: colors.red.accent2,
        errorOutline: colors.red.darken1,
        warning: "#ffc107",
        info: colors.orange.darken1,
        infoOutline: colors.orange.darken2,
        success: colors.green.accent4,
        mygenAccent: colors.pink.lighten3,
        mygenBase: colors.grey.lighten4,
        mygenCard: colors.grey.lighten5,
        mygenText: colors.blueGrey.darken4,
        mygenIcon: colors.blueGrey.lighten5,
      },
      dark: {
        // primary: "#00BCD4", // cyan
        primary: "#E91E63", // pink
        secondary: "#9E9E9E", // grey
        accent: "#8c9eff",
        error: "#E91E63", // pink
        warning: "#ffc107",
        info: "#ff5722",
        success: "#795548",
      },
    },
  },
});
