<template>
  <v-container>
    <v-row class="mx-auto py-0" style="max-width: 800px">
      <v-col cols="12 px-0">
        <v-card
          class="mx-auto"
          width="100%"
          outlined
          style="overflow-wrap: anywhere !important"
        >
          <v-card-text :class="isCard ? 'pa-3 mygenCard' : 'pa-3'">
            <div class="text-caption">
              {{ label }}
            </div>
            <div class="text-body-1 mygenText--text">
              {{ comment }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ContainerOrderComment",

  props: {
    propsComment: { type: String, default: "" },
    propsIsOption: { type: Boolean, default: false },
    propsIsCard: { type: Boolean, default: false },
  },

  setup(props) {
    const comment = ref(props.propsComment);
    const isOption = ref(props.propsIsOption);
    const isCard = ref(props.propsIsCard);
    const label =
      "削除アカウント" + (isOption.value ? "（契約オプション）" : "");

    return {
      comment,
      isOption,
      isCard,
      label,
    };
  },
});
</script>
