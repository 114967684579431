import { setDisplayList } from "@/utils/selectUtility.js";
import { selectedRules, equiredOrZeroRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "移行契約オプション",
  apiAddress: "/transferoption/",
  listSortBy: ["option"],
  listSortDesc: [false],
};

const forms = [
  {
    column: "option",
    label: "オプション",
    type: "selectOption",
    items: [],
    rules: [selectedRules],
    exportColumn: "option_reference___option_code",
  },
  {
    column: "option_quantity",
    label: "数量",
    type: "number",
    default: 0,
    rules: [equiredOrZeroRules],
    cols: 6,
  },
];

const postFixedValue = [
  {
    column: "transfer_plan",
    type: "props",
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "オプションコード",
    value: "option_reference___option_code",
    align: "start",
  },
  {
    text: "オプション名",
    value: "option_reference___option_name",
    align: "start",
  },
  {
    text: "数量表示",
    value: "option_reference___quantity_display",
    type: "select",
    items: setDisplayList(),
    align: "center",
  },
  {
    text: "数量",
    value: "option_quantity",
    type: "number",
    align: "end",
  },
  {
    text: "数量ラベル",
    value: "option_reference___quantity_label",
    align: "start",
  },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
