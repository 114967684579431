import { setDepositType, setPaymentMethod } from "@/utils/selectUtility.js";
import {
  requiredRules,
  bankCodeOrBlankRules,
  kanaHalfOrBlankRules,
  branchCodeOrBlankRules,
  numericOrBlankRules,
  selectedRules,
  dateOrBlankRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "顧客",
  apiAddress: "/client/",
  listSortBy: ["client_name"],
  listSortDesc: [false],
  isCsvImport: true,
  isTransferCheckExcel: true,
};

const forms = [
  {
    column: "client_code",
    label: "顧客コード",
    type: "text",
    rules: [requiredRules],
    importUnique: true,
  },
  {
    column: "client_name",
    label: "顧客名",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "bank_code",
    label: "銀行コード",
    type: "text",
    rules: [bankCodeOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_name",
    label: "銀行名",
    type: "text",
    rules: [kanaHalfOrBlankRules],
    cols: 6,
  },
  {
    column: "branch_code",
    label: "支店コード",
    type: "text",
    rules: [branchCodeOrBlankRules],
    cols: 6,
  },
  {
    column: "branch_name",
    label: "支店名",
    type: "text",
    rules: [kanaHalfOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_account_number",
    label: "口座番号",
    type: "text",
    rules: [numericOrBlankRules],
    cols: 6,
  },
  {
    column: "bank_account_name",
    label: "預金者名",
    type: "text",
    rules: [kanaHalfOrBlankRules],
    cols: 6,
  },
  {
    column: "deposit_type",
    label: "口座種別",
    type: "select",
    items: setDepositType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "payment_method",
    label: "支払方法",
    type: "select",
    items: setPaymentMethod(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "bank_transfer_start",
    label: "口座振替請求開始可能日",
    type: "date",
    menu: false,
    rules: [dateOrBlankRules],
    cols: 6,
    importBrank: true,
  },
  {
    column: "bank_comment",
    label: "口座コメント",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "brother",
    label: "担当・Brother",
    type: "selectBrother",
    items: [],
    rules: [],
    exportColumn: "brother_reference___brother_code",
    importBrank: true,
  },
  {
    column: "info_link",
    label: "情報リンク",
    type: "text",
    rules: [],
  },
  {
    column: "client_remarks",
    label: "備考",
    type: "text",
    rules: [],
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "顧客コード",
    value: "client_code",
    align: "start",
  },
  {
    text: "顧客名",
    value: "client_name",
    align: "start",
  },
  {
    text: "ステータス",
    value: "is_transfer",
    align: "center",
  },
  {
    text: "コネクト",
    value: "transferConnection",
    align: "center",
    sortable: false,
    handleKey: ["is_lock"],
  },
  {
    text: "切断",
    value: "transferDisconnect",
    align: "center",
    sortable: false,
    handleKey: ["is_lock"],
  },
  {
    text: "継承先アカウント",
    value: "customer_reference___account",
  },
  {
    text: "お客様コード",
    value: "customer_reference___customer_code",
  },
  {
    text: "お客様名",
    value: "customer_reference___customer_name",
  },
];

const searchSelect = {
  label: "ステータス",
  column: "customer__isnull",
  items: [
    { value: -1, text: "ALL" },
    { value: false, text: "継承済" },
    { value: true, text: "未継承" },
  ],
  value: true,
};

const excelTransferCheckFormat = [
  { label: "顧客コード", wpx: 70 },
  { label: "顧客名", wpx: 240 },
  { label: "継承先アカウント", wpx: 160 },
  { label: "お客様コード", wpx: 100 },
  { label: "お客様名", wpx: 240 },
];

export default {
  setting,
  forms,
  headers,
  searchSelect,
  excelTransferCheckFormat,
};
