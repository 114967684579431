import { selectedRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "切断対応",
  originalTitle: "切断対応",
  apiAddress: "/client/",
  isListReadonly: true,
};

const forms = [
  {
    column: "transfer_disconnect",
    label: "アカウントと切断する（継承済みのデータは削除されません！）",
    type: "checkbox",
    rules: [selectedRules],
  },
];

const postFixedValue = [
  {
    column: "customer",
    value: null,
  },
];

const headers = [];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
