import { selectedRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "取消対応",
  originalTitle: "取消対応",
  apiAddress: "/contractcancelbase/",
  formComment: [
    "上記の申し込みを削除します。",
    "削除を実行すると申込データ自体が削除されます。",
  ],
};

const forms = [
  {
    column: "order_delete",
    label: "申込みを削除する",
    type: "checkbox",
    rules: [selectedRules],
  },
];

const headers = [];

export default {
  setting,
  forms,
  headers,
};
