<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <CardCustomerData
          :key="'card-brother-' + formKey"
          propsTitle="Brother情報"
          :propsFormsObject="formsFirst"
          :propsIsConfirm="false"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <CardCustomerData
          :key="'card-invoice-' + formKey"
          propsTitle="アカウント情報"
          :propsFormsObject="formsSecond"
          :propsIsConfirm="false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import { settingDictionaryForms } from "@/utils/formUtility.js";
import FormsBrother from "@/forms/brother.js";
import FormsCustomer from "@/forms/customer.js";
import CardCustomerData from "@/components/atoms/CardCustomerData.vue";

export default defineComponent({
  name: "ContainerBrotherInfo",

  components: {
    CardCustomerData,
  },

  props: {
    propsBrotherId: { type: String, require: true },
  },

  setup(props) {
    const brotherId = ref(props.propsBrotherId);
    const formsAccount = [
      {
        column: "account",
        label: "アカウント",
        type: "text",
      },
    ];
    const formsFirst = reactive(settingDictionaryForms(FormsBrother.forms));
    const formsSecond = reactive(
      settingDictionaryForms(formsAccount.concat(FormsCustomer.forms))
    );
    const formKey = ref(createKey());

    const loadData = () => {
      api({
        method: "get",
        url: "/brother/?id=" + brotherId.value,
      }).then((response) => {
        const brotherData = response.data[0];
        for (const key in formsFirst) {
          formsFirst[key]["value"] = "";
          if (formsFirst[key]["column"] in brotherData) {
            if (formsFirst[key]["type"] === "selectUser") {
              // ブラザーアカウントが連携できている場合
              if (brotherData["user_reference"]) {
                formsFirst[key]["value"] =
                  brotherData["user_reference"]["email"];
              }
            } else {
              formsFirst[key]["value"] = brotherData[formsFirst[key]["column"]];
            }
          }
        }
        for (const key in formsSecond) {
          formsSecond[key]["value"] = "";
          if (
            brotherData["customer_reference"] &&
            formsSecond[key]["column"] in brotherData["customer_reference"]
          ) {
            formsSecond[key]["value"] =
              brotherData["customer_reference"][formsSecond[key]["column"]];
          }
        }
        formKey.value = createKey();
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      formsFirst,
      formsSecond,
      brotherId,
      formKey,
    };
  },
});
</script>
