const setting = {
  title: "コイン管理",
  apiAddress: "/customer/",
  apiListParam: "user__is_staff=False",
  isListReadonly: true,
  listSortBy: ["customer_code"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "coin",
  isCoinExcelExport: true,
  isCoinDetailExcelExport: true,
};

const headers = [
  {
    text: "お客様コード",
    value: "customer_code",
  },
  {
    text: "会社名",
    value: "customer_name",
  },
  {
    text: "検索コード",
    value: "customer_search_code_1",
    wpx: 100,
  },
  {
    text: "担当者名",
    value: "person_in_charge",
  },
  {
    text: "受付コイン",
    value: "charge_coin",
    type: "number",
    align: "end",
  },
  {
    text: "使用コイン",
    value: "use_coin",
    type: "number",
    align: "end",
  },
  {
    text: "コイン残高",
    value: "coin_balance",
    type: "number",
    align: "end",
  },
  {
    text: "情報",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchSelect = {
  label: "コイン残高",
  column: "coin_balance_param",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "残高なし" },
    { value: 10, text: "残高あり" },
  ],
  value: 10,
};

const excelManagementCoinFormat = [
  { label: "お客様コード", wpx: 100 },
  { label: "会社名", wpx: 220 },
  { label: "検索コード", wpx: 80 },
  { label: "担当者名", wpx: 120 },
  { label: "受付コイン", wpx: 100 },
  { label: "使用コイン", wpx: 100 },
  { label: "コイン残高", wpx: 100 },
  { label: "担当ブラザーコード", wpx: 150 },
  { label: "担当ブラザー名", wpx: 250 },
];

const excelManagementCoinDetailFormat = [
  { label: "お客様コード", wpx: 100 },
  { label: "会社名", wpx: 220 },
  { label: "日付", wpx: 100 },
  { label: "種別", wpx: 120 },
  { label: "枚数", wpx: 100 },
  { label: "備考", wpx: 240 },
  { label: "担当", wpx: 120 },
  { label: "検索コード1", wpx: 80 },
  { label: "検索コード2", wpx: 80 },
];

export default {
  setting,
  headers,
  searchSelect,
  excelManagementCoinFormat,
  excelManagementCoinDetailFormat,
};
