<template>
  <v-snackbar
    v-model="snackbar"
    centered
    :color="color"
    elevation="24"
    :min-width="$vuetify.breakpoint.xs ? '95%' : '600px'"
    :timeout="timeout"
    top
    transition="slide-y-transition"
    :key="'snackbar-' + formKey"
  >
    <v-row class="ma-0 py-0">
      <v-col cols="1" class="my-0 py-0" align-self="center">
        <v-icon dark>{{ icon }}</v-icon>
      </v-col>
      <v-col cols="11" class="my-0 py-0" align-self="center">
        <span v-for="(val, index) in messageArray" :key="index" class="mb-0">
          {{ val }}<br />
        </span>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import store from "@/store";
import { computed, defineComponent, ref } from "vue";
import { createKey } from "@/utils/utilities.js";

export default defineComponent({
  name: "SnackbarMessage",

  setup() {
    const snackbar = ref(false);
    const color = ref("");
    const icon = ref("");
    const messageArray = ref([]);
    const timeout = ref(-1);

    // const formKey = ref(createKey());

    const showSnackBar = (message) => {
      snackbar.value = false;
      color.value = "";
      icon.value = "";
      timeout.value = -1;
      messageArray.value.splice(0);
      if (message.error.length > 0) {
        color.value = "red lighten-1";
        icon.value = "mdi-alert";
        if (typeof message.error === "string") {
          messageArray.value.push(message.error);
        } else {
          messageArray.value = message.error;
        }
        snackbar.value = true;
      }
      if (message.warnings.length > 0) {
        color.value = "red lighten-1";
        icon.value = "mdi-alert";
        // 警告メッセージの配列変換
        if (typeof message.warnings === "string") {
          messageArray.value = message.warnings;
        } else if (Array.isArray(message.warnings)) {
          // メッセージが配列の場合の処理
          const count = message.warnings.length;
          for (const key in message.warnings) {
            if (message.warnings[key].length > 0) {
              let tempMessage = message.warnings[key];
              if (count > 1) {
                tempMessage + " (" + key + ")";
              }
              messageArray.value.push(tempMessage);
            }
          }
        } else {
          // メッセージが辞書型の場合の処理
          for (const key in message.warnings) {
            const obj = Object.values(message.warnings[key]);
            for (const key in obj) {
              messageArray.value.push(obj[key][0]);
            }
          }
        }
        snackbar.value = true;
      }
      if (message.info) {
        color.value = "green lighten-1";
        icon.value = "mdi-information";
        timeout.value = 4200;
        if (typeof message.info === "string") {
          messageArray.value.push(message.info);
        } else {
          messageArray.value = message.info;
        }
        snackbar.value = true;
      }
    };

    const formKey = computed(() => {
      showSnackBar(store.state.message);
      return createKey();
    });

    return {
      snackbar,
      color,
      icon,
      timeout,
      messageArray,
      formKey,
    };
  },
});
</script>
