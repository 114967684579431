<template>
  <div>
    <DivHomeStaff v-if="isStaff" />
    <DivHomeUser v-if="isUser" />
  </div>
</template>

<script>
import store from "@/store";
import { computed, defineComponent, onMounted } from "vue";
import DivHomeStaff from "@/components/organisms/DivHomeStaff.vue";
import DivHomeUser from "@/components/organisms/DivHomeUser.vue";

export default defineComponent({
  name: "HomeChildren",

  components: { DivHomeStaff, DivHomeUser },

  setup() {
    const isUser = computed(() => {
      return store.state.auth.isUser === true;
    });
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    return {
      isUser,
      isStaff,
    };
  },
});
</script>
