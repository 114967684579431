<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12">
        <v-card flat rounded="lg">
          <v-card-title class="mygenText--text font-weight-bold">
            お客様設定
          </v-card-title>
          <v-card-text>
            <v-container class="pa-0">
              <v-row>
                <v-col
                  v-for="(row, key) in settingData"
                  :key="'col-setting-' + key"
                  cols="12"
                  :md="row['cols'] ? row['cols'] : 12"
                  class="py-1"
                >
                  <v-card
                    class="mx-auto"
                    width="100%"
                    outlined
                    style="overflow-wrap: anywhere !important"
                  >
                    <v-card-text class="pa-3">
                      <div class="text-caption">{{ row.label }}</div>
                      <a
                        v-if="row.link && row.value !== '-----'"
                        :href="row.value"
                        target="_blank"
                        class="text-decoration-none"
                        title="情報リンクに移動"
                      >
                        {{ row.value }}
                      </a>
                      <div v-else class="text-body-1 mygenText--text">
                        {{ row.value }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref } from "vue";
import { setPaymentMethod, getSelectValue } from "@/utils/selectUtility.js";

export default defineComponent({
  name: "ContainerCustomerSetting",

  props: {
    propsCustomerData: { type: Object, default: () => {} },
  },

  setup(props) {
    const formsCustomerData = ref(props.propsCustomerData);
    const settingData = ref([]);

    const setCustomerData = () => {
      // 支払方法取得
      const paymentMethod = getSelectValue(
        formsCustomerData.value["payment_method"],
        setPaymentMethod()
      ).text;
      settingData.value = [
        {
          label: "担当・Brother",
          value: formsCustomerData.value["brother_name"],
        },
        {
          label: "支払方法",
          value: paymentMethod,
          cols: 6,
        },
        {
          label: "口座振替請求開始可能日",
          value: formsCustomerData.value["bank_transfer_start"] || "-----",
          cols: 6,
        },
        {
          label: "情報リンク",
          value: formsCustomerData.value["info_link"] || "-----",
          link: true,
        },
        {
          label: "検索コード1",
          value: formsCustomerData.value["customer_search_code_1"] || "-----",
          cols: 6,
        },
        {
          label: "検索コード2",
          value: formsCustomerData.value["customer_search_code_2"] || "-----",
          cols: 6,
        },
      ];
    };

    setCustomerData();

    return {
      formsCustomerData,
      settingData,
    };
  },
});
</script>
