import { getDateObject } from "@/utils/utilities.js";

const setting = {
  title: "操作ログ",
  apiAddress: "/logoperation/",
  isListReadonly: true,
  listSortBy: ["log_date"],
  listSortDesc: [true],
};

const headers = [
  {
    text: "日時",
    value: "log_date",
  },
  {
    text: "アカウント",
    value: "customer_reference___customer_name",
  },
  {
    text: "区分",
    value: "log_class",
  },
  {
    text: "操作",
    value: "log_operation",
  },
  {
    text: "ユーザー",
    value: "log_user",
  },
  {
    text: "コメント",
    value: "log_comment",
  },
  // {
  //   text: "情報",
  //   value: "log_information",
  // },
];

const searchDateFrom = {
  label: "日付（以降）",
  column: "created_at",
  menu: false,
  default: getDateObject(),
  formula: "gte", // 以上
};
const searchDateTo = {
  label: "日付（以前）",
  column: "created_at",
  menu: false,
  default: getDateObject("today", 0, 2),
  formula: "lte", // 以下
};

export default {
  setting,
  headers,
  searchDateFrom,
  searchDateTo,
};
