var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.form.type === 'divider')?_c('div',[_c('v-divider'),_c('div',{staticClass:"mt-5"},[_c('v-icon',{staticStyle:{"vertical-align":"-2px"},attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.form.icon)+" ")]),_c('span',{staticClass:"primary--text text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.form.label)+" ")])],1)],1):_vm._e(),(_vm.form.type === 'text')?_c('v-text-field',{attrs:{"label":_vm.form.label,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.mode === 'post' ||
      _vm.mode === 'put' ||
      _vm.mode === 'patch' ||
      _vm.mode === 'renew'
        ? false
        : true},on:{"input":function($event){return _vm.controlChange()},"change":function($event){return _vm.controlChange()}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'textarea')?_c('v-textarea',{attrs:{"counter":"","label":_vm.form.label,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.mode === 'post' ||
      _vm.mode === 'put' ||
      _vm.mode === 'patch' ||
      _vm.mode === 'renew' ||
      _vm.mode === 'readonly'
        ? false
        : true,"readonly":_vm.mode === 'readonly' ? true : false},on:{"input":function($event){return _vm.controlChange()},"change":function($event){return _vm.controlChange()}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'password')?_c('v-text-field',{attrs:{"label":_vm.form.label,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"append-icon":_vm.form.append ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.form.append ? 'text' : 'password',"disabled":_vm.mode === 'post' ||
      _vm.mode === 'put' ||
      _vm.mode === 'patch' ||
      _vm.mode === 'renew'
        ? false
        : true},on:{"click:append":function($event){_vm.form.append = !_vm.form.append}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'number')?_c('v-text-field',{attrs:{"type":"number","label":_vm.form.label,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.form.disabled === true
        ? true
        : _vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
        ? false
        : true,"min":_vm.form.min},on:{"input":function($event){return _vm.controlChange()},"change":function($event){return _vm.controlChange()}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", _vm._n($$v))},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'select')?_c('v-select',{attrs:{"label":_vm.form.label,"item-text":"text","item-value":"value","items":_vm.form.items,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.mode === 'post' ||
      _vm.mode === 'put' ||
      _vm.mode === 'patch' ||
      _vm.mode === 'renew'
        ? false
        : true,"return-object":""},on:{"change":function($event){return _vm.controlChange()}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(
      _vm.form.type === 'selectBrother' ||
      _vm.form.type === 'selectUser' ||
      _vm.form.type === 'selectAccount' ||
      _vm.form.type === 'selectCustomer' ||
      _vm.form.type === 'selectBrotherAccount' ||
      _vm.form.type === 'selectTransferUser' ||
      _vm.form.type === 'selectClient' ||
      _vm.form.type === 'selectPlan' ||
      _vm.form.type === 'selectOption' ||
      _vm.form.type === 'selectSupportRate' ||
      _vm.form.type === 'selectTaxRate' ||
      _vm.form.type === 'selectTaxRateValue' ||
      _vm.form.type === 'selectCoupon' ||
      _vm.form.type === 'selectIncentive' ||
      _vm.form.type === 'selectIncentiveFirst' ||
      _vm.form.type === 'selectIncentiveMonth'
    )?_c('v-select',{attrs:{"label":_vm.form.label,"item-text":"text","item-value":"value","items":_vm.form.items,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.mode === 'post' ||
      _vm.mode === 'put' ||
      _vm.mode === 'patch' ||
      _vm.mode === 'renew'
        ? false
        : true,"return-object":""},on:{"change":function($event){return _vm.controlChange()}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'datetime')?_c('v-text-field',{attrs:{"label":_vm.form.label,"rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":"true"},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'radio')?_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}},_vm._l((_vm.form.items),function(row,index){return _c('v-radio',{key:'radio-' + index,attrs:{"label":row['text'],"value":row['value'],"color":row['color']}})}),1):_vm._e(),(_vm.form.type === 'checkbox')?_c('v-checkbox',{attrs:{"label":_vm.form.label,"rules":_vm.form.rules},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e(),(_vm.form.type === 'date')?_c('v-menu',{ref:_vm.form.menu,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.form.label,"prepend-icon":"mdi-calendar","rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
            ? false
            : true,"readonly":false},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}},'v-text-field',
          _vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
            ? attrs
            : ''
        ,false),
          _vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
            ? on
            : ''
        ))]}}],null,false,3802967705),model:{value:(_vm.form.menu),callback:function ($$v) {_vm.$set(_vm.form, "menu", $$v)},expression:"form.menu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"jp-ja","day-format":(date) => new Date(date).getDate()},on:{"input":function($event){_vm.form.menu = false}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}})],1):_vm._e(),(_vm.form.type === 'dateMonthFirst' || _vm.form.type === 'dateMonthEnd')?_c('v-menu',{ref:_vm.form.menu,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.form.label,"prepend-icon":"mdi-calendar","rules":_vm.form.rules,"persistent-hint":"","hint":_vm.form.hint,"disabled":_vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
            ? false
            : true,"readonly":_vm.form.write ? false : true},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}},'v-text-field',
          _vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
            ? attrs
            : ''
        ,false),
          _vm.mode === 'post' ||
          _vm.mode === 'put' ||
          _vm.mode === 'patch' ||
          _vm.mode === 'renew'
            ? on
            : ''
        ))]}}],null,false,1444558479),model:{value:(_vm.form.menu),callback:function ($$v) {_vm.$set(_vm.form, "menu", $$v)},expression:"form.menu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"jp-ja","type":"month"},on:{"input":function($event){_vm.form.menu = false}},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}})],1):_vm._e(),(_vm.form.type === 'switch')?_c('v-switch',{attrs:{"inset":"","label":_vm.form.label},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", $$v)},expression:"form.value"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }