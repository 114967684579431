const setting = {
  title: "担当ユーザー",
  apiAddress: "/customer/",
  isBrotherOnly: true,
  isListReadonly: true,
  listSortBy: ["customer_code"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "brothercustomer",
};

const forms = [];

const headers = [
  {
    text: "お客様コード",
    value: "customer_code",
    align: "start",
  },
  {
    text: "会社名",
    value: "customer_name",
    align: "start",
  },
  {
    text: "担当者名",
    value: "person_in_charge",
  },
  {
    text: "都道府県",
    value: "prefecture",
  },
  {
    text: "住所1",
    value: "address_1",
  },
  {
    text: "住所2",
    value: "address_2",
  },
  // {
  //   text: "電話番号",
  //   value: "tel",
  // },
  {
    text: "情報",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  forms,
  headers,
};
