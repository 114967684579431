import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

// 共通前処理
api.interceptors.request.use(
  (config) => {
    // メッセージをクリア
    store.dispatch("message/clearMessages");
    // 認証用トークンがあればリクエストヘッダに加える
    const token = localStorage.getItem("access");
    if (token) {
      config.headers.Authorization = "JWT " + token;
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 共通エラー処理
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error.response=", error.response);
    const status = error.response ? error.response.status : 500;

    // エラーの内容に応じてstoreのメッセージを更新
    let message;
    if (status === 400) {
      // バリデーションNG
      let messages = [].concat.apply([], Object.values(error.response.data));
      if ("password" in error.response.data) {
        messages = error.response.data.password;
      } else {
        const errorData = error.response.data;
        const res = [];
        for (const key in errorData) {
          if (typeof errorData[key] === "string") {
            res.push(errorData[key]);
          } else if (typeof errorData[key] === "object") {
            for (const col1 in errorData[key]) {
              if (typeof errorData[key][col1] === "object") {
                for (const col2 in errorData[key][col1]) {
                  res.push(errorData[key][col1][col2]);
                }
              } else {
                res.push(errorData[key][col1]);
              }
            }
          } else {
            res.push("エラーが発生しました。");
          }
        }
        messages = res;
      }
      let warningMessages = [];
      messages.forEach(function (element) {
        warningMessages.push(
          element
            .replace("custom user", "アカウント")
            .replace(
              "Invalid password.",
              "現在のパスワードが正しくありません。"
            )
            .replace("Invalid token.", "無効なトークンです。")
        );
      });
      store.dispatch("message/setWarningMessages", {
        messages: warningMessages,
      });
    } else if (status === 401) {
      // 認証エラー
      const token = localStorage.getItem("access");
      if (token != null) {
        message = "ログイン有効期限切れ";
      } else {
        message = "認証エラー";
      }
      store.dispatch("auth/logout");
      store.dispatch("message/setErrorMessage", { message: message });
    } else if (status === 403) {
      // 権限エラー
      message = "権限エラーです。";
      store.dispatch("message/setErrorMessage", { message: message });
    } else {
      // その他のエラー
      message = "想定外のエラーです。";
      store.dispatch("message/setErrorMessage", { message: message });
    }
    return Promise.reject(error);
  }
);

export default api;
