import { getMonthObject, getDateObject } from "@/utils/utilities.js";
import { setPaymentMethod } from "@/utils/selectUtility.js";
import {
  requiredRules,
  dateMonthRules,
  selectedNotZeroRules,
  dateRules,
  selectedNotNullRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "請求アジャスト",
  apiAddress: "/invoiceadjustment/",
  listSortBy: ["invoice_adjustment_report_date"],
  listSortDesc: [false],
  isCsvImport: true,
};

const forms = [
  {
    column: "customer",
    label: "お客様",
    type: "selectCustomer",
    items: [],
    rules: [],
    exportColumn: "customer_reference___customer_code",
  },
  {
    column: "invoice_adjustment_report_date",
    label: "請求月",
    type: "dateMonthFirst",
    menu: false,
    default: getMonthObject(),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
    importRules: [dateRules],
    hint: "アジャストデータを含めたい請求月（請求書作成月）を指定します。",
  },
  {
    column: "invoice_adjustment_payment_method",
    label: "支払方法",
    type: "select",
    items: setPaymentMethod(),
    rules: [requiredRules, selectedNotZeroRules],
    cols: 6,
  },
  {
    column: "invoice_adjustment_date",
    label: "明細日付",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
    hint: "請求明細に表示される日付を指定します。",
  },
  {
    column: "invoice_adjustment_name",
    label: "品目名",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "invoice_adjustment_price",
    label: "単価",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "invoice_adjustment_quantity",
    label: "数量",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "tax_rate",
    label: "消費税率",
    type: "selectTaxRateValue",
    items: [],
    default: { text: 10, value: 10 },
    rules: [requiredRules, selectedNotNullRules],
    cols: 6,
  },
  {
    column: "incentive",
    label: "インセンティブ",
    type: "selectIncentive",
    items: [],
    default: { text: "Non", value: null },
    rules: [],
    cols: 6,
    exportColumn: "incentive_reference___incentive_code",
  },
  {
    column: "invoice_adjustment_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "調整コード",
    value: "invoice_adjustment_code",
  },
  {
    text: "請求月",
    value: "invoice_adjustment_report_date",
    align: "center",
  },
  {
    text: "お客様名",
    value: "customer_reference___customer_name",
  },
  {
    text: "明細日付",
    value: "invoice_adjustment_date",
    align: "center",
  },
  {
    text: "支払方法",
    value: "invoice_adjustment_payment_method",
    type: "select",
    items: setPaymentMethod(),
    align: "center",
  },
  {
    text: "品目名",
    value: "invoice_adjustment_name",
  },
  {
    text: "単価",
    value: "invoice_adjustment_price",
    type: "number",
    align: "end",
  },
  {
    text: "数量",
    value: "invoice_adjustment_quantity",
    type: "number",
    align: "end",
  },
  {
    text: "税率",
    value: "tax_rate",
    type: "number",
    align: "end",
  },
  {
    text: "インセンティブ",
    value: "incentive_reference___incentive_rate",
    type: "number",
    align: "end",
  },
  {
    text: "コメント",
    value: "invoice_adjustment_comment",
  },
];

const searchDateFrom = {
  label: "請求月（以降）",
  column: "invoice_adjustment_report_date",
  menu: false,
  default: getDateObject("first", 0),
  formula: "gte", // 以上
};

export default {
  setting,
  forms,
  headers,
  searchDateFrom,
};
