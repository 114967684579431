import {
  setPaymentMethod,
  setInvoiceTaxDisplay,
  setAccountChangeDisplay,
} from "@/utils/selectUtility.js";
import { selectedRules, charLength200Rules } from "@/utils/ruleUtility.js";

const setting = {
  title: "お客様設定",
  originalTitle: "お客様設定",
  apiAddress: "/customer/",
};

const forms = [
  {
    column: "brother",
    label: "担当・Brother",
    type: "selectBrother",
    items: [],
    rules: [],
  },
  {
    column: "payment_method",
    label: "支払方法",
    type: "select",
    items: setPaymentMethod(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "invoice_tax_display",
    label: "請求書税表示",
    type: "select",
    items: setInvoiceTaxDisplay(),
    rules: [selectedRules],
    default: true,
    cols: 6,
  },
  {
    column: "info_link",
    label: "情報リンク",
    type: "text",
    rules: [],
  },
  {
    column: "customer_search_code_1",
    label: "検索コード1",
    type: "text",
    rules: [charLength200Rules],
    cols: 6,
  },
  {
    column: "customer_search_code_2",
    label: "検索コード2",
    type: "text",
    rules: [charLength200Rules],
    cols: 6,
  },
  {
    column: "account_change_setting",
    label: "アカウント変更",
    type: "select",
    items: setAccountChangeDisplay(),
    rules: [selectedRules],
    cols: 6,
  },
];

export default {
  setting,
  forms,
};
