import { equiredOrZeroRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "インセンティブ設定",
  apiAddress: "/contractincentive/",
};

const forms = [
  {
    column: "incentive_first",
    label: "初回インセンティブ",
    type: "selectIncentiveFirst",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "incentive_month",
    label: "月度インセンティブ",
    type: "selectIncentiveMonth",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "incentive_discount_charge",
    label: "クーポンのインセンティブ対象",
    type: "number",
    rules: [equiredOrZeroRules],
    cols: 6,
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
];

export default {
  setting,
  forms,
};
