const setting = {
  title: "お問い合わせ",
  apiAddress: "/supporthandle/",
  isListReadonly: true,
  listSortBy: ["support_handle_first_date"],
  listSortDesc: [false],
};

const headers = [
  {
    text: "日付",
    value: "support_handle_first_date",
    align: "start",
  },
  {
    text: "担当者",
    value: "support_handle_first_staff",
    align: "start",
  },
  {
    text: "対応内容",
    value: "support_handle_first_comment",
    align: "start",
  },
];

export default {
  setting,
  headers,
};
