import {
  requiredRules,
  passwordLengthRules,
  passwordHalfRules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "パスワード再設定",
  buttonLabelSubmit: "再設定",
  thirdMessage: ["パスワードが再設定されました"],
  isStepThird: true,
  storeAddress: "signup/reset_password",
  isResetPassword: true,
  successMessage: "パスワードが再設定されました",
};

const forms = [
  {
    column: "new_password",
    label: "新パスワード",
    type: "password",
    rules: [requiredRules, passwordHalfRules, passwordLengthRules],
    append: false,
    emit: "newPassword",
    hint: "8文字以上のパスワードを設定してください",
  },
  {
    column: "re_new_password",
    label: "確認用パスワード",
    type: "password",
    rules: [requiredRules, passwordHalfRules, passwordLengthRules],
    append: false,
    emit: "reNewPassword",
  },
];

export default {
  setting,
  forms,
};
