import { setPrefectureList } from "@/utils/selectUtility.js";
import {
  requiredRules,
  zipCodeRules,
  selectedRules,
  telRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "お客様",
  apiAddress: "/customer/",
  apiListParam: "user__is_staff=False",
  isListReadonly: true,
  listSortBy: ["customer_code"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "customer",
  isCustomerExcelExport: true,
};

const forms = [
  {
    column: "customer_name",
    label: "会社名",
    type: "text",
    rules: [requiredRules, charLength200Rules],
    copy: true,
  },
  {
    column: "person_in_charge",
    label: "担当者名",
    type: "text",
    rules: [requiredRules, charLength200Rules],
    copy: true,
  },
  {
    column: "zip_code",
    label: "郵便番号",
    type: "text",
    rules: [requiredRules, zipCodeRules],
    cols: 6,
    hint: "0009999（ハイフンなし）",
  },
  {
    column: "prefecture",
    label: "都道府県",
    type: "select",
    items: setPrefectureList(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "address_1",
    label: "住所（市町村・番地）",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "address_2",
    label: "住所（建物名）",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "tel",
    label: "電話番号",
    type: "text",
    rules: [requiredRules, telRules],
    cols: 6,
    hint: "0311112222（ハイフンなし）",
  },
];

const postFixedValue = [
  {
    column: "user",
    type: "user",
  },
];

const headers = [
  {
    text: "お客様コード",
    value: "customer_code",
    align: "start",
  },
  {
    text: "アカウント",
    value: "email",
  },
  {
    text: "会社名",
    value: "customer_name",
  },
  {
    text: "LINK",
    value: "customerInfoLink",
    align: "center",
    handleKey: ["info_link"],
  },
  {
    text: "担当者名",
    value: "person_in_charge",
  },
  {
    text: "都道府県",
    value: "prefecture",
  },
  {
    text: "住所1",
    value: "address_1",
  },
  {
    text: "検索コード1",
    value: "customer_search_code_1",
  },
  {
    text: "設定",
    value: "settingItem",
    align: "center",
    sortable: false,
    settingType: "customersetting",
    handleKey: ["id", "account_change_setting"],
  },
  {
    text: "支払設定",
    value: "isBankStatus",
    align: "center",
    handleKey: ["is_bank"],
  },
  {
    text: "情報",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchSelect = {
  label: "支払設定",
  column: "search_bank_param",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "未登録" },
    { value: 10, text: "登録済" },
    { value: 50, text: "銀行振込" },
    { value: 60, text: "口座振替" },
    { value: 70, text: "クレジット" },
  ],
  value: -1,
};

const searchSubSelect = {
  label: "アカウント変更",
  column: "account_change_param",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "禁止" },
    { value: 10, text: "許可" },
  ],
  value: -1,
};

const tooltipText = [
  "契約サイトに登録されたお客様を確認できます。",
  "（STAFFユーザーのアカウントは表示されません）",
  "",
  "■設定",
  "担当Brotherやトークボードリンクの設定、請求用の設定が行えます。",
  "Brotherを設定するとBrotherアカウントの担当ユーザーリストにお客様情報が表示されます。",
  "トークボードリンクを設定するとお客様情報画面にリンクボタンが表示されます。",
  "請求用の設定はお客様情報画面に表示され、請求書作成時に設定が参照されます。",
  "",
  "■支払設定",
  "支払に関する情報を登録できます。",
  "支払方法が「銀行振込」あるいは「口座振替」にて請求書が作成されます。",
  "「口座振替」を指定した場合は「口座振替請求可能開始日」を設定する必要があります。",
  "上記の設定が行えているとチェックアイコンが表示され、支払設定の有効化を確認できます。",
  "",
  "■情報",
  "情報パネルからお客様の各情報を確認することができます。",
];

const excelCustomerFormat = [
  { label: "アカウント", wpx: 120 },
  { label: "お客様コード", wpx: 90 },
  { label: "お客様名", wpx: 220 },
  { label: "担当者", wpx: 90 },
  { label: "郵便番号", wpx: 80 },
  { label: "都道府県", wpx: 80 },
  { label: "住所1", wpx: 200 },
  { label: "住所2", wpx: 200 },
  { label: "電話番号", wpx: 80 },
  { label: "請求先名", wpx: 220 },
  { label: "請求先担当者", wpx: 90 },
  { label: "請求先郵便番号", wpx: 80 },
  { label: "請求先都道府県", wpx: 80 },
  { label: "請求先住所1", wpx: 200 },
  { label: "請求先住所2", wpx: 200 },
  { label: "請求先電話番号", wpx: 80 },
  { label: "支払方法", wpx: 100 },
  { label: "担当ブラザーコード", wpx: 120 },
  { label: "担当ブラザー名", wpx: 220 },
  { label: "検索コード1", wpx: 90 },
  { label: "検索コード2", wpx: 90 },
  { label: "顧客コード", wpx: 90 },
  { label: "顧客名", wpx: 220 },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
  searchSelect,
  searchSubSelect,
  tooltipText,
  excelCustomerFormat,
};
