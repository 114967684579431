<template>
  <v-data-table
    :headers="formObject.headers"
    :items="tableItems"
    item-key="id"
    multi-sort
    :search="search"
    :show-expand="formObject.setting.showExpand"
    :expanded.sync="expanded"
    :single-expand="true"
    :loading="loading"
    loading-text="Loading... Please wait"
    :no-data-text="dataTableNoDataText"
    :footer-props="dataTableFooterProps"
    :sort-by="tableSortBy"
    @update:sort-by="updateSortBy"
    :sort-desc="tableSortDesc"
    @update:sort-desc="updateSortDesc"
    :items-per-page="tableItemsPerPage"
    @update:items-per-page="updateItemsPerPage"
    class="mygen-table mygenText--text"
  >
    <template v-slot:top>
      <v-card
        flat
        v-if="
          isDetailSearch ||
          isSearchSelect ||
          isExcelExport ||
          isPaymentUserAllExcelExport ||
          isPaymentDetailAllExcelExport ||
          (isReadonly === false && isAddButton)
        "
      >
        <v-card-text>
          <v-container>
            <v-row>
              <!-- Searchボックス -->
              <v-col v-if="isDetailSearch" cols="12" sm="6" md="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="my-0 py-0"
                />
              </v-col>
              <!-- 日付Search -->
              <v-col
                v-if="isSearchDateFrom"
                cols="12"
                sm="6"
                md="3"
                class="pa-0"
              >
                <v-menu
                  :ref="searchDateFromMenu"
                  v-model="searchDateFromMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchDateFromValue"
                      :label="searchDateFromLabel"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :readonly="false"
                      @change="loadData()"
                      class="ma-0"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchDateFromValue"
                    no-title
                    locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()"
                    @input="searchDateFromMenu = false"
                    @change="loadData()"
                  />
                </v-menu>
              </v-col>
              <v-col v-if="isSearchDateTo" cols="12" sm="6" md="3" class="pa-0">
                <v-menu
                  :ref="searchDateToMenu"
                  v-model="searchDateToMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="searchDateToValue"
                      :label="searchDateToLabel"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :readonly="false"
                      @change="loadData()"
                      class="ma-0"
                    />
                  </template>
                  <v-date-picker
                    v-model="searchDateToValue"
                    no-title
                    locale="jp-ja"
                    :day-format="(date) => new Date(date).getDate()"
                    @input="searchDateToMenu = false"
                    @change="loadData()"
                  />
                </v-menu>
              </v-col>
              <!-- セレクタSearch -->
              <v-col v-if="isSearchSelect" cols="12" sm="6" md="3">
                <v-select
                  v-model="searchSelectValue"
                  :items="searchSelectItems"
                  :label="searchSelectLabel"
                  item-text="text"
                  item-value="value"
                  @change="loadData()"
                  class="my-0 py-0"
                />
              </v-col>
              <!-- セレクタSubSearch -->
              <v-col v-if="isSearchSubSelect" cols="12" sm="6" md="3">
                <v-select
                  v-model="searchSubSelectValue"
                  :items="searchSubSelectItems"
                  :label="searchSubSelectLabel"
                  item-text="text"
                  item-value="value"
                  @change="loadData()"
                  class="my-0 py-0"
                />
              </v-col>
              <!-- ExcelExport -->
              <v-col v-if="isExcelExport" cols="12" sm="6" md="3">
                <v-btn
                  block
                  color="primary"
                  outlined
                  rounded
                  class="font-weight-bold"
                  @click="excelListExport()"
                >
                  Excel
                  <v-icon right dark> mdi-download </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="isPaymentUserAllExcelExport" cols="12" sm="6" md="3">
                <v-btn
                  block
                  color="primary"
                  outlined
                  rounded
                  class="font-weight-bold"
                  @click="excelPaymentUserAllExport()"
                >
                  User Excel
                  <v-icon right dark> mdi-download </v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="isPaymentDetailAllExcelExport"
                cols="12"
                sm="6"
                md="3"
              >
                <v-btn
                  block
                  color="primary"
                  outlined
                  rounded
                  class="font-weight-bold"
                  @click="excelPaymentDetailAllExport()"
                >
                  Detail Excel
                  <v-icon right dark> mdi-download </v-icon>
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="6" md="3" align="right">
                <!-- データ追加ダイアログ -->
                <v-dialog
                  v-model="dialog"
                  :key="'dialog-' + formKey"
                  max-width="600"
                  :fullscreen="$vuetify.breakpoint.xs"
                >
                  <template
                    v-if="isReadonly === false && isAddButton"
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-btn
                      block
                      dark
                      rounded
                      elevation="4"
                      class="mygen-button font-weight-bold"
                      v-bind="attrs"
                      v-on="on"
                    >
                      追加
                    </v-btn>
                  </template>
                  <v-card>
                    <ModelForm
                      :key="'model-' + formKey"
                      :propsFormMode="childMode"
                      :propsFormType="formType"
                      :propsFormDialog="true"
                      :propsEditId="childEditId"
                      :propsFixedValue="filterId"
                      @cancel="cancelDialog"
                      @close="closeDialog"
                    />
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- データハンドリングダイアログ -->
      <v-dialog
        v-model="dialogSettingItem"
        max-width="1000"
        :fullscreen="$vuetify.breakpoint.xs"
      >
        <v-card
          v-if="
            dialogSettingType === 'contractsite' ||
            dialogSettingType === 'contractsitecancel' ||
            dialogSettingType === 'contractincentive' ||
            dialogSettingType === 'contractrenewalsingle'
          "
        >
          <ModelForm
            :key="'modelform-' + formKey"
            propsFormMode="patch"
            :propsFormType="dialogSettingType"
            :propsFormDialog="true"
            :propsEditId="dialogSettingId"
            @cancel="cancelDialog"
            @close="closeDialog"
          />
        </v-card>
      </v-dialog>
    </template>

    <!-- リンクデータ（サイトアドレス） -->
    <template v-slot:[`item.site_address`]="{ item }">
      <ButtonLink
        :propsLabel="item.site_address"
        propsTooltipText="サイトを開く"
        :propsIsHref="true"
        :propsLink="item.site_address"
      />
    </template>
    <!-- ステータスチップ -->
    <template
      v-for="rowChip in getSelectName"
      v-slot:[`item.${rowChip}`]="{ item }"
    >
      <ChipStatus
        :key="'model_table_chip-' + rowChip"
        :propsType="rowChip"
        :propsValue="item[rowChip]"
      />
    </template>
    <!-- 編集 -->
    <template v-slot:[`item.actions`]="{ item }">
      <div
        v-if="
          (isStaff === false ||
            (isStaff && item.management_coin_class !== 10)) &&
          (!item.is_lock || item.is_lock === false)
        "
        class="d-flex justify-center"
      >
        <ButtonFloating
          :propsClick="editItem"
          :propsParam1="item"
          :propsIcon="'bi-pen'"
          class="mx-1"
        />
        <ButtonFloating
          :propsClick="deleteItem"
          :propsParam1="item"
          :propsIcon="'bi-trash'"
        />
      </div>
      <div v-else style="white-space: nowrap">
        <ButtonFloating
          :propsClick="readItem"
          :propsParam1="item"
          :propsIcon="'bi-window'"
        />
      </div>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
      <ButtonFloating
        :propsClick="deleteItem"
        :propsParam1="item"
        :propsIcon="'bi-trash'"
      />
    </template>
    <!-- 請求書アイコン -->
    <template v-slot:[`item.reportInvoice`]="{ item }">
      <router-link
        :to="{ name: 'reportInvoice', params: { id: item.id } }"
        class="text-decoration-none"
        target="_blank"
      >
        <v-btn fab dark small elevation="0" color="mygenIcon">
          <v-icon size="22" color="mygenText"> bi-file-earmark </v-icon>
        </v-btn>
      </router-link>
    </template>
    <!-- インセンティブ出力アイコン -->
    <template v-slot:[`item.excelPaymentUser`]="{ item }">
      <ButtonFloating
        :propsClick="excelPaymentUserExport"
        :propsParam1="item"
        :propsColumn="'id'"
        :propsIcon="'bi-file-earmark-excel'"
      />
    </template>
    <!-- インセンティブ明細出力アイコン -->
    <template v-slot:[`item.excelPaymentDetail`]="{ item }">
      <ButtonFloating
        :propsClick="excelPaymentDetailExport"
        :propsParam1="item"
        :propsColumn="'id'"
        :propsIcon="'bi-file-earmark-excel'"
      />
    </template>
    <!-- 契約サイトリンクアイコン -->
    <template v-slot:[`item.siteAddress`]="{ item }">
      <div v-if="item.site_address">
        <ButtonFloating
          :propsIsHref="true"
          :propsLink="item.site_address"
          propsTooltipText="サイトへ移動"
          :propsIcon="'mdi-launch'"
        />
      </div>
      <div v-else>
        <v-icon size="22" color="grey"> mdi-launch </v-icon>
      </div>
    </template>
    <!-- サイト設定アイコン -->
    <template v-slot:[`item.contractSite`]="{ item }">
      <ButtonFloating
        :propsClick="contractSite"
        :propsParam1="item"
        :propsIcon="'bi-gear-wide'"
      />
    </template>
    <!-- 支払設定アイコン -->
    <template v-slot:[`item.contractIncentive`]="{ item }">
      <div v-if="item.is_contract_incentive === false">
        <ButtonFloating
          :propsClick="contractIncentive"
          :propsParam1="item"
          :propsIcon="'bi-bank2'"
        />
      </div>
      <div v-else>
        <v-icon size="22" color="grey"> bi-bank2 </v-icon>
      </div>
    </template>
    <!-- 更新設定アイコン -->
    <template v-slot:[`item.contractRenewalSingle`]="{ item }">
      <div
        v-if="
          item.contract___contract_class === 0 &&
          item.order_status === 50 &&
          item.renewal_revision === item.contract_revision
        "
      >
        <ButtonFloating
          :propsClick="contractRenewalSingle"
          :propsParam1="item"
          :propsIcon="'bi-wrench'"
        />
      </div>
      <div v-else>
        <v-icon size="22" color="grey"> bi-wrench </v-icon>
      </div>
    </template>
    <!-- 解約アイコン -->
    <template v-slot:[`item.contractSiteCancel`]="{ item }">
      <div
        v-if="
          item.contract_class_status === 1050 ||
          item.contract_class_status === 2050
        "
      >
        <ButtonFloating
          :propsClick="contractSiteCancel"
          :propsParam1="item"
          :propsIcon="'bi-scissors'"
        />
      </div>
      <div v-else>
        <v-icon size="22" color="grey"> bi-scissors </v-icon>
      </div>
    </template>
    <!-- 請求書アイコン -->
    <template v-slot:[`item.reportPayment`]="{ item }">
      <router-link
        :to="{ name: 'reportPayment', params: { id: item.id } }"
        class="text-decoration-none"
        target="_blank"
      >
        <v-btn fab dark small elevation="0" color="mygenIcon">
          <v-icon size="22" color="mygenText"> bi-file-earmark </v-icon>
        </v-btn>
      </router-link>
    </template>
    <!-- お問い合わせリンクアイコン -->
    <template v-slot:[`item.supportLink`]="{ item }">
      <div v-if="item.support_link">
        <ButtonFloating
          :propsIsHref="true"
          :propsLink="item.support_link"
          propsTooltipText="リンク先へ移動"
          :propsIcon="'mdi-launch'"
        />
      </div>
      <div v-else>
        <v-icon size="22" color="grey"> mdi-launch </v-icon>
      </div>
    </template>

    <!-- expanded-item -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="mygenCard">
        <v-container>
          <v-row class="my-5">
            <v-col>
              <PanelFlow
                v-if="isExpandContractHistory"
                :key="'list-expand_flow-' + formKey + '-' + item.id"
                :propsContractPlanId="item.id"
                :propsIsPanel="false"
              />
              <ContainerContractDetail
                v-if="isExpandContractInformation"
                :key="'table-expand_contract_information-' + item.id"
                :propsData="item.expand_child_contents"
                :propsSetting="true"
                :propsChange="false"
                :propsShowCharge="true"
              />
              <ModelDetail
                v-if="isExpandInvoiceDetail"
                :key="'table-expand_invoice_detail-' + formKey + '-' + item.id"
                propsFormType="invoicereportdetail"
                propsFilterColumn="id"
                :propsFilterId="item.id"
                :propsIsSpecifyId="true"
              />
              <ModelDetail
                v-if="isExpandPaymentDetail"
                :key="'table-expand_payment_deail-' + formKey + '-' + item.id"
                propsFormType="paymentbyuser"
                propsFilterColumn="payment_header"
                :propsFilterId="item.id"
                :propsIsSpecifyId="true"
              />
              <ModelDetail
                v-if="isExpandSupportHandle"
                :key="'table-expand_support_handle-' + formKey + '-' + item.id"
                propsFormType="supporthandle"
                propsFilterColumn="id"
                :propsFilterId="item.id"
                propsKeyColumn="key_id"
              />
            </v-col>
            <v-col v-if="isExpandContractInformation" cols="12" align="center">
              <div
                v-if="
                  item.contract_class_status === 1050 ||
                  item.contract_class_status === 2050
                "
              >
                <v-btn
                  color="error"
                  rounded
                  @click="contractSiteCancel(item)"
                  class="font-weight-bold"
                >
                  <v-icon small start class="mr-1">mdi-alert</v-icon>
                  この契約を解約する
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </td>
    </template>

    <OverlayProgress v-if="progress" />
  </v-data-table>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import store from "@/store";
import { createKey } from "@/utils/utilities.js";
import {
  getFormObject,
  getApiParameters,
  setApiToListData,
} from "@/utils/formUtility.js";
import { getSelectName, getSelectBrotherData } from "@/utils/selectUtility.js";
import {
  dataTableNoDataText,
  dataTableFooterProps,
} from "@/utils/listUtility.js";
import {
  exportListExcel,
  exportPaymentUserExcel,
  exportPaymentUserAllExcel,
  exportPaymentDetailAllExcel,
  exportPaymentDetailExcel,
} from "@/utils/excelUtility.js";
import api from "@/services/api";
import ButtonFloating from "@/components/atoms/ButtonFloating.vue";
import ButtonLink from "@/components/atoms/ButtonLink.vue";
import ChipStatus from "@/components/atoms/ChipStatus.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import ModelForm from "@/views/model/ModelForm.vue";
import ModelDetail from "@/views/model/ModelDetail.vue";
import ContainerContractDetail from "@/components/molecules/ContainerContractDetail.vue";
import PanelFlow from "@/components/organisms/PanelFlow.vue";

export default defineComponent({
  name: "ModelTable",

  components: {
    ButtonFloating,
    ButtonLink,
    ChipStatus,
    OverlayProgress,
    ModelForm,
    ModelDetail,
    ContainerContractDetail,
    PanelFlow,
  },

  props: {
    propsFormType: { type: String, required: true },
    propsFilterColumn: { type: String, required: true },
    propsFilterId: { type: String, required: true },
    propsIsAddButton: { type: Boolean, default: true },
    propsIsSearchBox: { type: Boolean, default: true },
  },

  emits: ["cancel", "update"],

  setup(props, { emit }) {
    const isLoggedIn = computed(() => {
      return store.state.auth.isLoggedIn;
    });
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const formType = computed(() => {
      if (isStaff.value && props.propsFormType === "managementcoinbrother") {
        return "managementcoin";
      }
      return props.propsFormType;
    });
    const filterColumn = ref(props.propsFilterColumn);
    const filterId = ref(props.propsFilterId);
    const isAddButton = ref(props.propsIsAddButton);
    const isDetailSearch = ref(props.propsIsSearchBox);

    const search = ref("");
    const dialog = ref(false);
    const dialogSettingItem = ref(false);
    const dialogSettingType = ref("");
    const dialogSettingId = ref("");
    const isDialogCancel = ref(false);

    const isSearchDateFrom = ref(false);
    const isSearchDateTo = ref(false);
    const searchDateFromValue = ref("");
    const searchDateFromLabel = ref("");
    const searchDateFromMenu = ref(false);
    const searchDateToValue = ref("");
    const searchDateToLabel = ref("");
    const searchDateToMenu = ref(false);
    const isSearchSelect = ref(false);
    const searchSelectValue = ref(-1);
    const searchSelectType = ref("");
    const searchSelectItems = ref([]);
    const searchSelectLabel = ref("");
    const isSearchSubSelect = ref(false);
    const searchSubSelectValue = ref(-1);
    const searchSubSelectType = ref("");
    const searchSubSelectItems = ref([]);
    const searchSubSelectLabel = ref("");

    const formObject = getFormObject(formType.value);
    const isReadonly = ref(formObject.setting.isListReadonly === true);
    const isLockMode = ref(false);
    if (formObject.searchDateFrom) {
      isSearchDateFrom.value = true;
      if (formObject.searchDateFrom.default !== "") {
        searchDateFromValue.value = formObject.searchDateFrom.default;
      }
      searchDateFromLabel.value = formObject.searchDateFrom.label;
      searchDateFromMenu.value = formObject.searchDateFrom.menu;
    }
    if (formObject.searchDateTo) {
      isSearchDateTo.value = true;
      if (formObject.searchDateTo.default !== "") {
        searchDateToValue.value = formObject.searchDateTo.default;
      }
      searchDateToLabel.value = formObject.searchDateTo.label;
      searchDateToMenu.value = formObject.searchDateTo.menu;
    }
    if (formObject.searchSelect) {
      isSearchSelect.value = true;
      searchSelectValue.value = formObject.searchSelect.value;
      searchSelectType.value = formObject.searchSelect.type;
      if (searchSelectType.value === "brother") {
        searchSelectItems.value = getSelectBrotherData(isLoggedIn);
      } else {
        searchSelectItems.value = formObject.searchSelect.items;
      }
      searchSelectLabel.value = formObject.searchSelect.label;
    }
    if (formObject.searchSubSelect) {
      isSearchSubSelect.value = true;
      searchSubSelectValue.value = formObject.searchSubSelect.value;
      searchSubSelectType.value = formObject.searchSubSelect.type;
      searchSubSelectItems.value = formObject.searchSubSelect.items;
      searchSubSelectLabel.value = formObject.searchSubSelect.label;
    }

    const loading = ref(false);

    const isDelete = ref(false);
    const editedIndex = ref(-1);
    const tableItems = ref([]);
    const tableSortBy = ref(formObject.setting.listSortBy || []);
    const tableSortDesc = ref(formObject.setting.listSortDesc || []);
    const tableItemsPerPage = ref(10);
    // ローカルストレージの情報をセット
    if (localStorage.getItem("modelTableOptions")) {
      let tempOptions = JSON.parse(localStorage.getItem("modelTableOptions"));
      if (!tempOptions) {
        tempOptions = {};
      }
      if ("sortBy" in tempOptions && formType.value in tempOptions["sortBy"]) {
        tableSortBy.value = tempOptions["sortBy"][formType.value];
      }
      if (
        "sortDesc" in tempOptions &&
        formType.value in tempOptions["sortDesc"]
      ) {
        tableSortDesc.value = tempOptions["sortDesc"][formType.value];
      }
      if (
        "itemsPerPage" in tempOptions &&
        formType.value in tempOptions["itemsPerPage"]
      ) {
        tableItemsPerPage.value = tempOptions["itemsPerPage"][formType.value];
      }
    }

    const itemsKey = ref([]);
    const expanded = ref([]);
    const storeMessage = ref("");

    const returnExpandFlag = (type) => {
      if (
        formObject.setting.showExpand &&
        formObject.setting.expandType === type
      ) {
        return true;
      }
      return false;
    };
    const isExpandContractHistory = computed(() => {
      return returnExpandFlag("contracthistory");
    });
    const isExpandContractInformation = computed(() => {
      return returnExpandFlag("contractinformation");
    });
    const isExpandInvoiceDetail = computed(() => {
      return returnExpandFlag("invoicedetail");
    });
    const isExpandPaymentDetail = computed(() => {
      return returnExpandFlag("paymentdetail");
    });
    const isExpandSupportHandle = computed(() => {
      return returnExpandFlag("supporthandle");
    });

    const childMode = computed(() => {
      return isDelete.value
        ? "delete"
        : isReadonly.value || isLockMode.value
        ? "readonly"
        : editedIndex.value === -1
        ? "post"
        : "patch";
    });
    const childEditId = computed(() => {
      return editedIndex.value === -1 ? "" : itemsKey.value[editedIndex.value];
    });
    const isExcelExport = computed(() => {
      if (formObject.setting.isExcelExport) {
        return true;
      }
      return false;
    });
    const isPaymentUserAllExcelExport = computed(() => {
      if (formObject.setting.isPaymentUserAllExcelExport) {
        return true;
      }
      return false;
    });
    const isPaymentDetailAllExcelExport = computed(() => {
      if (formObject.setting.isPaymentDetailAllExcelExport) {
        return true;
      }
      return false;
    });

    const progress = ref(false);
    const formKey = ref(createKey());

    const updateOptions = (type, value) => {
      // オプションをローカルストレージに保存
      if (value) {
        let tempOptions = JSON.parse(localStorage.getItem("modelTableOptions"));
        if (!tempOptions) {
          tempOptions = {};
        }
        if (type in tempOptions === false) {
          tempOptions[type] = {};
        }
        tempOptions[type][formType.value] = value;
        localStorage.setItem("modelTableOptions", JSON.stringify(tempOptions));
      }
    };
    const updateSortBy = (value) => {
      updateOptions("sortBy", value);
    };
    const updateSortDesc = (value) => {
      updateOptions("sortDesc", value);
    };
    const updateItemsPerPage = (value) => {
      updateOptions("itemsPerPage", value);
    };

    /**
     * データセット
     */
    const dataDisplay = (res) => {
      // リストセット
      const itemData = setApiToListData(res, formObject.headers);
      tableItems.value = itemData["listData"];
      itemsKey.value = itemData["listKey"];

      if (storeMessage.value) {
        store.dispatch("message/setInfoMessage", {
          message: storeMessage.value,
        });
        storeMessage.value = "";
      }
    };

    /**
     * api:データ取得
     */
    const loadData = () => {
      loading.value = true;
      // データ取得アドレス生成
      let address = formObject.setting.apiAddress;
      if (formObject.setting.apiListAddress !== undefined) {
        address = formObject.setting.apiListAddress;
      }

      // フィルター判定
      if (!filterId.value) {
        dataDisplay([]);
        loading.value = false;
        return;
      }

      // 検索条件パラメータ作成
      let param = getApiParameters(
        formObject,
        filterColumn,
        filterId,
        isSearchSelect,
        searchSelectValue,
        isSearchSubSelect,
        searchSubSelectValue,
        searchDateFromValue,
        searchDateToValue
      );

      if (param.length > 0) {
        address += "?" + param;
      }

      // データ取得
      api({
        method: "get",
        url: address,
      })
        .then((response) => {
          const res = response.data;
          dataDisplay(res);
        })
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const editItem = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      dialog.value = true;
    };

    const deleteItem = (item) => {
      isDelete.value = true;
      editedIndex.value = tableItems.value.indexOf(item);
      dialog.value = true;
    };

    const readItem = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      isLockMode.value = true;
      dialog.value = true;
    };

    const contractSite = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      dialogSettingType.value = "contractsite";
      dialogSettingId.value = childEditId.value;
      dialogSettingItem.value = true;
    };

    const contractSiteCancel = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      dialogSettingType.value = "contractsitecancel";
      dialogSettingId.value = childEditId.value;
      dialogSettingItem.value = true;
    };

    const contractIncentive = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      dialogSettingType.value = "contractincentive";
      dialogSettingId.value = item["child_handle_key"];
      dialogSettingItem.value = true;
    };

    const contractRenewalSingle = (item) => {
      editedIndex.value = tableItems.value.indexOf(item);
      dialogSettingType.value = "contractrenewalsingle";
      dialogSettingId.value = childEditId.value;
      dialogSettingItem.value = true;
    };

    const cancelDialog = (message) => {
      isDialogCancel.value = true; // キャンセルセット
      editedIndex.value = -1;
      dialog.value = false;
      dialogSettingItem.value = false;
      dialogSettingType.value = "";
      dialogSettingId.value = "";
      isDelete.value = false; // 初期化
      isLockMode.value = false; // 初期化
      if (message) {
        storeMessage.value = message;
      }
      emit("cancel");
    };

    const closeDialog = (message) => {
      editedIndex.value = -1;
      expanded.value = [];
      dialog.value = false;
      dialogSettingItem.value = false;
      dialogSettingType.value = "";
      dialogSettingId.value = "";
      isDelete.value = false; // 初期化
      isLockMode.value = false; // 初期化
      if (message) {
        storeMessage.value = message;
      }
      emit("update");
      loadData();
    };

    const clearMessage = () => {
      store.dispatch("message/clearMessages");
    };

    const checkLogin = () => {
      if (!isLoggedIn) {
        store.dispatch("message/setErrorMessage", {
          message: "再ログインしてください。",
        });
        return false;
      }
      return true;
    };

    /**
     * Excel出力
     */
    const excelListExport = () => {
      if (!isExcelExport.value) return;
      exportListExcel(formObject, tableItems);
      return;
    };

    /**
     * 支払ヘッダーデータExcel出力（全件）
     */
    const excelPaymentUserAllExport = () => {
      if (!isPaymentUserAllExcelExport.value) return;
      if (!filterId.value) return;
      exportPaymentUserAllExcel(formObject, filterId.value);
      return;
    };

    /**
     * 支払明細データExcel出力（全件）
     */
    const excelPaymentDetailAllExport = () => {
      if (!isPaymentDetailAllExcelExport.value) return;
      if (!filterId.value) return;
      exportPaymentDetailAllExcel(formObject, filterId.value);
      return;
    };

    /**
     * 支払データExcel出力
     */
    const excelPaymentUserExport = (header) => {
      if (!checkLogin()) return;
      if (header.length === 0 || !formObject.excelPaymentUserFormat) return;
      exportPaymentUserExcel(formObject, header);
      return;
    };

    /**
     * 支払明細データExcel出力
     */
    const excelPaymentDetailExport = (header) => {
      if (!checkLogin()) return;
      if (header.length === 0 || !formObject.excelPaymentDetailFormat) return;
      exportPaymentDetailExcel(formObject, header);
      return;
    };

    onMounted(() => {
      clearMessage();
    });

    watch(dialog, (val) => {
      if (isDialogCancel.value) {
        isDialogCancel.value = false;
      } else {
        val || closeDialog();
        formKey.value = createKey();
      }
    });
    watch(dialogSettingItem, (val) => {
      if (isDialogCancel.value) {
        isDialogCancel.value = false;
      } else {
        val || closeDialog();
        formKey.value = createKey();
      }
    });

    // データ取得
    loadData();

    return {
      getSelectName,
      dataTableNoDataText,
      dataTableFooterProps,

      isLoggedIn,
      isStaff,
      formType,
      filterColumn,
      filterId,
      isAddButton,
      isDetailSearch,

      search,
      dialog,
      dialogSettingItem,
      dialogSettingType,
      dialogSettingId,
      isSearchDateFrom,
      isSearchDateTo,
      searchDateFromValue,
      searchDateFromLabel,
      searchDateFromMenu,
      searchDateToValue,
      searchDateToLabel,
      searchDateToMenu,
      isSearchSelect,
      searchSelectValue,
      searchSelectType,
      searchSelectItems,
      searchSelectLabel,
      isSearchSubSelect,
      searchSubSelectValue,
      searchSubSelectType,
      searchSubSelectItems,
      searchSubSelectLabel,

      formObject,
      isReadonly,
      isLockMode,
      loading,

      isDelete,
      editedIndex,
      tableItems,
      tableSortBy,
      tableSortDesc,
      tableItemsPerPage,

      itemsKey,
      expanded,
      storeMessage,

      isExpandContractHistory,
      isExpandContractInformation,
      isExpandInvoiceDetail,
      isExpandPaymentDetail,
      isExpandSupportHandle,

      childMode,
      childEditId,
      isExcelExport,
      isPaymentUserAllExcelExport,
      isPaymentDetailAllExcelExport,

      progress,
      formKey,

      updateSortBy,
      updateSortDesc,
      updateItemsPerPage,
      dataDisplay,
      loadData,

      editItem,
      deleteItem,
      readItem,
      contractSite,
      contractSiteCancel,
      contractIncentive,
      contractRenewalSingle,
      cancelDialog,
      closeDialog,
      clearMessage,

      excelListExport,
      excelPaymentUserAllExport,
      excelPaymentDetailAllExport,
      excelPaymentUserExport,
      excelPaymentDetailExport,
    };
  },
});
</script>
