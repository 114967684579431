<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container
      :class="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'px-16 py-10'
      "
    >
      <v-row>
        <v-col>
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsComment="titleComment"
          />
        </v-col>
      </v-row>

      <v-row v-if="!isRenewMode">
        <v-spacer />
        <v-col>
          <v-card flat rounded="lg" class="mygenCard pa-3">
            <v-card-text
              v-for="(row, index) in listData"
              :key="index"
              class="pa-1 d-flex"
            >
              <v-sheet
                color="mygenBase"
                height="60"
                width="200"
                class="d-flex align-center pa-3"
                outlined
                dark
              >
                <span class="font-weight-bold mygenText--text">
                  {{ row["label"] }}
                </span>
              </v-sheet>
              <v-sheet
                color="mygenCard"
                height="60"
                width="500"
                class="d-flex align-center px-5 mygenText--text"
                outlined
              >
                {{ row["value"] }}
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row v-if="!isRenewMode">
        <v-spacer />
        <v-col cols="12" md="6">
          <v-dialog
            v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xs"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                dark
                rounded
                elevation="4"
                class="mygen-button font-weight-bold"
                v-bind="attrs"
                v-on="on"
              >
                登録情報の変更
              </v-btn>
            </template>
            <v-card>
              <ModelForm
                :key="'model-' + formKey"
                :propsFormMode="childMode"
                :propsFormType="formType"
                :propsFormDialog="true"
                :propsStoreId="storeId"
                @cancel="cancelDialog"
                @close="closeDialog"
              />
            </v-card>
          </v-dialog>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row v-if="isRenewMode">
        <ModelForm
          :key="'renew-' + formKey"
          :propsFormMode="childMode"
          :propsFormType="formType"
          :propsFormDialog="false"
          :propsIsTitle="false"
          :propsStoreId="storeId"
          @update="updateForm"
        />
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { onBeforeRouteUpdate } from "vue-router/composables";
import { createKey } from "@/utils/utilities.js";
import {
  getFormObject,
  settingDictionaryForms,
  setApiToFormsData,
} from "@/utils/formUtility.js";
import ModelForm from "@/views/model/ModelForm.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";

export default defineComponent({
  name: "ListUser",

  components: {
    ModelForm,
    DivTitle,
    OverlayProgress,
  },

  props: {
    propsFormType: {
      type: String,
      required: true,
    },
    propsStoreId: {
      type: String,
      default: "",
    },
  },

  emits: ["homeReload", "homeUpdate"],

  setup(props, { emit }) {
    const editId = computed(() => {
      return store.state.auth.customerId;
    });
    const formType = ref(props.propsFormType);
    const storeId = ref(props.propsStoreId);

    const isRenewMode = computed(() => {
      if (formType.value === "setpassword" || formType.value === "setemail") {
        return true;
      }
      return false;
    });
    const childMode = computed(() => {
      if (isRenewMode.value) {
        return "renew";
      }
      return "put";
    });
    const formObject = getFormObject(formType.value);
    const forms = computed(() => {
      if (!isRenewMode.value) {
        return reactive(settingDictionaryForms(formObject.forms));
      }
      return reactive({});
    });
    const listTitle = computed(() => {
      if (formObject.setting.originalTitle !== undefined) {
        return formObject.setting.originalTitle;
      }
      return formObject.setting.title + "情報";
    });
    const titleComment = computed(() => {
      if (formType.value === "customer") {
        return [
          "登録されているご契約者の情報は次のとおりです。",
          "登録情報を変更するには「登録情報の変更」ボタンを押してください。",
        ];
      } else if (formType.value === "invoiceaddress") {
        return [
          "登録されているご請求先の情報は次のとおりです。",
          "登録情報を変更するには「登録情報の変更」ボタンを押してください。",
        ];
      } else if (formType.value === "setpassword") {
        return [
          "現在ご利用のパスワード変更が行えます。",
          "変更後は新しいパスワードを使用してログインしてください。",
        ];
      } else if (formType.value === "setemail") {
        return [
          "現在ご利用のアカウントを変更できます。",
          "変更後は新しいE-mailアドレスを使用してログインしてください。",
        ];
      }
      return "";
    });

    const storeMessage = ref("");
    const listData = ref([]);

    const dialog = ref(false);
    const progress = ref(false);
    const formKey = ref(createKey());

    /**
     * データ取得処理
     */
    const loadData = () => {
      if (isRenewMode.value) {
        return;
      }

      progress.value = true;
      // api:データ取得
      api({
        method: "get",
        url: formObject.setting.apiAddress + editId.value + "/",
      })
        .then((response) => {
          const res = response.data;
          if (res) {
            const tempList = [];
            // お客様コード表示用特殊処理
            if (formType.value === "customer") {
              tempList.push({
                label: "お客様コード",
                value:
                  res["customer_code"] !== null &&
                  res["customer_code"] !== undefined
                    ? res["customer_code"]
                    : "",
              });
            }

            // apiで取得したデータをセットする
            const resData = setApiToFormsData(res, forms.value);
            listData.value = tempList.concat(resData["list"]);
          }

          if (storeMessage.value) {
            store.dispatch("message/setInfoMessage", {
              message: storeMessage.value,
            });
            storeMessage.value = "";
          }
          progress.value = false;
        })
        .catch(() => {
          progress.value = false;
        });
    };

    /**
     * ModelForm更新対応
     */
    const updateForm = (message) => {
      if (message) {
        formKey.value = createKey();
        storeMessage.value = message;
        store.dispatch("message/setInfoMessage", {
          message: message,
        });
      }
    };

    /**
     * ModelFormキャンセル対応
     */
    const cancelDialog = (message) => {
      dialog.value = false;
      if (message) {
        storeMessage.value = message;
      }
    };

    /**
     * ModelFormクローズ対応
     */
    const closeDialog = (message) => {
      dialog.value = false;
      if (message) {
        storeMessage.value = message;
      }
      emit("homeUpdate");
      // メインメニューのリロード
      emit("homeReload");
      loadData();
    };

    onBeforeRouteUpdate(async (to, from, next) => {
      next();
      // 遷移後処理
      if (to.query !== from.query) {
        // 再描画処理
        loadData();
      }
    });

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    watch(dialog, (val) => {
      val || closeDialog();
    });

    // スクロール位置調整
    window.scroll(0, 0);

    // データ取得
    loadData();

    return {
      editId,
      formType,
      storeId,

      isRenewMode,
      childMode,
      formObject,
      forms,
      listTitle,
      titleComment,

      listData,

      storeMessage,
      dialog,
      progress,
      formKey,

      updateForm,
      cancelDialog,
      closeDialog,
    };
  },
});
</script>
