<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="id"
    :show-expand="true"
    :expanded.sync="expanded"
    :single-expand="true"
    :loading="loading"
    loading-text="Loading... Please wait"
    :options="options"
    :no-data-text="dataTableNoDataText"
    :footer-props="dataTableFooterProps"
    class="mygen-table mygenText--text"
  >
    <template v-slot:[`item.site_address`]="{ item }">
      <a
        style="text-decoration: none"
        :href="item.site_address"
        target="_blank"
      >
        {{ item.site_address }}
      </a>
    </template>
    <template v-slot:[`item.invoice_group_status`]="{ item }">
      <ChipStatus
        propsType="invoice_group_status"
        :propsValue="item.invoice_group_status"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <router-link
        :to="{
          name: 'reportInvoice',
          params: { id: item.id },
        }"
        class="text-decoration-none"
        target="_blank"
        replace
      >
        <v-btn fab dark small elevation="0" color="mygenIcon">
          <v-icon size="22" color="mygenText"> bi-file-earmark </v-icon>
        </v-btn>
      </router-link>
    </template>

    <!-- expanded-item -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="mygenCard">
        <ModelDetail
          :key="'table-invoicereportdetail-' + item.id"
          propsFormType="invoicereportdetail"
          propsFilterColumn="id"
          :propsFilterId="item.id"
          :propsIsSpecifyId="true"
        />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import store from "@/store";
import api from "@/services/api";
import {
  dataTableNoDataText,
  dataTableFooterProps,
} from "@/utils/listUtility.js";
import { setPaymentMethod } from "@/utils/selectUtility.js";
import ChipStatus from "@/components/atoms/ChipStatus.vue";
import ModelDetail from "@/views/model/ModelDetail.vue";

export default defineComponent({
  name: "TableInvoiceInfo",

  components: {
    ChipStatus,
    ModelDetail,
  },

  props: {
    propsCustomerId: { type: String, default: "XXX", required: true },
  },

  setup(props) {
    const customerId = ref(props.propsCustomerId);

    const headers = ref([
      {
        text: "請求書No",
        value: "invoice_code",
        align: "start",
      },
      {
        text: "ステータス",
        value: "invoice_group_status",
        align: "center",
        sortable: false,
      },
      {
        text: "請求日",
        value: "invoice_date",
        align: "center",
      },
      {
        text: "締日",
        value: "invoice_deadline",
        align: "center",
      },
      {
        text: "請求金額",
        value: "invoice_charge",
        align: "end",
        sortable: false,
      },
      {
        text: "請求税込金額",
        value: "invoice_charge_including_tax",
        align: "end",
        sortable: false,
      },
      {
        text: "支払方法",
        value: "payment_type",
        align: "center",
      },
      {
        text: "請求書",
        value: "actions",
        align: "center",
        sortable: false,
      },
      {
        text: "明細",
        value: "data-table-expand",
        align: "center",
        sortable: false,
      },
    ]);
    const options = reactive({
      itemsPerPage: 10,
      sortBy: ["invoice_code"],
      sortDesc: [true],
    });
    const items = ref([]);
    const expanded = ref([]);
    const loading = ref(true);

    const loadData = () => {
      loading.value = true;
      items.value = [];
      // api:GEN-COIN情報取得
      api({
        method: "get",
        url:
          "/invoiceheader/?invoice_group_status=50&customer=" +
          customerId.value,
      }).then((response) => {
        const res = response.data;
        const selectItem = setPaymentMethod();
        for (const key in res) {
          const invoiceGroup = res[key]["invoice_group_reference"];
          let paymentType = "";
          for (const selectKey in selectItem) {
            if (selectItem[selectKey]["value"] == res[key]["payment_type"]) {
              paymentType = selectItem[selectKey]["text"];
            }
          }
          const line = {
            id: res[key]["id"],
            invoice_code: res[key]["invoice_code"],
            invoice_date: invoiceGroup["invoice_date"],
            invoice_deadline: invoiceGroup["invoice_deadline"],
            invoice_group_status: invoiceGroup["invoice_group_status"],
            invoice_charge: res[key]["invoice_charge"].toLocaleString(),
            invoice_charge_including_tax:
              res[key]["invoice_charge_including_tax"].toLocaleString(),
            payment_type: paymentType,
          };
          items.value.push(line);
        }
        loading.value = false;
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      customerId,

      dataTableNoDataText,
      dataTableFooterProps,
      headers,
      options,
      items,
      expanded,
      loading,
    };
  },
});
</script>
