<template>
  <v-card flat>
    <v-card-text>
      <v-btn
        block
        color="primary"
        outlined
        rounded
        class="font-weight-bold"
        href="/static/assets/pdf/GEN_TOS.pdf"
        target="_blank"
        @click="clickDownload()"
      >
        利用規約を確認
        <v-icon right dark> mdi-download </v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardTermsOfService",

  emits: ["download"],

  setup(props, { emit }) {
    const clickDownload = () => {
      emit("download");
    };

    return { clickDownload };
  },
});
</script>
