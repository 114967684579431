import { requiredRules, emailRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "アクティベーションメール再送信",
  buttonLabelSubmit: "送信",
  storeAddress: "signup/resend",
  successMessage: "アクティベーションメールを送信しました",
  errorMessage:
    "すでに有効化されているか、アクティベーションメールを送信できませんでした",
};

const forms = [
  {
    column: "email",
    label: "E-mail",
    type: "text",
    rules: [requiredRules, emailRules],
    emit: "resendEmail",
  },
];

export default {
  setting,
  forms,
};
