import { getDateObject } from "@/utils/utilities.js";
import { setCoinType } from "@/utils/selectUtility.js";
import {
  requiredRules,
  dateRules,
  selectedRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "コイン管理",
  apiAddress: "/managementcoin/",
  listSortBy: ["management_coin_date"],
  listSortDesc: [true],
};

const forms = [
  {
    column: "management_coin_date",
    label: "日付",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "management_coin_type",
    label: "種別",
    type: "select",
    items: setCoinType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "management_coin_quantity",
    label: "コイン枚数",
    type: "number",
    rules: [requiredRules],
    cols: 6,
    hint: "種別がチャージの時はプラス値に、チャージ以外の時はマイナス値に変換されます。",
  },
  {
    column: "management_coin_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "management_staff",
    label: "担当",
    type: "text",
    rules: [requiredRules],
  },
];

const postFixedValue = [
  {
    column: "customer",
    type: "props",
  },
  {
    column: "management_coin_status",
    value: 50,
  },
  {
    column: "management_coin_class",
    value: 0,
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    reference: "management_coin_class",
    align: "center",
    sortable: false,
  },
  {
    text: "管理コード",
    value: "management_coin_code",
  },
  {
    text: "日付",
    value: "management_coin_date",
    align: "center",
  },
  {
    text: "管理ステータス",
    value: "management_coin_status",
    align: "center",
  },
  {
    text: "種別",
    value: "management_coin_type",
    type: "select",
    items: setCoinType(),
    align: "center",
  },
  {
    text: "コイン枚数",
    value: "management_coin_quantity",
    type: "number",
    align: "end",
  },
  {
    text: "コメント",
    value: "management_coin_comment",
  },
  {
    text: "担当",
    value: "management_staff",
  },
  {
    text: "区分",
    value: "management_coin_class",
    align: "center",
  },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
