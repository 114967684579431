<template>
  <v-chip
    :color="chipColor"
    :dark="chipDark"
    :outlined="chipOutlined"
    x-small
    class="font-weight-bold"
  >
    {{ chipText }}
  </v-chip>
</template>

<script>
import { defineComponent, ref } from "vue";
import { getSelectObject } from "@/utils/selectUtility.js";

export default defineComponent({
  name: "ChipStatus",

  props: {
    propsType: { type: String, default: "" },
    propsValue: { type: Number, default: 0 },
  },

  setup(props) {
    const chipType = ref(props.propsType);
    const chipValue = ref(props.propsValue);

    const settingChip = () => {
      let dict = {};
      let color = "";
      let text = "";
      let variant = "";
      let isDark = false;
      let isOutlined = false;

      switch (chipType.value) {
        case "contract_renewal_status":
          dict = getSelectObject("contract_status", "renewal", chipValue.value);
          break;

        case "cancel_order_status":
          dict = getSelectObject(chipType.value, "all", chipValue.value);
          break;

        case "coin_order_status":
        case "coin_order_status_for_customer":
        case "management_coin_status":
          dict = getSelectObject(chipType.value, "all", chipValue.value);
          break;

        default:
          dict = getSelectObject(chipType.value, "", chipValue.value);
      }

      if (dict.length === 0) {
        color = "red-darken-1";
        text = "ERROR";
        variant = "elevated";
      } else {
        color = dict["color"];
        text = dict["text"];
        variant = dict["variant"];
      }
      if (
        chipType.value === "coin_order_status_for_customer" &&
        chipValue.value === 0
      ) {
        text = "新規申込中";
      }

      if (variant === "elevated") {
        isDark = true;
      }
      if (variant === "outlined") {
        isOutlined = true;
      }

      return [color, text, isDark, isOutlined];
    };

    const chipSet = settingChip();
    const chipColor = chipSet[0];
    const chipText = chipSet[1];
    const chipDark = chipSet[2];
    const chipOutlined = chipSet[3];

    return {
      chipColor,
      chipText,
      chipDark,
      chipOutlined,
    };
  },
});
</script>
