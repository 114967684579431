import { requiredRules, emailRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "",
  buttonLabelSubmit: "Login",
  storeAddress: "auth/login",
  isLogin: true,
  successMessage: "ログインしました",
};

const forms = [
  {
    column: "email",
    label: "E-mail",
    type: "text",
    rules: [requiredRules, emailRules],
    emit: "loginEmail",
  },
  {
    column: "password",
    label: "パスワード",
    type: "password",
    rules: [requiredRules],
    append: false,
    emit: "loginPassword",
  },
];

export default {
  setting,
  forms,
};
