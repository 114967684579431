<template>
  <div>
    <v-expansion-panels v-if="isPanel" v-model="panel" accordion hover multiple>
      <v-expansion-panel>
        <PanelHeader :propsLabel="'お客様情報'" />
        <v-expansion-panel-content>
          <ContainerCustomerInfo
            :key="'customer-' + formKey"
            :propsFormsFirst="formsFirst"
            :propsFormsSecond="formsSecond"
            :propsIsCopy="true"
          />
          <ContainerCustomerSetting
            :key="'customer-setting-' + formKey"
            :propsCustomerData="customerData"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <PanelHeader :propsLabel="'申込内容'" />
        <v-expansion-panel-content>
          <v-container>
            <v-row align="center">
              <v-col>
                <ContainerContractDetail
                  :key="'contract-detail-' + formKey"
                  :propsData="tableData"
                  :propsSetting="true"
                  :propsFlow="true"
                  :propsChange="true"
                  :propsShowCharge="true"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container v-else>
      <v-row align="center">
        <v-col>
          <ContainerContractDetail
            :key="'contract-detail-' + formKey"
            :propsData="tableData"
            :propsSetting="true"
            :propsFlow="true"
            :propsChange="true"
            :propsShowCharge="true"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { createKey, getBaseInfo } from "@/utils/utilities.js";
import {
  settingDictionaryForms,
  setResponseApplyFormsDict,
} from "@/utils/formUtility.js";
import PanelHeader from "@/components/atoms/PanelHeader.vue";
import ContainerCustomerInfo from "@/components/molecules/ContainerCustomerInfo.vue";
import ContainerCustomerSetting from "@/components/molecules/ContainerCustomerSetting.vue";
import ContainerContractDetail from "@/components/molecules/ContainerContractDetail.vue";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";
import FormsCustomer from "@/forms/customer.js";

export default defineComponent({
  name: "PanelFlow",

  components: {
    PanelHeader,
    ContainerContractDetail,
    ContainerCustomerInfo,
    ContainerCustomerSetting,
  },

  props: {
    propsContractPlanId: { type: String, require: true },
    propsIsPanel: { type: Boolean, default: true },
  },

  setup(props) {
    const contractPlanId = ref(props.propsContractPlanId);
    const isPanel = ref(props.propsIsPanel);

    const firstForms = FormsCustomer.forms;
    const addForms = {
      column: "account",
      label: "アカウント",
      type: "text",
      copy: true,
    };
    let formsFirst = reactive(
      settingDictionaryForms(firstForms.concat(addForms))
    );
    let formsSecond = reactive(
      settingDictionaryForms(FormsInvoiceAddress.forms)
    );
    let tableData = ref({});
    let customerData = ref({});
    const panel = ref([1]);
    const formKey = ref(createKey());

    const loadData = () => {
      api({
        method: "get",
        url: "/contractplanbase/?id=" + contractPlanId.value,
      }).then((response) => {
        const res = response.data[0];
        const orderCustomerData = res["contract"]["customer_reference"];
        tableData.value = res;
        customerData.value = orderCustomerData;
        // 契約者情報セット
        formsFirst = reactive(
          setResponseApplyFormsDict(orderCustomerData, formsFirst)
        );
        // 請求先情報セット
        formsSecond = reactive(
          setResponseApplyFormsDict(orderCustomerData, formsSecond)
        );

        // 元データ取得
        if (res["base_contract_plan"]) {
          api({
            method: "get",
            url: "/contractplanbase/?id=" + res["base_contract_plan"],
          }).then((tempResponse) => {
            const tempRes = tempResponse.data[0];
            res["base_contract"] = tempRes;
            res["base_info"] = getBaseInfo(tempRes, res);
            tableData.value = res;
            formKey.value = createKey();
          });
        } else {
          formKey.value = createKey();
        }
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      contractPlanId,
      isPanel,

      formsFirst,
      formsSecond,
      tableData,
      customerData,
      panel,
      formKey,
    };
  },
});
</script>
