import { getSelectObject } from "@/utils/selectUtility.js";
import { equiredOrZeroRules, charLength200Rules } from "@/utils/ruleUtility.js";

const setting = {
  title: "受付対応",
  originalTitle: "受付登録",
  apiAddress: "/contracthandle/",
  isListReadonly: true,
};

const forms = [
  {
    column: "order_status",
    label: "受付対応",
    type: "radio",
    items: getSelectObject("contract_status", "reception"),
    rules: [],
  },
  {
    column: "incentive_first",
    label: "初回インセンティブ",
    type: "selectIncentiveFirst",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "incentive_month",
    label: "月度インセンティブ",
    type: "selectIncentiveMonth",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "coupon",
    label: "クーポン",
    type: "selectCoupon",
    items: [],
    rules: [],
  },
  {
    column: "incentive_discount_charge",
    label: "クーポンのインセンティブ対象",
    type: "number",
    rules: [equiredOrZeroRules],
    cols: 6,
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
  {
    column: "contract___staff_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const postFixedValue = [
  {
    column: "reception_date",
    type: "today",
    equalOrRules: [
      ["order_status", 10],
      ["order_status", 90],
    ],
  },
];

const headers = [];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
