var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[(_vm.isSetting)?_c('v-row',{staticClass:"mb-7"},[(_vm.isFlow)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"overflow-wrap":"anywhere !important"},attrs:{"width":"100%","flat":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"block":"","color":"primary","outlined":"","rounded":""},on:{"click":function($event){return _vm.copyText(_vm.copyValue)}}},[_vm._v(" "+_vm._s(_vm.tooltipCommentCopy)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-content-copy ")])],1)],1)],1)],1):_vm._e(),_vm._l((_vm.settingData),function(row,index){return _c('v-col',{key:'col-setting-' + index,staticClass:"py-1",attrs:{"cols":"12","md":row['cols'] ? row['cols'] : 6}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"overflow-wrap":"anywhere !important"},attrs:{"width":"100%","outlined":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(row["title"]))]),(row['isAccount'])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"text-body-1 primary--text font-weight-bold text-decoration-none",on:{"click":function($event){return _vm.copyText(row['value'])}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(row["value"])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipAccountCopy))])]):_c('div',{staticClass:"text-body-1 mygenText--text"},[_vm._v(" "+_vm._s(row["value"])+" ")])],1)],1)],1)})],2):_vm._e(),_vm._l((_vm.confirmData),function(row,index){return _c('v-row',{key:'row-' + index,class:row[0] === 'label' ? 'mygenBase mx-auto' : 'mx-auto',staticStyle:{"max-width":"800px"}},[_c('v-col',{class:row[0] === 'label'
          ? 'py-1 mygenBase mygenText--text'
          : row[0] === 'dataCoupon'
          ? 'info--text'
          : 'mygenText--text',attrs:{"cols":"12","md":"5"}},[_c('span',{class:[
          row[0] === 'data' || row[0] === 'dataCoupon'
            ? 'font-weight-bold'
            : row[0] === 'total' || row[0] === 'totalCoupon'
            ? 'font-weight-bold primary--text'
            : row[0] === 'totalSub'
            ? 'font-weight-bold secondary--text'
            : row[0] === 'totalDate'
            ? 'text-subtitle-1 font-weight-bold secondary--text'
            : 'text-caption',
        ]},[_vm._v(" "+_vm._s(row[1])+" "),(row[3] === true)?_c('v-chip',{staticClass:"font-weight-bold ml-1",attrs:{"color":"pink","x-small":"","dark":""}},[_vm._v(" 変更 ")]):_vm._e(),(row[2])?_c('br'):_vm._e(),(row[2])?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(row[2]))])]):_vm._e()],1)]),_c('v-col',{class:row[0] === 'label'
          ? 'py-1 mygenBase mygenText--text'
          : row[0] === 'totalCoupon'
          ? 'primary--text'
          : row[0] === 'dataCoupon'
          ? 'info--text'
          : 'mygenText--text',attrs:{"cols":"6","md":"2"}},[_c('div',{attrs:{"align":"end"}},[_vm._v(_vm._s(row[4]))])]),_c('v-col',{class:row[0] === 'label'
          ? 'py-1 mygenBase mygenText--text'
          : row[0] === 'totalCoupon'
          ? 'primary--text'
          : row[0] === 'dataCoupon'
          ? 'info--text'
          : 'mygenText--text',attrs:{"cols":"6","md":"2"}},[_c('div',{attrs:{"align":"end"}},[_vm._v(_vm._s(row[5]))])]),_c('v-col',{class:row[0] === 'label'
          ? 'py-1 mygenBase mygenText--text'
          : 'mygenText--text',attrs:{"cols":"12","md":"3"}},[_c('div',{class:[
          row[0] === 'total' || row[0] === 'totalCoupon'
            ? 'text-right font-weight-bold primary--text'
            : row[0] === 'totalSub'
            ? 'text-right font-weight-bold secondary--text'
            : 'text-right font-weight-bold',
        ],attrs:{"align":"end"}},[(row[6])?_c('span',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(row[6])+" ")]):_vm._e(),(row[6])?_c('span',{staticClass:"text-body-2 pr-2"},[_vm._v("円")]):_vm._e(),(row[7])?_c('br'):_vm._e(),(row[7])?_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticClass:"text-caption"},[_vm._v("（税込")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(row[7]))]),_c('span',{staticClass:"text-caption"},[_vm._v("円）")])]):_vm._e()])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }