import { setInvoiceType } from "@/utils/selectUtility.js";
import {
  selectedNotNullRules,
  requiredRules,
  selectedRules,
  dateRules,
  dateMonthRules,
  equiredOrZeroRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "移行契約",
  apiAddress: "/transferplan/",
  listSortBy: ["client_code", "transfer_identifier"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "transfer",
  isCsvImport: true,
  isOptionCsvImport: true,
};

const forms = [
  {
    column: "client",
    label: "顧客",
    type: "selectClient",
    items: [],
    rules: [selectedNotNullRules],
    exportColumn: "client_reference___client_code",
  },
  {
    column: "transfer_identifier",
    label: "契約コード",
    type: "text",
    rules: [requiredRules],
    cols: 6,
    importValidation: true,
  },
  {
    column: "site_address",
    label: "サイト",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "site_name",
    label: "サイト名称",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "plan",
    label: "プラン",
    type: "selectPlan",
    items: [],
    rules: [selectedRules],
    exportColumn: "plan_reference___plan_code",
  },
  {
    column: "account_quantity",
    label: "アカウント数",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "coupon",
    label: "クーポン",
    type: "selectCoupon",
    items: [],
    rules: [],
    exportColumn: "coupon_reference___coupon_code",
  },
  {
    column: "start_contract_date",
    label: "契約開始月",
    type: "dateMonthFirst",
    menu: false,
    default: new Date().toISOString().substring(0, 7),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
    importRules: [dateRules],
  },
  {
    column: "start_invoice_date",
    label: "請求開始月",
    type: "dateMonthFirst",
    menu: false,
    default: new Date().toISOString().substring(0, 7),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
    importRules: [dateRules],
  },
  {
    column: "invoice_type",
    label: "請求単位",
    type: "select",
    items: setInvoiceType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
  {
    column: "incentive_first",
    label: "初回インセンティブ",
    type: "selectIncentiveFirst",
    items: [],
    rules: [],
    cols: 6,
    exportColumn: "incentive_first_reference___incentive_rate",
  },
  {
    column: "incentive_month",
    label: "月度インセンティブ",
    type: "selectIncentiveMonth",
    items: [],
    rules: [],
    cols: 6,
    exportColumn: "incentive_month_reference___incentive_rate",
  },
  {
    column: "incentive_discount_charge",
    label: "クーポンのインセンティブ対象",
    type: "number",
    rules: [equiredOrZeroRules],
    cols: 6,
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
  {
    column: "support_rate",
    label: "サポートレート",
    type: "selectSupportRate",
    items: [],
    rules: [selectedRules],
    cols: 6,
    exportColumn: "support_rate_reference___support_rate",
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "お客様コード",
    value: "client_reference___client_code",
    align: "start",
  },
  {
    text: "会社名",
    value: "client_reference___client_name",
    align: "start",
  },
  {
    text: "契約コード",
    value: "transfer_identifier",
    align: "start",
  },
  {
    text: "ステータス",
    value: "is_transfer",
    align: "center",
  },
  {
    text: "プラン",
    value: "plan_reference___plan_name",
  },
  {
    text: "アカウント数",
    value: "account_quantity",
    type: "numeric",
    align: "end",
  },
  {
    text: "クーポン",
    value: "coupon_reference___coupon_name",
  },
  {
    text: "初回インセンティブ",
    value: "incentive_first_reference___incentive_rate",
    align: "end",
  },
  {
    text: "月度インセンティブ",
    value: "incentive_month_reference___incentive_rate",
    align: "end",
  },
  {
    text: "OP数",
    value: "transfer_option_count",
    type: "numeric",
    align: "end",
  },
  {
    text: "情報",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchSelect = {
  label: "ステータス",
  column: "customer__isnull",
  items: [
    { value: -1, text: "ALL" },
    { value: false, text: "継承済" },
    { value: true, text: "未継承" },
  ],
  value: true,
};

export default {
  setting,
  forms,
  headers,
  searchSelect,
};
