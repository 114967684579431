<template>
  <v-card
    class="overflow-y-auto mt-5 pa-3"
    color="mygenBase"
    flat
    rounded="lg"
    max-height="250"
  >
    <v-card-text>
      <p class="font-weight-bold mygenText--text text-h6">注意事項</p>
      <ul class="mygenText--text">
        <li>
          契約１年目は、最低利用期間中のためサービスを解約することができません。
        </li>
        <li>
          基本となるサービスを解約されますと、すべてのオプションサービスも解約処理されることになります。
        </li>
        <li>
          解約のお申込みは、解約月を指定するのではなく、料金をお支払いいただいた期間をもちまして利用の終了とさせていただきます。解約手続きが完了しましたら「契約中のサービス一覧」に契約満了日が表示されます。
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardTermsCancel",

  setup() {
    return {};
  },
});
</script>
