<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container
      :class="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'px-16 py-10'
      "
    >
      <v-row>
        <v-col cols="12">
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsComment="titleComment"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-spacer />
            <v-col cols="12" sm="5">
              <ButtonCommon
                propsLabel="新規申込"
                :propsClick="clickCreateOrder"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <ButtonCommon
                propsLabel="簡単見積"
                :propsClick="clickCreateEstimate"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div v-for="(row, index) in mainData" :key="index">
            <v-card width="100%" class="mygenCard mt-5 pa-3" rounded="lg">
              <v-card-text class="mygenText--text">
                <v-row>
                  <v-col cols="12" class="text-h5 font-weight-bold">
                    {{ row["plan_name"] }}
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="6"
                    md="3"
                    v-if="
                      isChangeOrder(
                        row['order_status'],
                        row['is_revision'],
                        row['is_sub_data'],
                        row['is_contract_cancel'],
                        row['is_contract_date_after']
                      ) && false
                    "
                  >
                    <v-btn
                      block
                      dark
                      rounded
                      elevation="4"
                      class="mygen-button font-weight-bold"
                      @click="clickChangeAccountOrder(index)"
                    >
                      {{ buttonLabelAccount }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    v-if="
                      isChangeOrder(
                        row['order_status'],
                        row['is_revision'],
                        row['is_sub_data'],
                        row['is_contract_cancel'],
                        row['is_contract_date_after']
                      )
                    "
                  >
                    <v-btn
                      block
                      dark
                      rounded
                      elevation="4"
                      class="mygen-button font-weight-bold"
                      @click="clickChangeOrder(index)"
                    >
                      {{ buttonLabelChange }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                    v-if="
                      isChangeOrder(
                        row['order_status'],
                        row['is_revision'],
                        row['is_sub_data'],
                        row['is_contract_cancel'],
                        row['is_contract_date_after']
                      )
                    "
                  >
                    <v-btn
                      block
                      color="primary"
                      outlined
                      rounded
                      class="font-weight-bold"
                      @click="clickContractCancel(index)"
                    >
                      {{ buttonLabelCancel }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="row['order_status'] === 50" class="my-0 py-0">
                <ChipStatus
                  :propsType="'customer_order_status'"
                  :propsValue="row['order_status']"
                  class="mr-3"
                />
                <v-btn
                  text
                  plain
                  style="text-transform: none"
                  :href="row['site_address']"
                  target="_blank"
                  class="primary--text"
                >
                  {{ row["site_address"] }}
                </v-btn>
              </v-card-text>
              <v-card-text
                v-else-if="row['order_status'] === 70"
                class="my-0 py-0"
              >
                <ChipStatus
                  :propsType="'customer_order_status'"
                  :propsValue="row['order_status']"
                  class="mr-3"
                />
                <v-btn
                  text
                  plain
                  style="text-transform: none"
                  :href="row['site_address']"
                  target="_blank"
                >
                  {{ row["site_address"] }}
                </v-btn>
              </v-card-text>
              <v-card-text v-else class="my-0 py-0">
                <ChipStatus
                  :propsType="'customer_order_status'"
                  :propsValue="0"
                  class="mr-3"
                />
                <ChipStatus
                  v-if="
                    row['order_status'] === 10 || row['order_status'] === 20
                  "
                  :propsType="'customer_order_status'"
                  :propsValue="row['order_status']"
                  class="mr-3"
                />
              </v-card-text>

              <v-card-actions>
                <v-row
                  v-if="row['order_status'] === 50 || row['order_status'] == 70"
                  class="pl-3 mygenText--text text-caption"
                >
                  <v-col cols="12" md="6" class="py-0">
                    契約コード : {{ row["contract_code"] }}
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    契約開始日 : {{ row["contract_date"] }}
                  </v-col>
                  <v-col cols="12" class="py-0">
                    サイト名称 : {{ row["site_name"] }}
                  </v-col>
                </v-row>
                <v-row v-else class="px-3 py-5 text-caption">
                  <v-col cols="12" md="6" class="py-0">
                    申込コード : {{ row["order_code"] }}
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    申込日 : {{ row["order_date"] }}
                  </v-col>
                  <v-col cols="12" class="py-0">
                    サイト名称 : {{ row["site_name"] }}
                  </v-col>
                </v-row>

                <v-spacer />

                <v-btn icon @click="changeShowOrderMain(index)">
                  <v-icon>{{
                    showOrderMain[index] ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showOrderMain[index]">
                  <v-card-text>
                    <ContainerContractDetail
                      :key="'table-' + formKey + '-' + row['id']"
                      :propsData="row"
                      :propsShowCharge="row['order_status'] <= 50"
                    />
                  </v-card-text>
                </div>
              </v-expand-transition>

              <v-card-text v-if="row['is_contract_cancel']" class="my-0 py-0">
                <ChipStatus
                  :propsType="'cancel_order_status_for_customer'"
                  :propsValue="
                    row['contract_cancel']['cancel_order_status'] < 50 ? 0 : 50
                  "
                  class="mr-3"
                />
              </v-card-text>
              <v-card-text v-else-if="row['is_sub_data']" class="my-0 py-0">
                <ChipStatus
                  :propsType="'customer_order_status_change'"
                  :propsValue="row['sub_data']['order_status'] < 50 ? 0 : 50"
                  class="mr-3"
                />
                <ChipStatus
                  v-if="
                    row['sub_data']['order_status'] === 10 ||
                    row['sub_data']['order_status'] === 20
                  "
                  :propsType="'customer_order_status_change'"
                  :propsValue="row['sub_data']['order_status']"
                  class="mr-3"
                />
              </v-card-text>

              <v-card-actions v-if="row['is_contract_cancel']">
                <div class="pl-3 text-caption">
                  <span class="mr-5">
                    申込コード :
                    {{ row["contract_cancel"]["cancel_order_code"] }}
                  </span>
                  <span class="mr-5">
                    申込日 :
                    {{ row["contract_cancel"]["cancel_order_date"] }}
                  </span>
                  <span
                    v-if="
                      row['is_contract_cancel'] && row['contract_cancel_date']
                    "
                  >
                    契約満了日 : {{ row["contract_cancel_date"] }}
                  </span>
                </div>
              </v-card-actions>
              <v-card-actions v-else-if="row['is_sub_data']">
                <div
                  v-if="row['sub_data']['order_status'] === 50"
                  class="pl-3 text-caption"
                >
                  <span class="mr-5">
                    契約コード : {{ row["sub_data"]["contract_code"] }}
                  </span>
                  <span>
                    契約開始日 : {{ row["sub_data"]["contract_date"] }}
                  </span>
                </div>
                <div v-else class="pl-3 text-caption">
                  <span class="mr-5">
                    申込コード : {{ row["sub_data"]["order_code"] }}
                  </span>
                  <span>申込日 : {{ row["sub_data"]["order_date"] }} </span>
                </div>

                <v-spacer />

                <v-btn icon @click="changeShowOrderSub(row['sub_data']['id'])">
                  <v-icon>{{
                    showOrderSub[row["sub_data"]["id"]]
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="showOrderSub[row['sub_data']['id']]">
                  <v-divider></v-divider>
                  <v-card-text>
                    <ContainerContractDetail
                      :key="'table-sub-' + formKey + '-' + row['id']"
                      :propsData="row['sub_data']"
                      :propsChange="true"
                      :propsShowCharge="true"
                    />
                    <ContainerOrderComment
                      v-if="
                        row['sub_data']['order_account_comment'] &&
                        row['sub_data']['order_account_comment'].length > 0
                      "
                      :key="'comment-account-' + formKey + '-' + row['id']"
                      :propsComment="row['sub_data']['order_account_comment']"
                      :propsIsOption="false"
                      :propsIsCard="true"
                    />
                    <ContainerOrderComment
                      v-if="
                        row['sub_data']['order_option_comment'] &&
                        row['sub_data']['order_option_comment'].length > 0
                      "
                      :key="'comment-option-' + formKey + '-' + row['id']"
                      :propsComment="row['sub_data']['order_option_comment']"
                      :propsIsOption="true"
                      :propsIsCard="true"
                    />
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </div>
          <div v-if="isData === false">
            <v-card flat width="100%" class="mygenCard mt-5 pa-5" rounded="lg">
              <v-card-text class="mygenText--text">
                契約中のサービスは存在しません
              </v-card-text>
            </v-card>
          </div>
          <v-dialog
            v-model="dialogAccount"
            persistent
            no-click-animation
            :fullscreen="$vuetify.breakpoint.xs"
          >
            <v-card rounded="lg">
              <FormContractAccountOrder
                v-if="dialogAccount"
                :key="'form-account-order-' + formKey"
                :propsEditId="childEditId"
                @close="closeDialog"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogOrder"
            persistent
            no-click-animation
            :fullscreen="$vuetify.breakpoint.xs"
          >
            <v-card>
              <FormContractOrder
                v-if="dialogOrder"
                :key="'form-order-' + formKey"
                :propsFormMode="formMode"
                :propsEditId="childEditId"
                @close="closeDialog"
              />
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogCancel"
            width="1080"
            persistent
            no-click-animation
            :fullscreen="$vuetify.breakpoint.xs"
          >
            <v-card>
              <FormContractCancel
                v-if="dialogCancel"
                :key="'form-cancel-' + formKey"
                :propsFormMode="formMode"
                :propsEditId="childEditId"
                @close="closeDialog"
              />
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { createKey, getBaseInfo } from "@/utils/utilities.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import ChipStatus from "@/components/atoms/ChipStatus.vue";
import ContainerOrderComment from "@/components/atoms/ContainerOrderComment.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import ContainerContractDetail from "@/components/molecules/ContainerContractDetail.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";
import FormContractCancel from "@/views/pages/FormContractCancel.vue";
import FormContractAccountOrder from "@/views/pages/FormContractAccountOrder.vue";
import FormContractOrder from "@/views/pages/FormContractOrder.vue";

export default defineComponent({
  name: "ListService",

  components: {
    ButtonCommon,
    ChipStatus,
    ContainerOrderComment,
    ContainerContractDetail,
    DivTitle,
    FormContractCancel,
    FormContractAccountOrder,
    FormContractOrder,
    OverlayProgress,
  },

  setup() {
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });

    const listTitle = ref("契約中のサービス一覧");
    const titleComment = ref([
      "ご契約いただいているサービスの情報が確認できます。",
      "・アカウント数の変更、プランやオプションの変更は「契約変更申込」からお申し込みください",
      "・「契約変更申込」は契約開始日以降に行うことができます",
      "・お申し込み内容の訂正は、お問い合わせフォームよりご相談ください",
    ]);
    const cardTitle = ref("契約情報");
    const buttonLabelAccount = ref("アカウント数変更");
    const buttonLabelChange = ref("契約変更申込");
    const buttonLabelCancel = ref("解約申込");

    const isData = ref(false);
    const mainData = ref({});
    const showOrderMain = reactive({});
    const showOrderSub = reactive({});

    const formMode = ref("create");
    const editedIndex = ref(-1);
    const childEditId = computed(() => {
      return editedIndex.value === -1 ? "" : editedIndex.value;
    });

    const dialogAccount = ref(false);
    const dialogOrder = ref(false);
    const dialogCancel = ref(false);
    const progress = ref(false);
    const formKey = ref(createKey());

    // データセット
    const dataDisplay = (res) => {
      mainData.value = {};
      const tempMainData = {};
      const tempData = {};
      const nowDate = new Date(Date.now());
      for (const row of res) {
        const availabilityRevision = row["availability_revision"];
        const orderData = row["contract_plan"];
        let cancelOrderStatus = -1;
        let contractCancelDate = "";
        if (orderData.length !== 0) {
          // 解約情報取得
          if ("contract_cancel" in row && 0 in row["contract_cancel"]) {
            cancelOrderStatus =
              row["contract_cancel"][0]["cancel_order_status"];
            contractCancelDate = new Date(
              row["contract_cancel"][0]["contract_cancel_date"]
            );
          }
          // 契約LOOP
          for (const orderKey in orderData) {
            if (
              orderData[orderKey]["order_status"] === 90 ||
              orderData[orderKey]["contract_revision"] < availabilityRevision
            ) {
              // 取消契約と有効ではない契約は表示しない
              continue;
            } else if (
              row["contract_class"] === 80 &&
              contractCancelDate.length !== 0 &&
              nowDate > contractCancelDate
            ) {
              // 解約日を過ぎた契約は表示しない
              continue;
            } else if (
              row["contract_class"] === 80 &&
              cancelOrderStatus === -1
            ) {
              // 強制解約情報は表示しない
              continue;
            }
            if (
              orderData[orderKey]["order_type"] !== 10 &&
              orderData[orderKey]["contract_revision"] > availabilityRevision
            ) {
              // 未受理契約データセット
              tempData[orderData[orderKey]["base_contract_plan"]] =
                orderData[orderKey];
              showOrderSub[orderData[orderKey]["id"]] = false;
            } else {
              // 本契約データセット
              tempMainData[orderData[orderKey]["id"]] = orderData[orderKey];
              tempMainData[orderData[orderKey]["id"]]["availability_revision"] =
                availabilityRevision;
              tempMainData[orderData[orderKey]["id"]]["is_revision"] =
                availabilityRevision ===
                orderData[orderKey]["contract_revision"];
              tempMainData[orderData[orderKey]["id"]]["is_sub_data"] = false;
              tempMainData[orderData[orderKey]["id"]]["sub_data"] = { id: -1 };
              tempMainData[orderData[orderKey]["id"]]["site_address"] =
                row["site_address"];
              tempMainData[orderData[orderKey]["id"]]["site_name"] =
                row["site_name"];
              tempMainData[orderData[orderKey]["id"]]["is_contract_cancel"] =
                row["is_contract_cancel"];
              tempMainData[orderData[orderKey]["id"]]["contract_cancel_date"] =
                cancelOrderStatus === 50
                  ? row["contract_cancel"][0]["contract_cancel_date"]
                  : "";
              tempMainData[orderData[orderKey]["id"]]["contract_cancel"] =
                row["contract_cancel"][0];
              showOrderMain[orderData[orderKey]["id"]] = false;
              // 表示日（本日）が本契約の契約開始日以降か判定
              const contractDate =
                tempMainData[orderData[orderKey]["id"]]["contract_date"];
              tempMainData[orderData[orderKey]["id"]][
                "is_contract_date_after"
              ] = false;
              if (
                contractDate !== null &&
                contractDate !== undefined &&
                contractDate !== ""
              ) {
                const tmpContractDate = new Date(contractDate);
                if (nowDate > tmpContractDate) {
                  tempMainData[orderData[orderKey]["id"]][
                    "is_contract_date_after"
                  ] = true;
                }
              }
            }
          }
          if (Object.keys(tempData).length !== 0) {
            for (const tempKey in tempData) {
              tempData[tempKey]["base_info"] = getBaseInfo(
                tempMainData[tempKey],
                tempData[tempKey]
              );
              if (tempKey in tempMainData) {
                tempMainData[tempKey]["is_sub_data"] = true;
                tempMainData[tempKey]["sub_data"] = tempData[tempKey];
              }
            }
          }
        }
      }
      if (Object.keys(tempMainData).length !== 0) {
        isData.value = true;
      }
      mainData.value = tempMainData;
      formKey.value = createKey();
    };

    /**
     * データ取得処理
     */
    const loadData = () => {
      progress.value = true;
      // api:申込情報取得
      if (customerId.value !== "") {
        api({
          method: "get",
          url: "/contract/?customer=" + customerId.value,
        })
          .then((response) => {
            const res = response.data;
            dataDisplay(res);
          })
          .then(() => {
            progress.value = false;
          })
          .catch(() => {
            progress.value = false;
          });
      } else {
        dataDisplay({});
        progress.value = false;
      }
    };

    const isChangeOrder = (
      orderStatus,
      isRevision,
      isSubData,
      isCancel,
      isAfter
    ) => {
      if (
        orderStatus === 50 &&
        isRevision === true &&
        isSubData === false &&
        isCancel === false &&
        isAfter === true
      ) {
        return true;
      }
      return false;
    };

    const changeShowOrderMain = (index) => {
      if (index in showOrderMain) {
        const isShow = showOrderMain[index];
        showOrderMain[index] = !isShow;
      } else {
        showOrderMain[index] = false;
      }
      formKey.value = createKey();
    };

    const changeShowOrderSub = (index) => {
      if (index in showOrderSub) {
        const isShow = showOrderSub[index];
        showOrderSub[index] = !isShow;
      } else {
        showOrderSub[index] = false;
      }
      formKey.value = createKey();
    };

    const clickCreateEstimate = () => {
      formMode.value = "estimate";
      editedIndex.value = -1;
      dialogOrder.value = true;
    };

    const clickCreateOrder = () => {
      formMode.value = "create";
      editedIndex.value = -1;
      dialogOrder.value = true;
    };

    const clickChangeOrder = (index) => {
      formMode.value = "change";
      editedIndex.value = index;
      dialogOrder.value = true;
    };

    const clickChangeAccountOrder = (index) => {
      formMode.value = "account";
      editedIndex.value = index;
      dialogAccount.value = true;
    };

    const clickContractCancel = (index) => {
      editedIndex.value = index;
      dialogCancel.value = true;
    };

    const closeDialog = () => {
      formMode.value = "create";
      editedIndex.value = -1;
      dialogOrder.value = false;
      dialogAccount.value = false;
      dialogCancel.value = false;
      loadData();
      formKey.value = createKey();
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    watch(dialogAccount, (val) => {
      val || closeDialog();
      formKey.value = createKey();
    });
    watch(dialogOrder, (val) => {
      val || closeDialog();
      formKey.value = createKey();
    });
    watch(dialogCancel, (val) => {
      val || closeDialog();
      formKey.value = createKey();
    });

    // スクロール位置調整
    window.scroll(0, 0);

    // データ取得
    loadData();

    return {
      customerId,

      listTitle,
      titleComment,
      cardTitle,
      buttonLabelAccount,
      buttonLabelChange,
      buttonLabelCancel,

      isData,
      mainData,
      showOrderMain,
      showOrderSub,

      formMode,
      editedIndex,
      childEditId,

      dialogAccount,
      dialogOrder,
      dialogCancel,
      progress,
      formKey,

      isChangeOrder,
      changeShowOrderMain,
      changeShowOrderSub,
      clickCreateEstimate,
      clickCreateOrder,
      clickChangeOrder,
      clickChangeAccountOrder,
      clickContractCancel,
      closeDialog,
    };
  },
});
</script>
