import { getDateObject } from "@/utils/utilities.js";
import { getSelectObject } from "@/utils/selectUtility.js";

const setting = {
  title: "請求管理",
  originalTitle: "請求公開",
  apiAddress: "/invoicegroup/",
  isDeleteKey: "invoice_is_not_delete", // 削除可能データフラグ
  listSortBy: ["invoice_group_code"],
  listSortDesc: [true],
  showExpand: true,
  expandType: "invoice",
  formComment: ["「公開」登録すると請求金額確定メールがお客様に送信されます。"],
  isInvoiceCheck: true,
  createType: "invoice",
  isOverlayProgress: true,
};

const forms = [
  // 公開ハンドリングフォーム用
  {
    column: "invoice_group_status",
    label: "ステータス",
    type: "radio",
    items: getSelectObject("invoice_group_status"),
    rules: [],
  },
  // 請求書作成formsは[isInvoiceCreate]フラグで[FormInvoiceCreate]を呼び出している
  // {
  //   column: "invoice_date",
  //   label: "請求日",
  //   type: "date",
  //   menu: false,
  //   rules: [Utility.requiredRules, Utility.dateRules],
  //   cols: 6,
  // },
  // {
  //   column: "invoice_deadline",
  //   label: "締月度",
  //   type: "dateMonthEnd",
  //   menu: false,
  //   default: new Date().toISOString().substr(0, 7),
  //   rules: [Utility.requiredRules, Utility.dateMonthRules],
  //   cols: 6,
  // },
];

const headers = [
  // 削除機能ナシで運用可能と思われる
  {
    text: "削除",
    value: "delete",
    align: "center",
    sortable: false,
    // [isDeleteKey]ハンドリング用
    handleKey: ["invoice_is_not_delete"],
  },
  {
    text: "公開",
    value: "handleItemInvoiceGroup",
    align: "center",
    sortable: false,
    handleType: "invoicegroup",
    handleKey: ["invoice_is_last", "invoice_is_temp", "invoice_is_incentive"],
  },
  {
    text: "ステータス",
    value: "invoice_group_status",
    align: "center",
  },
  {
    text: "請求グループ",
    value: "invoice_group_code",
  },
  {
    text: "締日",
    value: "invoice_deadline",
    align: "center",
  },
  {
    text: "請求日",
    value: "invoice_date",
    align: "center",
  },
  {
    text: "カウント",
    value: "invoice_header_count",
    type: "number",
    align: "center",
  },
  {
    text: "合計金額",
    value: "invoice_charge_sum",
    type: "number",
    align: "end",
  },
  {
    text: "税込合計金額",
    value: "invoice_charge_including_tax_sum",
    type: "number",
    align: "end",
  },
  {
    text: "自動振替",
    value: "excelAutomaticTransfer",
    align: "center",
    sortable: false,
    handleKey: ["invoice_is_release", "invoice_is_incentive_release"],
  },
  {
    text: "インセンティブ",
    value: "handleItemIncentive",
    align: "center",
    sortable: false,
    handleType: "incentive",
    handleKey: [
      "invoice_is_release",
      "invoice_is_incentive",
      "invoice_is_incentive_release",
    ],
  },
  {
    text: "詳細",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchDateFrom = {
  label: "締日（以降）",
  column: "invoice_deadline",
  menu: false,
  default: getDateObject("first", -12),
  formula: "gte", // 以上
};

const searchSelect = {
  label: "ステータス",
  column: "invoice_group_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "未公開" },
    { value: 50, text: "公開済" },
  ],
  value: -1,
};

const excelAutomaticTransferFormat = [
  { label: "番号", wpx: 40 },
  { label: "名称", wpx: 120 },
  { label: "番号", wpx: 40 },
  { label: "名称", wpx: 120 },
  { label: "預金種目", wpx: 40 },
  { label: "口座番号", wpx: 80 },
  { label: "預金者名", wpx: 120 },
  { label: "引落金額", wpx: 80 },
  { label: "契約社名", wpx: 160 },
  { label: "契約社コード", wpx: 100 },
  { label: "検索コード1", wpx: 80 },
  { label: "検索コード2", wpx: 80 },
];
const excelDifferenceCheckFormat = [
  { label: "I/V CODE", wpx: 120 },
  { label: "CUST CODE", wpx: 80 },
  { label: "CUST NAME", wpx: 200 },
  { label: "AMOUNT1", wpx: 80 },
  { label: "AMOUNT2", wpx: 80 },
  { label: "I/V CODE", wpx: 120 },
  { label: "CUST CODE", wpx: 80 },
  { label: "CUST NAME", wpx: 200 },
  { label: "AMOUNT1", wpx: 80 },
  { label: "AMOUNT2", wpx: 80 },
  { label: "DUDGE", wpx: 70 },
  { label: "DIFF", wpx: 80 },
];

export default {
  setting,
  forms,
  headers,
  searchDateFrom,
  searchSelect,
  excelAutomaticTransferFormat,
  excelDifferenceCheckFormat,
};
