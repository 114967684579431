const setting = {
  title: "支払管理",
  originalTitle: "支払管理",
  apiAddress: "/paymentheader/",
  isPaymentCreate: true,
  isListReadonly: true,
  listSortBy: ["brother_reference___brother_code"],
  listSortDesc: [true],
  isExcelExport: true,
  isPaymentUserAllExcelExport: true,
  isPaymentDetailAllExcelExport: true,
  showExpand: true,
  expandType: "paymentdetail",
};

// formsはisPaymentCreateフラグでFormPaymentCreateを呼び出している
const forms = [];

const headers = [
  {
    text: "ブラザー",
    value: "brother_reference___brother_code",
    wpx: 60,
  },
  {
    text: "ブラザー名",
    value: "brother_reference___brother_name",
    wpx: 120,
  },
  {
    text: "対象金額",
    value: "payment_subject_charge",
    type: "number",
    align: "end",
  },
  {
    text: "支払金額",
    value: "payment_charge",
    type: "number",
    align: "end",
  },
  {
    text: "消費税",
    value: "payment_charge_tax",
    type: "number",
    align: "end",
  },
  {
    text: "支払税込金額",
    value: "payment_charge_including_tax",
    type: "number",
    align: "end",
  },
  {
    text: "Excel",
    value: "excelPaymentUser",
    align: "center",
    sortable: false,
  },
  {
    text: "明細Excel",
    value: "excelPaymentDetail",
    align: "center",
    sortable: false,
  },
  {
    text: "支払通知書",
    value: "reportPayment",
    align: "center",
    sortable: false,
    nonExcel: true,
  },
  {
    text: "明細",
    value: "data-table-expand",
    align: "center",
    sortable: false,
    nonExcel: true,
  },
];

const excelPaymentUserAllFormat = [
  { label: "ブラザーコード", wpx: 120 },
  { label: "ブラザー名", wpx: 140 },
  { label: "お客様コード", wpx: 120 },
  { label: "お客様名", wpx: 240 },
  { label: "対象金額", wpx: 100 },
  { label: "インセンティブ金額", wpx: 100 },
];

const excelPaymentDetailAllFormat = [
  { label: "ブラザーコード", wpx: 80 },
  { label: "ブラザー名", wpx: 120 },
  { label: "お客様", wpx: 200 },
  { label: "契約コード", wpx: 140 },
  { label: "種別", wpx: 70 },
  { label: "対象", wpx: 100 },
  { label: "月度", wpx: 90 },
  { label: "対象金額", wpx: 70 },
  { label: "レート", wpx: 60 },
  { label: "インセンティブ", wpx: 70 },
];

const excelPaymentUserFormat = [
  { label: "ブラザーコード", wpx: 120 },
  { label: "ブラザー名", wpx: 140 },
  { label: "お客様コード", wpx: 120 },
  { label: "お客様名", wpx: 240 },
  { label: "対象金額", wpx: 100 },
  { label: "インセンティブ金額", wpx: 100 },
];

const excelPaymentDetailFormat = [
  { label: "ブラザーコード", wpx: 80 },
  { label: "ブラザー名", wpx: 120 },
  { label: "お客様", wpx: 200 },
  { label: "契約コード", wpx: 140 },
  { label: "種別", wpx: 70 },
  { label: "対象", wpx: 100 },
  { label: "月度", wpx: 90 },
  { label: "対象金額", wpx: 70 },
  { label: "レート", wpx: 60 },
  { label: "インセンティブ", wpx: 70 },
];

export default {
  setting,
  forms,
  headers,
  excelPaymentUserAllFormat,
  excelPaymentDetailAllFormat,
  excelPaymentUserFormat,
  excelPaymentDetailFormat,
};
