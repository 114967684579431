<template>
  <v-container class="mx-0">
    <v-row>
      <v-col>
        <v-card
          width="800"
          min-height="800"
          rounded="lg"
          flat
          class="mx-0 pa-5"
        >
          <v-card-title class="font-weight-bold">
            <SpanHeadline :propsText="cardTitle" />
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="stepCount" vertical flat>
              <!-- ステップ1 -->
              <v-stepper-step
                :complete="stepCount > 1"
                step="1"
                class="mygenText--text text-h6"
              >
                {{ stepFirstTitle }}
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-form ref="formFirst" @submit.prevent>
                  {{ messageFirst }}

                  <v-card color="mygenBase" flat class="mt-5 pa-3" rounded="lg">
                    <v-card-text>
                      <p class="font-weight-bold mygenText--text text-h6">
                        {{ cancelData.plan_name }}
                      </p>
                      <v-row
                        v-for="(row, index) in plans"
                        :key="index"
                        class="ma-0 pa-0"
                      >
                        <v-col
                          cols="12"
                          sm="2"
                          class="ma-0 px-0 py-1 mygenText--text"
                        >
                          {{ row["label"] }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="10"
                          class="ma-0 px-0 py-1 mygenText--text"
                        >
                          {{ row["value"] }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card flat class="mt-5">
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtn1"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ2 -->
              <v-stepper-step
                :complete="stepCount > 2"
                step="2"
                class="mygenText--text text-h6"
              >
                {{ stepSecondTitle }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-form ref="formSecond" @submit.prevent>
                  {{ messageSecond }}

                  <CardTermsCancel />

                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="checkCancelTerms.value"
                          :label="checkCancelTerms.label"
                          :rules="checkCancelTerms.rules"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-card flat>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="確定"
                            :propsClick="submitBtn2"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ3 -->
              <v-stepper-step
                :complete="stepCount > 3"
                step="3"
                class="mygenText--text text-h6"
              >
                {{ stepThirdTitle }}
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-container>
                  <v-row align="center">
                    <v-col>
                      <p class="font-weight-bold primary--text text-h6">
                        申込が完了しました
                      </p>
                      <p class="mygenText--text text-body-1">
                        申し込みの進捗は［契約中のサービス一覧］のステータスでご確認いただけます。
                      </p>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <ButtonCommon
                        propsLabel="閉じる"
                        :propsClick="clickClose"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
      <v-spacer />
    </v-row>

    <OverlayProgress v-if="progress" />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { convertNumComma, createKey } from "@/utils/utilities.js";
import { requiredCheckboxRules } from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import CardTermsCancel from "@/components/atoms/CardTermsCancel.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";

export default defineComponent({
  name: "FormContractCancel",

  components: {
    CardTermsCancel,
    ButtonCommon,
    OverlayProgress,
    SpanHeadline,
  },

  props: {
    propsEditId: {
      type: String,
      required: true,
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const editId = ref(props.propsEditId);

    const cardTitle = ref("解約申込");
    const stepFirstTitle = ref("解約プラン確認");
    const stepSecondTitle = ref("解約の注意事項と同意");
    const stepThirdTitle = ref("申込完了");
    const messageFirst = ref(
      "以下のプランを解約する場合は「次へ」ボタンを押してください。一覧画面へ戻る場合は「閉じる」ボタンを押してください。"
    );
    const messageSecond = ref(
      "解約に関する注意事項をお読みください。注意事項に同意いただきましたら「確定」ボタンを押してください。一覧画面へ戻る場合は「閉じる」ボタンを押してください。"
    );
    const formFirst = ref(false);
    const formSecond = ref(false);

    const stepCount = ref(1);
    const cancelData = ref({});
    const plans = ref([]);
    const checkCancelTerms = reactive({
      value: false,
      label: "上記注意事項に同意の上、解約手続きを行います。",
      rules: [requiredCheckboxRules],
    });

    const progress = ref(false);
    const formKey = ref(createKey());

    /**
     * 情報取得（解約プランデータロード）
     */
    const loadData = () => {
      // api:データ取得
      api({
        method: "get",
        url: "/contractplanbase/?id=" + editId.value,
      }).then((response) => {
        const res = response.data[0];
        cancelData.value = res;
        plans.value = [
          { label: "契約番号：", value: res["contract_code"] },
          { label: "サイト：", value: res.contract.site_address },
          {
            label: "月額料金：",
            value:
              convertNumComma(res["monthly_charge"]) +
              "円（税込 " +
              convertNumComma(res["monthly_charge_including_tax"]) +
              "円）",
          },
        ];
      });
    };

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const data = {
        contract: cancelData.value.contract.id,
      };
      // 登録
      api({
        method: "post",
        url: "/contractcancel/",
        data: data,
      }).then(
        () => {
          store.dispatch("message/setInfoMessage", {
            message: "解約の申込が完了しました。",
          });
          stepCount.value++;
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // ボタン1
    const submitBtn1 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン2
    const submitBtn2 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formSecond.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        saveData();
      }
    };
    // 閉じるボタン
    const clickClose = () => {
      // ダイアログ
      emit("close");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      editId,

      cardTitle,
      stepFirstTitle,
      stepSecondTitle,
      stepThirdTitle,
      messageFirst,
      messageSecond,
      formFirst,
      formSecond,

      stepCount,
      cancelData,
      plans,
      checkCancelTerms,

      progress,
      formKey,

      submitBtn1,
      submitBtn2,
      clickClose,
    };
  },
});
</script>
