import { getDateObject } from "@/utils/utilities.js";
import { setTaxRate } from "@/utils/selectUtility.js";
import {
  requiredRules,
  dateRules,
  selectedRules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "消費税率",
  apiAddress: "/taxrate/",
  // DB構成から外したため閲覧のみ
  isListReadonly: true,
  listSortBy: ["reference_date"],
  listSortDesc: [true],
};

const forms = [
  {
    column: "reference_date",
    label: "基準日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "tax_rate",
    label: "消費税率",
    type: "select",
    items: setTaxRate(),
    default: setTaxRate(10),
    rules: [selectedRules],
    cols: 6,
  },
];

const headers = [
  {
    text: "基準日",
    value: "reference_date",
    align: "center",
  },
  {
    text: "消費税率",
    value: "tax_rate",
    type: "select",
    items: setTaxRate(),
    align: "center",
  },
];

export default {
  setting,
  forms,
  headers,
};
