<template>
  <v-app>
    <div id="auth-page">
      <SnackbarMessage />

      <v-container :class="$vuetify.breakpoint.xs ? '' : 'mt-16'">
        <v-row justify="center">
          <v-spacer />
          <v-col cols="12" sm="8" md="8" lg="5" xl="4" class="pa-0">
            <v-card ref="form" :elevation="$vuetify.breakpoint.xs ? '0' : '8'">
              <v-card
                color="primary"
                dark
                flat
                height="120"
                class="px-10 pt-16"
                :class="$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-b-0'"
              >
                <v-toolbar-title>MyGEN</v-toolbar-title>
              </v-card>

              <v-card
                flat
                class="py-10"
                :class="$vuetify.breakpoint.xs ? 'px-5' : 'px-10'"
              >
                <router-view :key="$route.fullPath" @update="updateForm" />

                <v-divider class="my-8"></v-divider>

                <v-card-text
                  v-if="this.$route.path.indexOf('/auth/login') === 0"
                >
                  <div class="text-center">
                    <router-link
                      to="/auth/reset"
                      class="primary--text small text-decoration-none"
                    >
                      パスワードを忘れましたか？
                    </router-link>
                  </div>
                  <div class="text-center mt-3">
                    <router-link
                      to="/auth/signup"
                      class="primary--text small text-decoration-none"
                    >
                      アカウントをお持ちでない方はこちら
                    </router-link>
                  </div>
                </v-card-text>

                <v-card-text
                  v-if="
                    this.$route.path.indexOf('/auth/signup') === 0 ||
                    this.$route.path.indexOf('/auth/resend') === 0 ||
                    this.$route.path === '/auth/reset' ||
                    this.$route.path.indexOf('/auth/resetpassword') === 0 ||
                    this.$route.path.indexOf('/auth/activation') === 0
                  "
                >
                  <div
                    class="text-center mb-3"
                    v-if="this.$route.path.indexOf('/auth/signup') === 0"
                  >
                    <router-link
                      to="/auth/resend"
                      class="primary--text small text-decoration-none"
                    >
                      アクティベーションメールを再送信する
                    </router-link>
                  </div>
                  <div class="text-center">
                    <router-link
                      to="/auth/login"
                      class="primary--text small text-decoration-none"
                    >
                      ログインする
                    </router-link>
                  </div>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import store from "@/store";
import SnackbarMessage from "@/components/atoms/SnackbarMessage.vue";

export default defineComponent({
  name: "BaseAuth",

  components: {
    SnackbarMessage,
  },

  setup() {
    const debug = ref(true);
    const form = ref({
      signupEmail: null,
      signupPassword: null,
      signupRePassword: null,
      loginEmail: null,
      loginPassword: null,
      resendEmail: null,
      resetEmail: null,
      newPassword: null,
      reNewPassword: null,
    });

    // 子コンポーネントデータ取得
    const updateForm = (formData) => {
      Object.assign(form, formData);
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    return {
      debug,
      form,
      updateForm,
    };
  },
});
</script>
