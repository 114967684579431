<template>
  <v-card flat rounded="lg" class="mygenCard pa-4">
    <v-card-title class="primary--text font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-text class="mygenText--text">
      <v-row>
        <v-col v-if="isComment" cols="12" class="pt-0">
          {{ comment }}
        </v-col>
        <v-col cols="12" class="text-center">
          <span
            v-if="isZero"
            class="font-weight-bold secondary--text text--lighten-5 text-h2"
          >
            {{ convertNumComma(count) }}
          </span>
          <span v-else class="font-weight-bold mygenAccent--text text-h2">
            {{ convertNumComma(count) }}
          </span>
          <span class="font-weight-bold secondary--text text-darken-3 text-h5">
            件
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="isButton">
      <ButtonCommon
        :key="'btn-homeinfo-' + formKey"
        :propsLabel="buttonLabel"
        :propsClick="clickButton"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router/composables";
import { convertNumComma, createKey } from "@/utils/utilities.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";

export default defineComponent({
  name: "CardHomeInfo",

  components: { ButtonCommon },

  props: {
    propsColor: { type: String, default: "primary" },
    propsTitle: { type: String, default: "", required: true },
    propsComment: { type: String, default: "" },
    propsCount: { type: Number, default: 0, required: true },
    propsButtonLabel: { type: String, default: "" },
    propsButtonPath: { type: String, default: "" },
    propsButtonSearch: { type: Number, default: null },
  },

  setup(props) {
    const router = useRouter();
    const color = ref(props.propsColor);
    const title = ref(props.propsTitle);
    const comment = ref(props.propsComment);
    const count = ref(props.propsCount);
    const buttonLabel = ref(props.propsButtonLabel);
    const buttonPath = ref(props.propsButtonPath);
    const buttonSearch = ref(props.propsButtonSearch);
    const formKey = ref(createKey());

    const isComment = computed(() => {
      return comment.value !== "";
    });
    const isZero = computed(() => {
      return count.value === 0;
    });
    const isButton = computed(() => {
      return buttonLabel.value !== "";
    });

    const clickButton = () => {
      router.push({
        path: buttonPath.value,
        query: {
          searchselectdefault:
            buttonSearch.value !== null && buttonSearch.value !== undefined
              ? buttonSearch.value
              : null,
        },
      });
    };

    return {
      color,
      title,
      comment,
      count,
      buttonLabel,
      formKey,
      isComment,
      isZero,
      isButton,
      convertNumComma,
      clickButton,
    };
  },
});
</script>
