import {
  setPrefectureList,
  setInvoiceNotification,
} from "@/utils/selectUtility.js";
import {
  requiredRules,
  zipCodeRules,
  selectedRules,
  telRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "請求先",
  apiAddress: "/customer/",
  isListReadonly: true,
  listSortBy: ["invoice_customer_name"],
  listSortDesc: [false],
};

const forms = [
  {
    column: "invoice_customer_name",
    label: "請求先",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "invoice_person_in_charge",
    label: "担当者名",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "invoice_zip_code",
    label: "郵便番号",
    type: "text",
    rules: [requiredRules, zipCodeRules],
    cols: 6,
    hint: "0009999（ハイフンなし）",
  },
  {
    column: "invoice_prefecture",
    label: "都道府県",
    type: "select",
    items: setPrefectureList(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "invoice_address_1",
    label: "住所（市町村・番地）",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "invoice_address_2",
    label: "住所（建物名）",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "invoice_tel",
    label: "電話番号",
    type: "text",
    rules: [requiredRules, telRules],
    cols: 6,
    hint: "0311112222（ハイフンなし）",
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
  {
    column: "invoice_notification",
    label: "請求書お知らせメール",
    type: "select",
    items: setInvoiceNotification(),
    rules: [selectedRules],
    default: true,
    cols: 6,
  },
];

const postFixedValue = [
  {
    column: "user",
    type: "user",
  },
];

const headers = [
  {
    text: "請求先",
    value: "invoice_customer_name",
    align: "start",
  },
  {
    text: "担当者名",
    value: "invoice_person_in_charge",
  },
  {
    text: "郵便番号",
    value: "invoice_zip_code",
  },
  {
    text: "都道府県",
    value: "invoice_prefecture",
  },
  {
    text: "住所1",
    value: "invoice_address_1",
  },
  {
    text: "住所2",
    value: "invoice_address_2",
  },
  {
    text: "電話番号",
    value: "invoice_tel",
  },
  {
    text: "表示",
    value: "readonly",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
