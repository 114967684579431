<template>
  <div>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col>
          <v-card flat width="1000" rounded="lg" class="mt-5 pa-5">
            <v-expansion-panels v-model="panel" accordion flat hover multiple>
              <v-expansion-panel key="0">
                <v-expansion-panel-header
                  class="font-weight-bold secondary--text text--darken-2"
                >
                  お客様情報
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ContainerCustomerInfo
                    :key="'customer-' + formKey"
                    :propsFormsFirst="formsFirst"
                    :propsFormsSecond="formsSecond"
                  />
                  <ContainerCustomerSetting
                    v-if="formType !== 'customersetting'"
                    :key="'customer-setting-' + formKey"
                    :propsCustomerData="customerData"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel key="1" v-if="formType !== 'customersetting'">
                <v-expansion-panel-header
                  class="font-weight-bold secondary--text text--darken-2"
                >
                  契約情報
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-5">
                  <ContainerContractDetail
                    :key="'contract-' + formKey"
                    :propsData="orderData"
                    :propsChange="true"
                    :propsShowCharge="true"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <ModelForm
              :key="'model-' + formKey"
              :propsFormMode="childMode"
              :propsFormType="formType"
              :propsFormDialog="true"
              :propsEditId="
                formType === 'customersetting' ? customerId : editId
              "
              @cancel="cancelDialog"
              @close="closeDialog"
            />
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { createKey, getBaseInfo } from "@/utils/utilities.js";
import {
  settingDictionaryForms,
  setResponseApplyFormsDict,
} from "@/utils/formUtility.js";
import ModelForm from "@/views/model/ModelForm.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import ContainerContractDetail from "@/components/molecules/ContainerContractDetail.vue";
import ContainerCustomerInfo from "@/components/molecules/ContainerCustomerInfo.vue";
import ContainerCustomerSetting from "@/components/molecules/ContainerCustomerSetting.vue";
import FormsCustomer from "@/forms/customer.js";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";

export default defineComponent({
  name: "FormContractHandle",

  components: {
    ModelForm,
    ContainerContractDetail,
    ContainerCustomerInfo,
    ContainerCustomerSetting,
    OverlayProgress,
  },

  props: {
    propsDialogType: {
      type: String,
      required: true,
    },
    propsFormType: {
      type: String,
      required: true,
    },
    propsEditId: {
      type: String,
      default: "XXXXX",
    },
    propsIsCancel: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["cancel", "close"],

  setup(props, { emit }) {
    // お客様設定（お客様リストから遷移するケースで利用）
    const dialogType = ref(props.propsDialogType);
    const formType = ref(props.propsFormType);
    const editId = ref(props.propsEditId);
    const isCancel = ref(props.propsIsCancel);

    let formsFirst = reactive(settingDictionaryForms(FormsCustomer.forms));
    let formsSecond = reactive(
      settingDictionaryForms(FormsInvoiceAddress.forms)
    );
    const orderData = ref({});
    const customerData = ref({});

    const customerId = computed(() => {
      return customerData.value["id"] !== null &&
        customerData.value["id"] !== undefined
        ? customerData.value["id"]
        : null;
    });
    const childMode = computed(() => {
      if (
        formType.value === "contractdelete" ||
        formType.value === "contractcanceldelete"
      ) {
        return "delete";
      }
      return "patch";
    });
    let tempPanel = [0, 1];
    // ローカルストレージのエクスパンションパネルの状態をセット
    if (localStorage.getItem("panelFormContractHandle")) {
      tempPanel = JSON.parse(localStorage.getItem("panelFormContractHandle"));
    }
    const panel = ref(tempPanel);
    const progress = ref(false);
    const formKey = ref(createKey());

    /**
     * 情報取得
     */
    const loadData = () => {
      progress.value = true;
      let apiAddress = "";
      if (dialogType.value === "customersetting") {
        // お客様設定（お客様リストから遷移するケース）
        apiAddress = "/customer/?id=" + editId.value;
      } else if (isCancel.value) {
        // 解約対応
        apiAddress = "/contractcancelbase/?id=" + editId.value;
      } else {
        // 契約対応
        apiAddress = "/contractplanbase/?id=" + editId.value;
      }
      api({
        method: "get",
        url: apiAddress,
      })
        .then((response) => {
          const res = response.data[0];
          if (dialogType.value === "customersetting") {
            orderData.value = {};
            customerData.value = res;
          } else if (isCancel.value) {
            orderData.value = res["contract_plan"][0];
            customerData.value = res["contract"]["customer_reference"];
          } else {
            // 元データ取得
            if (res["base_contract_plan"]) {
              api({
                method: "get",
                url: "/contractplanbase/?id=" + res["base_contract_plan"],
              })
                .then((tempResponse) => {
                  const tempRes = tempResponse.data[0];
                  res["base_info"] = getBaseInfo(tempRes, res);
                  orderData.value = res;
                  customerData.value = res["contract"]["customer_reference"];
                })
                .then(() => {
                  // 契約者情報セット
                  formsFirst = reactive(
                    setResponseApplyFormsDict(customerData.value, formsFirst)
                  );
                  // 請求先情報セット
                  formsSecond = reactive(
                    setResponseApplyFormsDict(customerData.value, formsSecond)
                  );
                })
                .then(() => {
                  progress.value = false;
                  formKey.value = createKey();
                });
            } else {
              orderData.value = res;
              customerData.value = res["contract"]["customer_reference"];
            }
          }
        })
        .then(() => {
          // 契約者情報セット
          formsFirst = reactive(
            setResponseApplyFormsDict(customerData.value, formsFirst)
          );
          // 請求先情報セット
          formsSecond = reactive(
            setResponseApplyFormsDict(customerData.value, formsSecond)
          );
        })
        .then(() => {
          progress.value = false;
          formKey.value = createKey();
        });
    };

    // キャンセルボタン
    const cancelDialog = (message) => {
      emit("cancel", message);
    };

    // 閉じるボタン
    const closeDialog = (message) => {
      emit("close", message);
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    onUpdated(() => {
      const elements = document.getElementsByClassName("v-dialog--active");
      if (elements && elements.length) {
        // スクロール位置をトップに
        elements[0].scrollTop = 0;
      }
    });

    watch(panel, (val) => {
      // エクスパンションパネルの状態をローカルストレージに保存
      localStorage.setItem("panelFormContractHandle", JSON.stringify(val));
    });

    // データ取得
    loadData();

    return {
      dialogType,
      formType,
      editId,
      isCancel,

      formsFirst,
      formsSecond,
      orderData,
      customerData,

      customerId,
      childMode,
      panel,
      progress,
      formKey,

      cancelDialog,
      closeDialog,
    };
  },
});
</script>
