import { getMonthObject } from "@/utils/utilities.js";
import {
  getSelectObject,
  setInvoiceType,
  setPaymentMethod,
} from "@/utils/selectUtility.js";
import {
  requiredRules,
  dateMonthRules,
  dateMonthOrBlankRules,
  selectedRules,
  selectedNotZeroRules,
  equiredOrZeroRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "公開対応",
  originalTitle: "公開登録",
  apiAddress: "/contracthandle/",
  isListReadonly: true,
};

const forms = [
  {
    column: "order_status",
    label: "設定対応",
    type: "radio",
    items: getSelectObject("contract_status", "release"),
    rules: [],
  },
  {
    column: "contract_date",
    label: "課金開始月（契約開始月）",
    type: "dateMonthFirst",
    menu: false,
    default: getMonthObject(),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
  },
  {
    column: "invoice_calculator_start_date",
    label: "請求計算開始月",
    type: "dateMonthFirst",
    menu: false,
    default: getMonthObject(),
    rules: [dateMonthOrBlankRules],
    write: true,
    cols: 6,
    hint: "必要に応じて請求計算を開始する月を指定します。（契約を継承している場合「請求開始月」が反映されています）",
  },
  {
    column: "contract___invoice_type",
    label: "請求単位",
    type: "select",
    items: setInvoiceType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "commission_payment_method",
    label: "初期・変更費用支払方法",
    type: "select",
    items: setPaymentMethod(),
    rules: [selectedNotZeroRules],
    cols: 6,
  },
  {
    column: "incentive_first",
    label: "初回インセンティブ",
    type: "selectIncentiveFirst",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "incentive_month",
    label: "月度インセンティブ",
    type: "selectIncentiveMonth",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "coupon",
    label: "クーポン",
    type: "selectCoupon",
    items: [],
    rules: [],
  },
  {
    column: "incentive_discount_charge",
    label: "クーポンのインセンティブ対象",
    type: "number",
    rules: [equiredOrZeroRules],
    cols: 6,
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
  {
    column: "contract___site_address",
    label: "サイトアドレス",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "contract___site_name",
    label: "サイト名称",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "contract___staff_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const postFixedValue = [
  {
    column: "release_date",
    type: "today",
    equalRules: ["order_status", 50],
  },
];

const headers = [];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
