<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col>
        <v-card width="600px" rounded="lg" :flat="true" :class="'pa-5'">
          <v-card-title class="font-weight-bold grey--text text--darken-1">
            {{ cardTitle }}
          </v-card-title>
          <v-card-text>
            <span
              v-for="(col, key) in cardComment"
              :key="'comment-' + key + '-' + formKey"
              class="grey--text text--darken-3"
              >{{ col }} <br />
            </span>
            <br />
            <span class="text-h5 font-weight-bold primary--text text--darken-1">
              {{ cardDate }}
            </span>
          </v-card-text>

          <v-card-text v-if="!progressLinear && !isDelete">
            <v-form ref="formCreate">
              <v-container>
                <v-row>
                  <v-col
                    v-for="(form, key) in forms"
                    :key="key + '-' + formKey"
                    cols="12"
                    :sm="form.cols ? form.cols : 12"
                  >
                    <FormControls propsMode="post" :propsForm="form" />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-text v-if="!progressLinear">
            <v-container>
              <v-row>
                <v-spacer />
                <v-col
                  cols="12"
                  sm="6"
                  v-if="(isUpdate || isCreate) && !isDelete"
                >
                  <ButtonCommon
                    :key="'button-' + formKey"
                    propsLabel="契約更新"
                    :propsClick="saveData"
                    :propsDisabled="isUpdate || buttonDesabeled"
                  />
                </v-col>
                <v-col cols="12" sm="6" v-if="isDelete">
                  <ButtonCommon
                    propsLabel="削除"
                    :propsClick="deleteData"
                    :propsDisabled="buttonDesabeled"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <ButtonCommon
                    propsColor="secondary"
                    propsLabel="キャンセル"
                    :propsOutlined="true"
                    :propsClick="clickCancel"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text v-if="progressLinear">
            <v-container style="height: 185px">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  {{ progressMessage }}
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    :key="'progress-' + loggingId"
                    color="deep-purple accent-4"
                    indeterminate
                    rounded
                    height="6"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>

    <OverlayProgress v-if="progress" />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, reactive, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router/composables";
import { createKey, getDateObject } from "@/utils/utilities.js";
import {
  requiredRules,
  dateRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";

export default defineComponent({
  name: "FormContractRenewalCreate",

  components: {
    FormControls,
    ButtonCommon,
    OverlayProgress,
  },

  props: {
    propsDeleteId: {
      type: String,
      default: "",
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const deleteId = ref(props.propsDeleteId);
    const isDelete = computed(() => {
      if (deleteId.value !== "") {
        return true;
      }
      return false;
    });

    const cardTitle = computed(() => {
      if (isDelete.value) {
        return "契約更新削除";
      }
      return "一括契約更新";
    });
    const cardComment = ref([]);
    const cardDate = ref("");
    const formCreate = ref(false);

    const renwalSetting = ref({});
    const invoiceGroup = ref({});
    const isUpdate = ref(false);
    const isCreate = ref(false);
    const contractDateMax = ref("");
    const flowCount = ref(0);
    const editId = ref("");

    const forms = reactive({
      contractRenewalDate: {
        column: "contract_renewal_date",
        label: "契約更新日",
        type: "date",
        menu: false,
        value: "",
        rules: [requiredRules, dateRules],
        cols: 6,
      },
      staffComment: {
        column: "staff_comment",
        label: "コメント",
        type: "text",
        value: "",
        rules: [charLength200Rules],
      },
    });

    const buttonDesabeled = ref(false);
    const progressMessage = ref("");
    const progressLinear = ref(false);
    const progress = ref(false);
    const loggingId = ref(createKey());
    const formKey = ref(createKey());

    /**
     * データ取得処理
     */
    const loadData = () => {
      progress.value = true;

      // api:データ取得（更新グループデータ）
      if (isDelete.value) {
        api({
          method: "get",
          url: "/contractrenewal/?id=" + deleteId.value,
        })
          .then((response) => {
            const res = response.data[0];
            if (res) {
              cardComment.value = [
                "以下の契約更新日の契約更新データを削除します",
              ];
              cardDate.value =
                res["contract_renewal_date"] +
                "（" +
                res["contract_renewal_code"] +
                "）";
              editId.value = res["id"];
              forms["contractRenewalDate"].value = res["contract_renewal_date"];
            } else {
              // Error
              cardComment.value = ["契約更新データを削除することができません"];
              cardDate.value = "";
            }
          })
          .then(() => {
            progress.value = false;
          });
      } else {
        // api:データ取得
        api({
          method: "get",
          url: "/contractinfo/",
        })
          .then((response) => {
            const res = response.data;
            renwalSetting.value = res;
            // 請求データの新規作成
            contractDateMax.value = res["contract_date_max"];
            if (contractDateMax.value === "") {
              cardComment.value = ["更新可能な契約データが存在しません"];
            } else if (res["flow_count"] > 0) {
              cardComment.value = [
                "契約変更の未対応フローが存在します",
                "未対応フローに対応してください",
              ];
            } else {
              cardComment.value = [
                "以下の日付が最新の契約日付です",
                "指定する契約更新日以降の契約は現在の契約開始日が維持されます",
              ];
              cardDate.value = contractDateMax.value;
              isCreate.value = true;
            }

            formKey.value = createKey();
          })
          .then(() => {
            // 本日の日付
            forms["contractRenewalDate"].value = getDateObject();
            progress.value = false;
          })
          .then(() => {
            api({
              method: "get",
              url: "/contractrenewalcreatesetting/",
            }).then((response) => {
              if (
                response.data &&
                response.data[0] &&
                response.data[0]["create_setting"]
              ) {
                buttonDesabeled.value = true;
                progressMessage.value = "契約更新データ作成中...";
                progressLinear.value = true;
              }
            });
          });
      }
    };

    const logging = () => {
      console.log("create data");
      return createKey();
    };

    /**
     * データ登録処理（非同期処理）
     */
    const saveData = async () => {
      const contractRenewalDate = forms["contractRenewalDate"].value;
      const renewalDate = new Date(contractRenewalDate);
      const maxDate = new Date(
        renwalSetting.value["contract_renewal_date_max"]
      );
      // 入力チェック
      if (
        contractRenewalDate === "" ||
        contractRenewalDate === undefined ||
        contractRenewalDate === null
      ) {
        store.dispatch("message/setErrorMessage", {
          message: "契約更新日の指定が正しくありません",
        });
        return false;
      } else if (renewalDate <= maxDate) {
        store.dispatch("message/setErrorMessage", {
          message: "指定された契約更新日以降の契約更新が存在します",
        });
        return false;
      }
      // form バリデーション
      const validResult = formCreate.value.validate();
      if (validResult) {
        buttonDesabeled.value = true;
        progressMessage.value = "契約更新データ作成中...";
        progressLinear.value = true;

        // apiで渡すデータを生成
        const data = {
          contract_renewal_date: forms["contractRenewalDate"].value,
          staff_comment: forms["staffComment"].value,
          user: store.state.auth.id,
        };

        loggingId.value = setInterval(() => logging(), 4000);

        // api:データ登録（非同期処理）
        api.defaults.timeout = 6000000;
        const results = await api({
          method: "post",
          url: "/contractrenewal/",
          data: data,
        })
          .then((response) => {
            const message = "作成しました";
            // 登録後処理
            emit("close", message);
            progressLinear.value = false;
            clearInterval(loggingId.value);
            return response;
          })
          .catch(function (error) {
            clearInterval(loggingId.value);
            return error.response;
          });
        return results;
      }
    };

    /**
     * データ削除処理（非同期処理）
     */
    const deleteData = async () => {
      if (isDelete.value) {
        buttonDesabeled.value = true;
        progressMessage.value = "請契約更新データ削除中...";
        progressLinear.value = true;

        // api:削除（非同期処理）
        api.defaults.timeout = 6000000;
        const results = await api({
          method: "delete",
          url: "/contractrenewal/" + deleteId.value + "/",
        })
          .then((response) => {
            const message = "削除しました";
            // 削除後処理（ダイアログクローズ）
            emit("close", message);
            progressLinear.value = false;
            return response;
          })
          .catch(function (error) {
            return error.response;
          });
        return results;
      } else {
        progressLinear.value = false;
        store.dispatch("message/setErrorMessage", {
          message: "削除できませんでした",
        });
      }
    };

    // キャンセルボタン押下
    const clickCancel = () => {
      // ダイアログクローズ
      emit("close");
    };

    onBeforeRouteUpdate(async (to, from, next) => {
      next();
      // 遷移後処理
      if (to.query !== from.query) {
        // 再描画処理
        loadData();
      }
    });

    // データ取得
    loadData();

    return {
      deleteId,
      isDelete,

      cardTitle,
      cardComment,
      cardDate,
      formCreate,

      renwalSetting,
      invoiceGroup,
      isUpdate,
      isCreate,
      contractDateMax,
      flowCount,
      editId,

      forms,

      buttonDesabeled,
      progressMessage,
      progressLinear,
      progress,
      loggingId,
      formKey,

      saveData,
      deleteData,
      clickCancel,
    };
  },
});
</script>
