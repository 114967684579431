<template>
  <v-container class="mx-0">
    <v-row>
      <v-col>
        <v-card
          width="700"
          min-height="700"
          rounded="lg"
          flat
          class="mx-0 pa-5"
        >
          <v-card-title>
            <SpanHeadline :propsText="cardTitle" />
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="stepCount" vertical flat>
              <!-- ステップ1 -->
              <v-stepper-step
                :complete="stepCount > 1"
                step="1"
                class="mygenText--text text-h6"
              >
                {{ stepFirstTitle }}
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-form ref="formFirst" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col cols="12">
                          <h3 class="primary--text">
                            {{ forms.coinRate }}
                          </h3>
                          <h4 class="secondary--text">
                            {{ forms.coinRateComment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.coinQuantity"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                        <v-col cols="12">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.coinComment"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtn1"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ2 -->
              <v-stepper-step
                :complete="stepCount > 2"
                step="2"
                class="mygenText--text text-h6"
              >
                {{ stepSecondTitle }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-form ref="formSecond" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col>
                          <div class="caption">
                            {{ forms.coinQuantity.label }}
                          </div>
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr
                                  v-for="(row, index) in confirmData"
                                  :key="index"
                                >
                                  <td
                                    :class="[
                                      row[0] === 'data'
                                        ? 'font-weight-bold'
                                        : row[0] === 'total'
                                        ? 'font-weight-bold primary--text'
                                        : 'text-caption',
                                    ]"
                                  >
                                    {{ row[1] }}
                                    <br v-if="row[2]" />
                                    <div v-if="row[2]">
                                      <span class="text-caption">{{
                                        row[2]
                                      }}</span>
                                    </div>
                                  </td>
                                  <td class="text-center">
                                    <v-chip
                                      v-if="row[3] === true"
                                      color="pink"
                                      x-small
                                      dark
                                      class="font-weight-bold mr-3"
                                    >
                                      変更
                                    </v-chip>
                                  </td>
                                  <td class="text-right">{{ row[4] }}</td>
                                  <td class="text-right">{{ row[5] }}</td>
                                  <td
                                    :class="[
                                      row[0] === 'total'
                                        ? 'text-right font-weight-bold primary--text text--darken-2'
                                        : 'text-right font-weight-bold',
                                    ]"
                                  >
                                    <span
                                      v-if="row[6]"
                                      class="text-body-1 font-weight-bold"
                                    >
                                      {{ row[6] }}
                                    </span>
                                    <span
                                      v-if="row[6]"
                                      class="text-body-2 pr-2"
                                    >
                                      円
                                    </span>
                                    <br v-if="row[7]" />
                                    <div
                                      v-if="row[7]"
                                      style="white-space: nowrap"
                                    >
                                      <span class="text-caption">（税込</span>
                                      <span class="text-body-2">
                                        {{ row[7] }}
                                      </span>
                                      <span class="text-caption">円）</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="12">
                          <div class="caption">
                            {{ forms.coinComment.label }}
                          </div>
                          <v-textarea
                            v-model="forms.coinComment.value"
                            readonly
                            flat
                            solo
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <CardTermsOfService @download="downloadTos" />
                          <v-checkbox
                            v-model="checkTermsOfService.value"
                            :label="checkTermsOfService.label"
                            :rules="checkTermsOfService.rules"
                            :disabled="!isTosDownload"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="確定"
                            :propsClick="submitBtn2"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ3 -->
              <v-stepper-step
                :complete="stepCount === 3"
                step="3"
                class="mygenText--text text-h6"
              >
                {{ stepThirdTitle }}
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-container>
                  <v-row align="center">
                    <v-col>
                      <span class="font-weight-bold primary--text text-h6">
                        申込が完了しました
                      </span>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <ButtonCommon
                        propsLabel="閉じる"
                        :propsClick="clickClose"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>

    <OverlayProgress v-if="progress" />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router/composables";
import { convertNumComma } from "@/utils/utilities.js";
import {
  numericIntegerRules,
  requiredCheckboxRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import CardTermsOfService from "@/components/atoms/CardTermsOfService.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";

export default defineComponent({
  name: "FormCoinOrder",

  components: {
    CardTermsOfService,
    FormControls,
    ButtonCommon,
    OverlayProgress,
    SpanHeadline,
  },

  emits: ["close"],

  setup(props, { emit }) {
    const router = useRouter();

    const cardTitle = ref("GEN-COIN購入");
    const stepFirstTitle = ref("COIN枚数選択");
    const stepSecondTitle = ref("申込内容確認");
    const stepThirdTitle = ref("申込完了");
    const formFirst = ref(false);
    const formSecond = ref(false);

    const stepCount = ref(1);
    let rates = reactive({});
    const forms = reactive({
      coinRate: "",
      coinQuantity: {
        type: "number",
        value: "0",
        label: "コイン",
        rules: [numericIntegerRules],
        min: 1,
      },
      coinComment: {
        type: "textarea",
        value: "",
        label: "備考",
        rules: [charLength200Rules],
        hint: "200文字以内でご入力いただけます",
      },
    });
    const checkTermsOfService = reactive({
      value: false,
      label: "利用規約に同意する（利用規約を確認するとチェックできます）",
      rules: [requiredCheckboxRules],
    });
    const confirmData = ref([]);
    const isTosDownload = ref(false);
    const progress = ref(false);

    /**
     * 情報取得
     */
    const loadData = () => {
      // api:設定情報取得
      api({
        method: "get",
        url: "/ordersetting/",
      }).then((response) => {
        // レート設定
        const res = response.data["order_setting_data"];
        rates = reactive({});
        if (res) {
          rates["order_tax_rate"] = res["tax_rate"];
          rates["coin_rate_including_tax"] = res["coin_rate_including_tax"];
          rates["order_support_rate"] = res["support_rate"];
          forms.coinRate =
            "GEN-COIN @" +
            convertNumComma(res["coin_rate"]) +
            "円（税込" +
            convertNumComma(res["coin_rate_including_tax"]) +
            "円）";
        }
      });
    };

    // オーダー情報ロード
    const loadOrderData = () => {
      // api:GEN-COIN購入金額計算情報取得
      api({
        method: "get",
        url: "/ordercoincalc/" + "?coin_quantity=" + forms.coinQuantity.value,
      }).then((response) => {
        const res = response.data["order_coin_data"];
        confirmData.value = [
          [
            "data",
            "GEN-COIN",
            "",
            "",
            "@" + convertNumComma(res["coin_rate"]),
            "x" + convertNumComma(res["coin_quantity"]),
            convertNumComma(res["coin_charge"]),
            convertNumComma(res["coin_charge_including_tax"]),
          ],
          [
            "total",
            "お支払い金額",
            "",
            "",
            "",
            "",
            convertNumComma(res["coin_charge"]),
            convertNumComma(res["coin_charge_including_tax"]),
          ],
        ];
      });
    };

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const coinQuantity =
        forms.coinQuantity.value === undefined ? 0 : forms.coinQuantity.value;

      const data = {
        customer_id: store.state.auth.customerId,
        coin_quantity: coinQuantity,
        coin_comment: forms.coinComment.value,
      };
      // 登録
      api({
        method: "post",
        url: "/contractcoin/",
        data: data,
      }).then(
        () => {
          store.dispatch("message/setInfoMessage", {
            message: "購入の申込が完了しました。",
          });
          stepCount.value++;
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // 利用規約ダウンロード
    const downloadTos = () => {
      isTosDownload.value = true;
    };

    // ボタン1
    const submitBtn1 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン2
    const submitBtn2 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formSecond.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        saveData();
      }
    };
    // ボタン3
    const submitBtn3 = () => {
      router.go({ path: "/", force: true });
    };
    // 戻るボタン
    const returnBtn = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };
    // 閉じるボタン
    const clickClose = () => {
      // ダイアログ
      emit("close");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      cardTitle,
      stepFirstTitle,
      stepSecondTitle,
      stepThirdTitle,
      formFirst,
      formSecond,

      stepCount,
      rates,
      forms,
      checkTermsOfService,
      confirmData,
      isTosDownload,
      progress,

      loadOrderData,
      downloadTos,
      submitBtn1,
      submitBtn2,
      submitBtn3,
      returnBtn,
      clickClose,
    };
  },
});
</script>
