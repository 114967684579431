<template>
  <v-chip
    :color="chipColor"
    :small="chipSmall"
    text-color="white"
    class="ma-1 font-weight-bold"
  >
    <v-icon v-if="chipClass === 10" small> mdi-checkbox-marked-circle </v-icon>
    <v-icon v-else-if="chipClass === 20" small> mdi-plus-circle </v-icon>
    <v-icon v-else small> mdi-minus-circle </v-icon>
    <span class="mx-1">
      {{ chipText }}
    </span>
  </v-chip>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ChipOption",

  props: {
    propsClass: { type: Number, default: 0 },
    propsIsSmall: { type: Boolean, default: false },
    propsText: { type: String, default: "" },
  },

  setup(props) {
    const chipSmall = ref(props.propsIsSmall);
    const chipText = ref(props.propsText);

    const chipColor = computed(() => {
      if (props.propsClass === 0) {
        return "grey";
      } else if (props.propsClass === 10) {
        return "success";
      } else if (props.propsClass === 20) {
        return "info";
      } else if (props.propsClass === 50) {
        return "primary";
      } else if (props.propsClass === 60) {
        return "info";
      }
      return "primary";
    });
    const chipClass = computed(() => {
      if (props.propsClass === 50) {
        return 10;
      }
      if (props.propsClass === 60) {
        return 20;
      }
      return props.propsClass;
    });

    return {
      chipSmall,
      chipText,
      chipColor,
      chipClass,
    };
  },
});
</script>
