<template>
  <v-overlay
    v-model="progress"
    :color="overlayColor"
    :opacity="overlayOpacity"
    class="align-center justify-center"
  >
    <v-progress-circular size="50" :color="circularColor" indeterminate />
    <div :class="textColor + ' mt-2'">wait...</div>
  </v-overlay>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "OverlayProgress",

  props: {
    propsIsTransparent: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const overlayColor = computed(() => {
      if (props.propsIsTransparent) {
        return "#212121";
      }
      return "white";
    });
    const overlayOpacity = computed(() => {
      if (props.propsIsTransparent) {
        return 0.46;
      }
      return 1;
    });
    const circularColor = computed(() => {
      if (props.propsIsTransparent) {
        return "white";
      }
      return "primary";
    });
    const textColor = computed(() => {
      if (props.propsIsTransparent) {
        return "white--text";
      }
      return "primary--text";
    });
    return {
      progress: true,
      overlayColor: overlayColor,
      overlayOpacity: overlayOpacity,
      circularColor: circularColor,
      textColor: textColor,
    };
  },
});
</script>
