import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/api";

Vue.use(Vuex);

// 認証情報
const authModule = {
  namespaced: true,
  state: {
    id: "",
    email: "",
    isUser: false,
    isStaff: false,
    staffClass: 0,
    customerId: "",
    brotherId: "",
    isLoggedIn: false,
    isCustomer: false,
    isBrother: false,
    avatarType: 0,
    isAccountChange: false,
  },
  mutations: {
    set(state, payload) {
      const customer = payload.user.customer_reference;
      const brother = payload.user.brother_reference;
      state.id = payload.user.id;
      state.email = payload.user.email;
      state.isUser = payload.user.is_staff === false;
      state.isStaff = payload.user.is_staff === true;
      if (state.isStaff) {
        state.staffClass = payload.user.staff_class;
      }
      if (customer === null || customer === false) {
        state.isCustomer = false;
        state.customerId = "";
        state.avatarType = 0;
        state.isAccountChange = false;
      } else {
        state.isCustomer = true;
        state.customerId = customer.id;
        state.avatarType = customer.avatar_type;
        state.isAccountChange = customer.account_change_setting;
      }
      if (brother === null || brother === false) {
        state.isBrother = false;
        state.brotherId = "";
      } else {
        state.isBrother = true;
        state.brotherId = brother.id;
      }
      state.isLoggedIn = true;
    },
    clear(state) {
      state.id = "";
      state.email = "";
      state.isUser = false;
      state.isStaff = false;
      state.staffClass = 0;
      state.customerId = "";
      state.isLoggedIn = false;
      state.isCustomer = false;
      state.isBrother = false;
      state.avatarType = 0;
      state.isAccountChange = false;
    },
  },
  actions: {
    /**
     * ログイン
     */
    login(context, payload) {
      return api
        .post("auth/jwt/create/", {
          email: payload.email,
          password: payload.password,
        })
        .then((response) => {
          // 認証用トークンをlocalStorageに保存
          localStorage.setItem("access", response.data.access);
          // ユーザー情報を取得してstoreのユーザー情報を更新
          return context.dispatch("renew");
        });
    },
    /**
     * ログアウト
     */
    logout(context) {
      // 認証用トークンをlocalStorageから削除
      localStorage.removeItem("access");
      // storeのユーザー情報をクリア
      context.commit("clear");
    },
    /**
     * ユーザー情報更新
     */
    renew(context) {
      return api.get("/auth/users/me/").then((response) => {
        const user = response.data;
        // storeのユーザー情報を更新
        context.commit("set", { user: user });
      });
    },
  },
};

// サインアップ
const signupModule = {
  namespaced: true,
  state: {
    email: "",
    id: "",
    statusCode: "",
  },
  mutations: {
    set(state, payload) {
      state.email = payload.res.data.email;
      state.id = payload.res.data.id;
      state.statusCode = payload.res.status;
    },
    error(state, payload) {
      state.email = "";
      state.id = "";
      state.statusCode = payload.res.status;
    },
    clear(state) {
      state.email = "";
      state.id = "";
      state.statusCode = "";
    },
  },
  actions: {
    /**
     * サインアップ
     */
    signup(context, payload) {
      return api
        .post("auth/users/", {
          email: payload.email,
          password: payload.password,
          re_password: payload.re_password,
        })
        .then((response) => {
          context.commit("set", { res: response });
        });
    },
    /**
     * アクティベーション
     */
    activation(context, payload) {
      return api
        .post("auth/users/activation/", {
          uid: payload.uid,
          token: payload.token,
        })
        .then((response) => {
          context.commit("set", { res: response });
        });
    },
    /**
     * アクティベーションメール再送信
     */
    resend(context, payload) {
      return api
        .post("auth/users/resend_activation/", {
          email: payload.email,
        })
        .then((response) => {
          // handle success
          context.commit("set", { res: response });
        })
        .catch(function (error) {
          // handle error
          context.commit("error", { res: error.response });
        });
    },
    /**
     * 再設定メール送信
     */
    reset(context, payload) {
      return api
        .post("auth/users/reset_password/", {
          email: payload.email,
        })
        .then((response) => {
          // handle success
          context.commit("set", { res: response });
        })
        .catch(function (error) {
          // handle error
          context.commit("error", { res: error.response });
        });
    },
    /**
     * パスワード再設定
     */
    reset_password(context, payload) {
      return api
        .post("auth/users/reset_password_confirm/", {
          uid: payload.uid,
          token: payload.token,
          new_password: payload.new_password,
          re_new_password: payload.re_new_password,
        })
        .then((response) => {
          context.commit("set", { res: response });
        });
    },
  },
};

// グローバルメッセージ
const messageModule = {
  namespaced: true,
  state: {
    error: "",
    warnings: [],
    info: "",
  },
  mutations: {
    set(state, payload) {
      if (payload.error) {
        state.error = payload.error;
      }
      if (payload.warnings) {
        state.warnings = payload.warnings;
      }
      if (payload.info) {
        state.info = payload.info;
      }
    },
    clear(state) {
      state.error = "";
      state.warnings = [];
      state.info = "";
    },
  },
  actions: {
    /**
     * エラーメッセージ表示
     */
    setErrorMessage(context, payload) {
      context.commit("clear");
      context.commit("set", { error: payload.message });
    },
    /**
     * 警告メッセージ（複数）表示
     */
    setWarningMessages(context, payload) {
      context.commit("clear");
      context.commit("set", { warnings: payload.messages });
    },
    /**
     * インフォメーションメッセージ表示
     */
    setInfoMessage(context, payload) {
      context.commit("clear");
      context.commit("set", { info: payload.message });
    },
    /**
     * 全メッセージ削除
     */
    clearMessages(context) {
      context.commit("clear");
    },
  },
};

const store = new Vuex.Store({
  modules: {
    signup: signupModule,
    auth: authModule,
    message: messageModule,
  },
});

export default store;

export const state = () => ({
  theme: false,
});

export const mutations = {
  theme(state, theme) {
    state.theme = theme;
  },
};

export const actions = {
  theme({ commit }, theme) {
    commit("theme", theme);
  },
};
