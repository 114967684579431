<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container
      :class="
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'px-16 py-10'
      "
    >
      <v-row>
        <v-col cols="12">
          <DivTitle
            :key="'title-' + formKey"
            :propsTitle="listTitle"
            :propsComment="titleComment"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="4">
              <ButtonCommon
                propsLabel="GEN-COIN購入"
                :propsClick="openDialog"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card flat width="100%" class="mygenCard mt-5">
            <v-card-text>
              <DivCoinInfo
                :key="'table-' + formKey"
                :propsCustomerId="customerId"
                :propsIsCustomer="true"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      width="750"
      persistent
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <FormCoinOrder :key="'form-' + formKey" @close="closeDialog" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { createKey } from "@/utils/utilities.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import DivTitle from "@/components/molecules/DivTitle.vue";
import DivCoinInfo from "@/components/organisms/DivCoinInfo.vue";
import FormCoinOrder from "@/views/pages/FormCoinOrder.vue";

export default defineComponent({
  name: "ListCoin",

  components: {
    ButtonCommon,
    DivTitle,
    DivCoinInfo,
    FormCoinOrder,
  },

  setup() {
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });

    const listTitle = ref("GEN-COIN照会");
    const titleComment = ref([
      "ご購入いただいたGEN-COINの情報が確認できます。",
    ]);

    const dialog = ref(false);
    const formKey = ref(createKey());

    const openDialog = () => {
      dialog.value = true;
    };
    const closeDialog = () => {
      dialog.value = false;
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    watch(dialog, (val) => {
      val || closeDialog();
      formKey.value = createKey();
    });

    // スクロール位置調整
    window.scroll(0, 0);

    return {
      customerId,

      listTitle,
      titleComment,
      dialog,
      formKey,

      openDialog,
      closeDialog,
    };
  },
});
</script>
