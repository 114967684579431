import { getDateObject } from "@/utils/utilities.js";

const setting = {
  title: "契約更新",
  apiAddress: "/contractrenewal/",
  listSortBy: ["contract_renewal_date", "contract_renewal_code"],
  listSortDesc: [true, true],
  createType: "renewal",
};

const headers = [
  {
    text: "削除",
    value: "delete",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "公開",
    value: "handleItemRenewal",
    align: "center",
    sortable: false,
    handleType: "contractrenewalrelease",
    handleKey: ["contract_renewal_status"],
  },
  {
    text: "区分",
    value: "contract_renewal_status",
    align: "center",
  },
  {
    text: "更新日",
    value: "contract_renewal_date",
    align: "center",
  },
  {
    text: "契約更新コード",
    value: "contract_renewal_code",
  },
  {
    text: "契約数",
    value: "contract_plan_count",
    type: "number",
    align: "end",
  },
  {
    text: "コメント",
    value: "staff_comment",
  },
];

const searchDateFrom = {
  label: "更新日（以降）",
  column: "contract_renewal_date",
  menu: false,
  default: getDateObject("first", -12),
  formula: "gte", // 以上
};

export default {
  setting,
  headers,
  searchDateFrom,
};
