import { getSelectObject } from "@/utils/selectUtility.js";
import {
  selectedRules,
  requiredRules,
  dateRules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "お知らせ",
  apiAddress: "/information/",
  listSortBy: ["information_date"],
  listSortDesc: [true],
  isCsvImport: true,
};

const forms = [
  {
    column: "information_date",
    label: "日付",
    type: "date",
    menu: false,
    rules: [requiredRules, dateRules],
    cols: 6,
  },
  {
    column: "information_display",
    label: "表示",
    type: "select",
    items: getSelectObject("information_display"),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "information_type",
    label: "区分",
    type: "select",
    items: getSelectObject("information_type"),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "information_title",
    label: "タイトル",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "information_link",
    label: "リンク",
    type: "text",
    // rules: [requiredRules],
    rules: [],
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "日付",
    value: "information_date",
    align: "center",
  },
  {
    text: "表示",
    value: "information_display",
    align: "center",
  },
  {
    text: "区分",
    value: "information_type",
    align: "center",
  },
  {
    text: "タイトル",
    value: "information_title",
    align: "start",
  },
  {
    text: "リンク",
    value: "informationLink",
    align: "start",
    handleKey: ["information_link"],
  },
];

const searchSelect = {
  label: "表示",
  column: "information_display",
  items: [
    { value: -1, text: "ALL" },
    { value: 1, text: "表示" },
    { value: 0, text: "非表示" },
  ],
  value: 1,
};

export default {
  setting,
  forms,
  headers,
  searchSelect,
};
