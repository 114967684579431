<template>
  <div>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col>
          <v-card flat width="1000" rounded="lg" class="mt-5 pa-5">
            <v-expansion-panels v-model="panel" accordion flat hover multiple>
              <v-expansion-panel key="0">
                <v-expansion-panel-header
                  class="font-weight-bold secondary--text text--darken-2"
                >
                  お客様情報
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ContainerCustomerInfo
                    :key="'customer-' + formKey"
                    :propsFormsFirst="formsFirst"
                    :propsFormsSecond="formsSecond"
                  />
                  <ContainerCustomerSetting
                    :key="'customer-setting-' + formKey"
                    :propsCustomerData="customerData"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header
                  class="font-weight-bold secondary--text text--darken-2"
                >
                  申込情報
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ContainerCoinChargeDetail
                    :key="'contract-coincharge-detail-' + formKey"
                    :propsData="orderData"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <ModelForm
              :key="'model-' + formKey"
              :propsFormMode="childMode"
              :propsFormType="formType"
              :propsFormDialog="true"
              :propsEditId="editId"
              @cancel="cancelDialog"
              @close="closeDialog"
            />
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { createKey } from "@/utils/utilities.js";
import {
  settingDictionaryForms,
  setResponseApplyFormsDict,
} from "@/utils/formUtility.js";
import ModelForm from "@/views/model/ModelForm.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import ContainerCustomerInfo from "@/components/molecules/ContainerCustomerInfo.vue";
import ContainerCustomerSetting from "@/components/molecules/ContainerCustomerSetting.vue";
import ContainerCoinChargeDetail from "@/components/molecules/ContainerCoinChargeDetail.vue";
import FormsCustomer from "@/forms/customer.js";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";

export default defineComponent({
  name: "FormCoinHandle",

  components: {
    ModelForm,
    ContainerCustomerInfo,
    ContainerCustomerSetting,
    ContainerCoinChargeDetail,
    OverlayProgress,
  },

  props: {
    propsFormType: {
      type: String,
      required: true,
    },
    propsEditId: {
      type: String,
      default: "XXXXX",
    },
  },

  emits: ["cancel", "close"],

  setup(props, { emit }) {
    const formType = ref(props.propsFormType);
    const editId = ref(props.propsEditId);

    const orderData = ref({});
    const customerData = ref({});
    let formsFirst = reactive(settingDictionaryForms(FormsCustomer.forms));
    let formsSecond = reactive(
      settingDictionaryForms(FormsInvoiceAddress.forms)
    );

    let tempPanel = [0, 1];
    // ローカルストレージのエクスパンションパネルの状態をセット
    if (localStorage.getItem("panelFormCoinHandle")) {
      tempPanel = JSON.parse(localStorage.getItem("panelFormCoinHandle"));
    }
    const panel = ref(tempPanel);
    const progress = ref(false);
    const formKey = ref(createKey());

    const childMode = computed(() => {
      if (formType.value === "coinchargedelete") {
        return "delete";
      }
      return "patch";
    });

    /**
     * 情報取得
     */
    const loadData = () => {
      api({
        method: "get",
        url: "/contractcoin/?id=" + editId.value,
      }).then((response) => {
        const res = response.data[0];
        orderData.value = res;
        customerData.value = res["customer_reference"];
        // 契約者情報セット
        formsFirst = reactive(
          setResponseApplyFormsDict(customerData.value, formsFirst)
        );
        // 請求先情報セット
        formsSecond = reactive(
          setResponseApplyFormsDict(customerData.value, formsSecond)
        );

        formKey.value = createKey();
      });
    };

    // キャンセルボタン
    const cancelDialog = (message) => {
      emit("cancel", message);
    };

    // 閉じるボタン
    const closeDialog = () => {
      emit("close");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    onUpdated(() => {
      const elements = document.getElementsByClassName("v-dialog--active");
      if (elements && elements.length) {
        // スクロール位置をトップに
        elements[0].scrollTop = 0;
      }
    });

    watch(panel, (val) => {
      // エクスパンションパネルの状態をローカルストレージに保存
      localStorage.setItem("panelFormCoinHandle", JSON.stringify(val));
    });

    // データ取得
    loadData();

    return {
      formType,
      editId,
      orderData,
      customerData,

      formsFirst,
      formsSecond,

      panel,
      progress,
      formKey,

      childMode,
      cancelDialog,
      closeDialog,
    };
  },

  updated() {
    const elements = document.getElementsByClassName("v-dialog--active");
    if (!elements || !elements.length) {
      // 要素が取得できなかった場合は終了
      return;
    }
    // スクロール位置をトップに
    elements[0].scrollTop = 0;
  },
});
</script>
