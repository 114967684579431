import { getDateObject } from "@/utils/utilities.js";
import { setPaymentMethod, getSelectObject } from "@/utils/selectUtility.js";
import {
  selectedNotZeroRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "COIN受付",
  originalTitle: "COIN受付登録",
  apiAddress: "/contractcoin/",
  apiListAddress: "/contractcoin/",
  isListReadonly: true,
  listSortBy: ["coin_order_code"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "charge",
};

const forms = [
  {
    column: "coin_order_status",
    label: "契約ステータス",
    type: "radio",
    items: getSelectObject("coin_order_status", "charge"),
    rules: [],
  },
  {
    column: "coin_payment_method",
    label: "コイン支払方法",
    type: "select",
    items: setPaymentMethod(),
    rules: [selectedNotZeroRules],
    cols: 6,
  },
  {
    column: "coin_incentive",
    label: "インセンティブ",
    type: "selectIncentiveFirst",
    items: [],
    rules: [],
    cols: 6,
  },
  {
    column: "reception_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const postFixedValue = [
  {
    column: "reception_date",
    type: "today",
    equalRules: ["coin_order_status", 50],
  },
];

const headers = [
  {
    text: "申込コード",
    value: "coin_order_code",
    align: "start",
  },
  {
    text: "受付",
    // value: "handleItem",
    value: "coinchargeLink",
    align: "center",
    sortable: false,
    handleType: "coincharge",
    handleKey: ["contract_coin_is_invoice", "contract_coin_is_contract_plan"],
  },
  {
    text: "取消",
    value: "coinchargeDeleteLink",
    align: "center",
    sortable: false,
    handleType: "coinchargedelete",
    handleKey: ["contract_coin_is_invoice", "contract_coin_is_contract_plan"],
  },
  {
    text: "ステータス",
    value: "coin_order_status",
    align: "center",
  },
  {
    text: "申込日",
    value: "coin_order_date",
    align: "center",
  },
  {
    text: "契約申込コード",
    value: "contract_coin_contract_code",
    align: "start",
  },
  {
    text: "お客様コード",
    value: "customer_reference___customer_code",
    align: "start",
  },
  {
    text: "お客様名",
    value: "customer_reference___customer_name",
    align: "start",
  },
  {
    text: "枚数",
    value: "coin_quantity",
    align: "right",
    type: "number",
  },
  {
    text: "金額",
    value: "coin_charge",
    align: "right",
    type: "number",
  },
  // {
  //   text: "受付日",
  //   value: "reception_date",
  //   align: "center",
  // },
  {
    text: "内容",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchDateFrom = {
  label: "申込日（以降）",
  column: "coin_order_date",
  menu: false,
  default: getDateObject("first", -1),
  formula: "gte", // 以上
};

const searchSelect = {
  label: "ステータス",
  column: "coin_order_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "未対応" },
    { value: 500, text: "未公開" }, // 500はfilterで設定
    { value: 50, text: "確定" },
  ],
  filter: {
    500: {
      value: 50,
      formula: "lt", // 未満
      // formula: "lte", // 以下
    },
  },
  value: 500,
};

const tooltipText = [
  "GEN-COIN購入申込の対応が行えます",
  "",
  "■受付",
  "受付対応が行えます。",
  "アイコンが表示されていないオーダーはプラン契約の申込に含まれるため、契約受付と連動しています。",
  "契約受付と連動しているオーダーは、契約が確定されるとGEN-COINの購入も確定されます。",
  "（支払方法やインセンティブも契約設定と連動します）",
  "",
  "※対応フローは請求書が発行されるとロックされます。",
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
  searchDateFrom,
  searchSelect,
  tooltipText,
};
