const setting = {
  title: "お問い合わせ",
  apiAddress: "/support/",
  isListReadonly: true,
  listSortBy: ["support_date", "support_code"],
  listSortDesc: [true, false],
  showExpand: true,
  expandType: "supporthandle",
};

const headers = [
  {
    text: "日時",
    value: "support_date",
    align: "start",
  },
  {
    text: "お問い合わせコード",
    value: "support_code",
    align: "start",
  },
  {
    text: "ステータス",
    value: "support_status",
    align: "center",
  },
  {
    text: "希望対応",
    value: "support_type",
    align: "center",
  },
  {
    text: "お問い合わせ内容",
    value: "support_comment",
  },
  {
    text: "リンク",
    value: "supportLink",
    align: "center",
    sortable: false,
    handleKey: ["support_link"],
  },
  {
    text: "対応",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  headers,
};
