<template>
  <footer class="print-footer">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="4">
          <ButtonCommon
            id="button-print"
            propsLabel="印刷"
            :propsClick="clickPrint"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <ButtonCommon
            id="button-close"
            propsColor="mygenIcon"
            propsLabel="閉じる"
            :propsClick="clickClose"
          />
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </footer>
</template>

<script>
import { defineComponent } from "vue";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";

export default defineComponent({
  name: "ReportFooter",

  components: { ButtonCommon },

  setup() {
    const clickClose = () => {
      window.close();
    };

    const clickPrint = () => {
      window.print();
    };

    return {
      clickClose,
      clickPrint,
    };
  },
});
</script>
