<template>
  <v-app id="reportInvoice">
    <body class="report-body">
      <div
        v-for="(pageRow, key) in detailPage"
        :id="'report-page-' + key"
        :key="'report-page-' + key"
        class="report-page"
      >
        <header :id="'header_' + key">
          <div class="report-title-section-payment">
            <div class="report-title-payment">支払通知書</div>
          </div>
          <div class="report-receiver-section-payment">
            <div class="report-receiver-name-payment">
              {{ headerData.payment_customer_name }}&nbsp;&nbsp;
              {{ headerData.payment_person_in_charge }}&nbsp;様
            </div>
            <div class="report-receiver-address-payment">
              <div>
                <span v-if="headerZipCode !== ''">{{ headerZipCode }}</span>
                {{ headerPrefecture }}
                {{ headerData.payment_address_1 }}
              </div>
              <div v-if="headerData.payment_address_2 !== ''">
                {{ headerData.payment_address_2 }}
              </div>
            </div>
          </div>
          <div class="report-registration-number-section-payment">
            <div class="report-registration-number-payment">
              登録番号 : {{ headerData.payment_registration_number }}
            </div>
          </div>

          <div v-if="key == 1">
            <div class="report-total-section-payment">
              <div class="report-total-label-payment">今回支払金額（税込）</div>
              <div class="report-total-price-payment">
                ¥{{ headerData.payment_charge_including_tax }} -
              </div>
            </div>
            <div class="report-subtotal-section-payment">
              <div class="report-subtotal-label-payment">
                合計金額（10%対象）
              </div>
              <div class="report-subtotal-price-payment">
                ¥{{ headerData.payment_subject_charge }} -
              </div>
            </div>
            <div class="report-tax-section-payment">
              <div class="report-tax-label-payment">消費税額（10%対象）</div>
              <div class="report-tax-price-payment">
                ¥{{ headerData.payment_charge_tax }} -
              </div>
            </div>
            <div class="report-message-section-payment">
              <div class="report-message-payment">
                <div>上記のとおりお支払い申し上げます。</div>
                <div>
                  （送付後一定期間内に連絡がない場合、確認があったものといたします。）
                </div>
                <div>今後ともご紹介も含めて、よろしくお願い致します。</div>
              </div>
            </div>

            <div class="report-condition-section-payment">
              <div class="report-condition-label-payment">
                <div>お客様のご指定の口座にお振込みいたします。</div>
              </div>
              <div class="report-condition-value-payment">
                <div>振込予定日：{{ headerData.payment_date }}</div>
              </div>
            </div>
          </div>
        </header>

        <section
          :id="'detail_' + key"
          :class="
            key == 1
              ? 'report-detail-section-payment'
              : 'report-detail-multiple-section-payment'
          "
        >
          <table :id="'table_' + key" class="report-detail-payment">
            <thead id="thead">
              <tr>
                <th class="report-detail-name-payment">コード</th>
                <th class="report-detail-remarks-payment">お客様名</th>
                <th class="report-detail-type-payment">月度</th>
                <th class="report-detail-charge-payment">金額</th>
              </tr>
              <tr>
                <td colspan="7" class="report-detail-top"></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in pageRow" :key="'tr-' + index">
                <td class="report-detail-name-payment">
                  {{ row["payment_detail_code"] }}
                </td>
                <td class="report-detail-remarks-payment">
                  {{ row["payment_detail_name"] }}
                </td>
                <td class="report-detail-type-payment">
                  {{ headerData.invoice_date }}
                </td>
                <td class="report-detail-charge-payment">
                  {{ convertNumComma(row["payment_detail_charge_sum"]) }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <div class="report-detail-bottom-section-payment">
          <div class="report-detail-bottom" />
        </div>

        <div v-if="key == 1">
          <section id="report-address" class="report-address-section-payment">
            <div class="report-sender">
              <div class="report-sender">GEN株式会社</div>
              <div class="report-sender">
                〒150-0036 東京都渋谷区南平台町7-9
              </div>
              <div class="report-sender">DENFLAT南平台2F</div>
              <div class="report-sender">TEL : 03-6416-1450</div>
              <div class="report-sender">https://www.gen-square.com</div>
            </div>
          </section>
          <div class="report-sender-stamp-section-payment">
            <img class="report-sender-stamp" src="@/assets/stamp.png" />
          </div>
        </div>

        <div class="report-timestamp-section-payment">
          <div class="report-pagearea-payment">表示日：{{ dateTimeLabel }}</div>
        </div>
        <div class="report-slipnumber-section-payment">
          <div class="report-pagearea-payment">
            支払コード：{{ headerData.payment_group_code }}
          </div>
        </div>
        <div class="report-pagenumber-section-payment">
          <div class="report-pagearea-payment">
            page {{ key }} / {{ detailPageCount }}
          </div>
        </div>

        <div class="report-logo-section-payment">
          <img
            class="report-logo-image"
            src="@/assets/images/mygenLogoPaper.svg"
          />
        </div>
      </div>
      <ReportFooter />
    </body>
  </v-app>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router/composables";
import { convertNumComma, getToday } from "@/utils/utilities.js";
import ReportFooter from "@/components/molecules/ReportFooter.vue";

export default defineComponent({
  name: "ReportPayment",

  components: { ReportFooter },

  setup() {
    const route = useRoute();
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const isBrother = computed(() => {
      return store.state.auth.isBrother === true;
    });
    const paymentHeaderId = computed(() => {
      return route.params.id;
    });
    const dateTimeLabel = computed(() => {
      return getToday("jp-datetime");
    });

    const headerData = ref({});
    const headerZipCode = computed(() => {
      if (
        "payment_zip_code" in headerData.value &&
        headerData.value.payment_zip_code !== "0000000"
      ) {
        return (
          "〒" +
          headerData.value.payment_zip_code.substr(0, 3) +
          "-" +
          headerData.value.payment_zip_code.substr(3)
        );
      } else {
        return "";
      }
    });
    const headerPrefecture = computed(() => {
      if (
        "payment_prefecture" in headerData.value &&
        headerData.value.payment_prefecture === "その他"
      ) {
        return "";
      } else {
        return headerData.value.payment_prefecture;
      }
    });
    const detailPage = ref({});
    const detailPageCount = ref(1);

    const setReportData = (res) => {
      const brotherRef = res["brother_reference"];
      const paymentGroupRef = res["payment_group_reference"];
      const detailList = res.payment_detail_list;
      const paymentDateArray = paymentGroupRef["payment_date"].split("-");
      const invoiceDateArray =
        paymentGroupRef["invoice_group_reference"]["invoice_date"].split("-");

      headerData.value = {
        payment_type: res["payment_type"],
        payment_code: res["payment_code"],
        payment_customer_code: brotherRef["brother_code"],
        payment_customer_name: brotherRef["brother_name"],
        payment_person_in_charge: brotherRef["person_in_charge"],
        payment_zip_code: brotherRef["zip_code"],
        payment_prefecture: brotherRef["prefecture"],
        payment_address_1: brotherRef["address_1"],
        payment_address_2: brotherRef["address_2"],
        payment_registration_number: brotherRef["registration_number"],
        payment_charge: convertNumComma(res["payment_charge"]),
        payment_charge_including_tax: convertNumComma(
          res["payment_charge_including_tax"]
        ),
        payment_subject_charge: convertNumComma(res["payment_subject_charge"]),
        payment_charge_tax: convertNumComma(res["payment_charge_tax"]),
        payment_date:
          paymentDateArray[0] + "年" + paymentDateArray[1] + "月 末日",
        invoice_date: invoiceDateArray[0] + "年" + invoiceDateArray[1] + "月",
        payment_group_code: paymentGroupRef["payment_group_code"],
      };

      const detailFirstCount = 10; // 1ページ目は明細9行
      const detailSecondCount = 20; // 2ページ目以降は明細20行
      let lineCount = 0;
      let isFirst = true;
      let tempPageCount = 1;
      const tempDetailPage = {};
      // detailList は配列（辞書型ではない）なので for of でループ処理
      for (const line of detailList) {
        lineCount++;
        if (isFirst && lineCount % detailFirstCount === 0) {
          // 1ページ目の明細行数
          isFirst = false;
          tempPageCount++;
        } else if (
          !isFirst &&
          (lineCount - detailFirstCount) % detailSecondCount === 0
        ) {
          // 2ページ以降の明細行数
          tempPageCount++;
        }
        if (tempDetailPage[tempPageCount]) {
          tempDetailPage[tempPageCount].push(line);
        } else {
          tempDetailPage[tempPageCount] = [line];
        }
      }
      detailPage.value = tempDetailPage;
      detailPageCount.value = tempPageCount;
    };

    /**
     * api:データ取得
     */
    const loadData = () => {
      let address = "/paymentreport/" + paymentHeaderId.value + "/";
      if (isStaff.value === false && isBrother.value === false) {
        address += "?customer=" + customerId.value;
      }

      api({
        method: "get",
        url: address,
      }).then((response) => {
        const res = response.data;
        setReportData(res);
      });
    };

    // データ取得
    loadData();

    return {
      convertNumComma,
      customerId,
      isStaff,
      isBrother,
      paymentHeaderId,
      dateTimeLabel,

      headerData,
      headerZipCode,
      headerPrefecture,
      detailPage,
      detailPageCount,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/report.scss";
</style>
