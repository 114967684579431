<template>
  <div>
    <v-card-title class="text-body-1 grey--text text--darken-1">
      {{ cardTitle }}
    </v-card-title>

    <v-card-text @input="submitForm">
      <v-form ref="formAuth" @submit.prevent>
        <v-container v-if="stepCount === 1">
          <v-row>
            <v-col
              cols="12"
              v-for="(msg, key) in firstMessage"
              :key="'first-' + key"
            >
              <p>{{ msg }}</p>
            </v-col>
            <v-col
              v-for="(form, key) in forms"
              :key="key"
              cols="12"
              class="pt-0"
            >
              <FormControls propsMode="post" :propsForm="form" />
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="stepCount === 2">
          <v-row>
            <v-col
              cols="12"
              v-for="(msg, key) in secondMessage"
              :key="'second-' + key"
            >
              <p>{{ msg }}</p>
            </v-col>
            <v-col
              v-for="(obj, key) in confirmObject"
              :key="'confirm-' + key"
              cols="12"
              class="pt-0"
            >
              <div class="text-caption">{{ obj.label }}</div>
              <div class="mb-5">
                <h3 class="font-weight-bold">{{ obj.value }}</h3>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="stepCount === 3">
          <v-row>
            <v-col cols="12">
              <p v-for="(msg, key) in thirdMessage" :key="'third-' + key">
                {{ msg }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-container class="mt-5">
        <v-row>
          <v-col cols="12" v-if="stepCount === 1">
            <ButtonCommon
              :key="'submit-' + formKey"
              :propsLabel="buttonLabelSubmit"
              :propsClick="submitButton"
              :propsDisabled="buttonDisabled"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="stepCount === 2">
            <ButtonCommon
              :key="'second-' + formKey"
              :propsLabel="buttonLabelSecond"
              :propsClick="submitButton"
              :propsDisabled="buttonDisabled"
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="stepCount === 2">
            <ButtonCommon
              :key="'cancel-' + formKey"
              :propsLabel="buttonLabelCancel"
              :propsOutlined="true"
              :propsClick="backStep"
              :propsDisabled="buttonDisabled"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <OverlayProgress v-if="progress" />
  </div>
</template>

<script>
import store from "@/store";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { checkMatch, createKey } from "@/utils/utilities.js";
import {
  getFormAuthObject,
  settingDictionaryForms,
} from "@/utils/formUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";

export default defineComponent({
  name: "ModelAuth",

  components: {
    FormControls,
    ButtonCommon,
    OverlayProgress,
  },

  props: {
    propsFormType: {
      type: String,
      required: true,
    },
    propsIsUidToken: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const formType = ref(props.propsFormType);
    const isUidToken = ref(props.propsIsUidToken);
    const uid = computed(() => {
      if ("params" in route && route.params.uid) {
        return route.params.uid;
      }
      return "";
    });
    const token = computed(() => {
      if ("params" in route && route.params.token) {
        return route.params.token;
      }
      return "";
    });

    const formObject = getFormAuthObject(formType.value);
    const forms = reactive(settingDictionaryForms(formObject.forms));
    for (const key in forms) {
      forms[key].value = "";
      forms[key].menu = false;
      if (forms[key].type === "password") {
        forms[key].append = false;
      }
    }
    const confirmObject = computed(() => {
      const confirms = {};
      for (const key in forms) {
        if (forms[key].type !== "password") {
          confirms[key] = forms[key];
        }
      }
      return confirms;
    });
    const formAuth = ref(false);

    const cardTitle = ref(formObject.setting.title);
    const buttonLabelSubmit = ref(formObject.setting.buttonLabelSubmit);
    const buttonLabelCancel = ref(formObject.setting.buttonLabelCancel);
    const buttonLabelSecond = ref(formObject.setting.buttonLabelSecond);
    const firstMessage = ref(formObject.setting.firstMessage);
    const secondMessage = ref(formObject.setting.secondMessage);
    const thirdMessage = ref(formObject.setting.thirdMessage);
    const stepCount = ref(1);
    const buttonDisabled = ref(false);
    const firstValidate = ref(false);

    const progress = ref(false);
    const formKey = ref(createKey());

    // ボタン制御
    const lockButton = (bool = false) => {
      buttonDisabled.value = bool;
      progress.value = bool;
      // createKeyにて再レンダリング
      formKey.value = createKey();
    };

    // 戻る処理
    const backStep = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };

    // emit処理
    const submitForm = () => {
      store.dispatch("message/clearMessages");
      const data = {};
      for (const key in forms) {
        data[forms[key].emit] = forms[key].value;
      }
      emit("update", data);
    };

    // 確定処理
    const submitButton = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formAuth.value.validate();
      if (validResult) {
        // アカウント作成用
        if (formObject.setting.isSignup && stepCount.value === 1) {
          if (checkMatch(forms, "password", "rePassword")) {
            stepCount.value++;
            firstValidate.value = true;
            return;
          } else {
            return;
          }
        }
        // パスワードリセット用
        if (formObject.setting.isResetPassword) {
          if (!checkMatch(forms, "newPassword", "reNewPassword")) {
            return;
          }
        }

        lockButton(true);
        const data = {};
        for (const key in forms) {
          data[forms[key].column] = forms[key].value;
        }
        // アクティベーション＆パスワードリセット用
        if (isUidToken.value) {
          data["uid"] = uid.value;
          data["token"] = token.value;
        }

        store
          .dispatch(formObject.setting.storeAddress, data)
          .then(() => {
            // ログイン処理
            if (formObject.setting.isLogin) {
              store.dispatch("message/setInfoMessage", {
                message: formObject.setting.successMessage,
              });
              // クエリ文字列に「next」がなければ、ホーム画面へ
              const next = route.query.next || "/";
              router.replace(next);
            } else if (store.state.signup.statusCode === 201) {
              stepCount.value++;
              store.dispatch("message/setInfoMessage", {
                message: formObject.setting.successMessage,
              });
              // ステップ
              if (formObject.setting.isStepThird) {
                stepCount.value = 3;
              }
            } else if (store.state.signup.statusCode === 204) {
              store.dispatch("message/setInfoMessage", {
                message: formObject.setting.successMessage,
              });
              lockButton(false);
              // ステップ
              if (formObject.setting.isStepThird) {
                stepCount.value = 3;
              }
            } else {
              store.dispatch("message/setErrorMessage", {
                message: formObject.setting.errorMessage,
              });
              lockButton(false);
            }
          })
          .catch(() => {
            lockButton(false);
          });
      }
    };

    // 自動アクティベーション
    const autoActivation = () => {
      const data = {};
      // アクティベーション用uid&token取得
      if (isUidToken.value) {
        data["uid"] = uid.value;
        data["token"] = token.value;
      } else {
        return false;
      }
      store
        .dispatch(formObject.setting.storeAddress, data)
        .then(() => {
          if (store.state.signup.statusCode === 204) {
            store.dispatch("message/setInfoMessage", {
              message: formObject.setting.successMessage,
            });
            stepCount.value = 3;
          }
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            store.dispatch("message/setErrorMessage", {
              message: formObject.setting.error204Message,
            });
            stepCount.value = 3;
            thirdMessage.value = formObject.setting.third204Message;
          } else if (error.response.status === 400) {
            store.dispatch("message/setErrorMessage", {
              message: formObject.setting.error400Message,
            });
            stepCount.value = 3;
            thirdMessage.value = formObject.setting.third400Message;
          }
          return error.response;
        });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
      // 自動アクティベーション処理
      if (formType.value === "activation") {
        autoActivation();
      }
    });

    watch(stepCount, () => {
      lockButton(false);
    });

    lockButton(false);

    return {
      cardTitle,
      stepCount,
      buttonLabelSubmit,
      buttonLabelCancel,
      buttonLabelSecond,
      buttonDisabled,
      firstMessage,
      secondMessage,
      thirdMessage,
      firstValidate,
      progress,
      isUidToken,
      uid,
      token,
      formType,
      formObject,
      confirmObject,
      forms,
      formAuth,
      formKey,
      backStep,
      submitForm,
      submitButton,
    };
  },
});
</script>
