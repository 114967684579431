<template>
  <v-container>
    <v-row class="mb-5">
      <v-col
        cols="12"
        :md="row['cols'] ? row['cols'] : 6"
        v-for="(row, index) in orderData"
        :key="'col-setting-' + index"
        class="py-1"
      >
        <v-card
          v-if="row['title'] !== '' || row['value'] !== ''"
          class="mx-auto"
          width="100%"
          outlined
          style="overflow-wrap: anywhere !important"
        >
          <v-card-text class="pa-3">
            <div class="text-caption">{{ row["title"] }}</div>
            <div class="text-body-1 mygenText--text">
              {{ row["value"] }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { convertNumComma } from "@/utils/utilities.js";

export default defineComponent({
  name: "ContainerCoinChargeDetail",

  props: {
    propsData: { type: Object, required: true },
  },

  setup(props) {
    const tableData = reactive(props.propsData);
    const orderData = ref([]);

    const setTableData = (res) => {
      // 設定情報
      const dummyText = "-----";
      const coinOrderCode = res["coin_order_code"] || dummyText;
      const coinOrderDate = res["coin_order_date"] || dummyText;
      const contractCoinContractCode =
        res["contract_coin_contract_code"] || dummyText;
      let coinQuantity = dummyText;
      if (res["coin_quantity"]) {
        coinQuantity = convertNumComma(res["coin_quantity"]) + "枚";
      }
      let coinRate = dummyText;
      if (res["coin_rate"]) {
        coinRate = convertNumComma(res["coin_rate"]) + "円";
      }
      let coinCharge = dummyText;
      if (res["coin_charge"]) {
        coinCharge = convertNumComma(res["coin_charge"]) + "円";
      }
      let taxRate = dummyText;
      if (res["tax_rate"]) {
        taxRate = res["tax_rate"] + "%";
      }
      let coinChargeIncludingTax = dummyText;
      if (res["coin_charge_including_tax"]) {
        coinChargeIncludingTax =
          convertNumComma(res["coin_charge_including_tax"]) + "円";
      }
      const coinOrderComment = res["coin_order_comment"] || dummyText;

      orderData.value = [
        { title: "申込コード", value: coinOrderCode, cols: 4 },
        { title: "申込日", value: coinOrderDate, cols: 4 },
        { title: "契約申込コード", value: contractCoinContractCode, cols: 4 },
        // { title: "", value: "", cols: 4 },
        { title: "コイン枚数", value: coinQuantity, cols: 4 },
        { title: "レート", value: coinRate, cols: 4 },
        { title: "税抜金額", value: coinCharge, cols: 4 },
        { title: "消費税率", value: taxRate, cols: 4 },
        { title: "税込金額", value: coinChargeIncludingTax, cols: 4 },
        { title: "備考", value: coinOrderComment, cols: 12 },
      ];
    };

    setTableData(tableData);

    return {
      orderData,
    };
  },
});
</script>
