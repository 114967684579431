const setting = {
  title: "請求明細",
  apiAddress: "/invoicereport/",
  isListReadonly: true,
  listArray: "invoice_detail_list",
  listSortBy: ["line"],
  listSortDesc: [true],
};

const forms = [];

const headers = [
  {
    text: "コード",
    value: "invoice_detail_code",
    handleKey: ["line"],
  },
  {
    text: "品名",
    value: "invoice_detail_name",
  },
  {
    text: "区分",
    value: "use_date_label",
  },
  {
    text: "単価",
    value: "invoice_detail_price",
    type: "number",
    align: "end",
  },
  {
    text: "数量",
    value: "invoice_detail_quantity",
    type: "number",
    align: "end",
  },
  {
    text: "金額",
    value: "invoice_detail_amount",
    type: "number",
    align: "end",
  },
  {
    text: "税率",
    value: "tax_label",
    align: "end",
  },
];

export default {
  setting,
  forms,
  headers,
};
