<template>
  <v-data-table
    :headers="formObject.headers"
    :items="tableItems"
    :item-key="keyColumn"
    multi-sort
    :loading="loading"
    loading-text="Loading... Please wait"
    dense
    :no-data-text="dataTableNoDataText"
    :footer-props="dataTableFooterProps"
    :sort-by="tableSortBy"
    @update:sort-by="updateSortBy"
    :sort-desc="tableSortDesc"
    @update:sort-desc="updateSortDesc"
    :items-per-page="tableItemsPerPage"
    @update:items-per-page="updateItemsPerPage"
    class="mygen-table mygenText--text"
  >
    <template
      v-for="rowChip in getSelectName"
      v-slot:[`item.${rowChip}`]="{ item }"
    >
      <ChipStatus
        :key="'model_detail_chip-' + rowChip"
        :propsType="rowChip"
        :propsValue="item[rowChip]"
      />
    </template>
  </v-data-table>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import store from "@/store";
import api from "@/services/api";
import { getFormObject, setApiToListData } from "@/utils/formUtility.js";
import {
  dataTableNoDataText,
  dataTableFooterProps,
} from "@/utils/listUtility.js";
import { getSelectName } from "@/utils/selectUtility.js";
import ChipStatus from "@/components/atoms/ChipStatus.vue";

export default defineComponent({
  name: "ModelDetail",

  components: {
    ChipStatus,
  },

  props: {
    propsFormType: { type: String, required: true },
    propsFilterColumn: { type: String, required: true },
    propsFilterId: { type: String, required: true },
    propsIsSpecifyId: { type: Boolean, default: false },
    propsKeyColumn: { type: String, default: "" },
  },

  setup(props) {
    const formType = ref(props.propsFormType);
    const filterColumn = ref(props.propsFilterColumn);
    const filterId = ref(props.propsFilterId);
    const isSpecifyId = ref(props.propsIsSpecifyId);

    const formObject = getFormObject(formType.value);
    const tableItems = ref([]);
    const tableSortBy = ref(formObject.setting.listSortBy || []);
    const tableSortDesc = ref(formObject.setting.listSortDesc || []);
    const tableItemsPerPage = ref(10);
    // ローカルストレージの情報をセット
    if (localStorage.getItem("modelDetailOptions")) {
      let tempOptions = JSON.parse(localStorage.getItem("modelDetailOptions"));
      if (!tempOptions) {
        tempOptions = {};
      }
      if ("sortBy" in tempOptions && formType.value in tempOptions["sortBy"]) {
        tableSortBy.value = tempOptions["sortBy"][formType.value];
      }
      if (
        "sortDesc" in tempOptions &&
        formType.value in tempOptions["sortDesc"]
      ) {
        tableSortDesc.value = tempOptions["sortDesc"][formType.value];
      }
      if (
        "itemsPerPage" in tempOptions &&
        formType.value in tempOptions["itemsPerPage"]
      ) {
        tableItemsPerPage.value = tempOptions["itemsPerPage"][formType.value];
      }
    }

    const itemsKey = ref([]);
    const expanded = ref([]);
    const loading = ref(false);

    const keyColumn = computed(() => {
      return props.propsKeyColumn !== "" ? props.propsKeyColumn : "id";
    });

    const updateOptions = (type, value) => {
      // オプションをローカルストレージに保存
      if (value) {
        let tempOptions = JSON.parse(
          localStorage.getItem("modelDetailOptions")
        );
        if (!tempOptions) {
          tempOptions = {};
        }
        if (type in tempOptions === false) {
          tempOptions[type] = {};
        }
        tempOptions[type][formType.value] = value;
        localStorage.setItem("modelDetailOptions", JSON.stringify(tempOptions));
      }
    };
    const updateSortBy = (value) => {
      updateOptions("sortBy", value);
    };
    const updateSortDesc = (value) => {
      updateOptions("sortDesc", value);
    };
    const updateItemsPerPage = (value) => {
      updateOptions("itemsPerPage", value);
    };

    const dataDisplay = (res) => {
      let listData = [];
      let listKey = [];

      // apiで取得したデータをdataにセットする
      if (res) {
        let resData;
        if (
          isSpecifyId.value &&
          formObject.setting.listArray &&
          formObject.setting.listArray in res
        ) {
          resData = res[formObject.setting.listArray];
        } else {
          resData = res;
        }
        const itemData = setApiToListData(resData, formObject.headers);
        // リストセット
        listData = itemData["listData"];
        listKey = itemData["listKey"];
      }

      // リストセット
      tableItems.value = listData;
      itemsKey.value = listKey;
    };

    const loadData = () => {
      loading.value = true;

      // データ取得アドレス生成
      let address = formObject.setting.apiAddress;
      if (formObject.setting.apiListAddress !== undefined) {
        address = formObject.setting.apiListAddress;
      }
      let param = "";
      // ユーザー指定
      if (!filterId.value) {
        dataDisplay([]);
        loading.value = false;
        return;
      } else if (isSpecifyId.value) {
        param = filterId.value + "/";
      } else {
        param = "?" + filterColumn.value + "=" + filterId.value;
      }
      // 検索条件追加
      if (formObject.setting.apiListParam) {
        param += "&" + formObject.setting.apiListParam;
      }
      address += param;
      // データ取得
      api({
        method: "get",
        url: address,
      }).then((response) => {
        const res = response.data;
        dataDisplay(res);
        loading.value = false;
      });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      getSelectName,
      formType,
      filterColumn,
      filterId,
      isSpecifyId,

      dataTableNoDataText,
      dataTableFooterProps,

      formObject,
      tableItems,
      tableSortBy,
      tableSortDesc,
      tableItemsPerPage,

      itemsKey,
      expanded,
      loading,
      keyColumn,

      updateSortBy,
      updateSortDesc,
      updateItemsPerPage,
    };
  },
});
</script>
