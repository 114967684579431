<template>
  <v-chip v-if="isOutline" x-small color="mygenAccent" label>
    <v-icon small left> mdi-shield-lock-outline </v-icon>
    <span class="font-weight-bold">{{ chipTitle }}</span>
  </v-chip>
  <v-chip v-else small color="mygenAccent" label text-color="white">
    <v-icon small left> mdi-shield-lock </v-icon>
    <span class="font-weight-bold">{{ chipTitle }}</span>
  </v-chip>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ChipStaff",

  props: {
    propsIsBrother: { type: Boolean, default: false },
    propsIsOutline: { type: Boolean, default: false },
  },

  setup(props) {
    const isOutline = ref(props.propsIsOutline);

    const chipTitle = computed(() => {
      if (props.propsIsBrother) {
        return "BROTHER ONLY";
      }
      return "STAFF ONLY";
    });

    return {
      isOutline,
      chipTitle,
    };
  },
});
</script>
