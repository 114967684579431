const setting = {
  title: "申込履歴",
  apiAddress: "/contractplanreadonly/",
  isListReadonly: true,
  listSortBy: ["contract_code"],
  listSortDesc: [false],
  showExpand: true,
  expandType: "contracthistory",
};

const headers = [
  {
    text: "申込コード",
    value: "order_code",
    align: "start",
  },
  {
    text: "種別",
    value: "contract_flow_status",
    align: "center",
  },
  {
    text: "状態",
    value: "final_order_status",
    align: "center",
  },
  {
    text: "契約コード",
    value: "contract_code",
    align: "center",
  },
  {
    text: "プラン",
    value: "plan_name",
  },
  {
    text: "サイト名称",
    value: "contract___site_name",
  },
  {
    text: "申込日",
    value: "order_date",
    align: "center",
  },
  {
    text: "更新",
    value: "contractRenewalSingle",
    align: "center",
    handleKey: [
      "contract___contract_class",
      "order_status",
      "availability_revision",
      "contract_revision",
      "renewal_revision",
    ],
  },
  {
    text: "内容",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  headers,
};
