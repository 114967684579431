<template>
  <v-container>
    <v-row class="mb-3">
      <v-col
        v-for="(row, index) in customerData"
        :key="'col-customer-' + index"
        cols="12"
        :md="row['cols'] ? row['cols'] : 6"
        class="py-1"
      >
        <v-card
          class="mx-auto"
          width="100%"
          outlined
          style="overflow-wrap: anywhere !important"
        >
          <v-card-text class="pa-3">
            <div class="text-caption">{{ row["title"] }}</div>
            <v-tooltip v-if="row['isCode']" top>
              <template v-slot:activator="{ on, attrs }">
                <a
                  @click="clickCustomerCode(row['value'])"
                  v-bind="attrs"
                  v-on="on"
                  class="text-body-1 primary--text font-weight-bold text-decoration-none"
                >
                  {{ row["value"] }}
                </a>
              </template>
              <span>{{ tooltipCodeLink }}</span>
            </v-tooltip>
            <div v-else class="text-body-1 mygenText--text">
              {{ row["value"] }}
              <v-tooltip v-if="row['isAccount']" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copyText(() => {}, row['value'])"
                  >
                    <v-icon color="primary" small> mdi-content-copy </v-icon>
                  </v-btn>
                </template>
                <span>{{ tooltipAccountCopy }}</span>
              </v-tooltip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col>
        <v-card outlined>
          <v-card-text class="pa-3">
            <div class="mb-3">
              <span class="text-caption mr-1">{{ title }}</span>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copyText(() => {}, item.support_comment)"
                  >
                    <v-icon color="primary" small> mdi-content-copy </v-icon>
                  </v-btn>
                </template>
                <span>{{ tooltipCommentCopy }}</span>
              </v-tooltip>
              <v-tooltip v-if="isLink" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :href="item.support_link"
                    target="_blank"
                  >
                    <v-icon color="primary" small> mdi-launch </v-icon>
                  </v-btn>
                </template>
                <span>{{ tooltipLink }}</span>
              </v-tooltip>
            </div>
            <div class="text-body-1 mygenText--text">
              {{ item.support_comment }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(row, index) in handleData"
        :key="'col-handle-' + index"
        cols="12"
        class="py-1"
      >
        <v-card
          class="mx-auto"
          width="100%"
          outlined
          style="overflow-wrap: anywhere !important"
        >
          <v-card-text class="pa-3">
            <div class="text-caption">{{ row["title"] }}</div>
            <div class="text-body-2 my-2 mygenText--text font-weight-bold">
              {{ row["date"] }}
            </div>
            <div class="text-body-1 mygenText--text">
              {{ row["comment"] }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import { useRouter } from "vue-router/composables";
import { computed, defineComponent, reactive, ref } from "vue";
import { createKey, copyToClipboard } from "@/utils/utilities.js";

export default defineComponent({
  name: "CardSupport",

  props: {
    propsItem: { type: Object },
  },

  components: {},

  setup(props) {
    const router = useRouter();

    const title = "お問い合わせ内容";
    const tooltipAccountCopy = "アカウントをクリップボードにコピー";
    const tooltipCommentCopy = "お問い合わせ内容をクリップボードにコピー";
    const tooltipCodeLink = "お客様リストに移動";
    const tooltipLink = "情報リンクのサイトに移動";
    const item = reactive(props.propsItem);
    const formKey = ref(createKey());

    const isLink = computed(() => {
      return (
        item.support_link !== "" &&
        item.support_link !== null &&
        item.support_link !== undefined
      );
    });

    const customerData = ref([
      {
        title: "お客様コード",
        value: item.customer_reference___customer_code,
        isCode: true,
        cols: 6,
      },
      {
        title: "お客様名",
        value: item.customer_reference___customer_name,
        cols: 6,
      },
      {
        title: "アカウント",
        value: item.customer_reference___account,
        isAccount: true,
        cols: 6,
      },
      { title: "電話番号", value: item.customer_reference___tel, cols: 6 },
    ]);

    const getDateAndStaff = (date, staff) => {
      let res = "";
      if (date !== "" && date !== null) {
        res += date;
      }
      if (staff !== "" && staff !== null) {
        if (res !== "") res += "：";
        res += staff;
      }
      return res;
    };

    const handleData = ref([
      {
        title: "対応1",
        date: getDateAndStaff(
          item.support_handle_first_date,
          item.support_handle_first_staff
        ),
        comment: item.support_handle_first_comment,
      },
      {
        title: "対応2",
        date: getDateAndStaff(
          item.support_handle_second_date,
          item.support_handle_second_staff
        ),
        comment: item.support_handle_second_comment,
      },
      {
        title: "対応3",
        date: getDateAndStaff(
          item.support_handle_third_date,
          item.support_handle_third_staff
        ),
        comment: item.support_handle_third_comment,
      },
    ]);

    const clickCustomerCode = (link) => {
      router.push({
        path: "/customer-list",
        query: {
          searchdefault:
            link !== null && link !== undefined && link !== "" ? link : null,
        },
      });
    };

    const copyText = (dummy, value) => {
      if (copyToClipboard(value)) {
        store.dispatch("message/setInfoMessage", {
          message: "クリップボードにコピーしました。",
        });
      } else {
        store.dispatch("message/setErrorMessage", {
          message: "クリップボードにコピーできませんでした。",
        });
      }
    };

    return {
      title,
      tooltipAccountCopy,
      tooltipCommentCopy,
      tooltipCodeLink,
      tooltipLink,
      isLink,
      item,
      customerData,
      handleData,
      formKey,

      clickCustomerCode,
      copyText,
    };
  },
});
</script>
