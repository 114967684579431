import { requiredRules, emailRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "パスワード再設定用メール送信",
  buttonLabelSubmit: "送信",
  thirdMessage: ["再設定メールを送信しました"],
  isStepThird: true,
  storeAddress: "signup/reset",
  successMessage: "再設定メールを送信しました",
  errorMessage: "再設定メールを送信できませんでした",
};

const forms = [
  {
    column: "email",
    label: "E-mail",
    type: "text",
    rules: [requiredRules, emailRules],
    emit: "resetEmail",
  },
];

export default {
  setting,
  forms,
};
