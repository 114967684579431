<template>
  <v-card flat :key="'card-' + formKey">
    <v-card-text>
      <span v-for="(option, key) in options" :key="'span-' + key">
        <ChipOption
          :propsClass="option.structure_class"
          :propsIsSmall="true"
          :propsText="option.option_name"
        />
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { createKey } from "@/utils/utilities.js";
import ChipOption from "@/components/atoms/ChipOption.vue";

export default defineComponent({
  name: "CardStructure",

  components: { ChipOption },

  props: {
    propsPlanId: { type: String, require: true },
  },

  setup(props) {
    const planId = ref(props.propsPlanId);
    const options = reactive({});
    const formKey = ref(createKey());

    const loadData = () => {
      // api:データ取得
      api({
        method: "get",
        url: "/option/",
      })
        .then((response) => {
          const res = response.data;
          for (const row of res) {
            options[row["id"]] = row;
            options[row["id"]]["structure_class"] = 0;
          }
        })
        .then(() => {
          api({
            method: "get",
            url: "/structure/?plan=" + planId.value,
          }).then((response) => {
            const res = response.data;
            // 構成データをセット
            for (const row of res) {
              const optionId = row["option"];
              if (optionId in options) {
                options[optionId]["structure_class"] = row["structure_class"];
              }
            }
            formKey.value = createKey();
          });
        });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      options,
      formKey,
    };
  },
});
</script>
