import { getDateObject } from "@/utils/utilities.js";
import { requiredRules, dateRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "コインレート",
  apiAddress: "/coinrate/",
  listSortBy: ["reference_date"],
  listSortDesc: [true],
  isCsvImport: true,
};

const forms = [
  {
    column: "reference_date",
    label: "基準日",
    type: "date",
    menu: false,
    default: getDateObject(),
    rules: [requiredRules, dateRules],
    importUnique: true,
    cols: 6,
  },
  {
    column: "coin_rate",
    label: "コインレート",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "基準日",
    value: "reference_date",
    align: "center",
  },
  {
    text: "コインレート",
    value: "coin_rate",
    type: "number",
    align: "center",
  },
];

export default {
  setting,
  forms,
  headers,
};
