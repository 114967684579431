<template>
  <v-container class="mx-0">
    <v-row>
      <v-col>
        <v-card
          width="700"
          min-height="700"
          rounded="lg"
          flat
          class="mx-0 pa-5"
        >
          <v-card-title>
            <SpanHeadline :propsText="cardTitle" />
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="stepCount" vertical flat>
              <!-- ステップ1 -->
              <v-stepper-step
                :complete="stepCount > 1"
                step="1"
                class="mygenText--text text-h6"
              >
                {{ stepFirstTitle }}
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-form ref="formFirst" @submit.prevent>
                  <v-card flat>
                    <v-card-text class="pa-3">
                      <v-card outlined rounded="lg">
                        <v-card-title class="mygenText--text">
                          {{ clientTitle }}
                        </v-card-title>
                        <v-card-text class="px-7">
                          <div class="caption">
                            {{ clientData.clientCode.title }}
                          </div>
                          <div class="subtitle-1 primary--text">
                            <h3>{{ clientData.clientCode.value }}</h3>
                          </div>
                          <div class="caption mt-2">
                            {{ clientData.clientName.title }}
                          </div>
                          <div class="subtitle-1 primary--text">
                            <h3>{{ clientData.clientName.value }}</h3>
                          </div>
                          <div class="caption mt-2">
                            {{ clientData.clientRemarks.title }}
                          </div>
                          <div class="subtitle-1 primary--text">
                            <h3>{{ clientData.clientRemarks.value }}</h3>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>

                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.customer"
                            @controlChange="loadUserData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container class="mt-5">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtn1"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ2 -->
              <v-stepper-step
                :complete="stepCount > 2"
                step="2"
                class="mygenText--text text-h6"
              >
                {{ stepSecondTitle }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-form ref="formSecond" @submit.prevent>
                  <v-card flat>
                    <v-card-text class="pa-3">
                      <v-card outlined rounded="lg">
                        <v-card-title class="mygenText--text">
                          {{ clientTitle }}
                        </v-card-title>
                        <v-card-text class="px-7">
                          <div class="caption">
                            {{ clientData.clientCode.title }}
                          </div>
                          <div class="subtitle-1 primary--text">
                            <h3>{{ clientData.clientCode.value }}</h3>
                          </div>
                          <div class="caption mt-2">
                            {{ clientData.clientName.title }}
                          </div>
                          <div class="subtitle-1 primary--text">
                            <h3>{{ clientData.clientName.value }}</h3>
                          </div>
                          <div class="caption mt-2">
                            {{ clientData.clientRemarks.title }}
                          </div>
                          <div class="subtitle-1 primary--text">
                            <h3>{{ clientData.clientRemarks.value }}</h3>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>

                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-card outlined rounded="lg">
                            <v-card-title
                              class="mygenText--tex font-weight-bold"
                            >
                              {{ accountData.title }}
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col class="mygenText--text">
                                    <div class="caption">
                                      {{ accountData.label }}
                                    </div>
                                    <div class="subtitle-1">
                                      {{ accountData.value }}
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <CardCustomerData
                            key="card-customer"
                            propsTitle="接続先お客様情報"
                            :propsFormsObject="formsCustomer"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="checkConnection.value"
                            :label="checkConnection.label"
                            :rules="checkConnection.rules"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="確定"
                            :propsClick="submitBtn2"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>

    <OverlayProgress v-if="progress" />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { settingDictionaryForms } from "@/utils/formUtility.js";
import {
  requiredCheckboxRules,
  selectedNotNullRules,
} from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import CardCustomerData from "@/components/atoms/CardCustomerData.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";
import FormsCustomer from "@/forms/customer.js";

export default defineComponent({
  name: "FormTransferConnection",

  components: {
    CardCustomerData,
    FormControls,
    ButtonCommon,
    OverlayProgress,
    SpanHeadline,
  },

  props: {
    propsEditId: {
      type: String,
      default: "",
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const editId = ref(props.propsEditId);

    const cardTitle = ref("コネクト処理");
    const stepFirstTitle = ref("アカウント選択");
    const stepSecondTitle = ref("接続先アカウント確認");
    const formFirst = ref(false);
    const formSecond = ref(false);

    const stepCount = ref(1);
    const clientTitle = ref("継承データ");
    const clientData = reactive({
      clientId: { title: "id", value: "" },
      clientCode: { title: "取引先コード", value: "" },
      clientName: { title: "取引先名", value: "" },
      clientRemarks: { title: "備考", value: "" },
    });
    const forms = reactive({
      customer: {
        column: "customer",
        label: "アカウント",
        type: "selectTransferUser",
        items: [],
        rules: [selectedNotNullRules],
        hint: "設定に注意してください！ アカウントユーザーに移行データが反映されます。",
      },
    });
    const formsCustomer = reactive(settingDictionaryForms(FormsCustomer.forms));
    const accountData = reactive({
      title: "接続先アカウント",
      label: "アカウント",
      value: "",
    });
    const checkConnection = reactive({
      value: false,
      label: "アカウントに接続する",
      rules: [requiredCheckboxRules],
    });
    const progress = ref(false);

    /**
     * 情報取得
     */
    const loadData = () => {
      // api:顧客情報取得
      api({
        method: "get",
        url: "/client/" + editId.value + "/",
      }).then((response) => {
        const res = response.data;
        const dummyText = "-----";
        clientData["clientId"].value = res["id"];
        clientData["clientCode"].value = res["client_code"] || dummyText;
        clientData["clientName"].value = res["client_name"] || dummyText;
        clientData["clientRemarks"].value = res["client_remarks"] || dummyText;
      });
    };

    // アカウント情報ロード
    const loadUserData = () => {
      const customerId = forms.customer.value.value;
      if (
        customerId === null ||
        customerId === undefined ||
        customerId === ""
      ) {
        return;
      }
      // api:customer情報取得
      api({
        method: "get",
        url: "/customer/" + customerId + "/",
      }).then((response) => {
        const customerData = response.data;
        for (const key in formsCustomer) {
          formsCustomer[key]["value"] = "";
          if (formsCustomer[key]["column"] in customerData) {
            if (formsCustomer[key]["type"] === "selectUser") {
              // ブラザーアカウントが連携できている場合
              if (customerData["account"]) {
                formsCustomer[key]["value"] = customerData["account"];
              }
            } else {
              formsCustomer[key]["value"] =
                customerData[formsCustomer[key]["column"]];
            }
          }
        }
        accountData.value = customerData["account"];
      });
    };

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const customerId = forms.customer.value.value;
      if (
        customerId === null ||
        customerId === undefined ||
        customerId === ""
      ) {
        return;
      }
      // 登録
      api({
        method: "patch",
        url: "/transferconnection/" + editId.value + "/",
        data: { customer: customerId },
      }).then(
        () => {
          const message = "アカウントに接続しました。";
          // 削除後処理（ダイアログクローズ）
          emit("close", message);
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // ボタン1
    const submitBtn1 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン2
    const submitBtn2 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formSecond.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        saveData();
      }
    };
    // 戻るボタン
    const returnBtn = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };
    // 閉じるボタン
    const clickClose = () => {
      // ダイアログ
      emit("close");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      cardTitle,
      stepFirstTitle,
      stepSecondTitle,
      formFirst,
      formSecond,

      stepCount,
      clientTitle,
      clientData,
      forms,
      formsCustomer,
      accountData,
      checkConnection,
      progress,

      loadUserData,
      submitBtn1,
      submitBtn2,
      returnBtn,
      clickClose,
    };
  },
});
</script>
