const setting = {
  title: "契約情報",
  apiAddress: "/contractplanreadonly/",
  apiListParam: "contract_class=0&type=availability",
  isListReadonly: true,
  listSortBy: ["contract_code"],
  listSortDesc: [false],
};

const headers = [
  {
    text: "契約コード",
    value: "contract_code",
    align: "start",
  },
  {
    text: "ステータス",
    value: "final_order_status",
    column: "final_order_status",
    align: "center",
  },
  {
    text: "お客様コード",
    value: "contract___customer_reference___customer_code",
    align: "start",
  },
  {
    text: "お客様名",
    value: "contract___customer_reference___customer_name",
    align: "start",
  },
  {
    text: "プラン",
    value: "plan_name",
  },
  {
    text: "サイト",
    value: "contract___site_address",
  },
  {
    text: "サイト名称",
    value: "contract___site_name",
  },
  {
    text: "アカウント数",
    value: "account_quantity",
    type: "number",
    align: "end",
  },
  {
    text: "月額料金",
    value: "monthly_charge",
    type: "number",
    align: "end",
  },
  {
    text: "課金開始日",
    value: "contract_date",
    align: "center",
  },
];

const searchSelect = {
  label: "ステータス",
  column: "final_order_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 50, text: "稼働中" },
    { value: 80, text: "解約" },
  ],
  value: 50,
};

export default {
  setting,
  headers,
  searchSelect,
};
