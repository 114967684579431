import store from "@/store";
import api from "@/services/api";
import { checkDate, checkNumber } from "@/utils/utilities.js";
import { getFormObject, settingDictionaryForms } from "@/utils/formUtility.js";

/**
 * api:移行契約設定データ取得
 */
const loadSettingData = () => {
  // データ取得
  api({
    method: "get",
    url: "/transfersetting/",
  }).then((response) => {
    return response.data;
  });
};

/**
 * csvインポート時のコード変換
 */
const csvConvertCodeToId = (type, value, isBrank, transferSettingData) => {
  let dataName;
  let codeName;
  switch (type) {
    case "selectClient":
      dataName = "client_data";
      codeName = "client_code";
      break;
    case "selectPlan":
      dataName = "plan_data";
      codeName = "plan_code";
      break;
    case "selectOption":
      dataName = "option_data";
      codeName = "option_code";
      break;
    case "selectCoupon":
      dataName = "coupon_data";
      codeName = "coupon_code";
      break;
    case "selectSupportRate":
      dataName = "support_rate_data";
      codeName = "support_rate";
      break;
    case "selectTaxRate":
    case "selectTaxRateValue":
      dataName = "tax_rate_data";
      codeName = "tax_rate";
      break;
    case "selectBrother":
      dataName = "brother_data";
      codeName = "brother_code";
      break;
    case "selectUser":
      dataName = "user_data";
      codeName = "email";
      break;
    case "selectAccount":
      dataName = "account_data";
      codeName = "user__email";
      break;
    case "selectCustomer":
      dataName = "customer_data";
      codeName = "customer_code";
      break;
    case "selectIncentive":
      dataName = "incentive_data";
      codeName = "incentive_code";
      break;
    case "selectIncentiveFirst":
      dataName = "first_rate_data";
      codeName = "incentive_rate";
      break;
    case "selectIncentiveMonth":
      dataName = "month_rate_data";
      codeName = "incentive_rate";
      break;
    case "selectTransferIdentifier":
      dataName = "transfer_identifier_data";
      codeName = "transfer_identifier";
      break;
    default:
      // 該当なし
      return false;
  }
  if (value) {
    if (type === "selectBrother" && value === "GEN") {
      // brank
      return "";
    }
    // マスタ値取得
    const settingData = transferSettingData[dataName];
    // 配列チェック
    for (const key in settingData) {
      if (settingData[key][codeName] == value) {
        if (type === "selectTaxRateValue") {
          return settingData[key][codeName];
        }
        return settingData[key]["id"];
      }
    }
  } else if (
    ((type === "selectCoupon" ||
      type === "selectUser" ||
      type === "selectIncentive" ||
      type === "selectIncentiveFirst" ||
      type === "selectIncentiveMonth") &&
      !value) ||
    (isBrank && !value)
  ) {
    // brank OK
    return "";
  }
  return false;
};

/**
 * csvインポート時の特殊チェック
 */
const csvValidation = (type, value, transferSettingData) => {
  if (type === "transfer_identifier") {
    const identifierData = transferSettingData["transfer_identifier_data"];
    if (identifierData.some((data) => data.transfer_identifier === value)) {
      return "既に " + value + " は登録されています。";
    }
  }
  // 該当なし
  return false;
};

/**
 * csvインポート（メイン）
 */
const readFileAsync = (
  file,
  formObject,
  importType = 0,
  transferSettingData
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // フォーム設定
    let forms = settingDictionaryForms(formObject.forms, true);
    // インポートAPIアドレス
    let importAddress = formObject.setting.apiAddress;

    let importObject;
    switch (importType) {
      case 10:
        // オプション設定用
        // フォーム設定
        importObject = getFormObject("structure");
        forms = settingDictionaryForms(importObject.forms, true);
        // インポートAPIアドレス
        importAddress = importObject.setting.apiAddress;
        break;

      case 20:
        // 移行契約オプション用
        // フォーム設定
        importObject = getFormObject("transferoption");
        forms = settingDictionaryForms(
          [
            {
              column: "transfer_plan",
              label: "旧契約コード",
              type: "selectTransferIdentifier",
              exportColumn: "transfer_plan_reference___transfer_identifier",
            },
          ].concat(importObject.forms),
          true
        );
        // インポートAPIアドレス
        importAddress = importObject.setting.apiAddress;
        break;

      default:
        break;
    }

    const lineLength = Object.keys(forms).length;
    const formData = [];
    reader.onload = () => {
      //文字列をバイト列に変換
      const lines = reader.result.split("\n");
      let lineNo = 0;
      let isFirst = true;
      let lineError = false;
      let isError = false;
      let errorMessage = [];
      const checkUnique = {};
      const lineUniqueData = [];

      // データ列チェック
      if (lines.length <= 1) {
        store.dispatch("message/setErrorMessage", {
          message: ["データが存在しません。"],
        });
        reject(new Error("format error"));
        return false;
      }

      // データ列ループ
      lines.forEach((element) => {
        // 行カウント
        lineNo++;
        if (isFirst || lineError) {
          // ヘッダ行読み飛ばし
          isFirst = false;
          return;
        }
        if (element === "") {
          // データ不在の行は読み飛ばす
          return;
        }
        // データ列を配列に変換
        const rowData = element.replace(/\r/g, "").split(",");
        // 列数チェック
        if (rowData.length > lineLength) {
          // エラーメッセージ
          errorMessage.push(
            lineNo + "行目：データ列（列数）が正しくありません。"
          );
          store.dispatch("message/setErrorMessage", {
            message: errorMessage,
          });
          isError = true;
          lineError = true;
          return false;
        }
        const line = {};
        let count = 0;
        for (const key in forms) {
          const columnName = forms[key]["column"];
          let value = rowData[count]
            ? rowData[count]
            : forms[key]["default"]
            ? forms[key]["default"]
            : "";
          let columnError = false;
          // ダブルクォーテーションを削除する
          value = value.toString().replace(/"/g, "");
          // 大文字カンマをコンバート
          let checkCount = 0;
          while (value.match(/，/) && checkCount < 20) {
            value = value.replace("，", ",");
            checkCount++;
          }
          if (
            forms[key]["type"] === "date" ||
            forms[key]["type"] === "dateMonthFirst"
          ) {
            value = value.replace("/", "-").replace("/", "-");
            // 日付チェック
            if (
              !value &&
              value === "" &&
              forms[key]["importBrank"] &&
              forms[key]["importBrank"] === true
            ) {
              value = null;
            } else if (!value || !checkDate(value)) {
              columnError = true;
            } else if (forms[key]["type"] === "dateMonthFirst") {
              // 月初日は日付を成形する
              const dateArray = value.split("-");
              dateArray[2] = 1;
              value = dateArray.join("-");
            }
          } else if (forms[key]["type"] === "number") {
            // 数値チェック
            if (!value || !checkNumber(value)) {
              columnError = true;
            }
          } else if (
            forms[key]["type"] === "selectClient" ||
            forms[key]["type"] === "selectPlan" ||
            forms[key]["type"] === "selectOption" ||
            forms[key]["type"] === "selectCoupon" ||
            forms[key]["type"] === "selectIncentive" ||
            forms[key]["type"] === "selectIncentiveFirst" ||
            forms[key]["type"] === "selectIncentiveMonth" ||
            forms[key]["type"] === "selectSupportRate" ||
            forms[key]["type"] === "selectTaxRate" ||
            forms[key]["type"] === "selectTaxRateValue" ||
            forms[key]["type"] === "selectBrother" ||
            forms[key]["type"] === "selectUser" ||
            forms[key]["type"] === "selectAccount" ||
            forms[key]["type"] === "selectCustomer" ||
            forms[key]["type"] === "selectTransferIdentifier"
          ) {
            // セレクトID取得
            const selectItem = csvConvertCodeToId(
              forms[key]["type"],
              value,
              forms[key]["importBrank"],
              transferSettingData
            );
            // brankOKのデータが返されることもあるためfalseで検知する
            if (selectItem === false) {
              columnError = true;
            } else {
              value = selectItem;
            }
          } else if (forms[key]["type"] === "select") {
            if (!value) {
              columnError = true;
            } else {
              const selectItem = forms[key]["items"];
              // 配列チェック
              let isSelect = false;
              for (const selectKey in selectItem) {
                if (selectItem[selectKey]["value"] == value) {
                  isSelect = true;
                }
              }
              if (!isSelect) {
                columnError = true;
              }
            }
          }
          // rulesチェック
          let ruleMessage = "";
          if (
            (forms[key]["rules"] && forms[key]["rules"].length > 0) ||
            (forms[key]["importRules"] && forms[key]["importRules"].length > 0)
          ) {
            let checkRules = forms[key]["rules"];
            if (
              forms[key]["importRules"] &&
              forms[key]["importRules"].length > 0
            ) {
              checkRules = forms[key]["importRules"];
            }
            checkRules.forEach((rule) => {
              const ruleJudge = rule(value);
              if (ruleJudge !== true) {
                columnError = true;
                ruleMessage = ruleJudge;
              }
            });
          }
          // csvインポート時の特殊バリデーション
          if (forms[key]["importValidation"]) {
            const isValidation = csvValidation(
              columnName,
              value,
              transferSettingData
            );
            if (isValidation) {
              isError = true;
              errorMessage.push(lineNo + "行目：" + isValidation);
            }
          }
          // エラーメッセージ
          if (columnError) {
            isError = true;
            errorMessage.push(
              lineNo +
                "行目：" +
                forms[key]["label"] +
                "の形式が正しくありません。" +
                (ruleMessage !== "" ? ruleMessage : "")
            );
          }

          // ユニークチェック
          if (!isError && forms[key]["importUnique"]) {
            let uniqueData = [];
            if (columnName in checkUnique) {
              uniqueData = checkUnique[columnName];
            }
            // 重複チェック
            if (uniqueData.includes(value)) {
              isError = true;
              errorMessage.push(
                lineNo + "行目：" + forms[key]["label"] + "が重複しています。"
              );
            } else {
              uniqueData.push(value);
              checkUnique[columnName] = uniqueData;
            }
          }

          // 登録データセット
          line[columnName] = value;
          count++;
        }

        // 移行オプション専用ユニークチェック
        if (importType === 20) {
          let lineValue = line["transfer_plan"] + ":::" + line["option"];
          // 重複チェック
          if (lineUniqueData.includes(lineValue)) {
            isError = true;
            errorMessage.push(lineNo + "行目：オプションが重複しています。");
          } else {
            lineUniqueData.push(lineValue);
          }
        }

        formData.push(line);
      });

      // 登録データチェック
      if (formData.length < 1) {
        store.dispatch("message/setErrorMessage", {
          message: ["データが存在しません。"],
        });
        reject(new Error("format error"));
        return false;
      }

      // エラー処理
      if (isError) {
        store.dispatch("message/setErrorMessage", {
          message: errorMessage,
        });
        reject(new Error("format error"));
        return false;
      }
      const dataLength = Object.keys(formData).length;
      if (dataLength > 0) {
        api({
          method: "post",
          url: importAddress,
          data: formData,
        })
          .then(() => {
            resolve(reader.result);
            return true;
          })
          .catch(function (error) {
            if (error.response === undefined) {
              reject(new Error("api error"));
              return false;
            }
            const errorData = error.response.data;
            const res = [];
            if (typeof errorData === "string") {
              res.push(errorData);
            } else if (typeof errorData === "object") {
              errorData.forEach((row, key) => {
                for (const col in row) {
                  const text = row[col][0];
                  if (col) {
                    const tempData = formData[key][col];
                    res.push(text + "（" + tempData + "）");
                  }
                }
              });
            } else {
              res.push("エラーが発生しました。");
            }
            store.dispatch("message/setErrorMessage", {
              message: res,
              // message: error.response.request.responseText,
              // message: error.response["statusText"],
            });
            reject(new Error("api error"));
            return false;
          });
      }
    };

    reader.onerror = reject;
    // Shift-jisでCSVファイルを読み込む
    // UTF-8のCSVファイルは文字化けする
    reader.readAsText(file, "Shift_JIS");
  });
};

/**
 * csvエクスポート
 */
const csvExport = (formObject, exportType, exportData) => {
  // フォーム設定
  let forms = settingDictionaryForms(formObject.forms, true);
  // CSVタイトル
  let title = formObject.setting.title;

  let exportObject;
  switch (exportType) {
    case 10:
      // オプション設定用
      // フォーム設定
      exportObject = getFormObject("structure");
      forms = settingDictionaryForms(exportObject.forms, true);
      // CSVタイトル
      title = exportObject.setting.title;
      break;

    case 20:
      // 移行契約オプション用
      // フォーム設定
      exportObject = getFormObject("transferoption");
      forms = settingDictionaryForms(
        [
          {
            column: "transfer_plan",
            label: "旧契約コード",
            type: "selectTransferIdentifier",
            exportColumn: "transfer_plan_reference___transfer_identifier",
          },
        ].concat(exportObject.forms),
        true
      );
      // CSVタイトル
      title = exportObject.setting.title;
      break;

    default:
      break;
  }

  const labels = [];
  const columns = [];
  let count = 0;
  for (const key in forms) {
    // ラベルセット
    labels.push(forms[key]["label"]);
    // カラムセット
    if (forms[key]["exportColumn"]) {
      // エクスポート専用カラム
      columns[count++] = forms[key]["exportColumn"];
    } else {
      columns[count++] = forms[key]["column"];
    }
  }
  let csvData = "\ufeff" + labels.join(",");

  // エクスポートデータ生成
  for (const parentKey in exportData) {
    const row = exportData[parentKey];
    const line = [];
    // カラムセットに応じてCSVデータ作成
    for (const childKey in columns) {
      const colName = columns[childKey];
      let colData;
      // JOINデータ取得
      if (colName.indexOf("___") > -1) {
        const colList = colName.split("___");
        let isFirst = true;
        for (const key of colList) {
          if (isFirst) {
            colData = row[key];
            isFirst = false;
          } else if (colData !== null) {
            colData = colData[key];
          } else {
            colData = "";
          }
        }
      } else if (row[columns[childKey]] || row[columns[childKey]] === 0) {
        colData = row[columns[childKey]];
      }
      line.push(colData);
    }
    csvData += "\n" + line.join(",");
  }
  let blob = new Blob([csvData], { type: "text/csv" });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = title + ".csv";
  link.click();
};

export { loadSettingData, readFileAsync, csvExport };
