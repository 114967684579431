import store from "@/store";
// import { useDisplay } from "vuetify";
// import { useMessageStore } from "@/stores/message.js";

/**
 * カンマ表示
 */
const convertNumComma = (value) => {
  if (!value) {
    value = 0;
  }
  return value.toLocaleString();
};

const convertKanaHalfToFull = (value) => {
  const kanaMap = {
    ｶﾞ: "ガ",
    ｷﾞ: "ギ",
    ｸﾞ: "グ",
    ｹﾞ: "ゲ",
    ｺﾞ: "ゴ",
    ｻﾞ: "ザ",
    ｼﾞ: "ジ",
    ｽﾞ: "ズ",
    ｾﾞ: "ゼ",
    ｿﾞ: "ゾ",
    ﾀﾞ: "ダ",
    ﾁﾞ: "ヂ",
    ﾂﾞ: "ヅ",
    ﾃﾞ: "デ",
    ﾄﾞ: "ド",
    ﾊﾞ: "バ",
    ﾋﾞ: "ビ",
    ﾌﾞ: "ブ",
    ﾍﾞ: "ベ",
    ﾎﾞ: "ボ",
    ﾊﾟ: "パ",
    ﾋﾟ: "ピ",
    ﾌﾟ: "プ",
    ﾍﾟ: "ペ",
    ﾎﾟ: "ポ",
    ｳﾞ: "ヴ",
    ﾜﾞ: "ヷ",
    ｦﾞ: "ヺ",
    ｱ: "ア",
    ｲ: "イ",
    ｳ: "ウ",
    ｴ: "エ",
    ｵ: "オ",
    ｶ: "カ",
    ｷ: "キ",
    ｸ: "ク",
    ｹ: "ケ",
    ｺ: "コ",
    ｻ: "サ",
    ｼ: "シ",
    ｽ: "ス",
    ｾ: "セ",
    ｿ: "ソ",
    ﾀ: "タ",
    ﾁ: "チ",
    ﾂ: "ツ",
    ﾃ: "テ",
    ﾄ: "ト",
    ﾅ: "ナ",
    ﾆ: "ニ",
    ﾇ: "ヌ",
    ﾈ: "ネ",
    ﾉ: "ノ",
    ﾊ: "ハ",
    ﾋ: "ヒ",
    ﾌ: "フ",
    ﾍ: "ヘ",
    ﾎ: "ホ",
    ﾏ: "マ",
    ﾐ: "ミ",
    ﾑ: "ム",
    ﾒ: "メ",
    ﾓ: "モ",
    ﾔ: "ヤ",
    ﾕ: "ユ",
    ﾖ: "ヨ",
    ﾗ: "ラ",
    ﾘ: "リ",
    ﾙ: "ル",
    ﾚ: "レ",
    ﾛ: "ロ",
    ﾜ: "ワ",
    ｦ: "ヲ",
    ﾝ: "ン",
    ｧ: "ァ",
    ｨ: "ィ",
    ｩ: "ゥ",
    ｪ: "ェ",
    ｫ: "ォ",
    ｯ: "ッ",
    ｬ: "ャ",
    ｭ: "ュ",
    ｮ: "ョ",
    "｡": "。",
    "､": "、",
    ｰ: "ー",
    "｢": "「",
    "｣": "」",
    "･": "・",
  };
  let reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");
  return value
    .replace(reg, function (s) {
      return kanaMap[s];
    })
    .replace(/ﾞ/g, "゛")
    .replace(/ﾟ/g, "゜");
};

/**
 * key生成機能
 * key:タイムスタンプ+乱数
 */
const createKey = () => {
  return (
    new Date().getTime().toString() + Math.floor(Math.random() * 10).toString()
  );
};

/**
 * SSID生成機能
 */
const createUUID = () => {
  // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
  // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
  let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
    }
  }
  return chars.join("");
};

/**
 * スネークケースからキャメルケースへの変換
 */
const convertSnakeToCamel = (str) => {
  return str.replace(/_./g, function (tmp) {
    return tmp.charAt(1).toUpperCase();
  });
};

/**
 * 配列の辞書型への変換
 */
const convertArrayToDict = (arr, name, value) => {
  const data = {};
  for (const row of arr) {
    data[row[name]] = row[value];
  }
  return data;
};

/**
 * 日付を月度に変換
 */
const convertDateToMonth = (date) => {
  if (date === null) {
    return "";
  }
  const year = String(date).split("-")[0];
  const month = String(date).split("-")[1];
  return String(year) + "-" + String(month);
};

/**
 * 月度を月初日付に変換
 */
const convertMonthToDateFirst = (month) => {
  var date = new Date(
    Number(String(month).split("-")[0]),
    Number(String(month).split("-")[1]) - 1,
    Number(1)
  );
  date.setDate(1);
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

/**
 * 月度を月末日付に変換
 */
const convertMonthToDateEnd = (month) => {
  var date = new Date(
    Number(String(month).split("-")[0]),
    Number(String(month).split("-")[1]),
    Number(0)
  );
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

/**
 * 同等チェック
 */
const checkEqual = (val1, val2) => {
  return val1 === val2;
};

/**
 * 必須入力チェック
 */
const checkRequired = (name, value) => {
  if (!value) {
    const message = name + "を入力してください";
    store.dispatch("message/setErrorMessage", { message: message });
    return false;
  }
  return true;
};

/**
 * 入力一致チェック
 */
const checkMatch = (obj, base, target) => {
  // const messageStore = useMessageStore();
  // // メッセージをクリア
  // messageStore.clear();
  const dataBase = obj[base];
  const dataTarget = obj[target];
  if (dataBase.value !== dataTarget.value) {
    const message = dataBase.label + "が一致していません";
    store.dispatch("message/setErrorMessage", { message: message });
    // // エラーメッセージを表示
    // messageStore.setErrorMessage(message);
    return false;
  }
  return true;
};

/**
 * 日付チェック
 */
const checkDate = (value) => {
  value = value.replace("-", "/").replace("-", "/");
  if (!value.match(/^\d{4}\/\d{1,2}\/\d{1,2}$/)) {
    return false;
  }
  var y = value.split("/")[0];
  var m = value.split("/")[1] - 1;
  var d = value.split("/")[2];
  var date = new Date(y, m, d);
  if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
    return false;
  }
  return true;
};

/**
 * 数値チェック
 */
const checkNumber = (value) => {
  if (value === null || value === true) {
    return false;
  } else if (isNaN(value)) {
    return false;
  }
  return true;
};

/**
 * 日付オブジェクト取得
 */
const getDateObject = (type = "today", month = 0, day = 0) => {
  let value = new Date();
  if (type == "first") {
    value.setDate(1);
  } else if (type == "last") {
    value = new Date(value.getFullYear(), value.getMonth() + 1, 0);
  }
  if (month !== 0) {
    value.setMonth(value.getMonth() + month);
  }
  if (day !== 0) {
    value.setDate(value.getDate() + day);
  }
  return value
    .toLocaleDateString("ja-JP", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replaceAll("/", "-");
};

/**
 * 月度オブジェクト取得
 */
const getMonthObject = (type = "today", month = 0, day = 0) => {
  let value = new Date();
  if (type == "first") {
    value.setDate(1);
  } else if (type == "last") {
    value = new Date(value.getFullYear(), value.getMonth() + 1, 0);
  }
  if (month !== 0) {
    value.setMonth(value.getMonth() + month);
  }
  if (day !== 0) {
    value.setDate(value.getDate() + day);
  }
  return value.toISOString().substring(0, 7);
};

/**
 * 日付情報取得
 */
const getToday = (type = "post") => {
  const today = new Date();
  let value =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  if (type === "jp" || type === "jp-datetime") {
    value =
      today.getFullYear() +
      "年" +
      (today.getMonth() + 1) +
      "月" +
      today.getDate() +
      "日";
  }
  if (type === "jp-datetime") {
    value +=
      " " +
      today.getHours().toString().padStart(2, "0") +
      ":" +
      today.getMinutes().toString().padStart(2, "0") +
      ":" +
      today.getSeconds().toString().padStart(2, "0");
  }
  return value;
};

/**
 * 契約元情報取得
 */
const getBaseInfo = (baseData, orderData) => {
  // この関数の返し値はAPIの base_info生成ロジック(get_base_info) と合わせる
  const orders = {};
  const options = [];
  const optionDiffInfo = {};
  const optionDelInfo = {};
  let isDiff = false;
  let isPlanChange = false;
  let isAccountChange = false;
  let isSupportRateChange = false;
  // オーダー情報を整形
  if (orderData["contract_option"]) {
    for (const key in orderData["contract_option"]) {
      orders[orderData["contract_option"][key]["option"]] =
        orderData["contract_option"][key]["option_quantity"];
    }
  }
  // 元情報と比較
  if (baseData && baseData["contract_option"]) {
    for (const key in baseData["contract_option"]) {
      const optionId = baseData["contract_option"][key]["option"];
      if (orders[optionId]) {
        const isChange =
          orders[optionId] !==
          baseData["contract_option"][key]["option_quantity"];
        optionDiffInfo[optionId] = { option_diff: isChange };
        if (isChange) isDiff = true;
      } else {
        optionDelInfo[optionId] = {
          option_name: baseData["contract_option"][key]["option_name"],
          option_fee_including_tax:
            baseData["contract_option"][key]["option_fee_including_tax"],
        };
        isDiff = true;
      }
      options.push(optionId);
    }
    // 新規追加分チェック
    for (const key in orders) {
      if (options.indexOf(key) === -1) {
        optionDiffInfo[key] = { option_diff: true };
        isDiff = true;
      }
    }
  }

  // プランの差分情報
  if (baseData && baseData["plan"] !== orderData["plan"]) {
    isDiff = true;
    isPlanChange = true;
  }
  if (
    baseData &&
    baseData["account_quantity"] !== orderData["account_quantity"]
  ) {
    isDiff = true;
    isAccountChange = true;
  }

  // サポートレートの差分情報
  if (baseData && baseData["support_rate"] !== orderData["support_rate"]) {
    isDiff = true;
    isSupportRateChange = true;
  }

  const baseInfo = {
    plan_diff: isPlanChange,
    account_diff: isAccountChange,
    support_rate_diff: isSupportRateChange,
    option_diff_info: optionDiffInfo,
    option_del_info: optionDelInfo,
    is_diff: isDiff,
  };

  return baseInfo;
};

/**
 * オプション情報変案
 */
const convertOptionFieldToDict = (value) => {
  const optionDict = {};
  for (const key in value) {
    const row = value[key];
    optionDict[row["option"]] = row;
  }
  return optionDict;
};

/**
 * クリップボードにコピー
 */
const copyToClipboard = (value) => {
  if (navigator.clipboard) {
    return navigator.clipboard
      .writeText(value)
      .then(function () {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  } else {
    return false;
  }
};

export {
  convertNumComma,
  convertKanaHalfToFull,
  createKey,
  createUUID,
  convertSnakeToCamel,
  convertArrayToDict,
  convertDateToMonth,
  convertMonthToDateFirst,
  convertMonthToDateEnd,
  checkEqual,
  checkRequired,
  checkMatch,
  checkDate,
  checkNumber,
  getDateObject,
  getMonthObject,
  getToday,
  getBaseInfo,
  convertOptionFieldToDict,
  copyToClipboard,
};
