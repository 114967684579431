<template>
  <div
    id="customer-create"
    style="min-height: 100%; background-color: rgba(33, 33, 33, 0.46)"
    :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-10'"
  >
    <SnackbarMessage />

    <v-container>
      <v-row>
        <v-spacer />
        <v-col class="pa-0">
          <v-card ref="form" width="800" class="pa-5">
            <v-card-title>
              <SpanHeadline :propsText="cardTitle" />
            </v-card-title>
            <v-card-text :class="$vuetify.breakpoint.xs ? 'px-0' : ''">
              <v-stepper v-model="stepCount" vertical flat>
                <!-- ステップ1 -->
                <v-stepper-step
                  :complete="stepCount > 1"
                  step="1"
                  class="mygenText--text text-h6"
                >
                  {{ stepFirstTitle }}
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-form ref="formFirst" @submit.prevent>
                    <span class="primary--text">
                      契約される会社情報を入力してください
                    </span>
                    <v-card class="mb-12" flat>
                      <v-container>
                        <v-row>
                          <v-col
                            v-for="(form, key) in formsFirst"
                            :key="'first-' + key"
                            cols="12"
                            :sm="form.cols ? form.cols : 12"
                          >
                            <FormControls propsMode="post" :propsForm="form" />
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <ButtonCommon
                              propsLabel="次へ"
                              :propsClick="submitBtn1"
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <ButtonCommon
                              propsColor="mygenIcon"
                              propsLabel="Logout"
                              :propsClick="clickLogout"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <!-- ステップ2 -->
                <v-stepper-step
                  :complete="stepCount > 2"
                  step="2"
                  class="mygenText--text text-h6"
                >
                  {{ stepSecondTitle }}
                </v-stepper-step>
                <v-stepper-content step="2">
                  <v-form ref="formSecond" @submit.prevent>
                    <span class="primary--text">
                      請求先の情報を入力してください
                    </span>
                    <v-card class="mb-12" flat>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <ButtonCommon
                              propsLabel="契約者情報をコピー"
                              :propsOutlined="true"
                              :propsClick="clickCopy"
                            />
                          </v-col>
                          <v-col
                            v-for="(form, key) in formsSecond"
                            :key="key"
                            cols="12"
                            :sm="form.cols ? form.cols : 12"
                          >
                            <FormControls propsMode="post" :propsForm="form" />
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <ButtonCommon
                              propsLabel="次へ"
                              :propsClick="submitBtn2"
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <ButtonCommon
                              propsColor="mygenIcon"
                              propsLabel="戻る"
                              :propsClick="returnBtn"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <!-- ステップ3 -->
                <v-stepper-step
                  :complete="stepCount > 3"
                  step="3"
                  class="mygenText--text text-h6"
                >
                  {{ stepThirdTitle }}
                </v-stepper-step>
                <v-stepper-content step="3">
                  <span class="primary--text"> 以下の情報で登録します </span>
                  <ContainerCustomerInfo
                    :key="'customer-' + formKey"
                    :propsFormsFirst="formsFirst"
                    :propsFormsSecond="formsSecond"
                    :propsIsConfirm="true"
                  />

                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <ButtonCommon
                          propsLabel="登録"
                          :propsClick="submitBtn3"
                        />
                      </v-col>
                      <v-col cols="12" sm="4">
                        <ButtonCommon
                          propsColor="mygenIcon"
                          propsLabel="契約者情報の修正"
                          :propsClick="returnStep1"
                        />
                      </v-col>
                      <v-col cols="12" sm="4">
                        <ButtonCommon
                          propsColor="mygenIcon"
                          propsLabel="請求先情報の修正"
                          :propsClick="returnStep2"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>

                <!-- ステップ4 -->
                <v-stepper-step
                  :complete="stepCount > 4"
                  step="4"
                  class="mygenText--text text-h6"
                >
                  {{ stepFourthTitle }}
                </v-stepper-step>
                <v-stepper-content step="4">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <ButtonCommon
                          propsLabel="ホーム画面へ"
                          :propsClick="submitBtn4"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>

    <!-- debug -->
    <!-- <div class="ma-10">
      <pre>route: {{ store.state.auth.id }}</pre>
      <pre>route: {{ this.$route.path }}</pre>
      <pre>state: {{ store.state }}</pre>
    </div> -->
  </div>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router/composables";
import { convertKanaHalfToFull, createKey } from "@/utils/utilities.js";
import { settingDictionaryForms } from "@/utils/formUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import SnackbarMessage from "@/components/atoms/SnackbarMessage.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";
import ContainerCustomerInfo from "@/components/molecules/ContainerCustomerInfo.vue";
import FormsCustomer from "@/forms/customer.js";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";

export default defineComponent({
  name: "FormCustomerCreate",

  components: {
    FormControls,
    ButtonCommon,
    SnackbarMessage,
    SpanHeadline,
    ContainerCustomerInfo,
  },

  setup() {
    const router = useRouter();
    const isLoggedIn = computed(() => {
      return store.state.auth.isLoggedIn;
    });

    const cardTitle = ref("お客様情報初回登録");
    const stepFirstTitle = ref("契約者情報");
    const stepSecondTitle = ref("請求先情報");
    const stepThirdTitle = ref("確認");
    const stepFourthTitle = ref("登録完了");
    const formFirst = ref(false);
    const formSecond = ref(false);

    const stepCount = ref(1);
    let formsFirst = reactive(settingDictionaryForms(FormsCustomer.forms));
    let formsSecond = reactive(
      settingDictionaryForms(FormsInvoiceAddress.forms)
    );

    const dialog = ref(false);
    const formKey = ref(createKey());

    /**
     * api:データ登録
     */
    const saveData = () => {
      // apiで渡すデータを生成
      const data = {};
      for (const key in formsFirst) {
        let value = formsFirst[key].value;
        if (formsFirst[key].type === "select") {
          value = formsFirst[key].value.value;
        }
        data[formsFirst[key].column] = value;
      }
      for (const key in formsSecond) {
        let value = formsSecond[key].value;
        if (formsSecond[key].type === "select") {
          value = formsSecond[key].value.value;
        }
        data[formsSecond[key].column] = value;
      }
      // ユーザー情報付加（storeから取得）
      data["user"] = store.state.auth.id;

      // 登録
      api({
        method: "post",
        url: FormsCustomer.setting.apiAddress,
        data: data,
      }).then(() => {
        store.dispatch("message/setInfoMessage", {
          message: "お客様情報を登録しました",
        });
        stepCount.value++;
      });
    };

    // ボタン1
    const submitBtn1 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        stepCount.value++;
        // 半角カナから全角カナへ変換
        for (const key in formsFirst) {
          if (formsFirst[key].type === "text") {
            formsFirst[key].value = convertKanaHalfToFull(
              (formsFirst[key].value + "").trim()
            );
          }
        }
      }
    };
    // ボタン2
    const submitBtn2 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formSecond.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        stepCount.value++;
        // 半角カナから全角カナへ変換
        for (const key in formsSecond) {
          if (formsSecond[key].type === "text") {
            formsSecond[key].value = convertKanaHalfToFull(
              (formsSecond[key].value + "").trim()
            );
          }
        }
      }
    };
    // ボタン3
    const submitBtn3 = () => {
      dialog.value = true;
      saveData();
    };
    // ボタン4
    const submitBtn4 = () => {
      router.go({ path: "/", force: true });
    };
    // 戻るボタン
    const returnBtn = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };
    const returnStep1 = () => {
      store.dispatch("message/clearMessages");
      stepCount.value = 1;
    };
    const returnStep2 = () => {
      store.dispatch("message/clearMessages");
      stepCount.value = 2;
    };
    // コピー
    const clickCopy = () => {
      formsSecond.invoiceCustomerName.value = formsFirst.customerName.value;
      formsSecond.invoicePersonInCharge.value = formsFirst.personInCharge.value;
      formsSecond.invoiceZipCode.value = formsFirst.zipCode.value;
      formsSecond.invoicePrefecture.value = formsFirst.prefecture.value;
      formsSecond.invoiceAddress1.value = formsFirst.address1.value;
      formsSecond.invoiceAddress2.value = formsFirst.address2.value;
      formsSecond.invoiceTel.value = formsFirst.tel.value;
      formKey.value = createKey();
    };
    // ログアウト
    const clickLogout = () => {
      store.dispatch("auth/logout");
      store.dispatch("message/setInfoMessage", {
        message: "ログアウトしました",
      });
      router.replace("/auth/login");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    watch(dialog, (val) => {
      if (!val) return;
      setTimeout(() => (dialog.value = false), 4000);
    });

    return {
      isLoggedIn,

      cardTitle,
      stepFirstTitle,
      stepSecondTitle,
      stepThirdTitle,
      stepFourthTitle,
      formFirst,
      formSecond,

      stepCount,
      formsFirst,
      formsSecond,

      dialog,
      formKey,

      submitBtn1,
      submitBtn2,
      submitBtn3,
      submitBtn4,
      returnBtn,
      returnStep1,
      returnStep2,
      clickCopy,
      clickLogout,
    };
  },
});
</script>
