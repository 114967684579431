const setting = {
  title: "アカウント",
  apiAddress: "/user/",
  isListReadonly: true,
  listSortBy: ["email"],
  listSortDesc: [false],
};

const headers = [
  {
    text: "アカウント",
    value: "email",
    align: "start",
  },
  {
    text: "有効化",
    value: "isActiveStatus",
    align: "center",
    handleKey: ["is_active"],
  },
  {
    text: "スタッフ",
    value: "isStaffStatus",
    align: "center",
    handleKey: ["is_staff"],
  },
  {
    text: "お客様コード",
    value: "customer_reference___customer_code",
  },
  {
    text: "会社名",
    value: "customer_reference___customer_name",
  },
  {
    text: "担当者",
    value: "customer_reference___person_in_charge",
  },
];

const searchSelect = {
  label: "有効化",
  column: "is_active",
  items: [
    { value: null, text: "ALL" },
    { value: true, text: "有効" },
    { value: false, text: "無効" },
  ],
  value: null,
};

const searchSubSelect = {
  label: "スタッフ",
  column: "is_staff",
  items: [
    { value: null, text: "ALL" },
    { value: true, text: "スタッフ" },
    { value: false, text: "ユーザー" },
  ],
  value: null,
};

export default {
  setting,
  headers,
  searchSelect,
  searchSubSelect,
};
