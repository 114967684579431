import { requiredRules, emailRules } from "@/utils/ruleUtility.js";

const setting = {
  originalTitle: "アカウント変更",
  apiAddress: "/auth/users/set_email/",
};

const forms = [
  {
    column: "new_email",
    label: "新しいE-mail",
    type: "text",
    rules: [requiredRules, emailRules],
  },
  {
    column: "current_password",
    label: "現在のパスワード",
    type: "password",
    rules: [requiredRules],
    append: false,
  },
];

export default {
  setting,
  forms,
};
