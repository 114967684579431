import { getMonthObject, getDateObject } from "@/utils/utilities.js";
import {
  requiredRules,
  dateRules,
  dateMonthRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "インセンティブアジャスト",
  apiAddress: "/paymentadjustment/",
  listSortBy: ["payment_adjustment_date", "user_reference___email"],
  listSortDesc: [false, false],
  isCsvImport: true,
};

const forms = [
  {
    column: "brother",
    label: "ブラザー",
    type: "selectBrother",
    items: [],
    rules: [],
    exportColumn: "brother_reference___brother_code",
  },
  {
    column: "payment_adjustment_date",
    label: "対象月",
    type: "dateMonthFirst",
    menu: false,
    default: getMonthObject(),
    rules: [requiredRules, dateMonthRules],
    cols: 6,
    importRules: [dateRules],
  },
  {
    column: "payment_adjustment_name",
    label: "調整名",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "payment_adjustment_charge",
    label: "調整金額",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
  {
    column: "payment_adjustment_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
    // 編集ハンドリング用（is_lockを指定するとModelListにてロックを自動判別）
    handleKey: ["is_lock"],
  },
  {
    text: "調整コード",
    value: "payment_adjustment_code",
  },
  {
    text: "対象月",
    value: "payment_adjustment_date",
    align: "center",
  },
  {
    text: "ブラザーコード",
    value: "brother_reference___brother_code",
  },
  {
    text: "ブラザー名",
    value: "brother_reference___brother_name",
  },
  {
    text: "調整名",
    value: "payment_adjustment_name",
  },
  {
    text: "対象金額",
    value: "payment_adjustment_charge",
    type: "number",
    align: "end",
  },
  {
    text: "コメント",
    value: "payment_adjustment_comment",
  },
];

const searchDateFrom = {
  label: "対象月（以降）",
  column: "payment_adjustment_date",
  menu: false,
  default: getDateObject("first", -12),
  formula: "gte", // 以上
};

export default {
  setting,
  forms,
  headers,
  searchDateFrom,
};
