<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col>
        <v-card width="600px" rounded="lg" :flat="true" :class="'pa-5'">
          <v-card-title
            v-if="isTitle"
            class="mygenText--text text-h6 font-weight-bold"
          >
            {{ cardTitle }}
          </v-card-title>
          <v-card-text>
            <div v-for="(msg, key) in cardComment" :key="'comment-span-' + key">
              {{ msg }}
            </div>
          </v-card-text>
          <v-card-text>
            <v-form ref="formModel">
              <v-container>
                <v-row>
                  <v-col
                    v-for="(form, key) in forms"
                    :key="key + '-' + formKey"
                    cols="12"
                    :sm="form.cols ? form.cols : 12"
                  >
                    <FormControls :propsMode="editMode" :propsForm="form" />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-text>
            <v-container>
              <v-row>
                <v-spacer />
                <v-col cols="12" sm="6" v-if="editMode === 'renew'">
                  <ButtonCommon
                    propsLabel="変更"
                    :propsClick="saveData"
                    :propsDisabled="buttonDisabled"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    editMode === 'post' ||
                    editMode === 'put' ||
                    editMode === 'patch'
                  "
                >
                  <ButtonCommon
                    propsLabel="登録"
                    :propsClick="saveData"
                    :propsDisabled="buttonDisabled"
                  />
                </v-col>
                <v-col cols="12" sm="6" v-if="editMode === 'delete'">
                  <ButtonCommon propsLabel="削除" :propsClick="deleteData" />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    editMode === 'readonly' ||
                    editMode === 'post' ||
                    editMode === 'put' ||
                    editMode === 'patch' ||
                    editMode === 'delete'
                  "
                >
                  <ButtonCommon
                    propsColor="secondary"
                    :propsLabel="formCancelButton"
                    :propsOutlined="true"
                    :propsClick="clickCancel"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />

      <OverlayProgress v-if="progress" />
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router/composables";
import {
  convertKanaHalfToFull,
  createKey,
  convertMonthToDateFirst,
  convertMonthToDateEnd,
  checkEqual,
  getToday,
} from "@/utils/utilities.js";
import {
  getFormObject,
  settingDictionaryForms,
  setApiToFormsData,
} from "@/utils/formUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";

export default defineComponent({
  name: "ModelForm",

  components: {
    FormControls,
    ButtonCommon,
    OverlayProgress,
  },

  props: {
    propsFormMode: {
      type: String,
      required: true,
    },
    propsFormType: {
      type: String,
      required: true,
    },
    propsFormDialog: {
      type: Boolean,
      default: false,
    },
    propsIsTitle: {
      type: Boolean,
      default: true,
    },
    propsStoreId: {
      type: String,
      default: "",
    },
    propsEditId: {
      type: String,
      default: "",
    },
    propsFixedValue: {
      type: String,
      default: "",
    },
  },

  emits: ["cancel", "close"],

  setup(props, { emit }) {
    const editMode = ref(props.propsFormMode);
    const formType = ref(props.propsFormType);
    const formDialog = ref(props.propsFormDialog);
    const isTitle = ref(props.propsIsTitle);
    const fixedValue = ref(props.propsFixedValue);
    const setting = ref({});

    const isOverlayProgress = computed(() => {
      if (formObject.setting.isOverlayProgress) {
        return true;
      }
      return false;
    });
    const formObject = getFormObject(formType.value);
    const forms = ref(settingDictionaryForms(formObject.forms));
    for (const key in forms.value) {
      forms.value[key].value = "";
      forms.value[key].menu = false;
      if (forms.value[key].type === "password") {
        forms.value[key].append = false;
      }
      if (forms.value[key].default) {
        forms.value[key].value = forms.value[key].default;
      }
    }
    const formModel = ref(false);

    const cardTitle = computed(() => {
      if (formObject.setting.originalTitle) {
        return formObject.setting.originalTitle;
      }
      return formObject.setting.title + "情報";
    });
    const cardComment = computed(() => {
      if (formObject.setting.formComment !== undefined) {
        return formObject.setting.formComment;
      }
      return "";
    });
    const formCancelButton = computed(() => {
      return editMode.value === "readonly" ? "閉じる" : "キャンセル";
    });
    const editId = computed(() => {
      if (props.propsStoreId === "customerId") {
        // お客様情報更新用
        return store.state.auth.customerId;
      }
      return props.propsEditId;
    });

    const localEditId = ref(editId.value);
    const isCreated = computed(() => {
      if (editMode.value === "renew") {
        return false;
      }
      return (
        localEditId.value !== undefined &&
        localEditId.value !== "" &&
        localEditId.value !== null
      );
    });

    const progress = ref(false);
    const buttonDisabled = ref(false);
    const formKey = ref(createKey());

    const dataDisplay = async (res) => {
      if (res) {
        // apiセレクタのitemsがセットされるタイミングまで遅延処理（nextTickが効いていない）
        for (const key in forms.value) {
          const type = forms.value[key].type;
          let items = forms.value[key].items;
          if (type.indexOf("select") === 0) {
            if (items.length === 0) {
              const sleep = (waitTime) =>
                new Promise((resolve) => setTimeout(resolve, waitTime));
              let isCondition = true;
              let count = 0;
              while (isCondition) {
                items = forms.value[key].items;
                // セレクタアイテムがセットされるか5secまで遅延
                if (items.length > 0 || count > 5) {
                  isCondition = false;
                }
                // 1sec遅延処理
                await sleep(1);
                count++;
              }
            }
          }
        }

        // apiで取得したデータをセットする
        const resData = setApiToFormsData(res, forms.value);
        forms.value = resData["forms"];
      }
    };

    const loadData = () => {
      if (!isCreated.value) {
        return;
      }
      nextTick(() => {
        // api:データ取得
        api({
          method: "get",
          url: formObject.setting.apiAddress + localEditId.value + "/",
        }).then((response) => {
          dataDisplay(response.data);
        });
      });
    };

    /**
     * キャンセルボタン押下
     */
    const clickCancel = () => {
      if (formDialog.value) {
        // ダイアログ
        emit("cancel");
      }
    };

    /**
     * データ登録処理
     */
    const saveData = () => {
      // form バリデーション
      const validResult = formModel.value.validate();
      if (validResult) {
        // パスワード変更専用バリデーション
        if (formType.value === "setpassword") {
          if (
            forms.value.currentPassword.value === forms.value.newPassword.value
          ) {
            store.dispatch("message/setErrorMessage", {
              message: "現在のパスワードと新しいパスワードが同じです",
            });
            return false;
          }
          if (
            forms.value.newPassword.value !== forms.value.reNewPassword.value
          ) {
            store.dispatch("message/setErrorMessage", {
              message: "新しいパスワードと確認用パスワードが異なります",
            });
            return false;
          }
        }

        // オーバーレイプログレス表示
        if (isOverlayProgress.value) {
          progress.value = true;
        }
        buttonDisabled.value = true;
        // apiで渡すデータを生成
        const formData = {};
        for (const key in forms.value) {
          let columnName = forms.value[key].column;
          if (columnName !== undefined && columnName.indexOf("___") > -1) {
            const colList = columnName.split("___");
            for (const key of colList) {
              columnName = key;
            }
          }
          // スペーサーはcontinue
          if (columnName === "") {
            continue;
          }

          if (forms.value[key].type.indexOf("select") === 0) {
            // セレクタの値補間
            if (forms.value[key].value === null) {
              formData[columnName] = null;
            } else {
              formData[columnName] = forms.value[key].value.value;
            }
          } else if (forms.value[key].type.indexOf("dateMonthFirst") === 0) {
            // 月度選択の値補間
            if (forms.value[key].value === null) {
              formData[columnName] = null;
            } else {
              formData[columnName] = convertMonthToDateFirst(
                forms.value[key].value
              );
            }
          } else if (forms.value[key].type.indexOf("dateMonthEnd") === 0) {
            // 月度選択の値補間
            if (forms.value[key].value === null) {
              formData[columnName] = null;
            } else {
              formData[columnName] = convertMonthToDateEnd(
                forms.value[key].value
              );
            }
          } else if (forms.value[key].type === "date") {
            // 日付選択の値補間
            if (
              forms.value[key].value === null ||
              forms.value[key].value === ""
            ) {
              formData[columnName] = null;
            } else {
              formData[columnName] = forms.value[key].value;
            }
          } else {
            if (
              (formType.value === "customer" ||
                formType.value === "invoiceaddress") &&
              forms.value[key].type === "text"
            ) {
              // 半角カナから全角カナへ変換
              formData[columnName] = convertKanaHalfToFull(
                (forms.value[key].value + "").trim()
              );
            } else {
              formData[columnName] = forms.value[key].value;
            }
          }
        }
        // 固定値追加
        if (formObject.postFixedValue) {
          const fixedObject = formObject.postFixedValue;
          for (const key in fixedObject) {
            let value = null;
            let isCheck = true;
            if (fixedObject[key]["equalRules"]) {
              isCheck = checkEqual(
                formData[fixedObject[key]["equalRules"][0]],
                fixedObject[key]["equalRules"][1]
              );
            }
            if (fixedObject[key]["equalOrRules"]) {
              isCheck =
                checkEqual(
                  formData[fixedObject[key]["equalOrRules"][0][0]],
                  fixedObject[key]["equalOrRules"][0][1]
                ) ||
                checkEqual(
                  formData[fixedObject[key]["equalOrRules"][1][0]],
                  fixedObject[key]["equalOrRules"][1][1]
                );
            }
            if (isCheck) {
              switch (fixedObject[key]["type"]) {
                case "today":
                  value = getToday();
                  break;
                case "user":
                  // storeから取得
                  value = store.state.auth.id;
                  break;
                case "customer":
                  // storeから取得
                  value = store.state.auth.customrId;
                  break;
                case "props":
                  value = fixedValue.value;
                  break;
                case "key":
                  value = formData[fixedObject[key]["key"]];
                  break;
                default:
                  value = fixedObject[key]["value"];
                  break;
              }
            }
            formData[fixedObject[key]["column"]] = value;
          }
        }

        let method = "get";
        if (
          editMode.value === "post" ||
          editMode.value === "put" ||
          editMode.value === "patch"
        ) {
          method = editMode.value;
        } else if (editMode.value === "renew") {
          method = "post";
        }

        // api:データ登録
        api({
          method: method,
          url: isCreated.value
            ? formObject.setting.apiAddress + localEditId.value + "/"
            : formObject.setting.apiAddress,
          data: formData,
        })
          .then((response) => {
            const message =
              editMode.value === "renew" || editMode.value === "put"
                ? "変更しました"
                : isCreated.value
                ? "更新しました"
                : "登録しました";

            // 登録後処理
            if (formDialog.value) {
              // ダイアログ
              emit("close", message);
            } else {
              dataDisplay(response.data);
              progress.value = false;
              buttonDisabled.value = false;

              emit("update", message);
            }
          })
          .catch(function (error) {
            progress.value = false;
            buttonDisabled.value = false;
            return error.response;
          });
      }
    };

    /**
     * データ削除処理
     */
    const deleteData = () => {
      // form バリデーション
      const validResult = formModel.value.validate();
      if (validResult) {
        if (isCreated.value) {
          // api:削除
          api({
            method: "delete",
            url: formObject.setting.apiAddress + localEditId.value + "/",
          }).then((response) => {
            localEditId.value = "";
            const message = "削除しました";
            // 削除後処理
            if (formDialog.value) {
              // ダイアログ
              emit("close", message);
            } else {
              store.dispatch("message/setInfoMessage", {
                message: message,
              });
            }
            return response;
          });
        } else {
          store.dispatch("message/setErrorMessage", {
            message: "削除できませんでした",
          });
        }
      }
    };

    onBeforeRouteUpdate(async (to, from, next) => {
      next();
      // 遷移後処理
      if (to.query !== from.query) {
        // 再描画処理
        loadData();
      }
    });

    onMounted(() => {
      nextTick(() => {
        // ビュー全体がレンダリングされた後にのみ実行されるコード
        if (editMode.value !== "post" && editMode.value !== "renew") {
          loadData();
        }
      });
    });

    return {
      formType,
      formDialog,
      isTitle,
      fixedValue,
      editMode,
      localEditId,
      cardTitle,
      cardComment,
      setting,
      forms,
      progress,
      buttonDisabled,
      formModel,
      formKey,
      formObject,
      formCancelButton,
      editId,
      isCreated,
      clickCancel,
      saveData,
      deleteData,
    };
  },
});
</script>

<style>
.theme--light.v-input--is-disabled input,
.theme--light.v-select .v-select__selection--disabled {
  color: #424242;
  font-weight: bold;
}
</style>
