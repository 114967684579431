import { selectedRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "契約解約",
  originalTitle: "契約解約登録",
  apiAddress: "/contractsitecancel/",
  formComment: ["*** 解約すると元に戻すことはできません ***"],
};

const forms = [
  {
    column: "contract_site_cancel",
    label: "契約を解約済みにする",
    type: "checkbox",
    rules: [selectedRules],
  },
];

const postFixedValue = [
  {
    column: "contract_class",
    value: 80,
  },
];

export default {
  setting,
  forms,
  postFixedValue,
};
