<template>
  <div :class="$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'">
    <v-container :class="$vuetify.breakpoint.xs ? '' : 'px-16 py-10'">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-icon color="mygenText--text" class="mb-2"> bi-megaphone </v-icon>
          <SpanHeadline :propsText="'GENからのお知らせ'" />
        </v-col>
        <v-col cols="12">
          <CardInformation
            propsLabel="GENからのお知らせ"
            :propsInformationData="informationData"
            :key="'newArrival-' + formKey"
          />
        </v-col>
      </v-row>
      <v-row class="pt-7">
        <v-col cols="12" class="pb-0">
          <v-icon color="mygenText--text" class="mb-2"> bi-hdd-stack </v-icon>
          <SpanHeadline :propsText="'契約情報'" />
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-card flat rounded="lg" class="mygenCard pa-4">
                <v-card-title class="primary--text font-weight-bold">
                  契約情報の確認
                </v-card-title>
                <v-card-text class="mygenText--text">
                  <div style="min-height: 40px">
                    ご契約いただいているサービスやオプション等の契約情報を表示します。新規申込や変更の手続きを行うことができます。
                  </div>
                </v-card-text>
                <v-card-actions>
                  <ButtonCommon
                    :key="'btn-contract-' + formKey"
                    :propsLabel="'契約中のサービス一覧'"
                    :propsClick="clickBtnContract"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card flat rounded="lg" class="mygenCard pa-4">
                <v-card-title class="primary--text font-weight-bold">
                  GEN COINの確認
                </v-card-title>
                <v-card-text class="mygenText--text">
                  <div style="min-height: 40px">
                    ご購入いただいたGEN
                    COINの照会情報を表示します。新規購入の手続きを行うことができます。
                  </div>
                </v-card-text>
                <v-card-actions>
                  <ButtonCommon
                    :key="'btn-coin-' + formKey"
                    :propsLabel="'GEN-COINの照会'"
                    :propsClick="clickBtnCoin"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="pt-7">
        <v-col cols="12" class="pb-0">
          <v-icon color="mygenText--text" class="mb-2"> bi-cash </v-icon>
          <SpanHeadline :propsText="'請求情報'" />
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-card flat rounded="lg" class="mygenCard pa-4">
                <v-card-title class="primary--text font-weight-bold">
                  請求内容の確認
                </v-card-title>
                <v-card-text class="mygenText--text">
                  <div style="min-height: 40px">
                    発行済の請求書を表示します。
                  </div>
                </v-card-text>
                <v-card-actions>
                  <ButtonCommon
                    :key="'btn-invoice-' + formKey"
                    :propsLabel="'請求書の確認'"
                    :propsClick="clickBtnInvoice"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-spacer />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div
      style="box-sizing: border-box; position: fixed"
      :style="
        $vuetify.breakpoint.xs
          ? 'bottom: 40px; left: 50%; transform: translateX(-50%);'
          : 'bottom: 40px; right: 40px'
      "
    >
      <v-btn
        color="primary"
        x-large
        dark
        class="white--text font-weight-bold px-16"
        elevation="14"
        @click="clickBtnContract"
      >
        新規申込・見積はこちらから
        <v-icon class="ml-2"> mdi-arrow-right-bold-circle-outline </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router/composables";
import store from "@/store";
import api from "@/services/api";
import { createKey } from "@/utils/utilities.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";
import CardInformation from "@/components/molecules/CardInformation.vue";

export default defineComponent({
  name: "DivHomeUser",

  components: { ButtonCommon, SpanHeadline, CardInformation },

  setup() {
    const router = useRouter();

    const informationData = ref([]);
    const progress = ref(false);
    const formKey = ref(createKey());

    const dataDisplay = (res) => {
      // apiで取得したデータをセットする
      const listData = [];
      if (res) {
        for (const row of res) {
          const line = {};
          line["date"] = row.information_date;
          line["type"] = row.information_type;
          line["title"] = row.information_title;
          line["link"] = row.information_link;
          if (row.information_display === 1) {
            listData.push(line);
          }
        }
      }
      informationData.value = listData;
      formKey.value = createKey();
    };

    const loadData = () => {
      // api:申込情報取得
      api({
        method: "get",
        url: "/information/?information_display__gte=1",
      }).then((response) => {
        dataDisplay(response.data);
      });
    };

    const clickBtnContract = () => {
      router.push({ path: "/service-reference" });
    };
    const clickBtnCoin = () => {
      router.push({ path: "/coin-reference" });
    };
    const clickBtnInvoice = () => {
      router.push({ path: "/invoice-reference" });
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // スクロール位置調整
    window.scroll(0, 0);

    // データ取得
    loadData();

    return {
      informationData,
      progress,
      formKey,

      clickBtnContract,
      clickBtnCoin,
      clickBtnInvoice,
    };
  },
});
</script>
