import { checkDate } from "@/utils/utilities.js";

const requiredRules = (v) => {
  return !!v || "入力必須項目です";
};
const equiredOrZeroRules = (v) => {
  return v === 0 || !!v || "入力必須項目です";
};
const requiredOrBlankRules = (v) => {
  return !!v || v === null || "入力必須項目です";
};
const selectedRules = (v) => {
  return !!v || "選択必須項目です";
};
const selectedOrZeroRules = (v) => {
  return v === 0 || !!v || "選択必須項目です";
};
const selectedOrBlankRules = (v) => {
  return !!v || v === null || "選択必須項目です";
};
const selectedNotZeroRules = (v) => {
  const value = typeof v === "object" ? v.value : v;
  if (v === undefined || value === 0 || value === "0") {
    return "選択必須項目です";
  }
  return true;
};
const selectedNotNullRules = (v) => {
  const value = typeof v === "object" ? v.value : v;
  if (v === undefined || value === null) {
    return "選択必須項目です";
  }
  return true;
};
const emailRules = (v) => {
  return /.+@.+\..+/.test(v) || "有効なE-mailアドレスを入力してください";
};
const zipCodeRules = (v) => {
  return /[0-9]{7}/.test(v) || "ハイフンなし7桁の郵便番号を入力してください";
};
const telRules = (v) => {
  return /[0-9]{10,11}/.test(v) || "ハイフンなしの電話番号を入力してください";
};
const dateRules = (v) => {
  if (v === null) return "有効な日付を指定してください";
  if (String(v).split("-").length !== 3) return "有効な日付を指定してください";
  if (!checkDate(v)) {
    return "有効な日付を指定してください";
  }
  return true;
};
const dateOrBlankRules = (v) => {
  if (v === null || v === "") return true;
  if (String(v).split("-").length !== 3) return "有効な日付を指定してください";
  if (!checkDate(v)) {
    return "有効な日付を指定してください";
  }
  return true;
};
const dateMonthRules = (v) => {
  if (v === null) return "有効な月を指定してください";
  if (String(v).split("-").length !== 2) return "有効な月を指定してください";
  var date = new Date(v.split("-")[0], v.split("-")[1] - 1, 1);
  if (
    String(v).split("-")[0] !== String(date.getFullYear()) ||
    String(v).split("-")[1] !== ("0" + (date.getMonth() + 1)).slice(-2)
  ) {
    return "有効な月を指定してください";
  }
  return true;
};
const dateMonthOrBlankRules = (v) => {
  if (v === null || v === "") return true;
  if (String(v).split("-").length !== 2) return "有効な月を指定してください";
  var date = new Date(v.split("-")[0], v.split("-")[1] - 1, 1);
  if (
    String(v).split("-")[0] !== String(date.getFullYear()) ||
    String(v).split("-")[1] !== ("0" + (date.getMonth() + 1)).slice(-2)
  ) {
    return "有効な月を指定してください";
  }
  return true;
};
const numericIntegerRules = (v) => {
  // 整数入力のチェック
  if (!/[0-9]/.test(v)) return "数値を入力してください";
  if (Number.isNaN(v)) return "数値を入力してください";
  // 型変換
  v = Number(v);
  if (!(v > 0)) return "有効な数値（正の整数）を指定してください";
  if (!Number.isInteger(v)) return "整数を指定してください";
  return true;
};
const numericIntegerOrZeroRules = (v) => {
  // 整数入力のチェック
  if (!/[0-9]/.test(v)) return "数値を入力してください";
  if (Number.isNaN(v)) return "数値を入力してください";
  // 型変換
  v = Number(v);
  if (!(v >= 0)) return "有効な数値（0以上の整数）を指定してください";
  if (!Number.isInteger(v)) return "整数を指定してください";
  return true;
};
const numericIntegerOrBlankRules = (v) => {
  // 空欄でなければ整数入力が期待される
  if (v === null || v === "") return true;
  if (!/[0-9]/.test(v)) return "数値を入力してください";
  if (Number.isNaN(v)) return "数値を入力してください";
  // 型変換
  v = Number(v);
  if (!(v > 0)) return "有効な数値（正の整数）を指定してください";
  if (!Number.isInteger(v)) return "整数を指定してください";
  return true;
};
const numericIntegerOrBlankOrZeroRules = (v) => {
  // 空欄でなければ整数入力が期待される
  if (v === null || v === "") return true;
  if (!/[0-9]/.test(v)) return "数値を入力してください";
  if (Number.isNaN(v)) return "数値を入力してください";
  // 型変換
  v = Number(v);
  if (!(v >= 0)) return "有効な数値（0以上の整数）を指定してください";
  if (!Number.isInteger(v)) return "整数を指定してください";
  return true;
};
const bankCodeRules = (v) => {
  return /[0-9]{4}/.test(v) || "4桁の銀行コードを入力してください";
};
const bankCodeOrBlankRules = (v) => {
  if (v === null || v === "") return true;
  return /[0-9]{4}/.test(v) || "4桁の銀行コードを入力してください";
};
const branchCodeRules = (v) => {
  return /[0-9]{3}/.test(v) || "3桁の支店コードを入力してください";
};
const branchCodeOrBlankRules = (v) => {
  if (v === null || v === "") return true;
  return /[0-9]{3}/.test(v) || "3桁の支店コードを入力してください";
};
const numericOrBlankRules = (v) => {
  if (v === null || v === "") return true;
  if (!/[0-9]/.test(v)) return "数値を入力してください";
  if (Number.isNaN(v)) return "数値を入力してください";
  return true;
};
const kanaHalfRules = (v) => {
  // return /^[ｦ-ﾟ.a-zA-Z()]+$/.test(v) || "半角文字を入力してください";
  return /^[ｦ-ﾟ･\x20-\x7e]*$/.test(v) || "半角文字を入力してください";
};
const kanaHalfOrBlankRules = (v) => {
  if (v === null || v === "") return true;
  // return /^[ｦ-ﾟ.a-zA-Z()]+$/.test(v) || "半角文字を入力してください";
  return /^[ｦ-ﾟ･\x20-\x7e]*$/.test(v) || "半角文字を入力してください";
};
const charLength100Rules = (v) => {
  if (v === undefined || v === null || v === "") return true;
  return v.length <= 100 || "100文字以内で入力してください";
};
const charLength200Rules = (v) => {
  if (v === undefined || v === null || v === "") return true;
  return v.length <= 200 || "200文字以内で入力してください";
};
const charLength500Rules = (v) => {
  if (v === undefined || v === null || v === "") return true;
  return v.length <= 500 || "500文字以内で入力してください";
};
const passwordLengthRules = (v) => {
  return v.length >= 8 || "8文字以上を入力してください";
};
const passwordHalfRules = (v) => {
  return (
    /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/.test(v) || "半角英数字記号を入力してください"
  );
};
const requiredCheckboxRules = (v) => {
  return !!v || v === null || "必須項目です";
};

// 関数をエクスポート
export {
  requiredRules,
  equiredOrZeroRules,
  requiredOrBlankRules,
  selectedRules,
  selectedOrZeroRules,
  selectedOrBlankRules,
  selectedNotZeroRules,
  selectedNotNullRules,
  emailRules,
  zipCodeRules,
  telRules,
  dateRules,
  dateOrBlankRules,
  dateMonthRules,
  dateMonthOrBlankRules,
  numericIntegerRules,
  numericIntegerOrZeroRules,
  numericIntegerOrBlankRules,
  numericIntegerOrBlankOrZeroRules,
  bankCodeRules,
  bankCodeOrBlankRules,
  branchCodeRules,
  branchCodeOrBlankRules,
  numericOrBlankRules,
  kanaHalfRules,
  kanaHalfOrBlankRules,
  charLength200Rules,
  charLength100Rules,
  charLength500Rules,
  passwordLengthRules,
  passwordHalfRules,
  requiredCheckboxRules,
};
