import {
  requiredRules,
  emailRules,
  passwordLengthRules,
  passwordHalfRules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "アカウント作成",
  buttonLabelSubmit: "アカウント作成",
  buttonLabelCancel: "キャンセル",
  buttonLabelSecond: "登録",
  secondMessage: ["以下のE-mailでアカウントを作成しますか？"],
  thirdMessage: [
    "メールを確認してください",
    "メールに記載されたリンクをクリックして、ユーザー登録を完了させてください。数分待ってもメールが届かない場合は「アクティベーションメールを再送信する」をクリックしてください。迷惑メールに振り分けられていないかもご確認ください。",
    "再送信を試みてもアクティベーションメールが届かない場合はお手数ですがGEN（代表電話：03-6416-1450）にお問い合わせください。",
  ],
  storeAddress: "signup/signup",
  isSignup: true,
  successMessage: "アクティベーションメールを送信しました",
};

const forms = [
  {
    column: "email",
    label: "E-mail",
    type: "text",
    rules: [requiredRules, emailRules],
    emit: "signupEmail",
  },
  {
    column: "password",
    label: "パスワード",
    type: "password",
    rules: [requiredRules, passwordHalfRules, passwordLengthRules],
    append: false,
    emit: "signupPassword",
    confirm: false,
    hint: "8文字以上のパスワードを設定してください",
  },
  {
    column: "re_password",
    label: "確認用パスワード",
    type: "password",
    rules: [requiredRules, passwordHalfRules, passwordLengthRules],
    append: false,
    emit: "signupRePassword",
    confirm: false,
  },
];

export default {
  setting,
  forms,
};
