import api from "@/services/api";

// item系配列からvalue値が含まれる辞書型配列を返す
const convertValueToDict = (arr, value) => {
  for (const key in arr) {
    if (arr[key]["value"] === value) {
      return arr[key];
    }
  }
  return {};
};

const getSelectName = [
  "order_type",
  "contract_class_status",
  "contract_flow_status",
  "contract_status",
  "contract_renewal_status",
  "order_status",
  "final_order_status",
  "customer_order_status",
  "customer_order_status_change",
  "cancel_order_status",
  "cancel_order_status_for_customer",
  "coin_order_status",
  "coin_order_status_for_customer",
  "management_coin_status",
  "information_display",
  "information_type",
  "incentive_class",
  "invoice_group_status",
  "payment_group_status",
  "support_type",
  "support_status",
  "management_coin_class",
  "is_transfer",
];

const getSelectObject = (name, type = "", value = "") => {
  let res = [];
  switch (name) {
    case "order_type":
      res = [
        {
          value: 10,
          text: "新規",
          color: "pink",
          variant: "outlined",
        },
        {
          value: 20,
          text: "変更",
          color: "primary",
          variant: "outlined",
        },
        {
          value: 21,
          text: "更新",
          color: "light-blue accent-4",
          variant: "outlined",
        },
        {
          value: 22,
          text: "更新",
          color: "light-blue accent-4",
          variant: "outlined",
        },
      ];
      break;

    case "contract_class_status":
      res = [
        {
          value: 80,
          text: "解約",
          color: "error",
          variant: "elevated",
        },
        {
          value: 1000,
          text: "新規申込中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 1010,
          text: "新規申込対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 1020,
          text: "新規申込対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 1050,
          text: "稼働中",
          color: "green",
          variant: "elevated",
        },
        {
          value: 2000,
          text: "変更申込中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 2010,
          text: "変更申込対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 2020,
          text: "変更申込対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 2050,
          text: "稼働中",
          color: "green",
          variant: "elevated",
        },
        {
          value: 2100,
          text: "更新対応中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 2110,
          text: "更新対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 2120,
          text: "更新対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 2150,
          text: "稼働中",
          color: "green",
          variant: "elevated",
        },
        {
          value: 2200,
          text: "更新対応中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 2210,
          text: "更新対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 2220,
          text: "更新対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 2250,
          text: "稼働中",
          color: "green",
          variant: "elevated",
        },
        {
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        },
      ];
      break;

    case "contract_flow_status":
      res = [
        {
          value: 10,
          text: "新規",
          color: "pink",
          variant: "outlined",
        },
        {
          value: 20,
          text: "変更",
          color: "primary",
          variant: "outlined",
        },
        {
          value: 21,
          text: "一括更新",
          color: "light-blue accent-4",
          variant: "outlined",
        },
        {
          value: 22,
          text: "個別更新",
          color: "light-blue accent-4",
          variant: "outlined",
        },
        {
          value: 70,
          text: "履歴",
          color: "grey",
          variant: "elevated",
        },
        {
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        },
      ];
      break;

    case "contract_status":
      if (
        type === "all" ||
        type === "reception" ||
        type === "cancelreception"
      ) {
        res.push({
          value: 0,
          text: "未対応",
          color: "error",
          variant: "elevated",
        });
      }
      if (type === "renewal") {
        res.push({
          value: 0,
          text: "未公開",
          color: "error",
          variant: "outlined",
        });
      }
      if (
        type === "all" ||
        type === "reception" ||
        type === "setting" ||
        type === "cancelreception" ||
        type === "cancelsetting"
      ) {
        res.push({
          value: 10,
          text: "受付",
          color: "info",
          variant: "elevated",
        });
      }
      if (
        type === "all" ||
        type === "setting" ||
        type === "release" ||
        type === "contract" ||
        type === "cancelsetting" ||
        type === "cancelrelease"
      ) {
        res.push({
          value: 20,
          text: "対応済",
          color: "accent",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 50,
          text: "稼働",
          color: "green",
          variant: "elevated",
        });
      } else if (
        type === "release" ||
        type === "contract" ||
        type === "cancelrelease" ||
        type === "renewal"
      ) {
        res.push({
          value: 50,
          text: "公開",
          color: "green",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 70,
          text: "履歴",
          color: "purple",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 80,
          text: "解約",
          color: "error",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        });
      }
      break;

    case "order_status":
    case "final_order_status":
      res = [
        {
          value: 0,
          text: "受付中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 10,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 20,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 50,
          text: "稼働中",
          color: "green",
          variant: "elevated",
        },
        {
          value: 70,
          text: "履歴",
          color: "grey",
          variant: "outlined",
        },
        {
          value: 80,
          text: "解約",
          color: "error",
          variant: "elevated",
        },
        {
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        },
      ];
      break;

    case "customer_order_status":
      res = [
        {
          value: 0,
          text: "新規申込中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 10,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 20,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 50,
          text: "稼働中",
          color: "green",
          variant: "elevated",
        },
        {
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        },
      ];
      break;

    case "customer_order_status_change":
      res = [
        {
          value: 0,
          text: "変更申込中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 10,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 20,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 50,
          text: "受付完了",
          color: "green",
          variant: "outlined",
        },
        {
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        },
      ];
      break;

    case "cancel_order_status":
      if (type === "all" || type === "cancelreception") {
        res.push({
          value: 0,
          text: "未対応",
          color: "error",
          variant: "elevated",
        });
      }
      if (
        type === "all" ||
        type === "cancelreception" ||
        type === "cancelsetting"
      ) {
        res.push({
          value: 10,
          text: "受付",
          color: "info",
          variant: "elevated",
        });
      }
      if (
        type === "all" ||
        type === "cancelsetting" ||
        type === "cancelrelease"
      ) {
        res.push({
          value: 20,
          text: "対応済",
          color: "accent",
          variant: "elevated",
        });
      }
      if (type === "all" || type === "cancelrelease") {
        res.push({
          value: 50,
          text: "解約済",
          color: "green",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 70,
          text: "履歴",
          color: "purple",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 80,
          text: "解約",
          color: "error",
          variant: "elevated",
        });
      }
      if (type === "all") {
        res.push({
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        });
      }
      break;

    case "cancel_order_status_for_customer":
      res = [
        {
          value: 0,
          text: "解約申込中",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 10,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 20,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 50,
          text: "解約受付",
          color: "error",
          variant: "elevated",
        },
        {
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        },
      ];
      break;

    case "coin_order_status":
    case "coin_order_status_for_customer":
    case "management_coin_status":
      if (type === "all" || type === "charge" || type === "transfer") {
        res.push({
          value: 0,
          text: "未対応",
          color: "errorOutline",
          variant: "outlined",
        });
      }
      if (type === "all") {
        res.push({
          value: 10,
          text: "契約受付",
          color: "info",
          variant: "elevated",
        });
        res.push({
          value: 20,
          text: "契約対応済",
          color: "accent",
          variant: "elevated",
        });
      }
      if (type === "all" || type === "charge" || type === "transfer") {
        res.push({
          value: 50,
          text: "確定",
          color: "green",
          variant: "elevated",
        });
      }
      if (type === "all" && type !== "transfer") {
        res.push({
          value: 90,
          text: "取消",
          color: "grey",
          variant: "elevated",
        });
      }
      break;

    case "information_display":
      res = [
        {
          value: 0,
          text: "非表示",
          color: "grey",
          variant: "elevated",
        },
        {
          value: 1,
          text: "表示",
          color: "green",
          variant: "elevated",
        },
      ];
      break;

    case "information_type":
      res = [
        {
          value: 0,
          text: "ご案内",
          color: "accent",
          variant: "elevated",
        },
        {
          value: 10,
          text: "メンテナンス",
          color: "info",
          variant: "elevated",
        },
        {
          value: 20,
          text: "障害",
          color: "errorOutline",
          variant: "outlined",
        },
      ];
      break;

    case "incentive_class":
      res = [
        {
          value: 0,
          text: "Non",
          color: "grey",
          variant: "outlined",
        },
        {
          value: 10,
          text: "初回",
          color: "accentOutline",
          variant: "outlined",
        },
        {
          value: 20,
          text: "月度",
          color: "green",
          variant: "outlined",
        },
      ];
      break;

    case "invoice_group_status":
    case "payment_group_status":
      res = [
        {
          value: 0,
          text: "未公開",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 50,
          text: "公開",
          color: "green",
          variant: "elevated",
        },
      ];
      break;

    case "support_type":
      res = [
        {
          value: 10,
          text: "メール回答希望",
          color: "accentOutline",
          variant: "outlined",
        },
        {
          value: 20,
          text: "電話回答希望",
          color: "accent",
          variant: "elevated",
        },
      ];
      break;

    case "support_status":
      res = [
        {
          value: 0,
          text: "未対応",
          color: "errorOutline",
          variant: "outlined",
        },
        {
          value: 10,
          text: "対応中",
          color: "info",
          variant: "elevated",
        },
        {
          value: 50,
          text: "終息",
          color: "green",
          variant: "elevated",
        },
      ];
      break;

    case "management_coin_class":
      res = [
        {
          value: 0,
          text: "GEN",
          color: "accentOutline",
          variant: "outlined",
        },
        {
          value: 10,
          text: "BROTHER",
          color: "green",
          variant: "outlined",
        },
      ];
      break;

    case "is_transfer":
      res = [
        {
          value: 1,
          text: "継承済",
          color: "green",
          variant: "outlined",
        },
        {
          value: 0,
          text: "未継承",
          color: "infoOutline",
          variant: "outlined",
        },
      ];
      break;

    default:
      break;
  }
  if (value !== "") {
    res = convertValueToDict(res, value);
  }
  return res;
};

// 表示セレクタ
const setDisplayList = (value = "") => {
  let res = [
    {
      value: 1,
      text: "表示",
    },
    {
      value: 0,
      text: "非表示",
    },
  ];
  if (value !== "") {
    res = convertValueToDict(res, value);
  }
  return res;
};

// コインセレクタ
const setCoinType = () => {
  return [
    {
      value: 0,
      text: "チャージ",
    },
    {
      value: 10,
      text: "テクニカルサポート利用",
    },
    {
      value: 20,
      text: "スクール利用",
    },
    {
      value: 30,
      text: "安心パック利用",
    },
    {
      value: 40,
      text: "導入支援 ",
    },
    {
      value: 50,
      text: "デモセミナ同行 ",
    },
    {
      value: 60,
      text: "提案採用 ",
    },
    {
      value: 70,
      text: "バグ報告 ",
    },
    {
      value: 90,
      text: "その他 ",
    },
  ];
};

// 口座種別セレクタ
const setDepositType = () => {
  return [
    {
      value: 0,
      text: "未選択",
    },
    {
      value: 1,
      text: "普通",
    },
    {
      value: 2,
      text: "当座",
    },
  ];
};

// 請求単位セレクタ
const setInvoiceType = () => {
  return [
    {
      value: 0,
      text: "月単位",
    },
    {
      value: 10,
      text: "年単位",
    },
  ];
};

// 支払方法セレクタ
const setPaymentMethod = () => {
  return [
    {
      value: 0,
      text: "未選択",
    },
    {
      value: 10,
      text: "銀行振込",
    },
    {
      value: 20,
      text: "口座振替",
    },
    {
      value: 30,
      text: "クレジット",
    },
  ];
};

// 都道府県セレクタ
const setPrefectureList = () => {
  const items = [];
  const prefecture = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
    "その他",
  ];
  for (const col of prefecture) {
    const data = {
      value: col,
      text: col,
    };
    items.push(data);
  }
  return items;
};
// アカウント変更セレクタ
const setAccountChangeDisplay = () => {
  return [
    {
      value: false,
      text: "禁止",
    },
    {
      value: true,
      text: "許可",
    },
  ];
};

// 税表示セレクタ
const setInvoiceTaxDisplay = () => {
  return [
    {
      value: false,
      text: "表示しない",
    },
    {
      value: true,
      text: "表示する",
    },
  ];
};

// 請求通知セレクタ
const setInvoiceNotification = () => {
  return [
    {
      value: false,
      text: "通知しない",
    },
    {
      value: true,
      text: "通知する",
    },
  ];
};

// クーポンセレクタ
const setCouponClass = () => {
  const items = [
    {
      value: -10,
      text: "初回",
    },
    {
      value: -20,
      text: "毎月",
    },
  ];
  for (let month = 1; month < 13; month++) {
    items.push({
      value: month,
      text: month + "ヵ月",
    });
  }
  return items;
};

// インセンティブタイプセレクタ
const setIncentiveType = () => {
  return [
    {
      value: 0,
      text: "月度",
    },
    {
      value: 10,
      text: "初回",
    },
    {
      value: 20,
      text: "変更",
    },
    {
      value: 30,
      text: "GEN-COIN",
    },
    {
      value: 40,
      text: "アジャスト",
    },
    {
      value: 50,
      text: "差額調整",
    },
  ];
};

// オプション区分セレクタ
const setOptionClass = () => {
  return [
    {
      value: 0,
      text: "ノーマル",
    },
    {
      value: 10,
      text: "アカウント系",
    },
  ];
};

// 税率セレクタ
const setTaxRate = (value = "") => {
  const taxRate = [0, 3, 5, 8, 10];
  let res = [];
  for (const key in taxRate) {
    const data = {
      value: taxRate[key],
      text: taxRate[key] + "%",
    };
    res.push(data);
  }
  if (value !== "") {
    res = convertValueToDict(res, value);
  }
  return res;
};

/**
 * セレクタ表示値取得
 */
const getSelectValue = (value, items, isValue = false) => {
  for (const row of items) {
    if (isValue) {
      // テキストと値が同一のセレクトパターン
      if (row.text === value) {
        return { text: row.text, value: row.text };
      }
    } else {
      if (row.value === value) {
        return { text: row.text, value: value };
      }
    }
  }
  return false;
};

/**
 * セレクタ表示テキスト取得
 */
const getSelectText = (value, items) => {
  for (const row of items) {
    if (row.value === value) {
      return row.text;
    }
  }
  return false;
};

/**
 * api:請求グループ：データ取得
 */
const getSelectInvoiceGroupData = (isLoggedIn) => {
  let items = [];
  if (isLoggedIn) {
    api({
      method: "get",
      url: "/invoicegroup/?invoice_group_status=0",
    }).then((response) => {
      const res = response.data;
      items = [{ text: "未選択", value: null }];
      for (const key in res) {
        items.push({
          text: res[key]["invoice_group_code"],
          value: res[key]["id"],
        });
      }
    });
  } else {
    items = [{ text: "Non", value: null }];
  }
  return items;
};

/**
 * api:ブラザー：データ取得
 */
const getSelectBrotherData = (isLoggedIn) => {
  let items = [];
  if (isLoggedIn) {
    api({
      method: "get",
      url: "/brother/",
    }).then((response) => {
      const res = response.data;
      items = [{ text: "未選択", value: -1 }];
      for (const key in res) {
        items.push({
          text: res[key]["brother_name"],
          value: res[key]["id"],
        });
      }
    });
  } else {
    items = [{ text: "Non", value: -1 }];
  }
  return items;
};

export {
  getSelectName,
  getSelectObject,
  setDisplayList,
  setCoinType,
  setDepositType,
  setInvoiceType,
  setPaymentMethod,
  setPrefectureList,
  setAccountChangeDisplay,
  setInvoiceTaxDisplay,
  setInvoiceNotification,
  setCouponClass,
  setIncentiveType,
  setOptionClass,
  setTaxRate,
  getSelectValue,
  getSelectText,
  getSelectInvoiceGroupData,
  getSelectBrotherData,
};
