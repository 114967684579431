var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[_c('div',{attrs:{"id":"auth-page"}},[_c('SnackbarMessage'),_c('v-container',{class:_vm.$vuetify.breakpoint.xs ? '' : 'mt-16'},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"8","md":"8","lg":"5","xl":"4"}},[_c('v-card',{ref:"form",attrs:{"elevation":_vm.$vuetify.breakpoint.xs ? '0' : '8'}},[_c('v-card',{staticClass:"px-10 pt-16",class:_vm.$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-b-0',attrs:{"color":"primary","dark":"","flat":"","height":"120"}},[_c('v-toolbar-title',[_vm._v("MyGEN")])],1),_c('v-card',{staticClass:"py-10",class:_vm.$vuetify.breakpoint.xs ? 'px-5' : 'px-10',attrs:{"flat":""}},[_c('router-view',{key:_vm.$route.fullPath,on:{"update":_vm.updateForm}}),_c('v-divider',{staticClass:"my-8"}),(this.$route.path.indexOf('/auth/login') === 0)?_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"primary--text small text-decoration-none",attrs:{"to":"/auth/reset"}},[_vm._v(" パスワードを忘れましたか？ ")])],1),_c('div',{staticClass:"text-center mt-3"},[_c('router-link',{staticClass:"primary--text small text-decoration-none",attrs:{"to":"/auth/signup"}},[_vm._v(" アカウントをお持ちでない方はこちら ")])],1)]):_vm._e(),(
                  this.$route.path.indexOf('/auth/signup') === 0 ||
                  this.$route.path.indexOf('/auth/resend') === 0 ||
                  this.$route.path === '/auth/reset' ||
                  this.$route.path.indexOf('/auth/resetpassword') === 0 ||
                  this.$route.path.indexOf('/auth/activation') === 0
                )?_c('v-card-text',[(this.$route.path.indexOf('/auth/signup') === 0)?_c('div',{staticClass:"text-center mb-3"},[_c('router-link',{staticClass:"primary--text small text-decoration-none",attrs:{"to":"/auth/resend"}},[_vm._v(" アクティベーションメールを再送信する ")])],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"primary--text small text-decoration-none",attrs:{"to":"/auth/login"}},[_vm._v(" ログインする ")])],1)]):_vm._e()],1)],1)],1),_c('v-spacer')],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }