<template>
  <v-expansion-panels v-model="panel" accordion hover multiple>
    <v-expansion-panel>
      <PanelHeader :propsLabel="'お客様情報'" />
      <v-expansion-panel-content>
        <ContainerCustomerInfo
          :key="'customer-' + formKey"
          :propsFormsFirst="formsFirst"
          :propsFormsSecond="formsSecond"
        />
        <ContainerCustomerBank
          v-if="isBrother"
          :key="'customer-bank-' + formKey"
          :propsCustomerData="customerData"
        />
        <ContainerCustomerSetting
          v-if="isStaff"
          :key="'customer-setting-' + formKey"
          :propsCustomerData="customerData"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="isStaff">
      <PanelHeader :propsLabel="'口座情報'" />
      <v-expansion-panel-content>
        <v-data-table :headers="headers" :items="items" hide-default-footer>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <PanelHeader :propsLabel="'契約情報'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'contract-info-' + customerId"
          :propsFormType="
            isBrother ? 'contractinformationbrother' : 'contractinformation'
          "
          propsFilterColumn="customer"
          :propsFilterId="customerId"
          :propsIsAddButton="false"
          :propsIsSearchBox="false"
          @update="closeDialog"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <PanelHeader :propsLabel="'GEN-COIN情報'" />
      <v-expansion-panel-content>
        <DivCoinInfo
          :key="'coin-' + customerId"
          :propsCustomerId="customerId"
          :propsIsCustomer="false"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="isStaff">
      <PanelHeader :propsLabel="'請求アジャスト'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'invoiceadjustment-' + customerId"
          propsFormType="invoiceadjustment"
          propsFilterColumn="customer"
          :propsFilterId="customerId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <PanelHeader :propsLabel="'請求情報'" />
      <v-expansion-panel-content>
        <TableInvoiceInfo
          :key="'invoice-' + customerId"
          :propsCustomerId="customerId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="isStaff && customerData.is_brother_account">
      <PanelHeader :propsLabel="'インセンティブアジャスト'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'paymentadjustment-' + customerId"
          propsFormType="paymentadjustment"
          propsFilterColumn="brother"
          :propsFilterId="accountBrotherId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="isStaff">
      <PanelHeader :propsLabel="'問い合わせ情報'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'support-' + customerId"
          propsFormType="supportinfo"
          propsFilterColumn="customer"
          :propsFilterId="customerId"
          :propsIsAddButton="false"
          :propsIsSearchBox="false"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="isStaff">
      <PanelHeader :propsLabel="'申込履歴'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'contracthistory-' + customerId"
          propsFormType="contracthistory"
          propsFilterColumn="customer"
          :propsFilterId="customerId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="isStaff">
      <PanelHeader :propsLabel="'ログ履歴'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'logoperation-' + customerId"
          propsFormType="logoperation"
          propsFilterColumn="customer"
          :propsFilterId="customerId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="mt-5" v-if="isBrother">
      <PanelHeader :propsLabel="'COIN利用登録'" />
      <v-expansion-panel-content>
        <ModelTable
          :key="'managementcoinbrother-' + customerId"
          propsFormType="managementcoinbrother"
          propsFilterColumn="customer"
          :propsFilterId="customerId"
          @cancel="cancelDialog"
          @update="closeDialog"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import store from "@/store";
import api from "@/services/api";
import { createKey } from "@/utils/utilities.js";
import {
  settingDictionaryForms,
  setResponseApplyFormsDict,
} from "@/utils/formUtility.js";
import { setDepositType, getSelectValue } from "@/utils/selectUtility.js";
import PanelHeader from "@/components/atoms/PanelHeader.vue";
import ContainerCustomerBank from "@/components/molecules/ContainerCustomerBank.vue";
import ContainerCustomerInfo from "@/components/molecules/ContainerCustomerInfo.vue";
import ContainerCustomerSetting from "@/components/molecules/ContainerCustomerSetting.vue";
import DivCoinInfo from "@/components/organisms/DivCoinInfo.vue";
import TableInvoiceInfo from "@/components/organisms/TableInvoiceInfo.vue";
import ModelTable from "@/views/model/ModelTable.vue";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";
import FormsCustomer from "@/forms/customer.js";

export default defineComponent({
  name: "PanelCustomer",

  components: {
    PanelHeader,
    ContainerCustomerBank,
    ContainerCustomerInfo,
    ContainerCustomerSetting,
    TableInvoiceInfo,
    DivCoinInfo,
    ModelTable,
  },

  props: {
    propsCustomerId: { type: String, require: true },
  },

  emits: ["cancel", "update"],

  setup(props, { emit }) {
    const customerId = ref(props.propsCustomerId);
    let formsFirst = reactive(settingDictionaryForms(FormsCustomer.forms));
    let formsSecond = reactive(
      settingDictionaryForms(FormsInvoiceAddress.forms)
    );
    let customerData = ref({});
    const panel = ref([]);
    const formKey = ref(createKey());

    const headers = ref([
      {
        text: "銀行コード",
        value: "bank_code",
        align: "start",
        sortable: false,
      },
      {
        text: "銀行名",
        value: "bank_name",
        align: "start",
        sortable: false,
      },
      {
        text: "支店コード",
        value: "branch_code",
        align: "start",
        sortable: false,
      },
      {
        text: "支店名",
        value: "branch_name",
        align: "start",
        sortable: false,
      },
      {
        text: "口座番号",
        value: "bank_account_number",
        align: "start",
        sortable: false,
      },
      {
        text: "預金者名",
        value: "bank_account_name",
        align: "start",
        sortable: false,
      },
      {
        text: "口座種別",
        value: "deposit_type",
        align: "start",
        sortable: false,
      },
    ]);
    const items = ref([]);

    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const isBrother = computed(() => {
      return store.state.auth.isBrother === true;
    });
    const accountBrotherId = computed(() => {
      return customerData.value["account_brother_id"];
    });

    const loadData = () => {
      api({
        method: "get",
        url: "/customer/?id=" + customerId.value,
      }).then((response) => {
        const res = response.data[0];
        customerData.value = res;
        // 契約者情報セット
        formsFirst = reactive(setResponseApplyFormsDict(res, formsFirst));
        // 請求先情報セット
        formsSecond = reactive(setResponseApplyFormsDict(res, formsSecond));

        items.value = [];
        items.value.push({
          bank_code: customerData.value["bank_code"],
          bank_name: customerData.value["bank_name"],
          branch_code: customerData.value["branch_code"],
          branch_name: customerData.value["branch_name"],
          bank_account_number: customerData.value["bank_account_number"],
          bank_account_name: customerData.value["bank_account_name"],
          deposit_type: getSelectValue(
            customerData.value["deposit_type"],
            setDepositType()
          ).text,
        });

        formKey.value = createKey();
      });
    };

    const cancelDialog = () => {
      emit("cancel");
    };

    const closeDialog = () => {
      emit("update");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      formsFirst,
      formsSecond,
      customerData,
      panel,
      formKey,
      customerId,
      headers,
      items,
      isStaff,
      isBrother,
      accountBrotherId,

      cancelDialog,
      closeDialog,
    };
  },
});
</script>
