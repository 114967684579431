import { getSelectObject } from "@/utils/selectUtility.js";
import { requiredRules, selectedRules } from "@/utils/ruleUtility.js";

const setting = {
  title: "インセンティブ",
  apiAddress: "/incentive/",
  listSortBy: ["incentive_class", "incentive_rate"],
  listSortDesc: [],
  isCsvImport: true,
};

const forms = [
  {
    column: "incentive_code",
    label: "インセンティブコード",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "incentive_name",
    label: "インセンティブ名",
    type: "text",
    rules: [requiredRules],
  },
  {
    column: "incentive_class",
    label: "区分",
    type: "select",
    items: getSelectObject("incentive_class"),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "incentive_rate",
    label: "レート",
    type: "number",
    rules: [requiredRules],
    cols: 6,
  },
];

const headers = [
  {
    text: "編集",
    value: "actions",
    align: "center",
    sortable: false,
  },
  {
    text: "インセンティブコード",
    value: "incentive_code",
  },
  {
    text: "タイプ",
    value: "incentive_class",
    align: "center",
  },
  {
    text: "インセンティブ名",
    value: "incentive_name",
  },
  {
    text: "レート",
    value: "incentive_rate",
    type: "number",
    align: "center",
  },
];

export default {
  setting,
  forms,
  headers,
};
