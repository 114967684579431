import { getDateObject } from "@/utils/utilities.js";
import { getSelectObject } from "@/utils/selectUtility.js";

const setting = {
  title: "支払管理",
  originalTitle: "支払公開",
  apiAddress: "/paymentgroup/",
  isListReadonly: true,
  isDeleteKey: "payment_is_not_release", // 削除可能データフラグ
  listSortBy: ["invoice_group_reference___invoice_deadline"],
  listSortDesc: [true],
  showExpand: true,
  expandType: "payment",
  createType: "payment",
};

const forms = [
  // 公開ハンドリングフォーム用
  {
    column: "payment_group_status",
    label: "ステータス",
    type: "radio",
    items: getSelectObject("payment_group_status"),
    rules: [],
  },
  // formsは[isPaymentCreate]フラグで[FormPaymentCreate]を呼び出している
];

const headers = [
  // 削除機能ナシで運用可能と思われる
  {
    text: "削除",
    value: "delete",
    align: "center",
    sortable: false,
    // [isDeleteKey]ハンドリング用
    handleKey: ["payment_is_not_release"],
  },
  {
    text: "公開",
    value: "handleItemPaymentGroup",
    align: "center",
    sortable: false,
    handleType: "paymentgroup",
    handleKey: ["payment_is_last", "payment_is_temp"],
  },
  {
    text: "ステータス",
    value: "payment_group_status",
    align: "center",
  },
  {
    text: "支払コード",
    value: "payment_group_code",
    align: "center",
  },
  {
    text: "締日",
    value: "invoice_group_reference___invoice_deadline",
    align: "center",
  },
  {
    text: "計算日",
    value: "payment_date",
    align: "center",
  },
  {
    text: "ブラザーカウント",
    value: "payment_header_count",
    type: "number",
    align: "center",
  },
  {
    text: "対象金額合計",
    value: "payment_subject_charge_sum",
    type: "number",
    align: "end",
  },
  {
    text: "支払金額合計",
    value: "payment_charge_sum",
    type: "number",
    align: "end",
  },
  {
    text: "支払税込金額合計",
    value: "payment_charge_including_tax_sum",
    type: "number",
    align: "end",
  },
  {
    text: "詳細",
    value: "data-table-expand",
    align: "center",
    sortable: false,
  },
];

const searchDateFrom = {
  label: "締日（以降）",
  column: "invoice_deadline",
  menu: false,
  default: getDateObject("first", -12),
  formula: "gte", // 以上
};

const searchSelect = {
  label: "ステータス",
  column: "payment_group_status",
  items: [
    { value: -1, text: "ALL" },
    { value: 0, text: "未公開" },
    { value: 50, text: "公開済" },
  ],
  value: -1,
};

export default {
  setting,
  forms,
  headers,
  searchDateFrom,
  searchSelect,
};
