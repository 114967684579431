<template>
  <v-tooltip top :disabled="tooltipText === ''">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="ishref"
        fab
        :small="!isXSmall"
        :x-small="isXSmall"
        elevation="0"
        color="mygenIcon"
        v-bind="attrs"
        v-on="on"
        :href="link"
        target="_blank"
      >
        <v-icon :size="iconSize" color="mygenText"> {{ icon }} </v-icon>
      </v-btn>
      <v-btn
        v-else
        fab
        :small="!isXSmall"
        :x-small="isXSmall"
        elevation="0"
        color="mygenIcon"
        v-bind="attrs"
        v-on="on"
        @click="click(param1, param2)"
      >
        <v-badge v-if="isDot" dot color="error">
          <v-icon :size="iconSize" color="mygenText"> {{ icon }} </v-icon>
        </v-badge>
        <v-badge v-else-if="isDotGreen" dot color="light-green accent-4">
          <v-icon :size="iconSize" color="mygenText"> {{ icon }} </v-icon>
        </v-badge>
        <v-icon v-else :size="iconSize" color="mygenText">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "ButtonFloating",

  props: {
    propsIsHref: { type: Boolean, default: false },
    propsClick: { type: Function, default: () => {} },
    propsColumn: { type: String, default: "" },
    propsParam1: { type: Object, default: () => {} },
    propsParam2: { type: String },
    propsLink: { type: String, default: "" },
    propsTooltipText: { type: String, default: "" },
    propsIcon: { type: String, default: "" },
    propsIsDot: { type: Boolean, default: false },
    propsIsDotGreen: { type: Boolean, default: false },
    propsIsXSmall: { type: Boolean, default: false },
  },

  setup(props) {
    const ishref = ref(props.propsIsHref);
    const click = ref(props.propsClick);
    const column = ref(props.propsColumn);
    const icon = ref(props.propsIcon);
    const link = ref(props.propsLink);
    const tooltipText = ref(props.propsTooltipText);
    const isDot = ref(props.propsIsDot);
    const isDotGreen = ref(props.propsIsDotGreen);
    const isXSmall = ref(props.propsIsXSmall);

    const param1 = computed(() => {
      if (column.value !== "") {
        return props.propsParam1[column.value];
      }
      return props.propsParam1;
    });
    const param2 = ref(props.propsParam2);

    const iconSize = computed(() => {
      if (isXSmall.value === true) {
        return 15;
      }
      return 22;
    });

    return {
      ishref,
      click,
      param1,
      param2,
      icon,
      link,
      tooltipText,
      isDot,
      isDotGreen,
      isXSmall,
      iconSize,
    };
  },
});
</script>
