var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',[_c('v-card',{class:'pa-5',attrs:{"width":"600px","rounded":"lg","flat":true}},[(_vm.isTitle)?_c('v-card-title',{staticClass:"mygenText--text text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]):_vm._e(),_c('v-card-text',_vm._l((_vm.cardComment),function(msg,key){return _c('div',{key:'comment-span-' + key},[_vm._v(" "+_vm._s(msg)+" ")])}),0),_c('v-card-text',[_c('v-form',{ref:"formModel"},[_c('v-container',[_c('v-row',_vm._l((_vm.forms),function(form,key){return _c('v-col',{key:key + '-' + _vm.formKey,attrs:{"cols":"12","sm":form.cols ? form.cols : 12}},[_c('FormControls',{attrs:{"propsMode":_vm.editMode,"propsForm":form}})],1)}),1)],1)],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-spacer'),(_vm.editMode === 'renew')?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ButtonCommon',{attrs:{"propsLabel":"変更","propsClick":_vm.saveData,"propsDisabled":_vm.buttonDisabled}})],1):_vm._e(),(
                  _vm.editMode === 'post' ||
                  _vm.editMode === 'put' ||
                  _vm.editMode === 'patch'
                )?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ButtonCommon',{attrs:{"propsLabel":"登録","propsClick":_vm.saveData,"propsDisabled":_vm.buttonDisabled}})],1):_vm._e(),(_vm.editMode === 'delete')?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ButtonCommon',{attrs:{"propsLabel":"削除","propsClick":_vm.deleteData}})],1):_vm._e(),(
                  _vm.editMode === 'readonly' ||
                  _vm.editMode === 'post' ||
                  _vm.editMode === 'put' ||
                  _vm.editMode === 'patch' ||
                  _vm.editMode === 'delete'
                )?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ButtonCommon',{attrs:{"propsColor":"secondary","propsLabel":_vm.formCancelButton,"propsOutlined":true,"propsClick":_vm.clickCancel}})],1):_vm._e(),_c('v-spacer')],1)],1)],1)],1)],1),_c('v-spacer'),(_vm.progress)?_c('OverlayProgress'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }