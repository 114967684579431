import { setIncentiveType } from "@/utils/selectUtility.js";

const setting = {
  title: "支払明細",
  apiAddress: "/paymentdetail/",
  isListReadonly: true,
  listSortBy: ["payment_detail_customer_name"],
  listSortDesc: [false],
};

const forms = [];

const headers = [
  {
    text: "お客様",
    value: "payment_detail_customer_name",
  },
  {
    text: "契約コード",
    value: "payment_detail_code",
  },
  {
    text: "区分",
    value: "payment_contract_class",
    type: "select",
    items: setIncentiveType(),
    align: "center",
  },
  {
    text: "対象",
    value: "payment_detail_class_display",
    align: "center",
  },
  {
    text: "月度",
    value: "monthly_label",
  },
  {
    text: "対象金額",
    value: "payment_detail_subject_charge",
    type: "number",
    align: "end",
  },
  {
    text: "レート",
    value: "incentive_rate_label",
    align: "end",
  },
  {
    text: "インセンティブ",
    value: "payment_detail_charge",
    type: "number",
    align: "end",
  },
];

export default {
  setting,
  forms,
  headers,
};
