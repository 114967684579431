import { setInvoiceType } from "@/utils/selectUtility.js";
import {
  selectedRules,
  requiredRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";

const setting = {
  title: "サイト設定",
  apiAddress: "/contractsite/",
};

const forms = [
  {
    column: "invoice_type",
    label: "請求単位",
    type: "select",
    items: setInvoiceType(),
    rules: [selectedRules],
    cols: 6,
  },
  {
    column: "space",
    type: "space",
    cols: 6,
  },
  {
    column: "site_address",
    label: "サイトアドレス",
    type: "text",
    rules: [requiredRules, charLength200Rules],
  },
  {
    column: "site_name",
    label: "サイト名称",
    type: "text",
    rules: [charLength200Rules],
  },
  {
    column: "staff_comment",
    label: "コメント",
    type: "text",
    rules: [charLength200Rules],
  },
];

export default {
  setting,
  forms,
};
